// @flow
import moment from 'moment';

import type {DateRange, DateRangeValue, DateString, IsoDate} from './types';

export const fromDateString = (s: DateString): string => ((s: any): string);

export const toDateString = (s: string): DateString => ((s: any): DateString);

export const momentFromDateString = (v: DateString): moment$Moment =>
  moment(fromDateString(v), 'YYYY-MM-DD');

export const dateStringFromMoment = (v: moment$Moment): DateString =>
  toDateString(v.format('YYYY-MM-DD'));

export const dateRangeFromValue = (v?: DateRangeValue): ?DateRange => {
  if (!v) {
    return null;
  }

  // we have to pack it and unpack it for flow to understand
  const {startDate, endDate} = v;
  if (startDate && endDate) {
    return {
      startDate,
      endDate,
    };
  }

  return null;
};

export const safelyDestructureDate = (v?: DateRangeValue): DateRangeValue => {
  if (!v)
    return {
      startDate: undefined,
      endDate: undefined,
    };

  return v;
};

export const getDaysApart = (a?: DateString, b?: DateString): ?number => {
  if (!a || !b) return undefined;

  return moment.utc(fromDateString(a)).diff(moment.utc(fromDateString(b)), 'days');
};

export const displayCancellationFee = (feeStart: IsoDate): boolean =>
  moment.utc().isSameOrAfter(feeStart);
