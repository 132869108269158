// @flow
import SectionFooter from 'components/SectionFooter';
import Page from 'pages/_Page';
import React from 'react';

import SectionTermsOfServices from './SectionTermsOfServices';

const TermsPage = () => (
  <Page>
    <SectionTermsOfServices />
    <SectionFooter />
  </Page>
);

export default TermsPage;
