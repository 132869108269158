// @flow
import {lighten} from 'global/theme';
import styled, {css} from 'styled-components';

export const ContextMenuWrap = styled.div`
  position: absolute;
  top: calc(100% + 0.8rem);
  ${p =>
    p.isRightAligned
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
  min-width: ${p => p.minWidth || 20}rem;
  max-height: ${p => p.maxHeight || 41}rem;

  z-index: ${props => props.theme.zIndices.contextMenu};
  overflow-y: scroll;

  background: ${props => props.theme.color.white};
  border-radius: 0.4rem;
  box-shadow: 0 3rem 5rem 0 ${props => lighten(props.theme.color.primaryText, 0.1)};
  border: solid 0.2rem ${props => props.theme.color.lightGray};

  ${p =>
    !p.isOpen &&
    css`
      display: none;
    `}
`;
