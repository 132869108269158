import styled from 'styled-components';

import {H2} from '../../componentsStyled/Typography';
import {Text} from '../../componentsStyled/Typography/Texts';

export const Heading = styled(H2)`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const ReturnConfirmationWrapper = styled.div`
  padding-top: 80px;
  text-align: center;
`;

export const Underline = styled.hr`
  border: 0;
  background: ${props => props.theme.color.green};
  height: 3px;
  width: 80px;
`;

export const FollowUpText = styled(Text)`
  margin: 40px 0;
`;
