// @flow
import type {Mutation} from 'common/graphql/types';
import gql from 'graphql-tag';

export const getRefreshTokenMutation: Mutation<
  {|
    email: string,
    refreshToken: string,
  |},
  string
> = {
  gql: gql`
    mutation getRefreshTokenMutation($email: LowercaseString!, $refreshToken: String!) {
      auth {
        getAccessToken(email: $email, refreshToken: $refreshToken) {
          accessToken
        }
      }
    }
  `,
  selector: ['auth', 'getAccessToken', 'accessToken'],
};

export const changePasswordMutation: Mutation<{|
  oldPassword: string,
  password: string,
|}> = {
  gql: gql`
    mutation changePasswordMutation($oldPassword: String!, $password: String!) {
      user {
        changePassword(oldPassword: $oldPassword, password: $password)
      }
    }
  `,
};
