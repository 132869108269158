// @flow
import Button from 'components/Button';
import {Text} from 'componentsStyled/Typography/Texts';
import urls from 'data/router/urls';
import withRouter from 'hoc/withRouter';
import React from 'react';
import type {HOC} from 'recompose';

import {EmptyStateWrap} from './styled';

const EmptyState = ({history}) => (
  <EmptyStateWrap>
    <Text>Nothing here!</Text>
    <Button onClick={() => history.push(urls.products)}>Reserve a product</Button>
  </EmptyStateWrap>
);

const enhancer: HOC<*, {||}> = withRouter;

export default enhancer(EmptyState);
