// @flow
import type {ID} from 'data/enums/types';

export const tryTrack = (name: string, getData: void => Object) => {
  try {
    const data = getData();
    window.analytics.track(name, data);
  } catch (e) {
    console.warn('failed analytics for ', name);
  }
};

export const tryIdentify = (id: ID, getData: void => Object) => {
  try {
    const data = getData();
    window.analytics.identify(id, data);
  } catch (e) {
    console.warn('failed analytics for identification');
  }
};
