// @flow
import {mq, mqMax} from 'common/mediaQuery';
import styled, {css} from 'styled-components';

export const FullWidthContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: ${p => p.theme.maxContentWidth};

  ${p =>
    p.center &&
    css`
      text-align: center;
    `}

  ${p =>
    p.narrow &&
    css`
      max-width: 59.2rem !important;
    `}
`;

export const HPaddedFullWidthContainer = styled(FullWidthContainer)`
  padding-left: 1.6rem;
  padding-right: 1.6rem;
`;

export const Container = styled(HPaddedFullWidthContainer)`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  ${p =>
    p.noPadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}

  ${mq.tabletWide} {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  ${p =>
    p.fixed &&
    css`
      position: fixed;
      overflow-y: scroll;
      height: 100%;
    `}
`;

export const VPaddedFullWidthContainer = styled(FullWidthContainer)`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  ${mqMax.phoneWide} {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  ${mq.tabletWide} {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
`;

export const HeightContainer = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  ${mq.tabletWide} {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
`;
