// @flow
import {chooseCategories, chooseGeolocation} from 'data/app/actions';
import {formatFilter} from 'data/app/formatters';
import type {Category, Location} from 'data/app/types';
import type {Manufacturer} from 'data/manufacturer/types';
import type {Product} from 'data/product/types';
import {clear, filter} from 'data/search/actions';
import {type SearchParams} from 'data/search/types';
import withRouter from 'hoc/withRouter';
import {isEmpty, isNil, omit, values} from 'ramda';
import React from 'react';
import {type HOC} from 'recompose';
import LocationFilter from 'searchFilters/FilterComponents/LocationFilter';
import DefaultFilter from 'searchFilters/Shared/DefaultFilter';
import DesktopFilter from 'searchFilters/Shared/DesktopFilter';

import {ClearButton, FilterWrapper, PrimaryFilters} from './styled';

type Props = {|
  geolocation: ?(Location[]),
  categories: ?(Category[]),
  clear: typeof clear,
  chooseGeolocation?: typeof chooseGeolocation,
  chooseCategories: typeof chooseCategories,
  params: SearchParams,
  filter: typeof filter,
  multipleBrands: boolean,
  filterOptions: {
    locationFilterOptions: Location[],
    categoryFilterOptions: Category[],
    brandFilterOptions: Manufacturer[],
    productFilterOptions: Product[],
  },
  filterOptionsLoading: {
    locationFilterOptionsLoading: boolean,
    categoryFilterOptionsLoading: boolean,
    brandFilterOptionsLoading: boolean,
    productFilterOptionsLoading: boolean,
  },
|};

const DesktopFilters = ({
  chooseGeolocation,
  geolocation,
  categories,
  chooseCategories,
  filter,
  clear,
  params,
  filterOptions,
  filterOptionsLoading,
}) => {
  const {locationFilterOptions, categoryFilterOptions, brandFilterOptions, productFilterOptions} =
    filterOptions;
  const {
    locationFilterOptionsLoading,
    categoryFilterOptionsLoading,
    brandFilterOptionsLoading,
    productFilterOptionsLoading,
  } = filterOptionsLoading;

  // Affiliate filter value is context from the affiliate page so it's not clearable
  const filterParams = omit(['affiliate'], params);
  const filterEmpty =
    isNil(filterParams) ||
    isEmpty(filterParams) ||
    values(filterParams).every(isNil) ||
    values(filterParams).every(isEmpty);

  return (
    <FilterWrapper>
      <PrimaryFilters>
        <DesktopFilter
          options={categoryFilterOptions}
          isLoading={categoryFilterOptionsLoading}
          value={categories}
          onChange={chooseCategories}
          component={DefaultFilter}
          formatter={formatFilter('Categories')}
          title="All Categories"
          idKey="categoryId"
          noXPadding
        />
        <DesktopFilter
          options={brandFilterOptions}
          isLoading={brandFilterOptionsLoading}
          value={params.brand}
          onChange={brand => filter({brand})}
          formatter={formatFilter('Brands')}
          component={DefaultFilter}
          title="All Brands"
          noXPadding
        />
        <DesktopFilter
          options={productFilterOptions}
          isLoading={productFilterOptionsLoading}
          value={params.product}
          onChange={product => filter({product})}
          formatter={formatFilter('Products')}
          component={DefaultFilter}
          title="All Products"
          noXPadding
        />
        {!filterEmpty && (
          <ClearButton primary onClick={clear}>
            Clear
          </ClearButton>
        )}
      </PrimaryFilters>
      {chooseGeolocation && (
        <DesktopFilter
          options={locationFilterOptions}
          isLoading={locationFilterOptionsLoading}
          value={geolocation}
          onChange={chooseGeolocation}
          component={LocationFilter}
          formatter={formatFilter('Locations')}
          title="All Locations"
          minWidth={28}
          isRightAligned
          noXPadding
        />
      )}
    </FilterWrapper>
  );
};

const enhancer: HOC<*, Props> = withRouter;

export default enhancer(DesktopFilters);
