// @flow
// Default font is always loaded
import 'assets/fonts/geomanist.css';

import {ConnectedRouter} from 'connected-react-router';
import apolloClient from 'global/apolloClient';
import config from 'global/config';
import history from 'global/history';
import store, {persistor} from 'global/store';
import React from 'react';
import {ApolloProvider} from 'react-apollo';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/es/integration/react';

import AppComponent from './AppComponent';

// Set the title for local development
// NOTE: On production it will be written directly into index.html
document.title = config.tenantTitle;

const MOUNT_NODE = document.getElementById('app');

if (MOUNT_NODE) {
  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history}>
            <AppComponent />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>,
    MOUNT_NODE
  );
}
