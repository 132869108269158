// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

import {align, color} from './enhancers';

export const FieldTitle = styled.h4`
  font-size: 1.3rem;
  color: ${props => props.theme.color.primaryText};
  margin: 0;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  display: ${p => (p.inline ? `inline` : `block`)};
`;

export const SubTitle = styled.h4`
  font-size: 1.8rem;
  color: ${props => props.theme.color.primaryText};
  line-height: 1.2;
  margin: ${p => (p.noMargin ? 0 : `1rem 0`)};

  ${color};
`;

export const Title = styled.h3`
  font-size: 2.6rem;
  font-weight: ${props => props.theme.fontWeights.ultra};
  color: ${props => props.theme.color.primaryText};
  line-height: 1;
  margin: 1rem 0;
  letter-spacing: 0.08rem;
  ${align};

  ${mq.tabletWide} {
    font-size: 4.8rem;
  }
`;

export const PageTitle = styled(Title)`
  position: relative;
  text-align: center;
  margin-top: 0;
  margin-bottom: 4rem;
`;
