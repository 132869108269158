// @flow
import styled, {css} from 'styled-components';

import smudge from './smudge.png';

export const SmudgeContainer = styled.div`
  position: relative;
  background: ${props => props.theme.color.light};
  padding-top: 3rem;
  padding-bottom: 3rem;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${smudge}) no-repeat;
    background-size: 100% auto;
    pointer-events: none;
  }

  &::after {
    transform: rotate(180deg);
  }

  ${p =>
    p.first &&
    css`
      &::before {
        display: none;
      }
    `}
`;
