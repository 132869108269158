// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import {formatTelNumber} from 'data/affiliate/formatters';
import type {AffiliateFull} from 'data/affiliate/types';
import React from 'react';

import {AffiliateLink, SectionTitle} from '../styled';

type Props = {|
  affiliate: AffiliateFull,
|};

const Contacts = ({affiliate}: Props) => (
  <React.Fragment>
    <SectionTitle>Contacts</SectionTitle>
    <Text lh={3}>
      <Text inline>Tel:</Text> &nbsp;
      <AffiliateLink to={`tel:${formatTelNumber(affiliate.phone)}`}>
        {affiliate.phone}
      </AffiliateLink>
    </Text>
    <Text lh={3}>
      <Text inline>Website:</Text> &nbsp;
      <AffiliateLink to={affiliate.website}>{affiliate.website}</AffiliateLink>
    </Text>
  </React.Fragment>
);

export default Contacts;
