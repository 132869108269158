import {Text} from 'componentsStyled/Typography';
import styled from 'styled-components';

export const OpeningHoursTableWrap = styled.div`
  margin-bottom: 3rem;
`;

export const Pair = styled.div`
  display: flex;
`;

export const Key = styled(Text)`
  width: 11rem;
  text-transform: capitalize;
`;

export const Val = styled(Text)`
  flex-grow: 1;
`;
