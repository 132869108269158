// @flow strict-local
import React from 'react';

import SettingContent from '../../SettingContent';
import UpdatePhoneNumberForm from './UpdatePhoneNumberForm';

const UpdatePhoneNumber = () => (
  <SettingContent title="Edit Phone Number">
    <UpdatePhoneNumberForm />
  </SettingContent>
);

export default UpdatePhoneNumber;
