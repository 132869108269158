// @flow
import type {DateString} from 'data/units/date/types';
import moment from 'moment';
import * as yup from 'yup';

import {requiredText} from './shared';
import type {Validator} from './types';

export const stringOptional: Validator<?string> = yup.string().nullable();

export const stringRequired: Validator<string> = stringOptional.required(requiredText);

export const emailOptional: Validator<?string> = stringOptional.email(
  'Must be correct email address'
);

export const emailRequired: Validator<string> = stringRequired.email(
  'Must be correct email address'
);

export const birthdayRequired: Validator<DateString> = stringRequired.test(
  'is-valid-format',
  'Birthday must have valid format',
  v => moment(v).isValid()
);

export const birthdayOptional: Validator<DateString> = yup.mixed(null, birthdayRequired);

export const minLengthRequired = (length: number): Validator<string> =>
  stringRequired.min(length, `Must be at least ${length} characters long`);

export const password: Validator<string> = minLengthRequired(8)
  .test('is-not-whitespace', 'Must contain non-empty characters', v => !/\s/.test(v))
  .test('contains-no-digit', 'Must contain at least 1 number', v => /\d/.test(v))
  .test('contains-no-letters', 'Must contain at least 1 letter', v => /[a-z]/i.test(v));

export const phoneNumber: Validator<string> = stringRequired.matches(/^[-0-9+() ]*$/, {
  message: 'Input must be a valid phone number format',
});

export const phoneNumberOptional: Validator<?string> = stringOptional.matches(/^[-0-9+() ]*$/, {
  message: 'Input must be a valid phone number format',
});
