// @flow
import {mq} from 'common/mediaQuery';
import styled, {css} from 'styled-components';

export const Wrap = styled.div`
  border-top: 0.1rem solid ${props => props.theme.color.light};
  padding: 2.4rem;
  background: ${props => props.theme.color.white};
  margin-bottom: 1rem;

  ${p =>
    p.stack &&
    css`
      margin-bottom: 0;
      border-top: 0;
    `}

  ${p =>
    p.bottom &&
    css`
      border-bottom: 0;
      margin-bottom: 0;
    `}
`;

export const WrapV = styled(Wrap)`
  padding: 2.4rem 0;
`;

export const WrapNoDesktop = styled(Wrap)`
  ${mq.tabletWide} {
    padding: 0;
    border: 0;
  }
`;

export const WrapGrouper = styled.div`
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
`;

export const Separator = styled.div`
  margin-bottom: 1.6rem;
  ${p =>
    p.includeLine &&
    css`
      border-top: 0.1rem solid ${props => props.theme.color.light};
    `}
`;
