// @flow
import CrossIcon from 'assets/icons/CrossIcon';
import {selectSearchParams} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import SearchIcon from './SearchIcon';
import {InputStyled, StyledForm} from './styled';

const Input = ({value, onChange, onClick, handleClear, isOpen, handleKeyDown, searchParams}) => {
  return (
    <StyledForm onKeyDown={handleKeyDown}>
      <SearchIcon />
      <InputStyled
        type="search"
        placeholder="Search for products"
        value={value}
        onChange={e => onChange(e.target.value)}
        onClick={onClick}
        bold={false}
      />
      {value && <CrossIcon onClick={handleClear} />}
    </StyledForm>
  );
};

type Outter = {|
  handleKeyDown: Function,
  value: ?string,
  onChange: (?string) => mixed,
  onClick: Function,
  close: Function,
  isOpen: boolean,
|};

const mapStateToProps = state => ({
  searchParams: selectSearchParams(state),
});

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps),
  withHandlers({
    handleClear: props => () => {
      props.onChange('');
      props.close();
    },
  })
);

export default enhancer(Input);
