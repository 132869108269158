// @flow
import * as React from 'react';

import {Iframe, Wrapper} from './styled';

type Props = {|
  source: string,
  title?: string,
  width?: string,
  height?: string,
|};

const YoutubeVideo = ({source, title = '', width = '560', height = '315'}: Props) => (
  <Wrapper>
    <Iframe
      title={title}
      width={width}
      height={height}
      src={source}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </Wrapper>
);

export default YoutubeVideo;
