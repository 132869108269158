// @flow
import {emailRequired, password, phoneNumber, stringRequired} from 'forms/validators/string';

export const schema = {
  firstName: stringRequired,
  lastName: stringRequired,
  email: emailRequired,
  password: password,
  phoneNumber: phoneNumber,
};
