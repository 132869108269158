// @flow
import Loader from 'components/Loader';
import {defaultTheme} from 'global/theme';
import React from 'react';
import {type HOC} from 'recompose';
import {ThemeProvider} from 'styled-components';

function withLoader<T>(predicate: T => boolean): HOC<T, T> {
  return Component => (props: T) => {
    function renderInner() {
      if (predicate(props)) {
        return <Loader />;
      } else {
        return <Component {...props} />;
      }
    }

    // NOTE(ray): In some cases it appears that `withLoader` is used higher up
    // in the tree than the theme provider in `AppComponent/Content`. As such, a
    // default theme provider must be set up here to ensure a theme is available
    // for descendent components.
    return <ThemeProvider theme={theme => theme || defaultTheme}>{renderInner()}</ThemeProvider>;
  };
}

export default withLoader;
