// @flow
import {center} from 'componentsStyled/Shared';
import styled, {css} from 'styled-components';

export const ImageSwitchIconicWrap = styled.div`
  position: absolute;
  top: 10rem;
  left: 4rem;
  overflow-y: auto;
  height: calc(100% - 15rem);
`;

export const ImageSwitchIconicTile = styled.div`
  width: 6rem;
  height: 6rem;
  transition: 0.25s ease border-color;
  position: relative;
  margin-bottom: 2rem;
  cursor: pointer;
  overflow: hidden;
  background: ${props => props.theme.color.white};
  border-radius: 0.4rem;
  background-color: ${props => props.theme.color.white};
  border: solid 0.1rem ${props => props.theme.color.lightGray};

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: ${props => props.theme.color.primaryAccent};
  }

  ${p =>
    p.selected &&
    css`
      cursor: default;

      &,
      &:hover {
        border-color: ${props => props.theme.color.primaryAccent};
      }
    `}
`;

export const StyledImage = styled.img`
  ${center};
  max-width: 90%;
  max-height: 90%;
`;
