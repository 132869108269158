// @flow
import type {FulfillmentType} from 'data/bookings/types';
import type {ID} from 'data/enums/types';
import {productAvailabilityQuery} from 'data/product/graphql/queries';
import type {ProductVariantAvailableAffiliate} from 'data/product/types';
import {setAvailability} from 'data/reservations/actions';
import {dayStatus} from 'data/reservations/constants';
import {selectAvailability} from 'data/reservations/selectors';
import {dateStringFromMoment, momentFromDateString} from 'data/units/date/helpers';
import type {DateRange, DateRangeValue} from 'data/units/date/types';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import moment from 'moment';
import {isEmpty, path, values} from 'ramda';
import React from 'react';
import {type HOC, compose, lifecycle, withStateHandlers} from 'recompose';

import ErrorMessage, {type Error as ErrorType} from './ErrorMessage';
import Help from './Help';
import {getCurrentMonth, getNextMonth} from './helpers';
import Month from './Month';
import PrevNextArrows from './PrevNextArrows';
import {CalendarWrap, MonthsWrap} from './styled';

const Calendar = ({
  availability,
  closeModal,
  onChange,
  onError,
  errorMessage,
  value,
  data,
  offset,
  prev,
  next,
  variantAffiliate,
  originalReservation,
  clear,
  showHelpText = true,
}) => {
  const currentDate =
    data && data.affiliateCurrentDate ? moment(data.affiliateCurrentDate) : moment();

  const availabilityDates = availability.length
    ? availability.map(dateRange => ({
        startDate: dateRange.startDate && moment(dateRange.startDate),
        endDate: dateRange.endDate && moment(dateRange.endDate),
      }))
    : availability;

  const closedDays = data && data.closedDays;

  const originalReservationDates = originalReservation && {
    startDate: originalReservation.startDate && moment(originalReservation.startDate),
    endDate: originalReservation.endDate && moment(originalReservation.endDate),
  };

  const valueMoment = {
    startDate: value && value.startDate && moment(value.startDate),
    endDate: value && value.endDate && moment(value.endDate),
  };

  const month = getCurrentMonth(offset);
  const nextMonth = getNextMonth(offset);

  return (
    <CalendarWrap>
      {showHelpText && <Help value={value} originalReservation={originalReservation} />}
      {errorMessage && <ErrorMessage error={errorMessage} closeModal={closeModal} />}
      <PrevNextArrows prev={prev} next={next} offset={offset} />
      <MonthsWrap>
        <Month
          availabilityDates={availabilityDates}
          value={valueMoment}
          closedDays={closedDays}
          onError={onError}
          onChange={onChange}
          variantAffiliate={variantAffiliate}
          month={month}
          originalReservation={originalReservationDates}
          currentDate={currentDate}
          clear={clear}
        />
        <Month
          availabilityDates={availabilityDates}
          value={valueMoment}
          closedDays={closedDays}
          onError={onError}
          onChange={onChange}
          variantAffiliate={variantAffiliate}
          month={nextMonth}
          originalReservation={originalReservationDates}
          currentDate={currentDate}
          clear={clear}
        />
      </MonthsWrap>
    </CalendarWrap>
  );
};

type Outter = {|
  closeModal: () => void,
  value?: DateRangeValue,
  onError?: ErrorType => mixed,
  errorMessage?: ErrorType,
  onChange: (?DateRangeValue) => mixed,
  variantAffiliate?: ProductVariantAvailableAffiliate,
  availabilityVariables?: {|
    productVariantIds: ID[],
    affiliateId: ID,
    fulfillmentType?: FulfillmentType,
  |},
  originalReservation: {
    ...$Exact<DateRange>,
    resId: number,
  },
  clear: Function,
  showHelpText?: boolean,
|};

const mapStateToProps = state => ({
  availability: selectAvailability(state),
});

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps, {setAvailability}),
  withStateHandlers(
    {
      offset: 0,
    },
    {
      next:
        ({offset}) =>
        () => ({offset: offset + 1}),
      prev:
        ({offset}) =>
        () => ({offset: offset - 1}),
      setOffset:
        ({offset}) =>
        v => ({offset: v}),
    }
  ),
  withQuery(productAvailabilityQuery, {
    variables: props => {
      if (!props.availabilityVariables) {
        throw new Error('impossible due to skip');
      }

      //First day of the 2 visible months
      const visibleStartOfMonth = dateStringFromMoment(
        moment().add(props.offset, 'months').startOf('month')
      );

      //Last day of the 2 visible months
      const visibleEndOfMonth = dateStringFromMoment(
        moment()
          .add(props.offset + 1, 'months')
          .endOf('month')
      );

      const selectedStartDate = path(['value', 'startDate'], props);

      let dateRange;
      if (props.originalReservation) {
        // If the calendar is being rendered with an original reservation, then we must be trying to extend that
        // reservation. If so, only fetch the availability from the day after the end of the current reservation as
        // that's all we're interested in.
        dateRange = {
          start: dateStringFromMoment(
            momentFromDateString(props.originalReservation.endDate).add(1, 'day')
          ),
          end: visibleEndOfMonth,
        };
      } else {
        //If the user has selected a start date on a date prior to what is currently
        //visible on the calendar, then we must fetch availability dates from that date.
        //Otherwise it's sufficient to just fetch for the visible dates.
        dateRange = {
          start:
            selectedStartDate && selectedStartDate < visibleStartOfMonth
              ? selectedStartDate
              : visibleStartOfMonth,
          end: visibleEndOfMonth,
        };
      }

      const affiliateId = parseInt(path(['availabilityVariables', 'affiliateId'], props), 10);
      return {
        variantsAvailabilityFilter: {
          ...props.availabilityVariables,
          ...dateRange,
          trimClosedFromStart: !props.originalReservation,
          availabilityForExtension: props.originalReservation
            ? props.originalReservation.resId
            : null,
        },
        closedDaysFilter: {
          affiliateId,
          ...dateRange,
        },
        affiliateId,
      };
    },
    config: {
      skip: props => !props.availabilityVariables,
    },
    noEmpty: true,
    noLoader: true,
  }),

  lifecycle({
    componentDidMount() {
      const {value, setOffset} = this.props;

      if (path(['endDate'], value)) {
        setOffset(moment.utc(value.endDate).diff(moment.utc(), 'months', false));
      }
    },
    componentDidUpdate(prevProps) {
      const {data, value, onChange, onError, setAvailability, loading, availability} = this.props;
      const newAvailability = path(['availability'], data);

      if (
        !loading &&
        newAvailability &&
        !isEmpty(newAvailability) &&
        JSON.stringify(availability) !== JSON.stringify(newAvailability)
      ) {
        setAvailability(newAvailability);
      }

      // Closed day selected in Calendar Filter
      const closedDays = data && data.closedDays;

      if (value && closedDays) {
        const dates = values(value);

        const fallsOnClosedDay = closedDays.some(closedDay => dates.includes(closedDay));

        if (fallsOnClosedDay) {
          onChange({
            startDate: undefined,
            endDate: undefined,
          });
          onError(dayStatus.closedSelected);
        }
      }
    },
  })
);

export default enhancer(Calendar);
