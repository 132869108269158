// @flow
import React from 'react';

const TipIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
    <g fill="none" fillRule="evenodd" transform="translate(0 -1)">
      <circle cx="8" cy="9" r="8" fill="#D8D8D8" fillRule="nonzero" />
      <text fill="#FFF" fontFamily="Geomanist-Book, Geomanist" fontSize="14" fontWeight="300">
        <tspan x="5.175" y="14">
          ?
        </tspan>
      </text>
    </g>
  </svg>
);

export default TipIcon;
