// @flow
import type {Selector} from 'common/redux/types';
import {compose, prop} from 'ramda';

import type {BasketState} from './reducer';
import type {PendingReservation} from './types';

const base: Selector<BasketState> = prop('basket');

export const selectBasketItems: Selector<Array<PendingReservation>> = compose(prop('items'), base);
