// @flow
import LogoLink from 'components/LogoLink';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import {Container} from 'componentsStyled/Layout/Containers';
import * as React from 'react';

import {HeaderWrap, PageContent} from '../styled';
import {ContentWrap, LogoWrap} from './styled';

type Props = {|
  children: React.Node,
  fixed?: boolean,
|};

const SpecialPage = ({title, backUrl, children, fixed}: Props) => (
  <React.Fragment>
    <Mobile>
      <Container noPadding fixed={fixed}>
        {children}
      </Container>
    </Mobile>
    <Desktop>
      <HeaderWrap>
        <LogoWrap>
          <LogoLink type="default" />
        </LogoWrap>
      </HeaderWrap>
      <PageContent>
        <ContentWrap>{children}</ContentWrap>
      </PageContent>
    </Desktop>
  </React.Fragment>
);

export default SpecialPage;
