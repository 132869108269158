import Description from 'components/Description';
import {H3} from 'componentsStyled/Typography';
import styled from 'styled-components';

export const HeadingWrap = styled.p`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 1.6rem;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.color.lightGray};
`;

export const BagAffiliateGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

export const DeliveryExplanationWrapper = styled.div`
  margin-top: 2rem;
  border-bottom: 1px solid ${props => props.theme.color.lightGray};

  ${H3} {
    font-weight: normal;
  }
`;

export const DeliveryExplanationContent = styled(Description)`
  &.ql-editor {
    padding: 0 0 1rem 0;
  }
`;
