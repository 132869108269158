// @flow
import type {FeetInch, InchFraction, MetersCentimeters} from './types';

/* Inc Transform - Methods */
export const mmToMtCm = (mm: number): MetersCentimeters => {
  if (isNaN(mm)) return {meters: 0, centimeters: 0};

  const meters = Math.floor(mm / 1000);
  const centimeters = Math.round((mm / 1000 - meters) * 100);

  return {meters, centimeters};
};

export const mmToFeetInch = (mm: number): FeetInch => {
  if (isNaN(mm)) return {feet: 0, inches: 0};

  const feet = Math.floor(mm / 304.8);
  const inches = Math.round(((mm / 304.8) % 1) * 12);

  // because of decimal precision or rounding there there can be 12 inches which is 1 feet
  if (inches === 12) {
    return {
      feet: feet + 1,
      inches: 0,
    };
  }
  return {feet, inches};
};

/* Out Transform - Methods */
export const mtCmToMm = (meters: number, centimeters: number) => {
  if (centimeters > 99) return 0;
  return meters * 1000 + centimeters * 10;
};

export const imperialToMm = (feet: number, inches: number) => {
  if (inches > 11) return 0;
  return feet * 304.8 + inches * 25.4;
};

// source https://stackoverflow.com/questions/38891250/convert-to-fraction-inches
export const mmToInchFractions = (mm: number): InchFraction => {
  const inches = mm / 25.4;

  const integer = Math.floor(inches);
  let denominator = 16;
  let numerator = Math.round((inches % 1) * denominator);

  while (numerator % 2 === 0 && denominator % 2 === 0) {
    numerator /= 2;
    denominator /= 2;
  }

  return {
    integer,
    numerator,
    denominator,
  };
};
