// @flow
import urls from 'data/router/urls';
import React from 'react';

import {FooterLinksContainer, Links, PoweredWrap, StyledNavLink as NavLink} from './styled';

type Props = {|
  // None
|};

const FooterLinks = (props: Props) => (
  <FooterLinksContainer>
    <Links>
      <NavLink secondary to={urls.terms}>
        Terms
      </NavLink>
      <NavLink secondary to={urls.privacy}>
        Privacy
      </NavLink>
    </Links>
    <PoweredWrap>
      <NavLink secondary to={urls.awayco}>
        Powered by Awayco
      </NavLink>
    </PoweredWrap>
  </FooterLinksContainer>
);

export default FooterLinks;
