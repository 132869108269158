// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

export const MonthWrap = styled.div`
  min-width: 25rem;

  ${mq.tabletWide} {
    width: 50%;
  }
`;

export const StyledMonth = styled.div`
  margin-bottom: 2.5rem;
  font-size: 1.4rem;
  letter-spacing: 0.05rem;
  font-weight: ${props => props.theme.fontWeights.book};
  color: ${props => props.theme.color.primaryText};
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
`;

export const WeekDay = styled.div`
  font-weight: ${props => props.theme.fontWeights.book};
  color: ${props => props.theme.color.secondaryText};
  font-size: 1.3rem;
  padding-bottom: 1rem;
  text-align: center;
  width: calc(100% / 7);
`;

export const EmptyDay = styled.div`
  width: calc(100% / 7);
`;
