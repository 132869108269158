// @flow
import CrossIcon from 'assets/icons/CrossIcon';
import {selectAppConfig} from 'data/app/selectors';
import {selectBasketItems} from 'data/basket/selectors';
import {openModal} from 'data/modals/actions';
import {reservationCountQuery} from 'data/reservations/graphql/queries';
import urls from 'data/router/urls';
import {logout} from 'data/user/actions';
import {type User} from 'data/user/types';
import {isPatagonia} from 'global/config';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import Login from 'modals/Login';
import {isNil} from 'ramda';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {CloseButton, ExternalLink, Header, Link, Links, SidebarWrap} from './styled';

const Sidebar = ({appConfig, bagItems, isOpen, close, user, login, logout}) => (
  <SidebarWrap isOpen={isOpen}>
    <Header>
      {appConfig && isPatagonia(appConfig.tenantName) ? (
        <a href="https://www.patagonia.com">
          <img
            src="https://uploads-ssl.webflow.com/6025bbd56886f21dc6bd1dc9/605d2253f68691e4b55ffed6_Patagonia-white.png"
            alt={'Patagonia'}
            height="16"
          />
        </a>
      ) : (
        <div>&nbsp;</div>
      )}
      <CloseButton onClick={close}>
        <CrossIcon color="current" />
      </CloseButton>
    </Header>
    {appConfig && isPatagonia(appConfig.tenantName) && (
      <Links>
        <ExternalLink to="https://rent.patagonia.com" onClick={close}>
          Rental
        </ExternalLink>
        <ExternalLink
          to={
            '/products?product_type=trail-mens&product_type=alpine-mens&product_type=fish-mens&product_type=seasonal-mens'
          }
          onClick={close}
        >
          Men's
        </ExternalLink>
        <ExternalLink
          to={
            '/products?product_type=trail-womens&product_type=alpine-womens&product_type=fish-womens&product_type=seasonal-womens'
          }
          onClick={close}
        >
          Women's
        </ExternalLink>
        <ExternalLink to={'/products?product_type=packs-and-gear'} onClick={close}>
          Packs &amp; Gear
        </ExternalLink>
      </Links>
    )}
    {user ? (
      <Links>
        <Link exact to={urls.home} onClick={close}>
          Home
        </Link>
        <Link to={urls.shoppingBasket} onClick={close}>
          <BagLinkLabel bagItems={bagItems} />
        </Link>
        <Link to={urls.reservations} onClick={close}>
          <ReservationsLinkLabel />
        </Link>
        <Link to={urls.account.index} onClick={close}>
          Your Account
        </Link>
        <Link onClick={logout}>Logout</Link>
      </Links>
    ) : (
      <Links>
        <Link exact to={urls.home} onClick={close}>
          Home
        </Link>
        <Link to={urls.shoppingBasket} onClick={close}>
          <BagLinkLabel bagItems={bagItems} />
        </Link>
        <Link primary onClick={login}>
          Log in
        </Link>
      </Links>
    )}
  </SidebarWrap>
);

const reservationsLinkLabelEnhancer: HOC<*, {}> = withQuery(reservationCountQuery, {
  noEmpty: true,
  noLoader: true,
});

const ReservationsLinkLabel = reservationsLinkLabelEnhancer(({data}) => {
  return <span>Your Reservations&nbsp;{isNil(data) ? '' : `(${data})`}</span>;
});

const BagLinkLabel = ({bagItems}) => {
  return <span>Your Cart&nbsp;{bagItems.length > 0 ? `(${bagItems.length})` : ''}</span>;
};

type Outter = {|
  isOpen: boolean,
  close: Function,
  user?: User,
|};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
  bagItems: selectBasketItems(state),
});

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps, {openModal, logout}),
  withHandlers({
    login: props => () => props.openModal(Login, {}),
  })
);

export default enhancer(Sidebar);
