// @flow
import {trackCategory} from 'analytics/app/analyticsTraits';
import ContextMenu from 'components/ContextMenu';
import Loader from 'components/Loader';
import OverlayWrapper from 'components/OverlayWrapper';
import {selectActivity} from 'data/app/selectors';
import withConnect from 'hoc/withConnect';
import withOpen from 'hoc/withOpen';
import withUser from 'hoc/withUser';
import {uniqBy} from 'ramda';
import * as React from 'react';
import {
  type Component,
  type HOC,
  compose,
  withHandlers,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose';

import FilterInput from '../FilterInput';
import DesktopFilterControls from './DesktopFilterControls';
import {DesktopFilterContent, DesktopRelativePositionWrap} from './styled';

const DesktopFilter = ({
  isOpen,
  open,
  handleClose,
  value,
  idKey = 'id',
  formatter,
  handleChange,
  handleClear,
  localValue,
  handleApply,
  component: Component,
  title,
  noControls,
  noXPadding,
  minWidth,
  maxHeight,
  flexDirection,
  closeOnChange,
  isRightAligned,
  options,
  isLoading,
}) => {
  const optionsAndSelections = uniqBy(
    option => option[idKey],
    [...(options || []), ...(value || [])]
  );

  if (optionsAndSelections.length <= 1) {
    return null;
  }

  return (
    <OverlayWrapper isOpen={isOpen} close={handleClose}>
      <FilterInput placeholder={title} value={value} formatter={formatter} onClick={open} />
      <ContextMenu
        isOpen={isOpen}
        close={handleClose}
        maxHeight={maxHeight}
        isRightAligned={isRightAligned}
      >
        <DesktopRelativePositionWrap>
          <DesktopFilterContent
            noXPadding={noXPadding}
            minWidth={minWidth}
            flexDirection={flexDirection}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <Component
                idKey={idKey}
                options={options}
                value={noControls ? value : localValue}
                onChange={handleChange}
              />
            )}
          </DesktopFilterContent>
          {!noControls && (
            <DesktopFilterControls
              hasValue={!!localValue || !!value}
              clear={handleClear}
              apply={handleApply}
              closeOnChange={closeOnChange}
            />
          )}
        </DesktopRelativePositionWrap>
      </ContextMenu>
    </OverlayWrapper>
  );
};

const mapStateToProps = state => ({
  activity: selectActivity(state),
});

type Outter = {|
  onChange: Function,
  formatter: Function,
  component: Component<{|
    value: any,
    onChange: Function,
    options: ?(any[]),
    idKey?: string,
  |}>,
  value: any,
  idKey?: string,
  title: string,
  noControls?: boolean,
  closeOnChange?: boolean,
  noXPadding?: boolean,
  minWidth?: number,
  maxHeight?: string,
  flexDirection?: string,
  isRightAligned?: boolean,
  options: ?(any[]),
  isLoading?: boolean,
|};

const outterEnhancer: HOC<*, Outter> = compose(withConnect(mapStateToProps, {}), withUser());

const innerEnhancer: HOC<*, *> = compose(
  withOpen,
  withStateHandlers(
    props => ({
      localValue: props.value,
    }),
    {
      setLocalValue: () => v => ({localValue: v}),
    }
  ),
  withHandlers({
    handleChange: props => v => {
      // Track category
      trackCategory(v, null);

      if (props.activity === v) {
        props.close();
        return;
      }

      if (props.noControls || props.closeOnChange) {
        props.close();
        return props.onChange(v);
      }

      return props.setLocalValue(v);
    },
    handleClear: props => () => {
      props.close();
      props.onChange(undefined);
      props.setLocalValue(undefined);
    },
    handleApply: props => v => {
      props.onChange(props.localValue);
      props.close();
    },
    handleClose: props => v => {
      props.close();
      props.setLocalValue(props.value);
    },
  }),
  withPropsOnChange(['value'], props => {
    props.setLocalValue(props.value);
  })
);

export const EnhancedDesktopFilter = innerEnhancer(DesktopFilter);

export default outterEnhancer(EnhancedDesktopFilter);
