// @flow
import ExploreButton from 'components/ExploreButton';
import React from 'react';

import HeroSection from './HeroSection';
import {ButtonWrap, Content, Hero, Inner, StyledText as Text} from './styled';

const SectionHero = () => (
  <HeroSection>
    <Inner>
      <Content>
        <Hero white>
          The best gear. The best places.
          <Text white lowercase>
            Awayco lets you rent premium equipment around the world so you can live free and travel
            light.
          </Text>
        </Hero>
        <ButtonWrap>
          <ExploreButton text="Find your gear" fullwidth />
        </ButtonWrap>
      </Content>
    </Inner>
  </HeroSection>
);

export default SectionHero;
