// @flow
import {chooseCategories, chooseGeolocation} from 'data/app/actions';
import {selectAppConfig, selectCategories, selectGeolocation} from 'data/app/selectors';
import {openModal} from 'data/modals/actions';
import {clear, filter} from 'data/search/actions';
import {selectSearchParams} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import ModalBody from 'modals/_Body';
import ModalContent from 'modals/_Content';
import FilterPanel from 'modals/FiltersModal/FilterPanel';
import {
  FilterModalCloseButton,
  FilterModalHeader,
  FilterModalTitle,
} from 'modals/FiltersModal/styled';
import {
  withBrandFilterDataForMobile,
  withCategoryFilterDataForMobile,
  withLocationFilterDataForMobile,
  withProductFilterDataForMobile,
} from 'pages/_Page/MainFilter/queryHOCs/mobile';
import * as React from 'react';
// $ReactHooks
import {useEffect, useMemo, useState} from 'react';
import type {HOC} from 'recompose';
import {compose, withState} from 'recompose';
import LocationFilter from 'searchFilters/FilterComponents/LocationFilter';
import DefaultFilter from 'searchFilters/Shared/DefaultFilter';
import DesktopFilterControls from 'searchFilters/Shared/DesktopFilter/DesktopFilterControls';

export const FilterPanelsContext = React.createContext({
  expandedPanelKey: null,
  setExpandedPanelKey: () => {},
});

const FiltersModal = ({
  close,
  chooseGeolocation,
  chooseCategories,
  filter,
  breakpoint,
  internalLocations,
  internalCategories,
  internalBrands,
  internalProducts,
  setInternalLocations,
  setInternalCategories,
  setInternalBrands,
  setInternalProducts,
  locationFilterOptions,
  categoryFilterOptions,
  brandFilterOptions,
  productFilterOptions,
  locationFilterOptionsLoading,
  categoryFilterOptionsLoading,
  brandFilterOptionsLoading,
  productFilterOptionsLoading,
  params,
}) => {
  const [expandedPanelKey, setExpandedPanelKey] = useState();
  const {affiliate} = params;

  const clear = () => {
    chooseGeolocation && chooseGeolocation(undefined);
    chooseCategories(undefined);
    filter({product: undefined, brand: undefined});
    close();
  };

  const apply = () => {
    chooseGeolocation && chooseGeolocation(internalLocations);
    chooseCategories(internalCategories);
    filter({product: internalProducts, brand: internalBrands});
    close();
  };

  const hasValue = useMemo(() => {
    const hasValueSelected = values => values && values.length > 0;
    return (
      hasValueSelected(internalLocations) ||
      hasValueSelected(internalCategories) ||
      hasValueSelected(internalBrands) ||
      hasValueSelected(internalProducts)
    );
  }, [internalLocations, internalCategories, internalBrands, internalProducts]);

  useEffect(() => {
    const closeIfAboveBreakpoint = () => {
      if (window.innerWidth > breakpoint) {
        close();
      }
    };
    window.addEventListener('resize', closeIfAboveBreakpoint);
    return () => window.removeEventListener('resize', closeIfAboveBreakpoint);
  }, [breakpoint, close]);

  return (
    <ModalBody fullscreen>
      <FilterModalHeader>
        <FilterModalCloseButton onClick={close} />
        <FilterModalTitle>Filters</FilterModalTitle>
      </FilterModalHeader>
      <ModalContent>
        <FilterPanelsContext.Provider value={{expandedPanelKey, setExpandedPanelKey}}>
          {!affiliate && (
            <FilterPanel
              panelKey="location"
              title="Location"
              options={locationFilterOptions}
              value={internalLocations}
              loading={locationFilterOptionsLoading}
            >
              <LocationFilter
                options={locationFilterOptions}
                value={internalLocations}
                onChange={setInternalLocations}
              />
            </FilterPanel>
          )}
          <FilterPanel
            panelKey="category"
            title="Category"
            plural="Categories"
            options={categoryFilterOptions}
            value={internalCategories}
            loading={categoryFilterOptionsLoading}
          >
            <DefaultFilter
              idKey={'categoryId'}
              options={categoryFilterOptions}
              value={internalCategories}
              onChange={setInternalCategories}
            />
          </FilterPanel>
          <FilterPanel
            panelKey="brand"
            title="Brand"
            options={brandFilterOptions}
            value={internalBrands}
            loading={brandFilterOptionsLoading}
          >
            <DefaultFilter
              options={brandFilterOptions}
              value={internalBrands}
              onChange={setInternalBrands}
            />
          </FilterPanel>
          <FilterPanel
            panelKey="product"
            title="Product"
            options={productFilterOptions}
            value={internalProducts}
            loading={productFilterOptionsLoading}
          >
            <DefaultFilter
              options={productFilterOptions}
              value={internalProducts}
              onChange={setInternalProducts}
            />
          </FilterPanel>
        </FilterPanelsContext.Provider>
      </ModalContent>
      <DesktopFilterControls apply={apply} clear={clear} hasValue={hasValue} />
    </ModalBody>
  );
};

type Outter = {
  close: Function,
  breakpoint: number,
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
  params: selectSearchParams(state),
  geolocation: selectGeolocation(state),
  categories: selectCategories(state),
});

const mapDispatchToProps = {
  filter,
  clear,
  chooseGeolocation,
  chooseCategories,
  openModal,
};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withState('internalLocations', 'setInternalLocations', props => props.geolocation),
  withState('internalCategories', 'setInternalCategories', props => props.categories),
  withState('internalBrands', 'setInternalBrands', props => props.params.brand),
  withState('internalProducts', 'setInternalProducts', props => props.params.product),
  withLocationFilterDataForMobile,
  withCategoryFilterDataForMobile,
  withBrandFilterDataForMobile,
  withProductFilterDataForMobile
);

export default enhancer(FiltersModal);
