// @flow
import {lighten} from 'global/theme';
import styled, {css} from 'styled-components';

const sharedStyle = css`
  border: solid 0.1rem ${props => props.theme.color.darkGray};
  color: ${props => props.theme.color.secondaryText};
  background: ${props => props.theme.color.white};
  font-size: 1.6rem;
  font-weight: ${props => props.theme.fontWeights.normal};
  width: 100%;
  outline: none;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  position: relative;

  &:focus {
    border-color: ${props => props.theme.color.primaryAccent};
    z-index: ${props => props.theme.zIndices.minimal};
  }

  &::placeholder {
    color: ${props => lighten(props.theme.color.secondaryText, 0.5)};
  }

  ::-webkit-search-cancel-button,
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${p =>
    p.left &&
    css`
      width: 50%;
      border-radius: 0.4rem 0 0 0.4rem;
      margin-right: -0.2rem;
    `}
  ${p =>
    p.right &&
    css`
      width: 50%;
      border-radius: 0 0.4rem 0.4rem 0;
    `}
`;

export const StyledTextarea = styled.textarea`
  ${sharedStyle}
  padding: 1.4rem 1.7rem;
  min-height: 10rem;
`;

export const StyledInput = styled.input`
  ${sharedStyle}
  padding: 0 1.7rem;
  height: 5rem;
  border-radius: 0.4rem;
`;
