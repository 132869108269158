// @flow
import {map} from 'ramda';

export const breakpoints = {
  tablet: 400,
  phone: 450,
  phoneWide: 600,
  tabletWide: 880,
  laptop: 1024,
  desktop: 1440,
};

export const mq = map(value => `@media (min-width: ${value}px)`, breakpoints);

export const mqMax = map(value => `@media (max-width: ${value}px)`, breakpoints);
