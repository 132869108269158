// @flow strict-local
import {getRentalAgreementForClient} from 'common/functions';
import Bag from 'components/Bag';
import BagWarning from 'components/BagWarning';
import Button from 'components/Button';
import CollapsibleParagraph from 'components/CollapsibleParagraph';
import RelatedProducts from 'components/RelatedProducts';
import {Space} from 'componentsStyled/Layout/Spacers';
import {WrapGrouper, WrapV} from 'componentsStyled/Layout/Wrap';
import {Text} from 'componentsStyled/Typography/Texts';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import {push} from 'connected-react-router';
import {selectAppConfig} from 'data/app/selectors';
import {clearBasket} from 'data/basket/actions';
import {getUniqueCurrencies} from 'data/basket/helpers';
import {selectBasketItems} from 'data/basket/selectors';
import {openModal} from 'data/modals/actions';
import urls from 'data/router/urls';
import withConnect from 'hoc/withConnect';
import withUser from 'hoc/withUser';
import SignUpModal from 'modals/SignUp';
import Page from 'pages/_Page';
import {StyledContainer} from 'pages/ShoppingBasket/styled';
import React from 'react';
import {type HOC, compose, withHandlers, withState} from 'recompose';

import Confirmation from './Confirmation';

const alertUntickedConditions = () => {
  alert('To proceed with the checkout, please read and then accept the Rental Agreement');
};

const ShoppingBasketPage = ({
  items,
  onClearBasket,
  onContinueShopping,
  onGoToCheckout,
  loading,
  appConfig,
  acceptedTermsAndConditions,
  toggleTermsAndConditions,
  user,
}) => {
  const hasMultipleCurrencies = getUniqueCurrencies(items).length > 1;

  // TODO(Jude): adjust related component algorithm to show related products
  // of multiple products. For now just using the first element in items
  // TODO(Jude): Handle multiple categories
  const firstProduct = items.length > 0 ? items[0].productVariant.product : null;
  const relatedProductsComponent = firstProduct ? (
    <RelatedProducts
      categoryName={firstProduct.categories[0].name}
      productId={firstProduct.id}
      isInsideBasket
      maxTilesPerRow={2}
    />
  ) : null;

  return (
    <Page>
      <StyledContainer narrow>
        <PageTitle>Your Cart</PageTitle>
        <BagWarning items={items} />
        <Bag
          canDelete={true}
          showCurrencyCode={hasMultipleCurrencies}
          excludeSummary={hasMultipleCurrencies}
        />
        <Space />
        <WrapGrouper>
          <CollapsibleParagraph group="Rental Agreement">
            <WrapV>
              <Text>{getRentalAgreementForClient(appConfig.tenantTitle)}</Text>
            </WrapV>
          </CollapsibleParagraph>
        </WrapGrouper>
        <Confirmation
          hasTermsAndConditions={acceptedTermsAndConditions}
          toggleTermsAndConditions={toggleTermsAndConditions}
        />
        <Space />
        <Button
          fullwidth
          onClick={acceptedTermsAndConditions ? onGoToCheckout : alertUntickedConditions}
          disabled={
            items.length === 0 || loading || !acceptedTermsAndConditions || hasMultipleCurrencies
          }
          data-cy={'basket-checkout'}
        >
          {!!user ? 'Checkout' : 'Create account'}
        </Button>
        <Space />
        <Button fullwidth secondary onClick={onContinueShopping}>
          Continue Shopping
        </Button>
        <Space />
        <WrapGrouper>{relatedProductsComponent}</WrapGrouper>
      </StyledContainer>
    </Page>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
  items: selectBasketItems(state),
});

const mapDispatchToProps = {
  openModal,
  clearBasket,
  push,
};

const enhancer: HOC<*, {||}> = compose(
  withUser(),
  withConnect(mapStateToProps, mapDispatchToProps),
  withState('acceptedTermsAndConditions', 'setAcceptedTermsAndConditions', false),
  withHandlers({
    onContinueShopping: props => event => {
      props.push(urls.products);
    },
    onGoToCheckout: props => event => {
      const gotToCheckoutOrDeliveryAddress = () => {
        //If any of the reservations needs to be delivered then direct user to delivery address page
        //Else go directly to checkout
        if (props.items.some(item => item.fulfillmentType === 'DELIVERY')) {
          props.push(urls.deliveryAddress);
        } else {
          props.push(urls.basketCheckout);
        }
      };
      if (!props.user) {
        props.openModal(SignUpModal, {callback: () => gotToCheckoutOrDeliveryAddress()});
      } else {
        gotToCheckoutOrDeliveryAddress();
      }
    },
    toggleTermsAndConditions:
      ({setAcceptedTermsAndConditions}) =>
      () =>
        setAcceptedTermsAndConditions(isAccepted => !isAccepted),
  })
);

export default enhancer(ShoppingBasketPage);
