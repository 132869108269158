// @flow
import type {Query} from 'common/graphql/types';
import type {EnumValue} from 'data/enums/types';
import type {User} from 'data/user/types';
import gql from 'graphql-tag';

import {userFragment} from './fragments';

export const getUserQuery: Query<User> = {
  gql: gql`
    query getUserQuery {
      user {
        getUser {
          ...userFragment
        }
      }
    }
    ${userFragment}
  `,
  selector: ['user', 'getUser'],
};

export const getPaymentMethodQuery: Query<{|
  brand: string,
  last4: string,
|}> = {
  gql: gql`
    query getPaymentMethod {
      payment {
        getCustomerPaymentMethod {
          card {
            brand
            last4
          }
        }
      }
    }
  `,
  selector: ['payment', 'getCustomerPaymentMethod', 'card'],
};

export type ListProfileOptionsQueryResult = {|
  stances: EnumValue[],
  skills: EnumValue[],
  waves: EnumValue[],
  skier: EnumValue[],
  genders: EnumValue[],
|};

export const listProfileOptionsQuery: Query<ListProfileOptionsQueryResult> = {
  gql: gql`
    query listProfileOptionsQuery {
      profile {
        listStances {
          id
          name: value
        }
        listWaves {
          id
          name: value
        }
        listSkills {
          id
          name: value
        }
        listSkierTypes {
          id
          name: value
        }
        listUserGenders {
          id
          name: value
        }
      }
    }
  `,
  selector: ['profile'],
  transform: ({listStances, listWaves, listSkills, listSkierTypes, listUserGenders}: any) => ({
    stances: listStances,
    skills: listSkills,
    waves: listWaves,
    skier: listSkierTypes,
    genders: listUserGenders,
  }),
};

export const claimMasquerade: Query<{|
  user: User,
  claimToken: string,
|}> = {
  gql: gql`
    query claimMasquerade($claimToken: String!) {
      auth {
        claimMasquerade(claimToken: $claimToken) {
          user {
            ...userFragment
          }
        }
      }
    }
    ${userFragment}
  `,
  selector: ['auth', 'claimMasquerade', 'user'],
};
