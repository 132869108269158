// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import styled from 'styled-components';

export const ClearButton = styled(Text)`
  margin-left: 1rem;
  cursor: pointer;
`;

export const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const PrimaryFilters = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;
