// @flow
import {minWidthImg} from 'data/images/helpers';
import type {Image} from 'data/images/types';

export const getImages = (images: Image[]): any[] =>
  images.map(image => {
    const imgSize = minWidthImg(image, 850);
    return {
      original: imgSize.url,
      thumbnail: imgSize.url,
    };
  });
