// @flow
import type {Query} from 'common/graphql/types';
import gql from 'graphql-tag';

import type {CategoryCountryAggregator, Country} from './types';

export const listCountriesPerCategoryQuery: Query<CategoryCountryAggregator[]> = {
  gql: gql`
    query listCountriesPerCategoryQuery {
      location {
        listCountriesPerCategory {
          name
          countries {
            name
            locations {
              name
            }
          }
        }
      }
    }
  `,
  selector: ['location', 'listCountriesPerCategory'],
};

export const listCountries: Query<Country[]> = {
  gql: gql`
    query listCountries {
      location {
        listCountries {
          id
          name
          locations {
            id
            name
          }
        }
      }
    }
  `,
  selector: ['location', 'listCountries'],
};
