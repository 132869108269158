// @flow
import {BagWarningWrap} from 'components/BagWarning/styled';
import {VPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import styled from 'styled-components';

export const StyledContainer = styled(VPaddedFullWidthContainer)`
  ${BagWarningWrap} {
    margin-bottom: 3.2rem;
  }
`;
