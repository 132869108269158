// @flow
import CloseButton from 'components/Button/CloseButton';
import styled from 'styled-components';

export const FilterModalHeader = styled.div`
  position: relative;
  background: ${props => props.theme.color.white};
  padding: 1.6rem;
  border-bottom: 1px solid ${props => props.theme.color.lightGray};
`;

export const FilterModalCloseButton = styled(CloseButton)`
  position: absolute;
`;

export const FilterModalTitle = styled.h1`
  margin: 0;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
`;
