// @flow
import CartIcon from 'assets/icons/CartIcon';
import {selectBasketItems} from 'data/basket/selectors';
import urls from 'data/router/urls';
import withConnect from 'hoc/withConnect';
import React from 'react';
import {type HOC, compose} from 'recompose';

import {ItemCount, ShoppingBasketLinkWrap} from './styled';

const ShoppingBasketLink = ({items}) => (
  <ShoppingBasketLinkWrap to={urls.shoppingBasket}>
    <CartIcon size={24} />
    {items.length > 0 && <ItemCount>({items.length})</ItemCount>}
  </ShoppingBasketLinkWrap>
);

const mapStateToProps = state => ({items: selectBasketItems(state)});

const enhancer: HOC<*, {||}> = compose(withConnect(mapStateToProps));

export default enhancer(ShoppingBasketLink);
