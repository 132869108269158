// @flow
import type {ExtractActionTypes} from 'common/redux/types';

import * as actions from './actions';
import type {Modal} from './types';

export type ModalState = Modal<any>[];

const initialState: ModalState = [];

type Action = ExtractActionTypes<typeof actions>;

function modalsReducer(state: ModalState = initialState, action: Action): ModalState {
  switch (action.type) {
    case 'MODALS/OPEN_MODAL':
      return state.concat(action.payload);
    case 'MODALS/CLOSE_MODAL':
      return state.slice(1);
    default:
      return state;
  }
}

export default modalsReducer;
