// @flow
import FilterIcon from 'assets/icons/FilterIcon';
import type {Category, Location} from 'data/app/types';
import {openModal} from 'data/modals/actions';
import type {SearchParams} from 'data/search/types';
import withConnect from 'hoc/withConnect';
import FiltersModal from 'modals/FiltersModal';
import {FilterCount, MobileFilterInput} from 'pages/_Page/MainFilter/MobileFilter/styled';
import {compose, sum} from 'ramda';
import * as React from 'react';
// $ReactHooks
import {useMemo} from 'react';
import type {HOC} from 'recompose';

const FilterInputContent = ({count}: {count?: number}) => {
  return <>Filters {!!count ? <FilterCount>{count}</FilterCount> : <FilterIcon />}</>;
};

const MobileFilter = ({openModal, geolocation, categories, params, breakpoint}) => {
  const numOfFilter = useMemo(() => {
    const getCount = values => (values ? values.length : 0);
    return (
      sum([
        getCount(categories),
        getCount(geolocation),
        getCount(params.brand),
        getCount(params.product),
      ]) || null
    );
  }, [categories, geolocation, params.brand, params.product]);

  return (
    <MobileFilterInput
      onClick={() =>
        openModal(FiltersModal, {
          breakpoint,
        })
      }
      placeholder={<FilterInputContent />}
      value={numOfFilter}
      formatter={count => <FilterInputContent count={count} />}
    />
  );
};

const mapDispatchToProps = {
  openModal,
};

export type Outter = {
  geolocation: ?(Location[]),
  categories: ?(Category[]),
  params: SearchParams,
  breakpoint: number,
};

const enhancer: HOC<*, Outter> = compose(withConnect(() => ({}), mapDispatchToProps));

export default enhancer(MobileFilter);
