// @flow
import * as React from 'react';

export type Props = {
  children?: React.Node,
  name?: string,
  onChange?: (value: any) => any,
  value?: any,
};

export const RadioContext = React.createContext({
  name: undefined,
  onChange: () => {},
  value: undefined,
});

/**
 * @param name - HTML name attribute for radio button inputs
 * @param onChange - on change handler that will be provided the 'value' of the selected radio button
 * @param value - The currently selected radio input option. onChange should set this.
 * @param children - JSX to render. Should include radio buttons that can access RadioContext.
 * @returns {JSX.Element}
 * @constructor
 */
const RadioGroup = ({name, onChange, value, children}: Props) => {
  return <RadioContext.Provider value={{name, onChange, value}}>{children}</RadioContext.Provider>;
};

export default RadioGroup;
