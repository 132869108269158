// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import CrossIcon from 'assets/icons/CrossIcon';
import StyledLink from 'components/StyledLink';
import * as React from 'react';

import {LeftIconWrap, ModalHeaderWrap, ModalTitle, RightIconWrap} from './styled';

type Props = {|
  close?: Function,
  back?: Function,
  title: string,
  clear?: Function,
  children?: React.Node,
|};

const ModalHeader = ({close, back, title, clear, children}: Props) => (
  <ModalHeaderWrap>
    {close && (
      <LeftIconWrap onClick={close}>
        <CrossIcon />
      </LeftIconWrap>
    )}
    {back && (
      <LeftIconWrap onClick={back}>
        <ArrowIcon rotation="left" />
      </LeftIconWrap>
    )}
    <ModalTitle>{title}</ModalTitle>
    {clear && (
      <RightIconWrap>
        <StyledLink onClick={clear}>Clear</StyledLink>
      </RightIconWrap>
    )}
    {children && <RightIconWrap>{children}</RightIconWrap>}
  </ModalHeaderWrap>
);

export default ModalHeader;
