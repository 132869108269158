// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import RelatedProducts from 'components/RelatedProducts';
import StoreSelect from 'components/StoreSelect';
import {Container} from 'componentsStyled/Layout/Containers';
import {Flex} from 'componentsStyled/Layout/Flex';
import {Space} from 'componentsStyled/Layout/Spacers';
import {Text} from 'componentsStyled/Typography/Texts';
import {Title} from 'componentsStyled/Typography/Titles';
import {setSelectedStoreId} from 'data/app/actions';
import {selectAppConfig, selectGeolocation, selectSelectedStoreId} from 'data/app/selectors';
import type {ID} from 'data/enums/types';
import {fullProductDetailQuery} from 'data/product/graphql/queries';
import urls from 'data/router/urls';
import {clearProduct} from 'data/search/actions';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import withRouter, {HISTORY_UPDATE} from 'hoc/withRouter';
import withUser from 'hoc/withUser';
import withUserLocation from 'hoc/withUserLocation';
import useQueryParam from 'hooks/useQueryParam';
import SubPage from 'pages/_Page/SubPage';
import {groupBy} from 'ramda';
import React from 'react';
// $ReactHooks
import {useMemo, useState} from 'react';
import {type HOC, compose} from 'recompose';

import Detail from './Detail';
import Reserve from './Detail/Reserve';
import {DetailWrap, DetailWrapNoPadding, StyledDescription} from './Detail/styled';
import Gallery from './Gallery';
import {Half, StoreSelectWrapper} from './styled';

/**
 * Page to show a product and its variants offered by an affiliate.
 * User gets to select the variant and create a reservation.
 */
const Product = ({
  user,
  data,
  setSelectedStoreId,
  selectedStoreId,
  geolocations,
  userLocation,
  history,
  location,
}) => {
  const {product} = data;
  const {coords} = userLocation || {};

  const allVariantAffiliates = useMemo(
    // $ExpectError - Flow doesn't know flatMap
    () => product.variants.flatMap(variant => variant.affiliates),
    [product]
  );

  // Returns UNIQUE affiliates but combines the pricing of affiliates across all variants. We do this
  // So the store selector has access to the range of prices across ALL variants.
  const uniqueAffiliatesForStoreSelector = useMemo(() => {
    //Will return an object of the form {id1: [...<affiliate with id1>], id2: [...<affiliates with id2>], ...}
    const groupedAffiliates = groupBy(({id}) => id, allVariantAffiliates);

    return Object.values(groupedAffiliates).map(affiliates => {
      return {
        // $ExpectError - There will always be a first element in the array
        ...affiliates[0],
        // $ExpectError - Flow doesn't know flatMap
        pricing: affiliates.flatMap(affiliate => affiliate.pricing),
      };
    });
  }, [allVariantAffiliates]);

  const [internalSelectedStoreId, setInternalSelectedStoreId] = useState(() => {
    //If only 1 affiliate, then set it
    if (uniqueAffiliatesForStoreSelector && uniqueAffiliatesForStoreSelector.length === 1) {
      return uniqueAffiliatesForStoreSelector[0].id;
    }

    //If the globally selected store is amongst those available for this product set it to that
    if (uniqueAffiliatesForStoreSelector.some(({id}) => id === selectedStoreId)) {
      return selectedStoreId;
    }

    //Else no initial value
    return null;
  });

  const setStore = (id: number | string) => {
    setSelectedStoreId(id);
    setInternalSelectedStoreId(id);
  };

  //All AffiliateOnVariants that match the selected affiliate ID (May be multiple due to multiple variants)
  const affiliateVariantsMatchingSelection = useMemo(
    () => allVariantAffiliates.filter(({id}) => internalSelectedStoreId === id),
    [internalSelectedStoreId, allVariantAffiliates]
  );

  const isValidStoreSelected =
    affiliateVariantsMatchingSelection && affiliateVariantsMatchingSelection.length !== 0;

  useQueryParam({
    value: internalSelectedStoreId,
    setValue: setStore,
    paramName: 'store',
    transformFromParam: id => (id != null ? Number(id) : null),
    historyUpdateMethod: HISTORY_UPDATE.PUSH,
    history,
  });

  const contentComponent = (
    <React.Fragment>
      <DetailWrap>
        <Text bigOnDesktop>{product.manufacturer.name}</Text>
        <Title>{product.name}</Title>
        <StoreSelectWrapper stores={uniqueAffiliatesForStoreSelector}>
          <StoreSelect
            stores={uniqueAffiliatesForStoreSelector}
            selectedLocations={geolocations}
            selectedStoreId={internalSelectedStoreId}
            onStoreSelect={setStore}
            userCoords={coords}
          />
        </StoreSelectWrapper>
        <Space />
        {isValidStoreSelected && (
          <Detail
            variantAffiliates={affiliateVariantsMatchingSelection}
            product={product}
            user={user}
          />
        )}
        {!isValidStoreSelected &&
          uniqueAffiliatesForStoreSelector &&
          uniqueAffiliatesForStoreSelector.length > 0 && <Reserve disabled />}
        <Space />
        <StyledDescription description={product.description} multiLine />
        <Space />
      </DetailWrap>
    </React.Fragment>
  );

  // TODO Handle multiple categories
  const relatedProductsComponent = (
    <RelatedProducts
      categoryName={product.categories[0].name}
      productId={product.id}
      isInsideBasket={false}
    />
  );

  return (
    <SubPage title={product.name} backUrl={urls.products}>
      <Gallery images={product.images} />
      <Mobile>
        {contentComponent}
        <DetailWrapNoPadding>{relatedProductsComponent}</DetailWrapNoPadding>
      </Mobile>
      <Desktop>
        <Container>
          <Flex>
            <Half />
            <Half white>{contentComponent}</Half>
          </Flex>
          <Space />
          {relatedProductsComponent}
        </Container>
      </Desktop>
    </SubPage>
  );
};

type Outter = {|
  match: {|
    params: {|
      productId: ID,
      affiliateId: ID,
    |},
  |},
|};

const mapDispatchToProps = {
  clearProduct,
  setSelectedStoreId,
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
  selectedStoreId: selectSelectedStoreId(state),
  geolocations: selectGeolocation(state),
});

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withUser(),
  withQuery(fullProductDetailQuery, {
    variables: props => ({
      productId: props.match.params.productId,
    }),
  }),
  withConnect(mapStateToProps, mapDispatchToProps),
  withUserLocation()
);

export default enhancer(Product);
