// @flow
import {dayStatus} from 'data/reservations/constants';
import type {DayStatus} from 'data/reservations/types';
import type {DateRangeMoment, DateString} from 'data/units/date/types';
import type Moment from 'moment';
import {equals, isEmpty} from 'ramda';

const isBetweenInclusive = (x: Moment, v: DateRangeMoment) =>
  x.isBetween(v.startDate, v.endDate, 'day', '[]');

export const isActive = (day: Moment, v: DateRangeMoment) => {
  if (!v || !v.startDate) return false;

  if (v.startDate && !v.endDate) return v.startDate.isSame(day, 'day');

  return isBetweenInclusive(day, v);
};

const isAvailable = (availabilityDates: Array<DateRangeMoment>, calendarDate: Moment) => {
  return availabilityDates.some(a => isBetweenInclusive(calendarDate, {...a}));
};

export const getStatus = (
  day: Moment,
  v: DateRangeMoment,
  availabilityDates?: Array<DateRangeMoment>,
  closedDays?: DateString[],
  originalReservation?: DateRangeMoment,
  currentDate: Moment
): DayStatus => {
  // The calendar day is before the current affiliate day
  if (day.isBefore(currentDate.format('YYYY-MM-DD'), 'day')) {
    return dayStatus.past;
  }

  const startDate = v && v.startDate;
  const endDate = v && v.endDate;

  // If extending, block selection of days prior to original reservaton start date
  if (originalReservation) {
    if (startDate && day.isBefore(startDate, 'day')) return dayStatus.past;
  } else {
    // If startDate is set but no endDate is given, block days
    if (startDate && !endDate && day.isBefore(startDate, 'day')) {
      return dayStatus.past;
    }
  }

  const isClosedDay = closedDays && closedDays.includes(day.format('YYYY-MM-DD'));

  // For availability dates in Product Availability Modal
  if (availabilityDates && !isEmpty(availabilityDates)) {
    // Block original booking days if extending a reservation
    if (originalReservation && isBetweenInclusive(day, originalReservation)) {
      return dayStatus.originalReservation;
    }

    // Block days not within availability
    if (!isAvailable(availabilityDates, day) && !isClosedDay) {
      return dayStatus.blocked;
    }

    // StartDate, multiple availability, block free days after the first blocked day from startDate
    if (startDate && availabilityDates.length > 1) {
      // If extending a Reservation
      if (originalReservation && originalReservation.endDate) {
        const endDate = originalReservation.endDate;
        const nextEndDate = endDate.clone().add(1, 'days');

        const nextAvailable = availabilityDates.filter(range =>
          isBetweenInclusive(nextEndDate, range)
        );

        const availabilityWithDay = nextAvailable.filter(range => isBetweenInclusive(day, range));

        if (!availabilityWithDay.length) {
          return dayStatus.blocked;
        }
      } else {
        // considering multiple availability
        const availabilityDatesWithStartDate = availabilityDates.filter(range =>
          isBetweenInclusive(startDate, range)
        );

        const availabilityWithDay = availabilityDatesWithStartDate.filter(range =>
          isBetweenInclusive(day, range)
        );

        const lastRangeWithDay = availabilityWithDay[availabilityWithDay.length - 1];
        const lastRange = availabilityDates[availabilityDates.length - 1];
        const dayInLastRange = equals(lastRangeWithDay, lastRange);

        if (!availabilityWithDay.length && !dayInLastRange) {
          if (!endDate) {
            // next end date has to be in same range as start date, unless it's the last
            return dayStatus.blocked;
          } else if (isClosedDay) {
            // next start date in a different available date range has unknown price, even when closed
            return dayStatus.closedHidePrice;
          } else {
            // next start date in a different available date range has unknown price
            return dayStatus.availableHidePrice;
          }
        }
      }
    }
  }

  // Shop is closed
  if (isClosedDay) {
    return dayStatus.closed;
  }

  return dayStatus.available;
};
