// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import urls from 'data/router/urls';
import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import ChangePassword from './Subpages/ChangePassword';
import DeleteAccount from './Subpages/DeleteAccount';
import List from './Subpages/List';
import Payments from './Subpages/Payments';
import UpdatePhoneNumber from './Subpages/UpdatePhoneNumber';

const commonRoutes = [
  {path: urls.account.password, component: ChangePassword},
  {path: urls.account.payments, component: Payments},
  {path: urls.account.deleteAccount, component: DeleteAccount},
  {path: urls.account.phoneNumber, component: UpdatePhoneNumber},
];

const Routes = () => (
  <React.Fragment>
    <Mobile>
      <Switch>
        <Route exact path={urls.account.index} component={List} />
        {commonRoutes.map(r => (
          <Route key={r.path} path={r.path} component={r.component} />
        ))}
        <Redirect to={urls.account.index} />
      </Switch>
    </Mobile>
    <Desktop>
      <Switch>
        {commonRoutes.map(r => (
          <Route key={r.path} path={r.path} component={r.component} />
        ))}
        <Redirect to={commonRoutes[0].path} />
      </Switch>
    </Desktop>
  </React.Fragment>
);

export default Routes;
