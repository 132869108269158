// @flow
import HomeIcon from 'assets/icons/HomeIcon';
import urls from 'data/router/urls';
import React from 'react';

import {HomeLinkWrap} from './styled';

const HomeLink = () => (
  <HomeLinkWrap to={urls.home}>
    <HomeIcon />
  </HomeLinkWrap>
);

export default HomeLink;
