// @flow
import {
  AddressAutocompleteWrapper,
  AddressTextInput,
  SelectableSuggestionItem,
  SuggestionItem,
  SuggestionsContainer,
  SuggestionsList,
} from 'components/AddressAutocomplete/styled';
import React from 'react';
import GooglePlacesAutoComplete from 'react-places-autocomplete';

export type Props = {
  onSelect?: (address: string, placeId: ?string, suggestion: ?Object) => any,
  onChange: (address: string) => void,
  value: string,
  placeholder?: string,
  debounce?: number,
  searchOptions?: Object,
  suggestionsFilter?: Object => boolean,
  highlightFirstSuggestion?: boolean,
  'data-cy'?: string,
};

const AddressAutocomplete = ({
  onSelect,
  onChange,
  value,
  placeholder,
  debounce,
  searchOptions,
  suggestionsFilter,
  highlightFirstSuggestion = true,
  'data-cy': dataCy,
}: Props) => (
  <GooglePlacesAutoComplete
    onSelect={onSelect}
    onChange={onChange}
    value={value}
    debounce={debounce}
    searchOptions={searchOptions}
    highlightFirstSuggestion={highlightFirstSuggestion}
  >
    {({getInputProps, getSuggestionItemProps, suggestions, loading}) => {
      const filteredSuggestions = suggestionsFilter
        ? suggestions.filter(suggestionsFilter)
        : suggestions;

      const hasSuggestions = filteredSuggestions && filteredSuggestions.length > 0;

      return (
        <AddressAutocompleteWrapper>
          <AddressTextInput
            {...getInputProps({
              placeholder,
              'data-cy': dataCy,
            })}
          />
          <SuggestionsContainer>
            {(loading || hasSuggestions) && (
              <SuggestionsList>
                {loading && !hasSuggestions && <SuggestionItem> Loading...</SuggestionItem>}
                {filteredSuggestions.map((suggestion, index) => (
                  <SelectableSuggestionItem
                    key={index}
                    {...getSuggestionItemProps(suggestion, {
                      isActive: suggestion.active,
                      'data-cy': dataCy ? `${dataCy}-suggestion` : null,
                    })}
                  >
                    {suggestion.description}
                  </SelectableSuggestionItem>
                ))}
              </SuggestionsList>
            )}
          </SuggestionsContainer>
        </AddressAutocompleteWrapper>
      );
    }}
  </GooglePlacesAutoComplete>
);

export default AddressAutocomplete;
