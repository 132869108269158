// @flow
import React from 'react';

export type Props = {
  size?: number,
};

const FilterIcon = ({size = 16}: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.19039 11.9419H6.78266C6.41659 11.9419 6.09516 12.2549 6.09516 12.6114C6.09516 13.0201 6.41659 13.3332 6.78266 13.3332H9.16361C9.58325 13.3332 9.90468 13.0201 9.90468 12.6375C9.90468 12.2549 9.58325 11.9419 9.19039 11.9419ZM13.9523 2.6665H2.02075C1.65468 2.6665 1.33325 2.97955 1.33325 3.33607C1.33325 3.69259 1.65468 4.05781 2.02075 4.05781H13.9523C14.3452 4.05781 14.6666 3.74476 14.6666 3.38824C14.6666 3.03172 14.3452 2.6665 13.9523 2.6665ZM12.0475 7.30418H3.92551C3.55944 7.30418 3.23801 7.61723 3.23801 7.97375C3.23801 8.38245 3.55944 8.69549 3.92551 8.69549H12.0208C12.4404 8.69549 12.7618 8.38245 12.7618 7.99984C12.7618 7.61723 12.4404 7.30418 12.0475 7.30418Z"
      fill="currentColor"
    />
  </svg>
);

export default FilterIcon;
