// @flow
import {compose, either, isEmpty, isNil, mapObjIndexed, path, pickBy, reject, values} from 'ramda';

import type {EnumFilter, RangeFilter} from '../graphql/queries/search';
import type {EnumParam, RangeParam, SearchParams} from '../types';

export const getRangeFilters = (params: SearchParams): RangeFilter[] => {
  const hasProperty = (val, key) => ['length', 'volume', 'radius', 'waist_width'].includes(key);

  const rangeParams: $Shape<{[string]: RangeParam}> = compose(
    reject(isNil),
    pickBy(hasProperty)
    // $Ramda
  )(params);

  return values(
    mapObjIndexed(
      ([from, to], key) => ({
        from,
        to,
        parameter: key,
      }),
      rangeParams
    )
  );
};
export const getEnumFilters = (params: SearchParams): EnumFilter[] => {
  const hasProperty = (val, key) =>
    [
      'board_type',
      'terrain',
      'gender',
      'rocker_type',
      'flex',
      'shape',
      'sup_type',
      'sup_technology',
      'year',
      'road_category',
      'mountain_category',
      'bike_wheel_size',
      'bike_frame_size',
      'color',
      'apparel_numeric_size',
      'apparel_descriptive_size',
      'apparel_type',
      'insulated',
    ].includes(key);

  const enumParams: $Shape<{[string]: EnumParam}> = compose(
    reject(either(isEmpty, isNil)),
    pickBy(hasProperty)
  )(params);

  return values(
    mapObjIndexed((val, key) => {
      const valueArray = Array.isArray(val) ? val : [val];
      return {
        enumValueIds: valueArray.map(x => x.id),
        parameter: key,
      };
    }, enumParams)
  );
};

export const getManufacturerIds = (params: SearchParams) => {
  const brands: EnumParam = path(['brand'], params) || [];
  if (brands.length === 0) return undefined;

  return brands.map(x => x.id);
};

export const getProductIds = (params: SearchParams) => {
  const products: EnumParam = path(['product'], params) || [];
  if (products.length === 0) return undefined;

  return products.map(x => x.id);
};
