// @flow
import {type HOC, lifecycle} from 'recompose';

function withOnNextProps<T>(onNextProps: (prev: T, next: T) => void): HOC<T, T> {
  return lifecycle({
    componentWillReceiveProps(next: T) {
      onNextProps(this.props, next);
    },
  });
}

export default withOnNextProps;
