// @flow
import React from 'react';

const Visa = () => (
  <svg height="100" width="160" viewBox="0 0 160 100">
    <g fill="none" fillRule="evenodd">
      <path fill="#F6F6F6" d="M0 9h160v80H0z" />
      <path d="M148 0H8C4 0 0 4 0 8v12h160v-8c0-8-4-12-12-12zm0 0" fill="#265697" />
      <path d="M0 80v8c0 8 4 12 12 12h136c8 0 12-4 12-12v-8H0zm0 0" fill="#D97B16" />
      <path
        d="M111.615 29.782c-2.243-.86-5.757-1.782-10.145-1.782-11.184 0-19.062 5.755-19.129 14.003-.063 6.097 5.624 9.498 9.918 11.528 4.405 2.08 5.886 3.406 5.865 5.263-.028 2.844-3.518 4.144-6.771 4.144-4.53 0-6.937-.644-10.654-2.228l-1.459-.674-1.588 9.498c2.643 1.185 7.532 2.211 12.608 2.264 11.897 0 19.621-5.689 19.709-14.496.043-4.827-2.973-8.5-9.503-11.529-3.956-1.963-6.379-3.273-6.353-5.26 0-1.764 2.05-3.65 6.481-3.65 3.702-.058 6.383.766 8.472 1.626l1.014.49 1.535-9.197M140.638 28.778h-8.746c-2.709 0-4.736.755-5.926 3.519l-16.81 38.885h11.885s1.944-5.228 2.383-6.376c1.299 0 12.845.018 14.496.018a820.847 820.847 0 0 1 1.377 6.358h10.502l-9.16-42.404zm-13.956 27.349c.936-2.445 4.509-11.862 4.509-11.862-.067.113.93-2.456 1.5-4.05l.766 3.659s2.167 10.128 2.62 12.252h-9.395zm0 0M59.25 71.169l7.076-42.432h11.319L70.564 71.17H59.25zm0 0M49.757 28.768L38.676 57.703l-1.181-5.88c-2.063-6.778-8.49-14.122-15.676-17.8l10.133 37.109 11.975-.014 17.82-42.35h-11.99"
        fill="#265697"
      />
      <path
        d="M28.396 28.742H10.144l-.144.883c14.2 3.512 23.595 12 27.495 22.198l-3.969-19.499c-.685-2.686-2.672-3.488-5.13-3.582"
        fill="#D97B16"
      />
    </g>
  </svg>
);

export default Visa;
