// @flow
import {assertNever} from 'common/flow';
import {noopAction} from 'common/redux/actions';
import {push} from 'connected-react-router';
import {onAffiliatePage, onProductPage, onSearchPage} from 'data/router/helpers';
import {selectPathname} from 'data/router/selectors';
import urls from 'data/router/urls';
import {Epic, ofType} from 'redux-observable';
import {map} from 'rxjs/operators';

import {type SearchAction, filter} from './actions';

const searchEpic: Epic<SearchAction> = (action$, state$) =>
  action$.pipe(
    ofType('SEARCH/FILTER', 'SEARCH/FILTER_DATES'),
    map(action => {
      if (action.type !== 'SEARCH/FILTER' && action.type !== 'SEARCH/FILTER_DATES') {
        throw new Error(`Invalid Action Occured: ${action.type}`);
      }

      const path = selectPathname(state$.value);

      // dont do anything if already on search, on affiliate page or just navigating to a product
      if (
        onSearchPage(path) ||
        onAffiliatePage(path) ||
        (onProductPage(path) && !action.payload.product)
      ) {
        return noopAction();
      }

      // redirect to search
      return push(urls.search);
    })
  );

const triggerSearchEpic: Epic<SearchAction> = (action$, state$) =>
  action$.pipe(
    ofType('SEARCH/ADD_RECENT_SEARCH', 'SEARCH/TRIGGER_RECENT_SEARCH'),
    map(action => {
      if (
        action.type !== 'SEARCH/ADD_RECENT_SEARCH' &&
        action.type !== 'SEARCH/TRIGGER_RECENT_SEARCH'
      ) {
        throw new Error('impossible');
      }

      switch (action.payload.type) {
        case 'affiliate':
          return noopAction();
        case 'manufacturer':
          return filter({brand: [action.payload]});
        case 'product':
          return filter({product: [action.payload]});
        default:
          return assertNever(action.payload.type);
      }
    })
  );

const epics = [searchEpic, triggerSearchEpic];
export default epics;
