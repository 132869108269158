// @flow
import styled from 'styled-components';

export const ProductListWrap = styled.div`
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
`;
