// @flow
import {mq, mqMax} from 'common/mediaQuery';
import {HPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {Text} from 'componentsStyled/Typography/Texts';
import styled from 'styled-components';

export const Inner = styled(HPaddedFullWidthContainer)`
  display: flex;
  justify-content: center;

  ${mq.tabletWide} {
    justify-content: flex-start;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.phone} {
    text-shadow: none;
  }

  width: 100%;
  flex-wrap: wrap;

  button {
    min-width: 100%;

    ${mq.phone} {
      min-width: 0;
    }
  }

  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
`;

export const Hero = styled.h1`
  margin-bottom: 4rem;
  font-size: 3.2rem;
  line-height: 3.2rem;
  font-weight: ${props => props.theme.fontWeights.ultra};
  color: #fff;
  z-index: 1;

  ${mq.tabletWide} {
    font-size: 4.8rem;
    line-height: 1;
  }

  ${mqMax.phone} {
    text-shadow: none;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  width: 35rem;

  ${mqMax.phone} {
    width: 100%;

    * {
      font-size: 1.5rem;
    }
    button {
      height: 4.2rem;
    }
  }
`;

export const StyledText = styled(Text)`
  margin-top: 2rem;
`;
