// @flow
import {kgToPounds, roundKg} from './converters';

export const formatKgToPounds = (kg: ?number): ?string => {
  if (!kg) return undefined;

  const converted = kgToPounds(kg);
  return `${converted} lb`;
};

export const formatKg = (kg: ?number): ?string => {
  if (!kg) return undefined;

  return `${roundKg(kg)} kg`;
};
