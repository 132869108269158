// @flow
import ContextMenu from 'components/ContextMenu';
import OverlayWrapper from 'components/OverlayWrapper';
import {selectRecentSearches} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import {unnest, values} from 'ramda';
import * as React from 'react';
import {type HOC, compose, lifecycle, withHandlers, withStateHandlers} from 'recompose';

import AutocompleteContent from './AutocompleteContent';
import Input from './Input';
import {SearchAutocompleteWrap} from './styled';
import withAutocomplete from './withAutocomplete';

const SearchAutocomplete = ({
  isOpen,
  open,
  close,
  handleKeyDown,
  activeIndex,
  activeItem,

  // withAutocomplete
  value,
  data,
  onQueryChange,

  onItemSelect,
  loading,
  recentSearches,
}) => {
  const isEmpty = value === '';
  const noRecent = recentSearches.length === 0;
  const hideMenu = isEmpty && noRecent && !loading;

  return (
    <SearchAutocompleteWrap>
      <OverlayWrapper isOpen={isOpen} close={close} transparent>
        <Input
          onClick={open}
          onChange={onQueryChange}
          value={value}
          close={close}
          isOpen={isOpen}
          handleKeyDown={handleKeyDown}
        />

        {!hideMenu && (
          <ContextMenu isOpen={isOpen} close={close}>
            <AutocompleteContent
              close={close}
              isEmpty={isEmpty}
              loading={loading}
              data={data}
              onItemSelect={onItemSelect}
              activeIndex={activeIndex}
            />
          </ContextMenu>
        )}
      </OverlayWrapper>
    </SearchAutocompleteWrap>
  );
};

type Outter = {|
  initialValue: ?string,
  isOpen: boolean,
  open: Function,
  close: Function,
|};

const mapStateToProps = state => ({
  recentSearches: selectRecentSearches(state),
});

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps),
  withAutocomplete,
  withStateHandlers(
    {
      activeIndex: 0,
    },
    {
      setActiveIndex: () => (activeIndex: number) => ({activeIndex}),
    }
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.data !== this.props.data) {
        this.props.setActiveIndex(0);
      }
    },
  }),
  withHandlers({
    handleKeyDown: props => e => {
      const key = e.keyCode;
      const {setActiveIndex, activeIndex, data} = props;
      const totalItems = data && unnest(values(data));
      const input = e.target;

      if (key === 38) {
        const length = input.value.length;
        input.setSelectionRange(length, length);
        e.preventDefault();
        if (activeIndex !== 0) return setActiveIndex(activeIndex - 1);
      }
      if (key === 40 && totalItems && activeIndex !== totalItems.length - 1) {
        setActiveIndex(activeIndex + 1);
      }
      if (key === 13) {
        e.preventDefault();

        if (totalItems && totalItems.length !== 0) {
          const activeItemByIndex = totalItems[props.activeIndex];
          props.onItemSelect(activeItemByIndex);
        }
      }
    },
  })
);

export default enhancer(SearchAutocomplete);
