// @flow
import styled from 'styled-components';

export const AlertWrap = styled.div`
  background: ${props => props.theme.color.red};
  color: ${props => props.theme.color.white};
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
`;
