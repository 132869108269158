// @flow
import {breakpoints} from 'common/mediaQuery';
import {values} from 'ramda';

// Taken from https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const formattedHex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(formattedHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

//Taken from tinycolor: https://github.com/bgrins/TinyColor/blob/2322a1a70a0cd075b98829d67dbd90228abebab4/tinycolor.js#L68
export const getBrightness = (hex: string) => {
  const rgb = hexToRgb(hex);
  return rgb ? (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 : null;
};

export const isDark = (hex: string) => {
  const brightness = getBrightness(hex);
  return brightness ? brightness < 128 : null;
};

export const isLight = (hex: string) => {
  const isDarkResult = isDark(hex);
  return isDarkResult == null ? null : !isDarkResult;
};

export function lighten(hex: string, alpha: number) {
  return `rgba(${parseInt(hex.substring(1, 3), 16)},${parseInt(hex.substring(3, 5), 16)},${parseInt(
    hex.substring(5, 7),
    16
  )},${alpha})`;
}

export type Theme = {|
  borderRadiuses: {|
    button: string,
    filterButton: string,
    input: string,
  |},
  breakpoints: Array<number>,
  color: {|
    background: string,
    green: string,
    light: string,
    lighterGray: string,
    lightGray: string,
    darkGray: string,
    neutralGrayLight: string,
    neutralGray: string,
    neutralGrayDark: string,
    neutralGrayDarker: string,
    primaryAccent: string,
    primaryAccentDark: string,
    primaryText: string,
    red: string,
    secondaryText: string,
    shadow: string,
    white: string,
    yellow: string,
    black: string,
    success: string,
    error: string,
  |},
  fontWeights: {|
    bold: number,
    book: number,
    medium: number,
    normal: number,
    ultra: number,
  |},
  zIndices: {|
    contextMenu: number,
    drawer: number,
    header: number,
    minimal: number,
    modal: number,
    notification: number,
    overlay: number,
    overlayContent: number,
    overlayOverlay: number,
  |},
  /**
   * Maximum content width to be used by "full width" containers. Should not
   * apply to the theme header in order to give the flexility to customise the
   * background.
   */
  maxContentWidth: string,
|};

export const defaultTheme: Theme = {
  color: {
    // Theme colors
    primaryAccent: '#00bed0',
    primaryAccentDark: '#009daf',
    primaryText: '#000f32',
    secondaryText: '#7f899e',
    background: '#ffffff',

    // Static colors - not intended to be overridden
    light: '#f5f5f7',
    shadow: '#f1f1f7',
    lighterGray: '#f7fafc',
    lightGray: '#d9dee6',
    darkGray: '#6c757d',
    neutralGrayLight: '#E2E8F0',
    neutralGray: '#A0AEC0',
    neutralGrayDark: '#718096',
    neutralGrayDarker: '#4A5568',
    white: '#ffffff',
    red: '#ec4833',
    green: '#0acd8e',
    yellow: '#ffa900',
    black: '#212529',
    success: '#32B67A',
    error: '#E53E3E',
  },
  zIndices: {
    minimal: 1,
    overlayOverlay: 98,
    overlayContent: 99,
    overlay: 100,
    header: 100,
    contextMenu: 500,
    drawer: 900,
    modal: 1000,
    notification: 10000,
  },
  breakpoints: values(breakpoints),
  fontWeights: {normal: 400, book: 500, medium: 600, bold: 700, ultra: 900},
  borderRadiuses: {button: '0.5rem', filterButton: '0.2rem', input: '0.5rem'},
  maxContentWidth: '96rem',
};

export const patagoniaThemeOverrides = {
  maxContentWidth: '1964px',
};
