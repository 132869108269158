// @flow
import Button from 'components/Button';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {Text} from 'componentsStyled/Typography/Texts';
import {notificationError} from 'data/notifications/actions';
import {getRecoveryEmailMutation} from 'data/user/graphql/mutations';
import Alert from 'forms/Alert';
import TextInput from 'forms/Input/Text';
import {emailRequired} from 'forms/validators/string';
import withForm from 'forms/withForm';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import React from 'react';
import {type HOC, compose} from 'recompose';

import {TextWrap} from './styled';

const ForgotPasswordForm = ({error, isSubmitting}) => (
  <React.Fragment>
    <ModalContent>
      <Wrap>
        <Alert>{error}</Alert>
        <TextWrap>
          <Text>We will send you an email with further instructions to reset your password.</Text>
        </TextWrap>
        <TextInput name="email" label="Email" placeholder="Enter your email" />
      </Wrap>
    </ModalContent>
    <ModalControls>
      <Button fullwidth loading={isSubmitting}>
        Reset
      </Button>
    </ModalControls>
  </React.Fragment>
);

const mapDispatchToProps = {
  notificationError,
};

const schema = {
  email: emailRequired,
};

type Outter = {|
  setEmailSent: boolean => mixed,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withMutation(getRecoveryEmailMutation),
  withForm({
    schema,
    onSubmit: props => props.submitMutation,
    onSuccess: props => () => props.setEmailSent(true),
  })
);

export default enhancer(ForgotPasswordForm);
