// @flow
import {activities} from 'data/app/constants';
import type {CategoryCountryAggregator} from 'data/locations/types';
import {flatten, groupBy, path, prop} from 'ramda';

import mountainBikeImg from './images/mountain_bike.jpg';
import roadBikeImg from './images/road_bike.jpg';
import skiImg from './images/ski.jpg';
import snowboardImg from './images/snowboard.jpg';
import splitboardImg from './images/splitboard.jpg';
import supImg from './images/sup.jpg';
import surfImg from './images/surf.jpg';

export type LocationsPerActivity = {
  activity: string,
  locations: number,
};

export const getLocationsNumberPerCategory = (
  data: CategoryCountryAggregator[]
): LocationsPerActivity[] => {
  const locationsPerActivity = data.map(activity => ({
    activity: activity.name,
    locations: flatten(activity.countries.map(country => country.locations)).length,
  }));

  return locationsPerActivity;
};

const mapLocations = (
  data: {
    [index: string]: LocationsPerActivity[],
  },
  key: string
): string => {
  if (!path([key], data)) return '0';

  return data[key].map(d => d.locations).join();
};

export const getCategories = (locationsPerActivity: LocationsPerActivity[]) => {
  const data = groupBy(prop('activity'), locationsPerActivity);

  return {
    ocean: [
      {
        name: 'surf',
        activity: activities.SURF,
        image: surfImg,
        locations: mapLocations(data, 'surfs'),
      },
      {
        name: 'sup',
        activity: activities.SUP,
        image: supImg,
        locations: mapLocations(data, 'sups'),
      },
    ],
    mountain: [
      {
        name: 'ski',
        activity: activities.SKI,
        image: skiImg,
        locations: mapLocations(data, 'skis'),
      },
      {
        name: 'snowboard',
        activity: activities.SNOWBOARD,
        image: snowboardImg,
        locations: mapLocations(data, 'snowboards'),
      },
      {
        name: 'splitboard',
        activity: activities.SPLITBOARD,
        image: splitboardImg,
        locations: mapLocations(data, 'splitboards'),
      },
    ],
    wheels: [
      {
        name: 'road bike',
        activity: activities.ROAD_BIKE,
        image: roadBikeImg,
        locations: mapLocations(data, 'road_bikes'),
      },
      {
        name: 'mountain bike',
        activity: activities.MOUNTAIN_BIKE,
        image: mountainBikeImg,
        locations: mapLocations(data, 'mountain_bikes'),
      },
    ],
  };
};
