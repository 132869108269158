// @flow
import type {EnumValue} from 'data/enums/types';
import Empty from 'hoc/withEmpty/Empty';
import React from 'react';
import Multiselect from 'searchFilters/Shared/Multiselect';

type Props = {
  onChange: (?(EnumValue[])) => mixed,
  value: ?(EnumValue[]),
  options: ?(EnumValue[]),
  idKey?: string,
};

function DefaultFilter({options, value, onChange, idKey}: Props) {
  const hasNoOptions = !options || options.length === 0;
  const hasNoSelections = !value || value.length === 0;

  if (hasNoOptions && hasNoSelections) {
    return <Empty />;
  }

  return <Multiselect {...{options, value, onChange, idKey}} />;
}

export default DefaultFilter;
