// @flow
import {openModal} from 'data/modals/actions';
import withConnect from 'hoc/withConnect';
import ModalBody from 'modals/_Body';
import ModalHeader from 'modals/_Header';
import ForgotPassword from 'modals/ForgotPassword';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import LoginForm from './LoginForm';

const LoginModal = ({close, openSignup, openForgotPassword, callback}) => (
  <ModalBody close={close} data-cy={'login-form'}>
    <ModalHeader close={close} title="Welcome Back" />
    <LoginForm close={close} openForgotPassword={openForgotPassword} callback={callback} />
  </ModalBody>
);

const mapDispatchToProps = {
  openModal,
};

type Outter = {|
  close: Function,
  callback?: Function,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withHandlers({
    openForgotPassword: props => () => {
      props.close();
      props.openModal(ForgotPassword, {});
    },
  })
);

export default enhancer(LoginModal);
