// @flow
import Button from 'components/Button';
import ToolTip from 'components/ToolTip';
import {Flex} from 'componentsStyled/Layout/Flex';
import {affiliateExtensionDetailsQuery} from 'data/affiliate/graphql';
import {openModal} from 'data/modals/actions';
import {reservationStatuses} from 'data/reservations/constants';
import {isWithinExtensionPeriod} from 'data/reservations/helpers';
import type {Reservation} from 'data/reservations/types';
import urls from 'data/router/urls';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import withRouter from 'hoc/withRouter';
import ProductAvailability from 'modals/ProductAvailability';
import * as R from 'ramda';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {momentFromDateString} from '../../../data/units/date/helpers';
import type {DateRange} from '../../../data/units/date/types';
import {getToolTipMessage} from './messages';
import {Callout, CalloutTitle, Center} from './styled';

const validReservationStatusValuesForExtension = [
  reservationStatuses.confirmed,
  reservationStatuses.outbound,
  reservationStatuses.pre_processing,
  reservationStatuses.checked_out,
];

const affiliateHasNoAvailability = (reservation: Reservation): boolean => {
  const dayAfterEnd = momentFromDateString(reservation.booking.end)
    .add(1, 'day')
    .format('YYYY-MM-DD');
  const affiliateAvailability = reservation.availableExtensionIntervals;

  if (!affiliateAvailability) {
    return true;
  }

  const affiliateHasAvailabilityAfterBookingEnd = affiliateAvailability.some(
    (dateRange: DateRange) => dateRange.startDate === dayAfterEnd
  );

  return !affiliateHasAvailabilityAfterBookingEnd;
};

const NoAvailability = () => (
  <Callout>
    <CalloutTitle>Extension unavailable:</CalloutTitle> This item has been booked by someone else or
    is unavailable beyond these dates.
  </Callout>
);

export const ExtendReservation = ({data, handleExtend, reservation}: any) => {
  const userCannotExtendReservation =
    !R.contains(reservation.status, validReservationStatusValuesForExtension) ||
    !isWithinExtensionPeriod(
      reservation,
      data.affiliateExtensionDetails,
      data.affiliateCurrentDate
    ) ||
    affiliateHasNoAvailability(reservation);

  if (userCannotExtendReservation) {
    return <NoAvailability />;
  }
  // TODO(Jude): Handle multiple categories
  const productName = reservation.productItem.productVariant.product.categories[0].name;

  return (
    <Center>
      <Flex>
        <Button onClick={handleExtend}>Extend reservation</Button>
        <ToolTip withButton content={getToolTipMessage(productName, 'extension')} />
      </Flex>
    </Center>
  );
};

const mapDispatchToProps = {
  openModal,
};

type Outter = {|
  reservation: Reservation,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withQuery(affiliateExtensionDetailsQuery, {
    noEmpty: true,
    variables: props => ({
      affiliateId: props.reservation.productItem.affiliate.id,
    }),
    noLoader: true,
  }),
  withConnect(() => ({}), mapDispatchToProps),
  withHandlers({
    handleExtend: props => () => {
      const originalReservation = {
        startDate: props.reservation.booking.start,
        endDate: props.reservation.booking.end,
        resId: props.reservation.id,
      };

      // Apollo cache polutes ProductVariantAvailableAffiliate list, filter them out
      const affiliateId = props.reservation.productItem.affiliate.id;
      const variantAffiliate = props.reservation.productItem.productVariant.affiliates.find(
        a => a.id === affiliateId
      );

      // HACK(Jude): Replace variant affiliate pricing detail with reservation pricing detail.
      // variantAffiliate pricing is used to show how much extended reservation dates
      // will add to price on the calendar - its function is entirely aesethetic; there may now differences in
      // pricing detail expected due to the possibility of the reservation item being part of a bundle
      const editedVariantAffiliate = {
        ...variantAffiliate,
        pricing: props.reservation.pricingDetail.prices,
      };

      const productVariant = props.reservation.productItem.productVariant;

      variantAffiliate &&
        props.openModal(ProductAvailability, {
          product: props.reservation.productItem,
          availabilityInput: props.reservation.productItem.productVariant,
          variantAffiliate: editedVariantAffiliate,
          originalReservation: originalReservation,
          callback: () => {
            props.history.push(urls.extendReservation(productVariant.id, variantAffiliate.id));
          },
        });
    },
  })
);

export default enhancer(ExtendReservation);
