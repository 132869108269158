// @flow
import {getRefreshTokenMutation} from 'data/auth/graphql/mutations';

import {getEmailFromAccessToken, getRefreshToken, purgeAccessToken, setAccessToken} from './crypto';
import {mutate} from './helpers';

// this fetch handles fetching new access token using refresh tokens

const customFetch = (uri: string, options: Object) =>
  fetch(uri, options).then(res => {
    // leave as is, if not auth error
    if (res.status !== 401) {
      return res;
    }

    const email = getEmailFromAccessToken();
    const refreshToken = getRefreshToken();
    if (!email || !refreshToken) {
      throw new Error('auth error');
    }

    purgeAccessToken();
    return mutate(getRefreshTokenMutation, {
      refreshToken,
      email,
    }).then(accessToken => {
      setAccessToken(accessToken);
      options.headers.authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    });
  });

export default customFetch;
