// @flow
import * as React from 'react';

import {ContextMenuWrap} from './styled';

type Props = {|
  children: React.Node,
  isOpen: boolean,
  close: Function,
  maxHeight?: string,
  minWidth?: number,
  isRightAligned?: boolean,
|};

const ContextMenu = ({children, isOpen, close, maxHeight, minWidth, isRightAligned}: Props) => (
  <ContextMenuWrap
    isOpen={isOpen}
    maxHeight={maxHeight}
    minWidth={minWidth}
    isRightAligned={isRightAligned}
  >
    {isOpen && children}
  </ContextMenuWrap>
);

export default ContextMenu;
