// @flow
import {type Availability, trackAvailability} from 'analytics/availability/analyticsTraits';
import type {ReservationPricingQueryInput} from 'data/reservations/graphql/queries';
import {
  reservationExtensionPricingQuery,
  reservationPricingQuery,
} from 'data/reservations/graphql/queries';
import type {OriginalReservation} from 'data/reservations/types';
import type {User} from 'data/user/types';
import withOnMount from 'hoc/withOnMount';
import withOnNextProps from 'hoc/withOnNextProps';
import withQuery from 'hoc/withQuery';
import React from 'react';
import {type HOC, branch, compose} from 'recompose';

import ReservationPaymentSummary from './ReservationPaymentSummary';
import ReservationPricingDetail from './ReservationPricingDetail';

const ReservationPricing = ({data, originalReservation, input, categoryName, summary}) =>
  summary ? (
    // TODO(Jude) Implement loading animation for this component because reservationPricingQuery
    // takes a while to perform the AvaTax API call and we end up with "No search results" as a placeholder
    <ReservationPaymentSummary {...data} originalReservation={originalReservation} input={input} />
  ) : (
    <ReservationPricingDetail
      {...data}
      originalReservation={originalReservation}
      input={input}
      categoryName={categoryName}
    />
  );

type Outter = {|
  categoryName: string,
  input: ReservationPricingQueryInput,
  originalReservation: ?OriginalReservation,
  availability?: Availability,
  user: User,
  summary?: boolean,
|};

const enhancer: HOC<*, Outter> = compose(
  branch(
    props => !props.originalReservation,
    withQuery(reservationPricingQuery, {
      variables: props => ({
        input: props.input,
      }),
      // Necessary because of refetch() below. Otherwise it will spin endlessly even though the data has been refetched
      noLoader: true,
    }),
    withQuery(reservationExtensionPricingQuery, {
      variables: props => ({
        input: {
          end: props.input.end,
          // $ExpectError impossible due to branch
          reservationId: props.originalReservation.resId,
        },
      }),
      // Necessary because of refetch() below. Otherwise it will spin endlessly even though the data has been refetched
      noLoader: true,
    })
  ),
  withOnMount(props => {
    if (props.availability) {
      trackAvailability('Selected Dates', props.availability);
    }
  }),
  withOnNextProps((prev, next) => {
    if (prev.user !== next.user) {
      next.refetch();
    }
  })
);

export default enhancer(ReservationPricing);
