import {append, assocPath, path, pathOr} from 'ramda';

const pageSize = 16;

export const getPageInfoPath = append('pageInfo');
export const getDataPath = append('data');

export const getPageInfo = (pagination, data) => pathOr({}, getPageInfoPath(pagination), data);

export const getPaginationVariables = (pagination, data) => {
  if (!pagination) return {};

  const pageInfo = getPageInfo(pagination, data);

  return {
    limit: pageSize,
    offset: pageInfo.nextOffset,
  };
};

export const mergeData = (prev, next, pagination) => {
  const dataPath = getDataPath(pagination);
  const paginationPath = getPageInfoPath(pagination);

  const oldData = path(dataPath, prev);
  const newData = path(dataPath, next);
  const newPagination = path(paginationPath, next);

  const withMergedData = assocPath(dataPath, [...oldData, ...newData], prev);
  const withMergedPagination = assocPath(paginationPath, newPagination, withMergedData);

  return withMergedPagination;
};
