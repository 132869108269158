// @flow
import {mq, mqMax} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import styled, {css} from 'styled-components';

export const commonConfigurability = css`
  ${p =>
    p.center &&
    css`
      text-align: center;
    `}
  ${p =>
    p.nomargin &&
    css`
      margin: 0 !important;
    `}
  ${p =>
    p.black &&
    css`
      color: ${props => props.theme.color.primaryText};
    `}
  ${p =>
    p.green &&
    css`
      color: ${props => props.theme.color.green};
    `}
  ${p =>
    p.white &&
    css`
      color: ${props => props.theme.color.white};
    `}
  ${p =>
    p.danger &&
    css`
      color: ${props => props.theme.color.red};
    `}
  ${p =>
    p.primary &&
    css`
      color: ${props => props.theme.color.primaryAccent};
    `}
  ${p =>
    p.bold &&
    css`
      font-weight: ${props => props.theme.fontWeights.medium};
    `}
  ${p =>
    p.nowrap &&
    css`
      white-space: nowrap;
    `}
`;

export const Hero = styled.h1`
  margin: 0 0 4rem;
  font-size: 3.2rem;
  line-height: 3rem;
  font-weight: ${props => props.theme.fontWeights.ultra};

  ${mq.tablet} {
    font-size: 3.8rem;
    line-height: 4rem;
    margin-bottom: 4.5rem;
  }

  ${mq.tabletWide} {
    font-size: 4.5rem;
    line-height: 4.8rem;
    margin-bottom: 6rem;
  }

  ${mq.desktop} {
    font-size: 6rem;
    line-height: 5rem;
    margin-bottom: 8rem;
  }

  ${commonConfigurability};
`;

export const H1 = styled(Hero)`
  text-align: center;
  position: relative;
  padding-bottom: 1rem;

  ${mq.desktop} {
    padding-bottom: 3rem;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 0.4rem;
    width: 4rem;
    background: ${props => props.theme.color.primaryAccent};
    transform: translateX(-50%);

    ${mq.tablet} {
      width: 8rem;
    }
  }
  ${commonConfigurability};
`;

export const H2 = styled.h2`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: ${props => props.theme.fontWeights.bold};
  margin: 0 0 1rem;
  color: ${props => props.theme.color.primaryText};

  ${commonConfigurability};
`;

export const H3 = styled.h3`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.color.primaryText};
  margin: 0;
  ${commonConfigurability};
`;

export const Title = styled.h1`
  text-align: center;
  position: relative;
  padding-bottom: 1rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 0.4rem;
    width: 4rem;
    background: ${props => props.theme.color.primaryAccent};
    transform: translateX(-50%);

    ${mq.tablet} {
      width: 8rem;
    }
  }
  ${commonConfigurability};
`;

export const BottomSpace = styled.div`
  margin-bottom: 3rem;
  width: 100%;

  ${p =>
    p.small &&
    css`
      margin-bottom: 1rem;
    `} ${commonConfigurability};
`;

export const TextStyle = css`
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 0 0 3rem;
  color: ${props => props.theme.color.secondaryText};

  ${commonConfigurability};
`;
export const Text = styled.p`
  ${TextStyle}
`;

export const InlineText = styled.span`
  ${TextStyle}
`;

export const Perex = styled(Text)`
  ${mq.tablet} {
    font-size: 2.4rem;
    line-height: 4rem;
    margin: 0 0 4rem;
  }
`;

export const Hint = styled.small`
  display: block;
  font-size: 1.2rem;
  color: ${props => props.theme.color.secondaryText};
  line-height: 2rem;
  margin-bottom: 0.7rem;

  ${commonConfigurability}
`;

export const Link = styled(StyledLink)`
  position: relative;
  color: ${props => props.theme.color.primaryAccent};
  font-weight: ${props => props.theme.fontWeights.medium};
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  line-height: 3rem;
  display: inline-block;
  width: fit-content;

  ${p =>
    !p.noline &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0.4rem;
        left: 0;
        width: 100%;
        border-bottom: 0.2rem solid transparent;
      }

      &:hover {
        &::after {
          border-color: ${props => props.theme.color.primaryAccent};
        }
      }
    `}

  ${p =>
    p.nobold &&
    css`
      font-weight: ${props => props.theme.fontWeights.normal};
    `}
  ${commonConfigurability}
`;

export const NavLink = styled(StyledLink)`
  color: ${props => props.theme.color.primaryText};
  font-weight: ${props => props.theme.fontWeights.book};
  font-size: 1.3rem;
  transition: color 0.25s ease;
  border: none;
  outline: none;
  cursor: pointer;

  & + & {
    margin-left: 2rem;

    ${mq.tabletWide} {
      margin-left: 2.9rem;
    }
    ${mq.desktop} {
      margin-left: 3.7rem;
    }
    ${mqMax.tablet} {
      margin-left: 1rem;
    }
  }
  ${p =>
    p.secondary &&
    css`
      color: ${props => props.theme.color.secondaryText};

      &:hover {
        color: ${props => props.theme.color.primaryText};
      }
    `}
`;
