// @flow
import {trackCard} from 'analytics/creditCard/analyticsTraits';
import {getUserQuery} from 'data/user/graphql/queries';
import {selectLoggedIn} from 'data/user/selectors';
import {query} from 'global/apolloClient/helpers';
import store from 'global/store';

export const trackErrorData = (err: any) => {
  if (!selectLoggedIn(store.getState())) {
    return;
  }
  query(getUserQuery)
    .then(user => {
      if (!err.data.code) {
        return;
      }

      let error;
      switch (err.data.code) {
        case 'E_PAYMENT_API_CONNECTION':
        case 'E_PAYMENT_API':
        case 'E_PAYMENT_CARD':
        case 'E_PAYMENT_INVALID_REQUEST':
        case 'E_PAYMENT_RATE_LIMIT':
        case 'E_PAYMENT_NO_TOKEN':
        case 'E_PAYMENT_CUSTOMER_EXISTS':
        case 'E_PAYMENT_CUSTOMER_DOES_NOT_EXISTS':
        case 'E_PAYMENT_ERROR':
          error = true;
          break;
        default:
          error = false;
          break;
      }

      return {
        error,
        user,
        desc: err.message || '',
      };
    })
    .then(res => {
      if (res && res.error === true) {
        return trackCard('Card Rejected', res.user, res.desc);
      }
    });
};
