// @flow
import ShopIcon from 'assets/icons/ShopIcon';
import TruckIcon from 'assets/icons/TruckIcon';
import {Spread} from 'componentsStyled/Layout/Flex';
import {Text} from 'componentsStyled/Typography/Texts';
import {FieldTitle} from 'componentsStyled/Typography/Titles';
import type {FulfillmentType} from 'data/bookings/types';
import {formatReadableDateRange} from 'data/units/date/formatters';
import {FulfillmentTitle} from 'pages/Cart/CartForm/Dates/styled';
import * as React from 'react';

type Props = {
  dates: any,
  originalReservation?: any,
  fulfillmentType?: FulfillmentType,
};

const FulfillmentDisplay = ({fulfillmentType}) =>
  fulfillmentType === 'DELIVERY' ? (
    <FulfillmentTitle>
      <TruckIcon /> Delivery
    </FulfillmentTitle>
  ) : (
    <FulfillmentTitle>
      <ShopIcon /> Pick up
    </FulfillmentTitle>
  );

const Dates = ({dates, originalReservation, fulfillmentType}: Props) => (
  <React.Fragment>
    {!originalReservation && <FulfillmentDisplay fulfillmentType={fulfillmentType} />}
    <FieldTitle>Dates</FieldTitle>
    {originalReservation ? (
      <div>
        <Spread>
          <Text>Previous Dates</Text>
          <Text>{formatReadableDateRange(originalReservation)}</Text>
        </Spread>
        <Spread>
          <Text book>New Dates</Text>
          <Text book>{formatReadableDateRange(dates)}</Text>
        </Spread>
      </div>
    ) : (
      <Text>{formatReadableDateRange(dates)}</Text>
    )}
  </React.Fragment>
);
export default Dates;
