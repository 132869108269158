// @flow
import AffiliateList from 'components/AffiliateList';
import {Container} from 'componentsStyled/Layout/Containers';
import {WhiteWrap} from 'componentsStyled/Layout/WhiteWrap';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import {nearbyStoresQuery} from 'data/affiliate/graphql';
import type {Location} from 'data/app/types';
import withQuery from 'hoc/withQuery';
import React from 'react';
import {type HOC, compose} from 'recompose';

const SectionTopSellers = ({data}) => (
  <React.Fragment>
    <WhiteWrap>
      <Container>
        <PageTitle>Top Locations Nearby</PageTitle>
        <AffiliateList affiliates={data} />
      </Container>
    </WhiteWrap>
  </React.Fragment>
);

type Outter = {|
  geolocations: ?(Location[]),
|};

const enhancer: HOC<*, Outter> = compose(
  withQuery(nearbyStoresQuery, {
    noEmpty: true,
    variables: ({geolocations}) => ({
      limit: 4,
      discountId: undefined,
      locationIds: geolocations ? geolocations.map(geo => geo.id) : undefined,
      categories: undefined,
    }),
  })
);

export default enhancer(SectionTopSellers);
