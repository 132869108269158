// @flow
import {Label, Text} from 'componentsStyled/Typography/Texts';
import styled, {css} from 'styled-components';

export const ItemGroupsWrap = styled.div`
  padding: 1rem 2.5rem 1.6rem 2.5rem;
`;

export const GroupWrap = styled.div`
  margin: 2rem 0;
`;

export const GroupLabel = styled(Label)`
  margin-bottom: 0.5rem;
`;

export const GroupItem = styled(Text)`
  cursor: pointer;
  ${p =>
    p.active &&
    css`
      color: ${props => props.theme.color.primaryAccent};
      font-weight: ${props => props.theme.fontWeights.book};
    `}
`;

export const NoResults = styled.div`
  padding: 1.2rem 2.5rem 1.6rem 2.5rem;
  margin: 0.5rem 0;
  color: ${props => props.theme.color.secondaryText};
`;
