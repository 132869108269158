import styled, {css} from 'styled-components';

export const ShowMoreContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: ${p => p.maxHeight};

  ${p =>
    p.showMore &&
    css`
      max-height: initial;
    `}
`;

export const ShowMoreButton = styled.span`
  cursor: pointer;
  color: ${props => props.theme.color.primaryAccent};
  background: white;
`;

export const ShowMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
