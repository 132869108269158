// @flow
import RadioGroup from 'components/Radio/RadioGroup';
import type {ProductVariantOnProduct} from 'data/product/types';
import * as React from 'react';

import {StyledVariantSelect, VariantPill} from './styled';

export type Props = {|
  productVariants: ProductVariantOnProduct[],
  onChange: (value: any) => any,
  value: any,
|};

export const VariantSelect = ({productVariants, onChange, value}: Props) => {
  if (!productVariants) {
    return null;
  }

  return (
    <StyledVariantSelect>
      <RadioGroup name="variant" {...{onChange, value}}>
        {productVariants.map(variant => (
          <VariantPill data-cy="variant-radio-button" key={variant.id} value={variant.id}>
            {variant.name}
          </VariantPill>
        ))}
      </RadioGroup>
    </StyledVariantSelect>
  );
};

export default VariantSelect;
