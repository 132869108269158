// @flow
import {formatActivity} from 'data/app/formatters';
import type {Activity} from 'data/app/types';
import type {User} from 'data/user/types';

import {tryTrack} from '../helpers';

const activityTrait = (a: Activity, u: ?User) => ({
  email: u && u.email,
  activity: formatActivity(a),
});

const categoryTrait = (a: Activity, u: ?User) => ({
  email: u && u.email,
  category: a.name,
});

export const trackActivity = (a: Activity, u: ?User) =>
  tryTrack('Activity', () => activityTrait(a, u));

export const trackCategory = (a: Activity, u: ?User) =>
  tryTrack('Product Group', () => categoryTrait(a, u));
