// @flow
import type {Theme} from 'global/theme';
import React from 'react';
import {withTheme} from 'styled-components';

const CheckIcon = ({theme}: {theme: Theme}) => (
  <svg width="14" height="12">
    <path
      d="M1.1 5.452L5.556 9.95l7.013-7.997"
      stroke={theme.color.white}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

export default withTheme(CheckIcon);
