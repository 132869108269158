import ShopIcon from 'assets/icons/ShopIcon';
import TruckIcon from 'assets/icons/TruckIcon';
import {
  BagAffiliateGroupContainer,
  DeliveryExplanationContent,
  DeliveryExplanationWrapper,
  HeadingWrap,
} from 'components/Bag/BagAffiliateGroup/styled';
import BagBookingGroup from 'components/Bag/BagBookingGroup';
import CollapsibleParagraph from 'components/CollapsibleParagraph';
import type {FulfillmentType} from 'data/bookings/types';
import {ReservationPricingForAffiliate} from 'data/reservations/types';

export type Props = {
  reservationPricingForAffiliate: ReservationPricingForAffiliate,
};

export type HeadingProps = {
  name: string,
  fulfillmentType: FulfillmentType,
};

const Heading = ({name, fulfillmentType}: HeadingProps) => {
  const isDelivery = fulfillmentType === 'DELIVERY';
  const Icon = isDelivery ? TruckIcon : ShopIcon;
  const headingText = isDelivery ? `Delivery from ${name}` : `Pick up from ${name}`;

  return (
    <HeadingWrap>
      <Icon /> {headingText}
    </HeadingWrap>
  );
};

const BagAffiliateGroup = ({reservationPricingForAffiliate}: Props) => {
  const {inStoreReservations, deliveryReservations, affiliate} = reservationPricingForAffiliate;
  const {deliveryExplanation} = affiliate;

  return (
    <BagAffiliateGroupContainer>
      {/*In store bookings */}
      {inStoreReservations &&
        inStoreReservations.length > 0 &&
        inStoreReservations.map(reservationPricingForDate => (
          <div key={reservationPricingForDate.date}>
            <Heading name={affiliate.name} fulfillmentType="IN_STORE" />
            <BagBookingGroup
              fulfillmentType="IN_STORE"
              affiliate={affiliate}
              pricingDetails={reservationPricingForDate}
            />
          </div>
        ))}
      {/*Delivery bookings */}
      {deliveryReservations &&
        deliveryReservations.length > 0 &&
        deliveryReservations.map(reservationPricingForDate => (
          <div key={reservationPricingForDate.date}>
            <Heading name={affiliate.name} fulfillmentType="DELIVERY" />
            <BagBookingGroup
              fulfillmentType="DELIVERY"
              affiliate={affiliate}
              pricingDetails={reservationPricingForDate}
            />
            {deliveryExplanation && (
              <DeliveryExplanationWrapper>
                <CollapsibleParagraph
                  group="How delivery works for this store"
                  showExpandText={false}
                >
                  <DeliveryExplanationContent description={deliveryExplanation} multiLine />
                </CollapsibleParagraph>
              </DeliveryExplanationWrapper>
            )}
          </div>
        ))}
    </BagAffiliateGroupContainer>
  );
};

export default BagAffiliateGroup;
