// @flow
import React from 'react';

import {ActivityTileWrap, Inner, Subtitle, TileWrap, Title} from './styled';

type Props = {|
  img: string,
  title: string,
  subtitle: string,
  onClick: Function,
|};

const ActivityTile = ({img, title, subtitle, onClick}: Props) => (
  <TileWrap>
    <ActivityTileWrap bg={img} onClick={onClick} draggable="false">
      <Inner>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Inner>
    </ActivityTileWrap>
  </TileWrap>
);

export default ActivityTile;
