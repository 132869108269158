// @flow
import StyledLink from 'components/StyledLink';
import styled from 'styled-components';

export const ReservationLinkWrap = styled(StyledLink)`
  font-size: 1.3rem;
  font-weight: ${props => props.theme.fontWeights.book};
  color: ${props => props.theme.color.primaryText};
  transition: color 0.25s ease;

  &:hover {
    color: ${props => props.theme.color.primaryAccent};
  }
`;
