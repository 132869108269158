// @flow
import type {Theme} from 'global/theme';
import React from 'react';
import {withTheme} from 'styled-components';

import {ArrowWrap} from './styled';

const rotations = {
  left: 90,
  up: 180,
  right: -90,
};

type Props = {
  theme: Theme,
  rotation?: string,
  color?: 'black' | 'primary' | 'gray' | 'white' | 'current',
  marginLeft?: boolean,
  floatRight?: boolean,
};

const ArrowIcon = ({rotation, color, marginLeft, floatRight, theme}: Props) => {
  function getFill() {
    switch (color) {
      case 'black':
        return theme.color.primaryText;
      case 'primary':
        return theme.color.primaryAccent;
      case 'gray':
        return theme.color.secondaryText;
      case 'white':
        return theme.color.white;
      case 'current':
        return 'currentColor';
      default:
        return theme.color.primaryText;
    }
  }

  return (
    <ArrowWrap
      rotation={rotation && rotations[rotation]}
      marginLeft={marginLeft}
      floatRight={floatRight}
    >
      <svg width="18" height="18">
        <path
          d="M8.839 7.374l5.303-5.303L12.374.303 7.071 5.607 1.768.303 0 2.071l7.071 7.071L8.84 7.374z"
          fill={getFill()}
          fillRule="evenodd"
          transform="translate(2, 4)"
        />
      </svg>
    </ArrowWrap>
  );
};

export default withTheme(ArrowIcon);
