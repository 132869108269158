// @flow
import Button from 'components/Button';
import {H2, Text} from 'componentsStyled/Typography';
import React from 'react';

import {InnerContainer} from '../styled';

type Props = {|
  openLogin: Function,
|};

const Success = ({openLogin}: Props) => (
  <InnerContainer>
    <H2>Your password has been successfully reset!</H2>
    <Text>You can now login with your new credentials</Text>
    <Button onClick={openLogin}>Login</Button>
  </InnerContainer>
);

export default Success;
