// @flow
import urls from 'data/router/urls';
import withRouter from 'hoc/withRouter';
import SubPage from 'pages/_Page/SubPage';
import SettingList from 'pages/Account/Routes/SettingList';
import * as React from 'react';

const List = props => (
  <SubPage title="Account" backUrl={urls.products}>
    <SettingList />
  </SubPage>
);

export default withRouter(List);
