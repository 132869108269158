// @flow
import type {DateRangeValue} from 'data/units/date/types';

import type {AutocompleteItem} from './graphql/queries/autocomplete';
import type {SearchParams} from './types';

type Filter = {|
  type: 'SEARCH/FILTER',
  payload: SearchParams,
|};
export const filter = (params: SearchParams): Filter => ({
  type: 'SEARCH/FILTER',
  payload: params,
});

type FilterDates = {|
  type: 'SEARCH/FILTER_DATES',
  payload: ?DateRangeValue,
|};
export const filterDates = (d: ?DateRangeValue): FilterDates => ({
  type: 'SEARCH/FILTER_DATES',
  payload: d,
});

type ClearProduct = {|
  type: 'SEARCH/CLEAR_PRODUCT',
|};
export const clearProduct = (): ClearProduct => ({
  type: 'SEARCH/CLEAR_PRODUCT',
});

type ClearAffiliate = {|
  type: 'SEARCH/CLEAR_AFFILIATE',
|};
export const clearAffiliate = (): ClearAffiliate => ({
  type: 'SEARCH/CLEAR_AFFILIATE',
});

type Clear = {|
  type: 'SEARCH/CLEAR',
|};
export const clear = (): Clear => ({
  type: 'SEARCH/CLEAR',
});

type AddRecentSearch = {|
  type: 'SEARCH/ADD_RECENT_SEARCH',
  payload: AutocompleteItem,
|};
export const addRecentSearch = (x: AutocompleteItem): AddRecentSearch => ({
  type: 'SEARCH/ADD_RECENT_SEARCH',
  payload: x,
});

type TriggerRecentSearch = {|
  type: 'SEARCH/TRIGGER_RECENT_SEARCH',
  payload: AutocompleteItem,
|};
export const triggerRecentSearch = (x: AutocompleteItem): TriggerRecentSearch => ({
  type: 'SEARCH/TRIGGER_RECENT_SEARCH',
  payload: x,
});

export type SearchAction =
  | Filter
  | FilterDates
  | ClearProduct
  | ClearAffiliate
  | Clear
  | AddRecentSearch
  | TriggerRecentSearch;
