// @flow
import DeliverySummary from 'components/DeliverySummary';
import {DetailsGroup} from 'components/DeliverySummary/styled';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {lighten} from 'global/theme';
import styled from 'styled-components';

export const HighlightedWrap = styled(Wrap)`
  background: ${props => lighten(props.theme.color.primaryAccent, 0.1)};
`;

export const StyledDeliverySummary = styled(DeliverySummary)`
  ${DetailsGroup} {
    p:first-child {
      font-size: 1.3rem;
      line-height: 1.5;
      font-weight: bold;
    }
  }
`;
