// @flow
import {lighten} from 'global/theme';
import styled from 'styled-components';

export const WarningWrap = styled.div`
  background: ${props => lighten(props.theme.color.red, 0.1)};
  color: ${props => props.theme.color.red};
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 1rem;
  border-radius: 0.4rem;
  margin-bottom: 2.5rem;
  text-align: center;
`;
