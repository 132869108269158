// @flow
import type {Mutation} from 'common/graphql/types';
import {reservationCountQuery, reservationListQuery} from 'data/reservations/graphql/queries';
import type {ReservationInput} from 'data/reservations/types';
import gql from 'graphql-tag';

export const createOrderFromReservationsMutation: Mutation<{|
  reservations: ReservationInput[],
|}> = {
  gql: gql`
    mutation createOrderFromReservations($reservations: [ReservationInput!]!) {
      order {
        createOrderFromReservations(reservations: $reservations) {
          orderId
          tenantId
          customerId
          bookings {
            affiliateId
          }
        }
      }
    }
  `,
  selector: ['order'],
  refetch: [reservationListQuery, reservationCountQuery],
};

export const markItemAsInbound: Mutation<{|token: string|}> = {
  gql: gql`
    mutation MarkInbound($token: String!) {
      booking {
        markInbound(token: $token) {
          bookingId
        }
      }
    }
  `,
};
