// @flow
import StyledLink from 'components/StyledLink';
import {Text} from 'componentsStyled/Typography/Texts';
import {FieldTitle} from 'componentsStyled/Typography/Titles';
import styled from 'styled-components';

export const AffiliateLink = styled(StyledLink)`
  text-decoration: none;
  color: ${props => props.theme.color.primaryAccent};
`;

export const LinkWrap = styled(Text)`
  margin-bottom: 2.4rem;
`;

export const SectionTitle = styled(FieldTitle)`
  margin-top: 2.4rem;
`;

export const Inventory = styled.div`
  position: absolute;
  background: #fff;
  left: 0;
`;

export const Split = styled.div`
  display: flex;
  margin-bottom: 6rem;
`;

export const Left = styled.div`
  width: 45rem;
  margin-right: 5rem;
  flex-shrink: 0;
`;

export const Right = styled.div`
  flex-grow: 1;
`;
