// @flow
import {mq} from 'common/mediaQuery';
import {Container} from 'componentsStyled/Layout/Containers';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 4rem;

  ${mq.tabletWide} {
    padding-bottom: 0;
  }
`;

export const StyledContainer = styled(Container)`
  ${mq.tabletWide} {
    padding-bottom: 2rem;
  }
`;
