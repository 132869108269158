// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import * as React from 'react';

import {BackLink} from './styled';

type Props = {|
  children: React.Node,
  to: string,
  withFilter?: boolean,
|};

const ArrowLink = ({children, ...rest}: Props) => (
  <BackLink {...rest}>
    <ArrowIcon rotation="left" />
    <span>{children}</span>
  </BackLink>
);

export default ArrowLink;
