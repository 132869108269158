// @flow
import styled from 'styled-components';

export const LogoCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SearchWrapper = styled.div`
  margin-right: 1.5rem;
  width: 100%;

  display: flex;
  justify-content: center;
`;
