// @flow
import {VPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {selectBasketItems} from 'data/basket/selectors';
import type {PendingReservation} from 'data/basket/types';
import urls from 'data/router/urls';
import withConnect from 'hoc/withConnect';
import SubPage from 'pages/_Page/SubPage';
import DeliveryAddressForm from 'pages/DeliveryAddress/DeliveryAddressForm';
import {StyledPageTitle} from 'pages/DeliveryAddress/styled';
import React from 'react';
import {Redirect} from 'react-router-dom';
import type {HOC} from 'recompose';

type Props = {
  items: PendingReservation[],
};

const DeliveryAddress = ({items}: Props) => {
  //If we have no delivery reservations, redirect user away from this page
  if (!items.some(item => item.fulfillmentType === 'DELIVERY')) {
    return <Redirect to={urls.shoppingBasket} />;
  }

  return (
    <SubPage title="Delivery Address" backUrl={urls.shoppingBasket}>
      <VPaddedFullWidthContainer narrow>
        <StyledPageTitle>Delivery Address</StyledPageTitle>
        <DeliveryAddressForm />
      </VPaddedFullWidthContainer>
    </SubPage>
  );
};

const mapStateToProps = state => ({
  items: selectBasketItems(state),
});

const enhancer: HOC<*, {}> = withConnect(mapStateToProps);

export default enhancer(DeliveryAddress);
