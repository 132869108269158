// @flow
import styled, {css} from 'styled-components';

export const PageWrap = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrap = styled.header`
  width: 100%;
  z-index: ${props => props.theme.zIndices.header};

  ${props =>
    !props.advancedHeader &&
    css`
      padding: 12px 0px;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      margin: 0px auto;
    `}
`;

export const MessageWrap = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const NavWrap = styled.div`
  padding: 1rem 0;
`;

export const BodyWrap = styled.main`
  flex: 1;
`;

export const FooterWrap = styled.footer``;

export const Line = styled.div`
  border-bottom: 0.1rem solid ${props => props.theme.color.light};
`;

export const PageContent = styled.div`
  padding-top: 6rem;
`;

export const PageContentWithFilter = styled.div`
  padding-top: 11.1rem;
`;
