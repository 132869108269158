// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import React from 'react';

import {List} from './styled';

const Content = () => (
  <React.Fragment>
    <Text black book>
      What does deleting your account mean?
    </Text>
    <List>
      <li>
        <Text>All upcoming reservations will be cancelled immediately.</Text>
      </li>
      <li>
        <Text>
          You are still financially responsible for any fees, claims, or reimbursements related to
          your past or upcoming reservations.
        </Text>
      </li>
      <li>
        <Text>You will no longer be able to login to your account.</Text>
      </li>
      <li>
        <Text>Any information associated with your account will be deleted.</Text>
      </li>
      <li>
        <Text>If you choose to proceed, the action will be irreversible.</Text>
      </li>
    </List>
  </React.Fragment>
);

export default Content;
