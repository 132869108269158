// @flow
import {type HOC, lifecycle} from 'recompose';

function withOnUnmount<T>(onUnmount: T => void): HOC<T, T> {
  return lifecycle({
    componentWillUnmount() {
      onUnmount(this.props);
    },
  });
}

export default withOnUnmount;
