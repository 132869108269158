// @flow
import {type HOC, lifecycle} from 'recompose';

function withOnMount<T>(onMount: T => void): HOC<T, T> {
  return lifecycle({
    componentDidMount() {
      onMount(this.props);
    },
  });
}

export default withOnMount;
