import styled, {css} from 'styled-components';

export const lineItemGrid = css`
  display: grid;
  grid-template-columns: 1fr ${props => (props.canDelete ? '10rem' : '7rem')};
  grid-column-gap: 3.2rem;
  grid-row-gap: 1rem;
`;

export const StyledBag = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

export const LineItems = styled.div`
  ${lineItemGrid}
`;

export const LineItemName = styled.span`
  justify-self: end;
`;

export const SubtotalAmountGroup = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
`;

export const SummaryTitle = styled.p`
  padding-bottom: 1rem;
  margin: 0 0 1rem 0;
  border-bottom: 1px solid ${props => props.theme.color.lightGray};
`;

export const SummarySection = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.color.lightGray};
`;
