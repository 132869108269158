import ArrowIcon from 'assets/icons/ArrowIcon';
import * as React from 'react';

import StyledButton from './styled';

type Props = {
  onClick: Function,
  direction: 'left' | 'right',
};

const ChangeSlideButton = ({onClick, direction = 'left'}: Props) => (
  <StyledButton onClick={onClick}>
    <ArrowIcon rotation={direction} />
  </StyledButton>
);

export default ChangeSlideButton;
