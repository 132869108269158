// @flow
import errorMap from 'common/graphql/getErrorMessage/errorMap';
import Button from 'components/Button';
import {Col, Row} from 'componentsStyled/Layout/Grid';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {center} from 'componentsStyled/Shared';
import {Text} from 'componentsStyled/Typography/Texts';
import {FieldTitle} from 'componentsStyled/Typography/Titles';
import {isPrepaid} from 'data/stripe/helpers';
import type {StripeCard, StripeTokenId} from 'data/stripe/types';
import Alert from 'forms/Alert';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import React from 'react';
import {CardCVCElement, CardExpiryElement, CardNumberElement} from 'react-stripe-elements';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';
import {withTheme} from 'styled-components';

import LockerIcon from './LockerIcon';
import PoweredBy from './PoweredBy';
import {getStripeStyle, StyledForm, StyledFormContent, StyledLabel, TextWrap} from './styled';
import withInjectStripe from './withInjectStripe';

const CheckoutForm = ({
  theme,
  error,
  loading,
  handleSubmit,
  displayText = 'Please add payment details to be able to make reservations',
  submitText = 'Confirm',
  currentStep,
  finalStep,
}) => (
  <React.Fragment>
    <StyledForm onSubmit={handleSubmit} autoComplete="new-cc">
      <ModalContent>
        <StyledFormContent autoComplete="new-cc">
          <Wrap stack bottom>
            {displayText && (
              <TextWrap>
                <Text book black>
                  {displayText}
                </Text>
              </TextWrap>
            )}
            <Alert>{error}</Alert>
            <center>
              <PoweredBy />
            </center>
            <br />
            <StyledLabel>
              <FieldTitle>
                Card number&nbsp;&nbsp;&nbsp;
                <LockerIcon />
              </FieldTitle>
              <CardNumberElement style={getStripeStyle(theme)} autoComplete="new-cc" />
            </StyledLabel>
            <Row>
              <Col>
                <StyledLabel>
                  <FieldTitle>Expiration</FieldTitle>
                  <CardExpiryElement style={getStripeStyle(theme)} />
                </StyledLabel>
              </Col>
              <Col>
                <StyledLabel>
                  <FieldTitle>CVC</FieldTitle>
                  <CardCVCElement style={getStripeStyle(theme)} />
                </StyledLabel>
              </Col>
            </Row>
          </Wrap>
        </StyledFormContent>
      </ModalContent>
      <ModalControls column>
        {currentStep && finalStep ? (
          <Text black center>
            Step{' '}
            <Text inline black bold>
              {currentStep}
            </Text>{' '}
            of {finalStep}
          </Text>
        ) : (
          ''
        )}
        <Button fullwidth loading={loading}>
          {submitText}
        </Button>
      </ModalControls>
    </StyledForm>
  </React.Fragment>
);

type Outter = {|
  onSubmit: StripeTokenId => Promise<mixed>,
  type: 'add' | 'edit',
  data?: StripeCard,
  displayText?: string,
  submitText?: string,
  currentStep?: string,
  finalStep?: string,
|};

const enhancer: HOC<*, Outter> = compose(
  withInjectStripe,
  withStateHandlers(
    {
      error: null,
      loading: false,
    },
    {
      setError: () => error => ({error}),
      setLoading: () => loading => ({loading}),
    }
  ),
  withHandlers({
    handleError: props => (errorMessage: any) => {
      props.setLoading(false);
      props.setError(errorMessage);
    },
  }),
  withHandlers({
    handleSubmit: props => event => {
      // We don't want to let default form submission happen here, which would refresh the page.
      event.preventDefault();

      const {setLoading, setError, stripe, onSubmit, handleError} = props;

      // Within the context of `Elements`, this call to createToken knows which Element to
      // tokenize, since there's only one in this group.
      setLoading(true);
      setError(null);

      stripe.createToken().then(({token, error}) => {
        if (error) {
          return handleError(error.message);
        }

        if (!token) {
          return handleError(errorMap.E_PAYMENT_NO_TOKEN);
        }

        if (isPrepaid(token)) {
          return handleError(errorMap.E_PAYMENT_PREPAID_CARD);
        }

        onSubmit(token.id).then(() => {
          setLoading(false);
        });
      });
    },
  }),
  withTheme
);

export default enhancer(CheckoutForm);
