// @flow
import {mq} from 'common/mediaQuery';
import Description from 'components/Description';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import styled from 'styled-components';

export const DetailWrap = styled(Wrap)`
  ${mq.tabletWide} {
    max-width: 40rem;
    margin: 0 auto;
    padding: 0;
    border: 0;
  }
`;

export const SectionHeading = styled.div`
  font-size: 1.4rem;
  color: ${props => props.theme.color.secondaryText};
  font-weight: ${props => props.theme.fontWeights.bold};
  letter-spacing: 0.05rem;
  margin-bottom: 1.5rem;
`;

export const DetailWrapNoPadding = styled(DetailWrap)`
  padding: 0;
`;

export const StyledDescription = styled(Description)`
  &.ql-editor {
    padding: 0;
  }
`;
