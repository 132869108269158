// @flow
import Button from 'components/Button';
import {push} from 'connected-react-router';
import {openModal} from 'data/modals/actions';
import urls from 'data/router/urls';
import withConnect from 'hoc/withConnect';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {StyledText as Text} from './styled';

const ExploreButton = ({handleClick, large, fullwidth, text = 'Get a free day'}) => (
  <Button onClick={handleClick} fullwidth={fullwidth} large={large}>
    <Text>{text}</Text>
  </Button>
);

const mapDispatchToProps = {
  openModal,
  push,
};

type Outter = {|
  large?: boolean,
  fullwidth?: boolean,
  text?: string,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withHandlers({
    handleClick: props => () => {
      props.push(urls.products);
    },
  })
);

export default enhancer(ExploreButton);
