import {StyledInput} from 'componentsStyled/Forms/Input';
import {isDark} from 'global/theme';
import styled, {css} from 'styled-components';

export const AddressTextInput = styled(StyledInput)``;

export const SuggestionsContainer = styled.div`
  width: 100%;
  position: absolute;
  z-index: ${props => props.theme.zIndices.contextMenu};
`;

export const SuggestionsList = styled.ul`
  background: ${props => props.theme.color.white};
  border: solid 0.1rem ${props => props.theme.color.darkGray};
  border-radius: 0.4rem;
  margin-top: 0.5rem;
  overflow: scroll;
  max-height: 16rem;
`;

export const SuggestionItem = styled.li`
  padding: 1.2rem 1.7rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1;
  color: ${props => props.theme.color.secondaryText};
`;

export const SelectableSuggestionItem = styled(SuggestionItem)`
  cursor: pointer;

  ${props =>
    props.isActive &&
    css`
      background: ${props.theme.color.primaryAccent};
      ${isDark(props.theme.color.primaryAccent) && `color: ${props.theme.color.white}`};
    `}
`;

export const AddressAutocompleteWrapper = styled.div`
  position: relative;
`;
