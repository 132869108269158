// @flow
import {chooseActivity} from 'data/app/actions';
import {selectActivity, selectGeolocation} from 'data/app/selectors';
import urls from 'data/router/urls';
import {addRecentSearch, clear, filter} from 'data/search/actions';
import {
  type AutocompleteItem,
  searchAutoCompleteQuery,
} from 'data/search/graphql/queries/autocomplete';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import withRouter from 'hoc/withRouter';
import withValue from 'hoc/withValue';
import {type HOC, compose, withHandlers} from 'recompose';

const mapStateToProps = state => ({
  activity: selectActivity(state),
  geolocation: selectGeolocation(state),
});

const mapDispatchToProps = {
  filter,
  chooseActivity,
  addRecentSearch,
  clear,
};

type Outter = {|
  close: Function,
  isOpen: boolean,
  open: Function,
  initialValue: ?string,
|};

const withAutocomplete: HOC<*, Outter> = compose(
  withValue,
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onItemSelect: props => (item: AutocompleteItem) => {
      props.clear();
      props.addRecentSearch(item);
      props.setValue('');
      props.close();

      if (item.type === 'affiliate') {
        props.history.push(urls.affiliate(item.id));
      }
    },
    onQueryChange: props => props.setValue,
    clearQuery: props => () => {
      props.filter({name: undefined});
      props.setValue('');
    },
  }),
  withQuery(searchAutoCompleteQuery, {
    noLoader: true,
    noEmpty: true,
    variables: props => ({
      query: props.value,
    }),
    config: {
      skip: props => !props.value,
    },
  })
);

export default withAutocomplete;
