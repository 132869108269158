import {booleanOptional} from 'forms/validators/boolean';
import {
  emailOptional,
  phoneNumberOptional,
  stringOptional,
  stringRequired,
} from 'forms/validators/string';
import * as yup from 'yup';

const schema = {
  name: stringRequired,
  company: stringOptional,
  //If using manual address entry then validate as required string, else using address
  //autocomplete and validate the presence of addressObject to verify the value isn't
  //manually entered text.
  address: stringRequired.when('useManualAddress', {
    is: value => {
      return !value;
    },
    then: yup.string().test({
      name: 'hasAddressObject',
      message: 'Please select a valid address or you may choose to enter one manually',
      test: function () {
        return this.parent.addressObject;
      },
    }),
  }),
  instructions: stringOptional,
  enableAdditionalContact: booleanOptional,
  additionalEmail: stringOptional.when('enableAdditionalContact', {
    is: true,
    then: emailOptional,
  }),
  additionalPhone: stringOptional.when('enableAdditionalContact', {
    is: true,
    then: phoneNumberOptional,
  }),
  useManualAddress: booleanOptional,
  //Not a field in the DOM, just used to store the data from the address lookup
  addressObject: yup.object().nullable(),
};

export default schema;
