// @flow
import type {Activity} from './types';

export const activities: {
  ['SURF' | 'SKI' | 'SNOWBOARD' | 'SPLITBOARD' | 'SUP' | 'ROAD_BIKE' | 'MOUNTAIN_BIKE']: Activity,
} = {
  SURF: {
    id: 1,
    name: 'surfs',
  },
  SKI: {
    id: 2,
    name: 'skis',
  },
  SNOWBOARD: {
    id: 3,
    name: 'snowboards',
  },
  SPLITBOARD: {
    id: 4,
    name: 'splitboards',
  },
  SUP: {
    id: 5,
    name: 'sups',
  },
  ROAD_BIKE: {
    id: 6,
    name: 'road_bikes',
  },
  MOUNTAIN_BIKE: {
    id: 7,
    name: 'mountain_bikes',
  },
};

export const getActivities = (): Activity[] => {
  const activityKeys = Object.keys(activities);

  const activityList = activityKeys.map(activityKey => activities[activityKey]);

  return activityList;
};

export const URLParam: {
  ['brand' | 'product' | 'location' | 'affiliate' | 'activity' | 'productType']: string,
} = {
  brand: 'brand',
  product: 'product',
  location: 'location',
  affiliate: 'affiliate',
  activity: 'activity',
  productType: 'product_type',
};
