// @flow
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {Text} from 'componentsStyled/Typography/Texts';
import type {StripeCard, StripeTokenId} from 'data/stripe/types';
import config from 'global/config';
import withOnMount from 'hoc/withOnMount';
import React from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import Input from './Input';

const stripeKeyExists = config.stripeKey && config.stripeKey !== '';

const CreditCardForm = ({
  data,
  stripe,
  initStripe,
  onSubmit,
  type,
  displayText,
  submitText,
  currentStep,
  finalStep,
}) => {
  if (!stripeKeyExists) {
    return (
      <Wrap>
        <Text>Stripe failed to load</Text>
      </Wrap>
    );
  }

  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <Input
          onSubmit={onSubmit}
          type={type}
          data={data}
          displayText={displayText}
          submitText={submitText}
          currentStep={currentStep}
          finalStep={finalStep}
        />
      </Elements>
    </StripeProvider>
  );
};

type Outter = {|
  onSubmit: StripeTokenId => Promise<mixed>,
  type: 'add' | 'edit',
  data?: StripeCard,
  displayText?: string,
  submitText?: string,
  currentStep?: string,
  finalStep?: string,
|};

const enhancer: HOC<*, Outter> = compose(
  withStateHandlers(
    {
      stripe: null,
    },
    {
      setStripe:
        () =>
        ({stripe}) => ({stripe}),
    }
  ),
  withHandlers({
    initStripe: props => () => {
      if (stripeKeyExists) {
        const stripe = window.Stripe(config.stripeKey);
        props.setStripe({stripe});
      }
    },
  }),
  withOnMount(props => {
    if (window.Stripe) {
      props.initStripe();
    } else {
      const el = document.querySelector('#stripe-js');
      el &&
        el.addEventListener('load', () => {
          props.initStripe();
        });
    }
  })
);

export default enhancer(CreditCardForm);
