// @flow
import type {ID} from 'data/enums/types';
import {productDetailQuery} from 'data/product/graphql/queries';
import urls from 'data/router/urls';
import withQuery from 'hoc/withQuery';
import SubPage from 'pages/_Page/SubPage';
import React from 'react';
import {type HOC} from 'recompose';

import ExtendReservation from './CheckoutForm';

const Checkout = ({data, match}) => {
  const variantAffiliate = data.affiliates.find(a => a.id === +match.params.affiliateId);
  if (!variantAffiliate) return null;

  return (
    <SubPage title="Checkout" backUrl={urls.cart(data.id, variantAffiliate.id)}>
      {variantAffiliate && (
        <ExtendReservation productVariant={data} variantAffiliate={variantAffiliate} />
      )}
    </SubPage>
  );
};

type Outter = {|
  match: {|
    params: {|
      productVariantId: ID,
      affiliateId: ID,
    |},
  |},
|};

const enhancer: HOC<*, Outter> = withQuery(productDetailQuery, {
  variables: props => ({
    affiliateId: props.match.params.affiliateId,
    productVariantId: props.match.params.productVariantId,
  }),
});

export default enhancer(Checkout);
