// @flow
import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 2rem;
  }
`;

export const RrpPrice = styled.span`
  text-decoration: line-through;
  font-weight: 400;
`;
