// @flow
import type {ProductVariantAvailableAffiliate} from 'data/product/types';
import type {OriginalReservation} from 'data/reservations/types';
import {getDaysApart} from 'data/units/date/helpers';
import type {DateRange} from 'data/units/date/types';
import {repeat} from 'ramda';

export const getPricePerDayArray = (
  dates: ?DateRange,
  a: ProductVariantAvailableAffiliate,
  originalReservation?: ?OriginalReservation
): number[] => {
  if (!dates) return [];

  const pricing = a.pricing;

  const daysApart = getDaysApart(dates.endDate, dates.startDate) + 1;
  const daysWithinPricing = pricing.slice(0, daysApart);
  const daysForLastPrice = repeat(
    pricing[pricing.length - 1],
    Math.max(0, daysApart - pricing.length)
  );
  const priceArray = daysWithinPricing.concat(daysForLastPrice);

  if (originalReservation) {
    const extendedDaysApart = getDaysApart(originalReservation.endDate, dates.startDate) + 1;
    return priceArray.slice(extendedDaysApart);
  }

  return priceArray;
};
