// @flow
import type {ProductVariantAvailableAffiliate} from 'data/product/types';
import {dateStringFromMoment, getDaysApart} from 'data/units/date/helpers';
import type {DateRangeMoment, DateString} from 'data/units/date/types';
import {formatMoney} from 'data/units/money/formatters';
import React from 'react';

import {getPriceForDay} from './helpers';
import {DayPriceWrap} from './styled';

type Props = {|
  day: DateString,
  value: DateRangeMoment,
  variantAffiliate?: ProductVariantAvailableAffiliate,
|};

const DayPrice = ({handleChange, day, value, variantAffiliate}: Props) => {
  if (!value || !value.startDate) return null;

  const daysFromStart = getDaysApart(day, dateStringFromMoment(value.startDate));
  const price = getPriceForDay(variantAffiliate, daysFromStart);
  const currency = variantAffiliate ? variantAffiliate.location.country.currency : 'USD';

  let text = formatPrice(day, value, currency, price);
  if (!text) return null;
  return <DayPriceWrap>{text}</DayPriceWrap>;
};

const formatPrice = (day: DateString, value: DateRangeMoment, currency, price) => {
  const money = formatMoney(price, false, currency);
  if (money === null || money === undefined) return undefined;
  if (day === value.startDate) {
    return money;
  }
  return '+' + money;
};

export default DayPrice;
