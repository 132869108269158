// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import React from 'react';

import {MAX_MONTH_OFFSET} from '../helpers';
import {ArrowWrap, PrevNextArrowsWrap} from './styled';

type Props = {|
  offset: number,
  prev: void => mixed,
  next: void => mixed,
|};

const PrevNextArrows = ({offset, prev, next}: Props) => {
  const leftDisabled = offset <= 0;
  const rightDisabled = offset + 1 >= MAX_MONTH_OFFSET;

  return (
    <PrevNextArrowsWrap>
      <ArrowWrap onClick={prev} disabled={leftDisabled}>
        <ArrowIcon rotation={'left'} />
      </ArrowWrap>
      <ArrowWrap onClick={next} disabled={rightDisabled}>
        <ArrowIcon rotation={'right'} />
      </ArrowWrap>
    </PrevNextArrowsWrap>
  );
};

export default PrevNextArrows;
