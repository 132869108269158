// @flow
import type {ExtractActionTypes} from 'common/redux/types';

import * as actions from './actions';

export type UserState = {|
  loggedIn: boolean,
  loggingOut: boolean,
  isMasquerading: boolean,
|};

const initialState: UserState = {
  loggedIn: false,
  loggingOut: false,
  isMasquerading: false,
};

type Action = ExtractActionTypes<typeof actions>;

function userReducer(state: UserState = initialState, action: Action): UserState {
  switch (action.type) {
    case 'USER/LOGIN':
      return {
        ...state,
        loggedIn: true,
        loggingOut: false,
      };
    case 'USER/LOGOUT':
      return {
        ...state,
        loggedIn: false,
        loggingOut: true,
      };
    case 'USER/LOGOUT_FINISHED':
      return {
        ...initialState,
        loggedIn: false,
        loggingOut: false,
      };
    case 'USER/MASQUERADE':
      return {
        loggedIn: true,
        loggingOut: false,
        isMasquerading: true,
      };
    default:
      return state;
  }
}

export default userReducer;
