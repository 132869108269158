// @flow
import {lineItemGrid} from 'components/Bag/styled';
import StyledLink from 'components/StyledLink';
import styled from 'styled-components';

export const BasketPreviewWrap = styled.div`
  ${lineItemGrid};
  margin-bottom: 1.6rem;
`;
export const ItemDetailWrap = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;
`;
export const ImageWrap = styled.div`
  width: 8.9rem;
  height: 9.6rem;
  display: flex;
  position: relative;
  margin-right: 1.6rem;

  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
`;

export const ItemPricingWrap = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`;

export const ItemPricing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  p {
    margin: 0;
  }
`;

export const DeleteIconWrap = styled(StyledLink)``;
