// @flow
import {compose, either, isNil, pathEq, prop} from 'ramda';
import {type Component, type HOC, branch, renderComponent} from 'recompose';

import Empty from './Empty';

const defaultPredicate = either(compose(isNil, prop('data')), pathEq(['data', 'length'], 0));

const withEmpty = <T>(
  predicate: T => boolean = defaultPredicate,
  component: Component<T> = Empty
): HOC<T, T> => branch(predicate, renderComponent(component));

export default withEmpty;
