// @flow
import * as Sentry from '@sentry/react';
import {onError} from 'apollo-link-error';
import errorMap from 'common/graphql/getErrorMessage/errorMap';
import {logout} from 'data/user/actions';
import {selectLoggedIn} from 'data/user/selectors';
import store from 'global/store';
import {path} from 'ramda';

import {purgeEverything} from './index';

const linkErrors = onError(({graphQLErrors, networkError}) => {
  if (networkError) {
    if (selectLoggedIn(store.getState())) {
      store.dispatch(logout());
    } else {
      purgeEverything(false);
    }
  }

  if (graphQLErrors && graphQLErrors.find(x => path(['data', 'code'], x) === 'E_AUTHORIZATION')) {
    store.dispatch(logout());
  }

  // log error
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      const errorCode = path(['data', 'code'], error);

      // logging additional info about unknown errors for future debugging
      if (!errorMap[errorCode]) {
        const path = error.path ? `at ${error.path.toString()}` : '';
        const message = errorCode ? `${errorCode} - ${error.message}` : error.message;

        Sentry.captureMessage(`Unknown error occurred ${path}: ${message}`);
      }
    });
  }
});

export default linkErrors;
