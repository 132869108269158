import {
  brandFilterQuery,
  categoryFilterQuery,
  locationFilterQuery,
  productFilterQuery,
} from 'data/search/graphql/queries/filters';
import withQuery from 'hoc/withQuery';
import {generateIdMapper} from 'pages/_Page/MainFilter/queryHOCs/utils';
import {path} from 'ramda';
import {compose, mapProps} from 'recompose';

// The below are for the mobile filter modal that needs filter options to be updated as the user makes
// selections that are not persisted to the Redux store. These HOCs are ultimately the same as
// the default HOCs, but the selectors are looking for the 'internal' selections used only in the modal
// instead of the Redux stores selections.
const getCategoryIds = generateIdMapper(['internalCategories'], 'categoryId');
const getBrandIds = generateIdMapper(['internalBrands']);
const getLocationIds = generateIdMapper(['internalLocations']);
const getProductIds = generateIdMapper(['internalProducts']);
const getAffiliateId = path(['params', 'affiliate']);

// ***IMPORTANT ***
// Updates to the mobile filters should have the same changes made to the default queries
// to ensure they remain in sync
export const withLocationFilterDataForMobile = compose(
  withQuery(locationFilterQuery, {
    noEmpty: true,
    noLoader: true,
    variables: props => ({
      filter: {
        categoryIds: getCategoryIds(props),
        affiliateId: getAffiliateId(props),
        manufacturerIds: getBrandIds(props),
        productIds: getProductIds(props),
      },
    }),
  }),
  mapProps(({data, loading, ...rest}) => ({
    ...rest,
    locationFilterOptions: data,
    locationFilterOptionsLoading: loading,
  }))
);

export const withCategoryFilterDataForMobile = compose(
  withQuery(categoryFilterQuery, {
    noEmpty: true,
    noLoader: true,
    variables: props => ({
      filter: {
        locationIds: getLocationIds(props),
        affiliateId: getAffiliateId(props),
        manufacturerIds: getBrandIds(props),
        productIds: getProductIds(props),
        availableOnly: true,
      },
    }),
  }),
  mapProps(({data, loading, ...rest}) => ({
    ...rest,
    categoryFilterOptions: data,
    categoryFilterOptionsLoading: loading,
  }))
);

export const withBrandFilterDataForMobile = compose(
  withQuery(brandFilterQuery, {
    noEmpty: true,
    noLoader: true,
    variables: props => ({
      filter: {
        categoryIds: getCategoryIds(props),
        affiliateIds: getAffiliateId(props) ? [getAffiliateId(props)] : undefined,
        locationIds: getLocationIds(props),
        productIds: getProductIds(props),
        availableOnly: true,
      },
    }),
  }),
  mapProps(({data, loading, ...rest}) => ({
    ...rest,
    brandFilterOptions: data,
    brandFilterOptionsLoading: loading,
  }))
);

export const withProductFilterDataForMobile = compose(
  withQuery(productFilterQuery, {
    noEmpty: true,
    noLoader: true,
    variables: props => ({
      filter: {
        categoryIds: getCategoryIds(props),
        affiliateId: getAffiliateId(props),
        locationIds: getLocationIds(props),
        manufacturerIds: getBrandIds(props),
        availableOnly: true,
      },
    }),
  }),
  mapProps(({data, loading, ...rest}) => ({
    ...rest,
    productFilterOptions: data,
    productFilterOptionsLoading: loading,
  }))
);
