// @flow
import {breakpoints} from 'common/mediaQuery';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import {chooseCategories, chooseGeolocation} from 'data/app/actions';
import {selectCategories, selectGeolocation} from 'data/app/selectors';
import {openModal} from 'data/modals/actions';
import {clear, filter} from 'data/search/actions';
import {selectSearchParams} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import withLoader from 'hoc/withLoader';
import withRouter from 'hoc/withRouter';
import useQueryParam from 'hooks/useQueryParam';
import {paramStringToValueArray, valueArrayToParamString} from 'hooks/useQueryParam/transformers';
import MobileFilter from 'pages/_Page/MainFilter/MobileFilter';
import {
  withBrandFilterData,
  withCategoryFilterData,
  withLocationFilterData,
  withProductFilterData,
} from 'pages/_Page/MainFilter/queryHOCs/default';
import React from 'react';
import {type HOC, compose, mapProps} from 'recompose';

import DesktopFilters from './DesktopFilters';
import {MainFilterWrap} from './styled';

const FILTER_BREAKPOINT = breakpoints.phoneWide;

const MainFilter = ({
  geolocation,
  categories,
  chooseGeolocation,
  chooseCategories,
  params,
  clear,
  filter,
  data,
  filterOptions,
  filterOptionsLoading,
  history,
}) => {
  const multipleBrands =
    data && data.brand.type === 'enum_value' && data.brand.enum.values.length > 1;

  useQueryParam({
    value: geolocation,
    setValue: chooseGeolocation,
    paramName: 'location',
    transformFromParam: param => {
      const transformer = param =>
        filterOptions.locationFilterOptions.find(location => location.id === Number(param));
      return paramStringToValueArray(param, transformer);
    },
    transformToParam: value => {
      const transformer = location => location.id;
      return valueArrayToParamString(value, transformer);
    },
    history,
  });

  return (
    <MainFilterWrap>
      <Mobile breakpoint={FILTER_BREAKPOINT}>
        <MobileFilter
          breakpoint={FILTER_BREAKPOINT}
          geolocation={geolocation}
          categories={categories}
          chooseGeolocation={chooseGeolocation}
          chooseCategories={chooseCategories}
          params={params}
        />
      </Mobile>
      <Desktop breakpoint={FILTER_BREAKPOINT}>
        <DesktopFilters
          filterOptions={filterOptions}
          filterOptionsLoading={filterOptionsLoading}
          geolocation={geolocation}
          categories={categories}
          chooseGeolocation={chooseGeolocation}
          chooseCategories={chooseCategories}
          params={params}
          filter={filter}
          clear={clear}
          multipleBrands={multipleBrands}
        />
      </Desktop>
    </MainFilterWrap>
  );
};

const mapStateToProps = state => ({
  params: selectSearchParams(state),
  geolocation: selectGeolocation(state),
  categories: selectCategories(state),
});

const mapDispatchToProps = {
  filter,
  clear,
  chooseGeolocation,
  chooseCategories,
  openModal,
};

const enhancer: HOC<*, {||}> = compose(
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps),
  withLocationFilterData,
  withCategoryFilterData,
  withBrandFilterData,
  withProductFilterData,
  withLoader(
    props =>
      !props.locationFilterOptions ||
      !props.categoryFilterOptions ||
      !props.brandFilterOptions ||
      !props.productFilterOptions
  ),
  mapProps(
    ({
      locationFilterOptions,
      categoryFilterOptions,
      brandFilterOptions,
      productFilterOptions,
      locationFilterOptionsLoading,
      categoryFilterOptionsLoading,
      brandFilterOptionsLoading,
      productFilterOptionsLoading,
      ...rest
    }) => ({
      ...rest,
      filterOptions: {
        locationFilterOptions,
        categoryFilterOptions,
        brandFilterOptions,
        productFilterOptions,
      },
      filterOptionsLoading: {
        locationFilterOptionsLoading,
        categoryFilterOptionsLoading,
        brandFilterOptionsLoading,
        productFilterOptionsLoading,
      },
    })
  )
);

export default enhancer(MainFilter);
