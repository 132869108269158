// @flow
import type {
  AutocompleteItem,
  AutocompleteQueryData,
} from 'data/search/graphql/queries/autocomplete';
import {keys, sum} from 'ramda';
import React from 'react';

import {GroupItem, GroupLabel, GroupWrap, ItemGroupsWrap} from '../styled';

type Props = {|
  activeIndex: number,
  data: AutocompleteQueryData,
  onItemSelect: AutocompleteItem => mixed,
|};

// Label to show in search autocomplete, keyed by the key of the item
const ITEM_LABELS = {
  affiliates: 'Partners',
  manufacturers: 'Brands',
};

const ItemGroups = ({data, onItemSelect, activeIndex}: Props) => {
  const totalItemsLength = [];

  return (
    <ItemGroupsWrap>
      {keys(data).map(k => {
        if (data[k].length === 0) return null;

        totalItemsLength.push(data[k].length);
        let itemLabel = ITEM_LABELS[k] != null ? ITEM_LABELS[k] : k;

        return (
          <GroupWrap key={k}>
            <GroupLabel>{itemLabel}</GroupLabel>
            {data[k].map((x, i) => {
              const shiftedTotalItems = totalItemsLength.slice(0, totalItemsLength.length - 1);
              const idx = sum(shiftedTotalItems) + i;
              const isActive = activeIndex === idx;

              return (
                <GroupItem key={idx} onClick={() => onItemSelect(x)} active={isActive}>
                  {x.name}
                </GroupItem>
              );
            })}
          </GroupWrap>
        );
      })}
    </ItemGroupsWrap>
  );
};

export default ItemGroups;
