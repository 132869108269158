// @flow
import type {EnumValue} from 'data/enums/types';
import type {DateString} from 'data/units/date/types';
import {formatMmToFeetInch} from 'data/units/length/formatters';
import {formatKgToPounds} from 'data/units/weight/formatters';
import {selectPrimary} from 'data/user/helpers';
import type {User} from 'data/user/types';

import {tryIdentify, tryTrack} from '../helpers';

type EditProfileValues = {
  firstName: string,
  lastName: string,
  userGender: ?EnumValue,
  birthdate: DateString,
  height: ?number,
  weight: ?number,
  bootLength: ?number,
  din: ?number,
  waveType: ?EnumValue,
  skillLevel: ?EnumValue,
  surfStance: ?EnumValue,
  snowboardStance: ?EnumValue,
  skierType: ?EnumValue,
};

const userIdentifyTrait = (user: User) => {
  const primaryProfile = selectPrimary(user);
  if (!primaryProfile) return {email: user.email};

  return {
    firstName: primaryProfile.firstName,
    lastName: primaryProfile.lastName,
    userGender: primaryProfile.userGender,
    email: user.email,
    height: primaryProfile.height && formatMmToFeetInch(primaryProfile.height),
    weight: primaryProfile.weight && formatKgToPounds(primaryProfile.weight),
    surfStance: primaryProfile.surfStance && primaryProfile.surfStance.name,
    snowboardStance: primaryProfile.snowboardStance && primaryProfile.snowboardStance.name,
    waveType: primaryProfile.waveType && primaryProfile.waveType.name,
    skillLevel: primaryProfile.skillLevel && primaryProfile.skillLevel.name,
  };
};

const editProfileTrait = (profile: EditProfileValues) => ({
  height: profile.height && formatMmToFeetInch(profile.height),
  weight: profile.weight && formatKgToPounds(profile.weight),
  surfStance: profile.surfStance && profile.surfStance.name,
  snowboardStance: profile.snowboardStance && profile.snowboardStance.name,
  waveType: profile.waveType && profile.waveType.name,
  skillLevel: profile.skillLevel && profile.skillLevel.name,
});

export const identifyUser = (x: User) => tryIdentify(x.id, () => userIdentifyTrait(x));

export const trackEditProfile = (x: EditProfileValues) =>
  tryTrack('Profile Updated', () => editProfileTrait(x));
