// @flow
import {minHeightImg} from 'data/images/helpers';
import type {Image} from 'data/images/types';
import * as React from 'react';

import {ImageSwitchIconicTile, ImageSwitchIconicWrap, StyledImage} from './styled';

type Props = {|
  images: Image[],
  activeIndex: number,
  onChangeIndex: number => void,
|};

const ImageSwitchIconic = ({images, activeIndex, onChangeIndex}: Props) => {
  if (images.length < 2) return null;

  return (
    <ImageSwitchIconicWrap>
      {images.map((image, index) => {
        const imageSize = minHeightImg(image, 50);

        return (
          <ImageSwitchIconicTile
            selected={index === activeIndex}
            key={index}
            onClick={() => onChangeIndex(index)}
          >
            <StyledImage src={imageSize.url} alt={image.alt} draggable="false" />
          </ImageSwitchIconicTile>
        );
      })}
    </ImageSwitchIconicWrap>
  );
};

export default ImageSwitchIconic;
