// @flow
import {productItemFragment, productItemMinFragment} from 'data/product/graphql/fragments';
import gql from 'graphql-tag';

import {bookingFragment, bookingMinFragment} from '../../bookings/graphql/fragments';

export const reservationFragment = gql`
  fragment reservationFragment on Reservation {
    id
    status
    externalId
    basketCode
    booking {
      ...bookingFragment
    }
    productItem {
      ...productItemFragment
    }
    pricingDetail {
      currency
      cancellationFee
      cancellationFeeStartsAt
      totalDays
      totalPrice
      fullPrice
      prices
      discounts {
        percentage
        type
        detail
        limitDays
      }
      accessories {
        name
        prices
      }
      tax
    }
  }
  ${productItemFragment}
  ${bookingFragment}
`;

export const reservationMinFragment = gql`
  fragment reservationMinFragment on Reservation {
    id
    status
    booking {
      ...bookingMinFragment
    }
    externalId
    productItem {
      ...productItemMinFragment
    }
  }
  ${productItemMinFragment}
  ${bookingMinFragment}
`;
