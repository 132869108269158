// @flow
import moment from 'moment';

export const MAX_MONTH_OFFSET = 6;

export const getCurrentMonth = (offset: number): moment$Moment =>
  moment().add(Math.min(offset, MAX_MONTH_OFFSET - 1), 'months');

export const getNextMonth = (offset: number): moment$Moment =>
  moment().add(Math.min(offset + 1, MAX_MONTH_OFFSET), 'months');
