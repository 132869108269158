// @flow
import {StyledInput} from 'componentsStyled/Forms/Input';
import withFormField, {type ReqOutter} from 'forms/withFormField';
import React from 'react';
import type {HOC} from 'recompose';

const Input = ({value = '', type = 'text', onChange, autoComplete = 'on', ...rest}) => (
  <StyledInput
    type={type}
    onInput={onChange}
    onChange={onChange}
    value={value}
    autoComplete={autoComplete}
    {...rest}
  />
);

type Outter = {|
  ...$Exact<ReqOutter>,
  type?: string,
  placeholder?: string,
  inputmode?: 'numeric',
  pattern?: string,
  min?: string,
  autoComplete?: string,
  errorPosition?: string,
  title?: string,
  // disabled?: boolean,
|};

const enhancer: HOC<*, Outter> = withFormField;

export default enhancer(Input);
