// @flow
import type {StripeToken} from 'data/stripe/types';
import {injectStripe} from 'react-stripe-elements';
import {type Component} from 'recompose';

type Added = {|
  stripe: {|
    createToken: void => Promise<{|
      token?: StripeToken,
      error?: {|
        message: string,
      |},
    |}>,
  |},
|};

function withInjectStripe<T>(C: Component<{|...$Exact<T>, ...$Exact<Added>|}>): Component<T> {
  return injectStripe(C);
}

export default withInjectStripe;
