// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle} from 'componentsStyled/Typography/Titles';
import {selectAppConfig} from 'data/app/selectors';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import type {Product} from 'data/product/types';
import urls from 'data/router/urls';
import {formatPricing, getPriceRange} from 'data/units/money/formatters';
import withConnect from 'hoc/withConnect';
import withRouter from 'hoc/withRouter';
import useUniqueCountriesAcrossAffiliates from 'hooks/useUniqueCountriesAcrossAffiliates';
import {uniqBy} from 'ramda';
import React from 'react';
// $ReactHooks
import {useMemo} from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import type {AppConfig} from '../../../data/app/types';
import {Body, ImageWrap, ProductTileWrap} from './styled';

export const ProductTileInner = ({
  productOffering,
  maxTilesPerRow,
  handleClick,
  appConfig,
  showPrices,
}: Props) => {
  const allVariantAffiliates = useMemo(
    // $ExpectError - Flow doesn't know flatMap
    () => productOffering.variants.flatMap(variant => variant.affiliates),
    [productOffering]
  );

  const uniqueAffiliates = useMemo(
    () => uniqBy(({id}) => id, allVariantAffiliates),
    [allVariantAffiliates]
  );

  const [minPrice, maxPrice] = useMemo(() => {
    const allPrices = allVariantAffiliates.flatMap(affiliate => affiliate.pricing);
    return getPriceRange(allPrices);
  }, [allVariantAffiliates]);

  const uniqueCountries = useUniqueCountriesAcrossAffiliates(uniqueAffiliates);

  const showMinimalProductTileInfo = appConfig && appConfig.showMinimalProductTileInfo;
  const indexImage = selectIndexImage(productOffering.images);
  const imageSize = minHeightImg(indexImage, 250);
  const currency = uniqueAffiliates[0].location.country.currency;

  return (
    <ProductTileWrap
      onClick={handleClick}
      minimal={showMinimalProductTileInfo}
      maxTilesPerRow={maxTilesPerRow}
      data-cy={'product-tile'}
    >
      <ImageWrap>
        <img src={imageSize.url} alt={indexImage.alt} draggable="false" />
      </ImageWrap>
      <Body>
        {!showMinimalProductTileInfo && <Text small>{productOffering.manufacturer.name}</Text>}
        <SubTitle data-cy={'product-name'}>{productOffering.name}</SubTitle>
        <Text small>
          {showPrices ? (
            <>
              <Text small inline black book>
                {formatPricing([maxPrice, minPrice], currency)}
              </Text>{' '}
              per day
            </>
          ) : (
            `${uniqueAffiliates.length} store${uniqueAffiliates.length === 1 ? '' : 's'}, ${
              uniqueCountries.length
            } countr${uniqueCountries.length === 1 ? 'y' : 'ies'}`
          )}
        </Text>
      </Body>
    </ProductTileWrap>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

type Outter = {|
  productOffering: Product,
  showPrices?: boolean,
  maxTilesPerRow?: number,
  queryParams?: string,
|};

type Props = Outter & {|
  appConfig: AppConfig,
  handleClick: Function,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withConnect(mapStateToProps),
  withHandlers({
    handleClick:
      ({history, productOffering, queryParams}) =>
      () => {
        history.push(`${urls.product(productOffering.id)}${queryParams ? `?${queryParams}` : ''}`);
      },
  })
);

export default enhancer(ProductTileInner);
