// @flow
import styled, {css} from 'styled-components';

export const Split = styled.div`
  display: flex;
`;

export const Left = styled.div`
  width: calc(50% - 24.5rem);
  position: relative;

  &::after {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    /* this is calculated from HPaddedFullWidthContainer */
    width: calc(50vw - (48rem - 1.6rem) + 22.5rem);
    background: #f8f9fa;
    content: '';
    z-index: -1;
  }
`;

export const Right = styled.div`
  flex-grow: 1;
  position: relative;

  &::after {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    /* this is calculated from HPaddedFullWidthContainer */
    width: calc(100vw - (50vw - (48rem - 1.6rem) + 22.5rem));
    background: #fff;
    content: '';
    z-index: -1;
  }
`;

export const Inner = styled.div`
  margin: 0 auto;
  width: 66rem;

  ${p =>
    p.narrow &&
    css`
      width: 44rem;
    `}
`;
