// @flow
import {mqMax} from 'common/mediaQuery';
import styled from 'styled-components';

export const AffiliateNameWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  width: 100%;
  position: relative;

  ${mqMax.phone} {
    * {
      font-size: 1.5rem;
    }
  }

  svg {
    margin-right: 0.6rem;
    fill: ${props => props.theme.color.secondaryText};
  }
`;
