// @flow
import {errorCodes} from 'common/graphql/errors';
import GDPRWarning from 'components/GDPRWarning';
import Loader from 'components/Loader';
import {setAllCategories, setAppConfig, setTheme} from 'data/app/actions';
import {appConfigQuery, categoryListQuery, themeQuery} from 'data/app/graphql/queries';
import {selectAppConfig} from 'data/app/selectors';
import {selectLoggingOut} from 'data/user/selectors';
import {query} from 'global/apolloClient/helpers';
import {isPatagonia} from 'global/config';
import {loadGlobalStyles} from 'global/global-styles';
import {type Theme, defaultTheme, patagoniaThemeOverrides} from 'global/theme';
import withConnect from 'hoc/withConnect';
import withOnMount from 'hoc/withOnMount';
import withOnNextProps from 'hoc/withOnNextProps';
import withRouter from 'hoc/withRouter';
import withUser from 'hoc/withUser';
import ErrorPage from 'pages/Error';
import * as R from 'ramda';
import * as React from 'react';
import {type HOC, compose, lifecycle, withHandlers, withStateHandlers} from 'recompose';
import {ThemeProvider} from 'styled-components';

import MasterModal from './MasterModal';
import Notifications from './Notifications';
import Routes from './Routes';

const Content = ({loggingOut, error, theme, loading}) => {
  function renderInner() {
    if (error) {
      return <ErrorPage />;
    } else if (loading || loggingOut) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <GDPRWarning />
          <Routes />
          <Notifications />
          <MasterModal />
        </React.Fragment>
      );
    }
  }

  return <ThemeProvider theme={theme}>{renderInner()}</ThemeProvider>;
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
  loggingOut: selectLoggingOut(state),
});

const mapDispatchToProps = {
  setAppConfig,
  setAllCategories,
  /** NOTE: Not calling it `setTheme` to avoid naming conflict with the injected theme setter */
  setThemeInState: setTheme,
};

type Outter = {|
  error: ?string,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps),
  withUser(),
  withStateHandlers(
    {
      loading: true,
      theme: defaultTheme,
    },
    {
      setLoading: () => v => ({loading: v}),
      /** Sets the theme that gets injected by ThemeProvider */
      setTheme: () => (theme: Theme) => ({theme}),
    }
  ),
  withHandlers({
    prefetchQueries: props => () => {
      props.setLoading(true);
      Promise.all([query(appConfigQuery), query(themeQuery), query(categoryListQuery)])
        .then(([appConfig, backendTheme, categories]) => {
          console.log('Loaded app config:', appConfig);
          props.setAppConfig(appConfig);
          props.setAllCategories(categories);
          // Set the theme into the store, which contains header and footer
          props.setThemeInState(backendTheme);

          // Set the theme that gets injected by ThemeProvider, which includes
          // colours and border radiuses
          let injectedTheme: Theme = {
            ...defaultTheme,
            color: {
              ...defaultTheme.color,
              ...backendTheme.colors,
            },
            borderRadiuses: {
              ...defaultTheme.borderRadiuses,
              ...backendTheme.borderRadiuses,
            },
          };

          // HACK(ray): Apply Patagonia specific theming
          // TODO(ray>someone): Allow this to be parameterised from the backend
          if (isPatagonia(appConfig.tenantName)) {
            injectedTheme = {
              ...injectedTheme,
              ...patagoniaThemeOverrides,
            };
          }

          console.log('Setting theme:', injectedTheme);

          props.setTheme(injectedTheme);

          // Initialize the global styles, which includes fonts
          loadGlobalStyles(injectedTheme, {
            ...backendTheme.fonts,
          });

          props.setLoading(false);
        })
        .catch(error => {
          if (R.path(['networkError', 'result', 'code'], error) === errorCodes.TENANT_NOT_ALLOWED) {
            // Move customer to 'Page not found' if 'tenant not found' errors
            // arise from the above queries by purposely redirecting to an invalid
            // path; failure of above queries is indicative of a tenant not existing or being not
            // allowed
            props.history.push(' ', {error: errorCodes.TENANT_NOT_ALLOWED});
          }
          props.setLoading(false);
        });
    },
  }),
  withOnMount(props => {
    if (!props.loggingOut) {
      props.prefetchQueries();
    }
  }),
  withOnNextProps((prev, next) => {
    if (prev.loggingOut && !next.loggingOut) {
      prev.prefetchQueries();
    }
  }),
  lifecycle({
    componentDidUpdate() {
      const {error, loggingOut, appConfig} = this.props;
      if (error || loggingOut) {
        document.title = 'Awayco';
      } else {
        document.title = appConfig ? appConfig.tenantTitle : 'Awayco';
      }
    },
  })
);

export default enhancer(Content);
