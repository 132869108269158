// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import * as React from 'react';

import DesktopLoggedOutNav from './DesktopLoggedOutNav';
import MobileLoggedOutNav from './MobileLoggedOutNav';
import {LoggedOutNavWrap} from './styled';

const LoggedOutNav = () => (
  <LoggedOutNavWrap>
    <Mobile>
      <MobileLoggedOutNav />
    </Mobile>
    <Desktop>
      <DesktopLoggedOutNav />
    </Desktop>
  </LoggedOutNavWrap>
);

export default LoggedOutNav;
