// @flow
import {mqMax} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import styled from 'styled-components';

export const TileWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const Title = styled.h4`
  font-size: 1.8rem;
  color: ${props => props.theme.color.white};
  line-height: 1.2;

  ${mqMax.phone} {
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
  }
`;

export const Subtitle = styled.div`
  font-size: 1.4rem;
  color: ${props => props.theme.color.white};
  line-height: 1;
  margin-bottom: 1.5rem;

  ${mqMax.phone} {
    font-weight: ${props => props.theme.fontWeights.book};
    margin-bottom: 4.5rem;
    font-size: 1.2rem;
  }
`;

export const ActivityTileWrap = styled(StyledLink)`
  width: 100%;
  display: flex;
  position: relative;
  background: url(${p => p.bg}) center center / cover no-repeat;
  text-align: center;
  border-radius: 0.3rem;
  margin: 0 1rem;
  max-width: 36rem;
  min-width: 22rem;
  min-height: 22rem;

  ${mqMax.tabletWide} {
    min-width: 14rem;
    min-height: 14rem;
  }

  ${mqMax.phone} {
    min-width: 10rem;
    min-height: 10rem;
    margin: 0;
  }

  &::before {
    content: '';
    display: flex;
    width: 100%;
    padding-bottom: 55%;
  }
`;

export const Inner = styled.div`
  position: absolute;
  bottom: 0.8rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  & * {
    text-shadow: 0 0 1rem rgba(0, 0, 2, 0.6);
  }

  ${mqMax.phone} {
    bottom: -2.5rem;

    & * {
      text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.6);
    }
  }
`;
