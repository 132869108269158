// @flow
import type {Mutation} from 'common/graphql/types';
import type {StripeTokenId} from 'data/stripe/types';
import {getUserQuery} from 'data/user/graphql/queries';
import type {User} from 'data/user/types';
import gql from 'graphql-tag';

import {userFragment} from './fragments';
import {getPaymentMethodQuery} from './queries';

export const getRecoveryEmailMutation: Mutation<{|email: string|}> = {
  gql: gql`
    mutation getRecoveryEmailMutation($email: LowercaseString!) {
      auth {
        resetPasswordCode(email: $email)
      }
    }
  `,
};

export const editCreditCardMutation: Mutation<{|token: StripeTokenId|}> = {
  gql: gql`
    mutation editCreditCardMutation($token: String!) {
      payment {
        updateCustomer(token: $token) {
          id
        }
      }
    }
  `,
  refetch: [getPaymentMethodQuery],
};

export const subscribeNewsletterMutation: Mutation<{|email: string|}> = {
  gql: gql`
    mutation subscribeNewsletterMutation($email: LowercaseString!) {
      newsletter {
        addSubscriber(email: $email) {
          id
        }
      }
    }
  `,
};

export const loginMutation: Mutation<
  {|
    email: string,
    password: string,
  |},
  User
> = {
  gql: gql`
    mutation loginMutation($email: LowercaseString!, $password: String!) {
      auth {
        login(email: $email, password: $password) {
          user {
            ...userFragment
          }
        }
      }
    }
    ${userFragment}
  `,
  selector: ['auth', 'login', 'user'],
};

export const loginGoogleMutation: Mutation<
  {|
    idToken: string,
  |},
  User
> = {
  gql: gql`
    mutation loginGoogleMutation($idToken: String!) {
      auth {
        loginGoogle(idToken: $idToken) {
          user {
            ...userFragment
          }
        }
      }
    }
    ${userFragment}
  `,
  selector: ['auth', 'loginGoogle', 'user'],
};

export const loginFacebookMutation: Mutation<
  {|
    accessToken: string,
  |},
  User
> = {
  gql: gql`
    mutation loginFacebookMutation($accessToken: String!) {
      auth {
        loginFacebook(accessToken: $accessToken) {
          user {
            ...userFragment
          }
        }
      }
    }
    ${userFragment}
  `,
  selector: ['auth', 'loginFacebook', 'user'],
};

export const registerMutation: Mutation<
  {|
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    phoneNumber: string,
  |},
  User
> = {
  gql: gql`
    mutation registerMutation(
      $firstName: String!
      $lastName: String!
      $email: LowercaseString!
      $password: String!
      $phoneNumber: String!
    ) {
      user {
        register(
          firstName: $firstName
          lastName: $lastName
          email: $email
          password: $password
          phoneNumber: $phoneNumber
        ) {
          user {
            ...userFragment
          }
        }
      }
    }
    ${userFragment}
  `,
  selector: ['user', 'register', 'user'],
};

export const resetPasswordMutation: Mutation<{|
  password: string,
  code: string,
|}> = {
  gql: gql`
    mutation resetPasswordMutation($password: String!, $code: String!) {
      auth {
        resetPassword(password: $password, code: $code)
      }
    }
  `,
};

export const deleteAccountMutation: Mutation<{||}> = {
  gql: gql`
    mutation deleteAccountMutation {
      user {
        delete
      }
    }
  `,
};

export const updatePhoneNumberMutation: Mutation<{|
  phoneNumber: string,
|}> = {
  gql: gql`
    mutation updatePhoneNumberMutation($phoneNumber: String!) {
      user {
        updatePhoneNumber(phoneNumber: $phoneNumber)
      }
    }
  `,
};

export const createCustomerMutation: Mutation<{|token: StripeTokenId|}> = {
  gql: gql`
    mutation createCustomerMutation($token: String!) {
      payment {
        createCustomer(token: $token) {
          id
        }
      }
    }
  `,
  refetch: [getUserQuery],
};
