// @flow
import type {RouterHistory} from 'data/router/types';
import type {HistoryUpdateType} from 'hoc/withRouter';
import {HISTORY_UPDATE} from 'hoc/withRouter';
import usePrevious from 'hooks/usePrevious';
// $ReactHooks
import {useEffect, useMemo, useRef} from 'react';

export type Props<T> = {|
  value: T,
  setValue: (value: T) => void,
  paramName: string,
  transformToParam?: (a: any) => any,
  transformFromParam?: (a: any) => any,
  historyUpdateMethod?: HistoryUpdateType,
  history: RouterHistory,
|};

const areParamsDifferent = (paramA: any, paramB: any) => {
  //If either is nully then compare directly
  if (paramA == null || paramB == null) {
    return paramA !== paramB;
  }
  //Else convert both to string and compare
  return `${paramA}` !== `${paramB}`;
};

/**
 * Used to sync state with query params
 * @param paramName - The name of the parameter to appears in the URL query params
 * @param value - The value that is watched and placed into the URL query params
 * @param setValue - function used to set value. This is called with the query param value
 * on mount in order to initialise state from query params.
 * @param transformToParam - (Optional). Used to transform value into a form compatible with
 * query params (e.g. value is a Date object, we can transform it into formatted string 'YYYY-MM-DD').
 * @param transformFromParam - (Optional). Use to transform the param from the URL into the form
 * that value is expected to be (e.g. the URL Param is a date string ('YYYY-MM-DD') we can call the
 * Date constructor to transform it into a Date object before setting it.
 * @param historyUpdateMethod - (Optional). The method with which to update the URL. 'push' will create history
 * entry, while 'replace' will replace the current history entry.
 * @param history - React Router History
 */
function useQueryParam<T>({
  value,
  setValue,
  paramName,
  transformToParam = value => value,
  transformFromParam = value => value,
  historyUpdateMethod = HISTORY_UPDATE.REPLACE,
  history,
}: Props<T>) {
  const {location} = history;
  const isInitialMountRef = useRef(true);

  //We cannot watch `window.location.search` so we need to use the location's query params
  //to be able to watch a change in the query params of the URL.
  const locationQueryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const paramValue = locationQueryParams.get(paramName);
  const prevParamValue = usePrevious(paramValue);

  const transformedValue = useMemo(() => transformToParam(value), [transformToParam, value]);
  const prevTransformedValue = usePrevious(transformedValue);

  //URL PARAM WATCHER
  //Watches for changes in the URL and updates the application's value if it changes.
  //Will ignore the absence of a param in the initial mount so we don't override application
  //state by the absence of query params.
  useEffect(() => {
    const isParamDifferentToValue = areParamsDifferent(transformedValue, paramValue);
    const hasParamValueChanged = areParamsDifferent(paramValue, prevParamValue);

    // End early if there's no change to param or param is not different to value
    if (!hasParamValueChanged || !isParamDifferentToValue) {
      return;
    }

    // If it's the initial mount and the param has no value then end early as we
    // don't want to clear values on initial mount
    if (isInitialMountRef.current && !paramValue) {
      return;
    }

    setValue(transformFromParam(paramValue));
  }, [paramName, paramValue, prevParamValue, setValue, transformFromParam, transformedValue]);

  //APPLICATION VALUE WATCHER
  //Watches for changes in the application value and updates the URL param if it changes.
  //Will not cause changes in the URL param on initial mount as we want to take the query param
  //value over the initial application value.
  useEffect(() => {
    const isParamDifferentToValue = areParamsDifferent(transformedValue, paramValue);
    const hasValueChanged = areParamsDifferent(transformedValue, prevTransformedValue);

    // End early if there's no change to value or param is not different to value
    if (!hasValueChanged || !isParamDifferentToValue) {
      return;
    }

    //If it's the initial mount and there is a value in the URL param, then don't overwrite it.
    if (isInitialMountRef.current && paramValue) {
      return;
    }

    //We use queryParams directly from the window instead of the react-router location object as it will be
    //update-to-date (unlike the location object) which will help prevent race conditions.
    const queryParams = new URLSearchParams(window.location.search);

    //Set the new value or remove the param it if the value is now empty
    if (transformedValue != null) {
      queryParams.set(paramName, transformedValue);
    } else {
      queryParams.delete(paramName);
    }

    const paramString = queryParams.toString();
    const search = `${paramString ? `?${paramString}` : ''}`;
    const historyUpdateFn = isInitialMountRef.current
      ? HISTORY_UPDATE.REPLACE
      : historyUpdateMethod;

    history[historyUpdateFn]({search});
  }, [
    paramName,
    transformedValue,
    historyUpdateMethod,
    paramValue,
    prevTransformedValue,
    locationQueryParams,
    history,
  ]);

  useEffect(() => {
    isInitialMountRef.current = false;
  }, []);
}

export default useQueryParam;
