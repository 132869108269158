// @flow
import styled from 'styled-components';

export const LogoWrap = styled.div`
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWrap = styled.div`
  padding: 4.8rem;
  margin: 0 auto;
  align-content: center;
  display: flex;
  flex-direction: column;
`;
