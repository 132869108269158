// @flow
import React from 'react';

import CheckIcon from './CheckIcon';
import {CheckboxWrap} from './styled';

type Props = {|
  value: ?boolean,
  onClick?: Function,
  big?: boolean,
  'data-cy'?: string,
  disabled?: boolean,
|};

/* TODO(t04435): FIX: checkbox should have the label(text) as part of this component */
const Checkbox = ({value, onClick, big, 'data-cy': dataCy, disabled}: Props) => (
  <CheckboxWrap disabled={disabled} onClick={onClick} checked={!!value} big={big} data-cy={dataCy}>
    {value && <CheckIcon />}
  </CheckboxWrap>
);

export default Checkbox;
