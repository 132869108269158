import styled from 'styled-components';

export const SubGroupWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
`;

export const Expander = styled.div`
  display: flex;
  align-items: center;
`;

export const Subtitle = styled.span`
  color: ${props => props.theme.color.neutralGray};
  font-size: 1.2rem;
  font-weight: 400;
`;
