// @flow
import type {Query} from 'common/graphql/types';
import type {Accessory} from 'data/reservations/types';
import gql from 'graphql-tag';

import {mapPreselectToPricingTemplateAccessories} from './helpers';

export const accessoryListQuery: Query<Accessory[]> = {
  gql: gql`
    query accessoryListQuery($affiliateId: Int!, $productVariantId: Int!) {
      pricingTemplate {
        listAccessories(affiliateId: $affiliateId, productVariantId: $productVariantId) {
          name
          prices
        }
      }
      accessory {
        list(filter: {limit: 999}) {
          data {
            name
            preselect
          }
        }
      }
    }
  `,
  transform: data => (!data.loading ? mapPreselectToPricingTemplateAccessories(data) : []),
  options: () => ({
    fetchPolicy: 'network-only',
  }),
};
