// @flow
import {selectPrimary} from 'data/user/helpers';
import type {User} from 'data/user/types';

import {tryTrack} from '../helpers';

const membershipTrait = (user: User) => {
  const primaryProfile = selectPrimary(user);
  return {
    path: window.location.pathname,
    firstName: primaryProfile && primaryProfile.firstName,
    lastName: primaryProfile && primaryProfile.lastName,
    email: user.email,
  };
};

export const trackSignup = (u: User) => tryTrack('User Sign Up', () => membershipTrait(u));

export const trackPauseMembership = (u: User) =>
  tryTrack('User Pause Membership', () => membershipTrait(u));
