// @flow
import * as React from 'react';

import ChangeSlideButton from './ChangeSlideButton';

const commonOptions = {
  edgeEasing: 'easeBackOut',
  enableKeyboardControls: true,
  renderCenterLeftControls: ({previousSlide}: any) => (
    <ChangeSlideButton onClick={previousSlide} direction="left" />
  ),
  renderCenterRightControls: ({nextSlide}: any) => (
    <ChangeSlideButton onClick={nextSlide} direction="right" />
  ),
  opacityScale: 0.5,
  slidesToScroll: 'auto',
  slidesToShow: 4,
};

export const mobileView = {
  ...commonOptions,
  slidesToShow: 3,
  speed: 200, // miliseconds
};

export const desktopView = {
  ...commonOptions,
};

export const individualView = {
  ...commonOptions,
  renderBottomCenterControls: () => {},
  transitionMode: 'scroll3d',
  wrapAround: true,
  slidesToScroll: 1,
};
