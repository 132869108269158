// @flow
import React from 'react';

const FacebookIcon = () => (
  <svg width="20" height="20">
    <g fill="none">
      <path
        fill="#FFF"
        d="M20 18.75c0 .69-.56 1.25-1.25 1.25H1.25C.56 20 0 19.44 0 18.75V1.25C0 .56.56 0 1.25 0h17.5C19.44 0 20 .56 20 1.25v17.5z"
      />
      <path
        fill="#3B5998"
        d="M13.75 20v-7.5h2.5l.625-3.125H13.75v-1.25c0-1.25.626-1.875 1.875-1.875h1.25V3.125h-2.5c-2.297 0-3.75 1.8-3.75 4.375v1.875h-2.5V12.5h2.5V20h3.125z"
      />
    </g>
  </svg>
);

export default FacebookIcon;
