// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import {H3} from 'componentsStyled/Typography';
import {Text} from 'componentsStyled/Typography/Texts';
import withOnMount from 'hoc/withOnMount';
import * as React from 'react';
import {Collapse} from 'react-collapse';
import {type HOC, compose, withStateHandlers} from 'recompose';

import {Expander, SubGroupWrap, Subtitle} from './styled';

type Outter = {|
  children: React.Node,
  group: string,
  subtitle?: string,
  center?: boolean,
  open?: boolean,
  maxHeight?: string,
  showExpandText?: boolean,
  className?: string,
|};

export type Props = {
  ...Outter,
  toggleCollapse: Function,
  isCollapsed: boolean,
};

// Like Collapsible Panel, however it is styled to better suit its application
const CollapsibleParagraph = ({
  group,
  toggleCollapse,
  isCollapsed,
  children,
  maxHeight,
  className,
  showExpandText = true,
  subtitle,
}: Props) => {
  const rotationCondition = isCollapsed ? 'up' : undefined;
  const style = maxHeight ? {overflowY: 'scroll', maxHeight} : null;

  return (
    <React.Fragment>
      <SubGroupWrap className={className} onClick={toggleCollapse} isOpened={!isCollapsed}>
        <div>
          <H3 bold black>
            {group}
          </H3>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </div>
        <Expander>
          {showExpandText && <Text>{isCollapsed ? 'Collapse' : 'Expand'}</Text>}
          <ArrowIcon marginLeft floatRight rotation={rotationCondition} />
        </Expander>
      </SubGroupWrap>
      <Collapse style={style} isOpened={isCollapsed}>
        {children}
      </Collapse>
    </React.Fragment>
  );
};

const enhancer: HOC<*, Outter> = compose(
  withStateHandlers(
    {isCollapsed: false},
    {
      toggleCollapse: props => () => ({isCollapsed: !props.isCollapsed}),
    }
  ),
  withOnMount(props => {
    if (props.open) props.toggleCollapse();
  })
);

const ControlledCollapsibleParagraph = enhancer(CollapsibleParagraph);

export {
  ControlledCollapsibleParagraph as default,
  CollapsibleParagraph as UncontrolledCollapsibleParagraph,
};
