// @flow
import {mqMax} from 'common/mediaQuery';
import styled from 'styled-components';

export const DestinationsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 60rem;
  max-height: ${p => `${p.maxHeight * 1.5}rem`};

  ${mqMax.tabletWide} {
    max-height: ${p => `${p.maxHeight * 2}rem`};
    align-items: flex-start;
  }
  ${mqMax.phone} {
    max-height: ${p => `${p.maxHeight * 2.5}rem`};
    align-items: flex-start;
  }
`;
