// @flow
import type {ChooseActivity} from 'data/app/actions';
import type {DateRangeValue} from 'data/units/date/types';
import type {Logout} from 'data/user/actions';

import type {SearchAction} from './actions';
import type {AutocompleteItem} from './graphql/queries/autocomplete';
import type {SearchParams} from './types';

export type SearchState = {|
  params: SearchParams,
  dates: ?DateRangeValue,
  recentSearches: AutocompleteItem[],
|};

const initialState: SearchState = {
  params: {},
  dates: undefined,
  recentSearches: [],
};

function searchReducer(
  state: SearchState = initialState,
  action: SearchAction | Logout | ChooseActivity
): SearchState {
  switch (action.type) {
    case 'SEARCH/FILTER':
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      };
    case 'SEARCH/FILTER_DATES':
      return {
        ...state,
        dates: action.payload,
      };
    case 'SEARCH/ADD_RECENT_SEARCH': {
      const payload = action.payload;
      return {
        ...state,
        params: {
          affiliate: state.params.affiliate,
        },
        dates: undefined,
        // unique items only
        recentSearches: state.recentSearches.find(
          x => x.id === payload.id && x.type === payload.type
        )
          ? state.recentSearches
          : state.recentSearches.concat(payload).slice(0, 5),
      };
    }
    case 'SEARCH/TRIGGER_RECENT_SEARCH':
      return {
        ...state,
        params: {
          affiliate: state.params.affiliate,
        },
        dates: undefined,
      };
    case 'SEARCH/CLEAR_PRODUCT':
      return {
        ...state,
        params: {
          ...state.params,
          product: undefined,
        },
        recentSearches: state.recentSearches,
      };
    case 'SEARCH/CLEAR_AFFILIATE':
      return {
        ...state,
        params: {
          ...state.params,
          affiliate: undefined,
        },
        recentSearches: state.recentSearches,
      };
    case 'SEARCH/CLEAR':
      return {
        ...initialState,
        params: {
          affiliate: state.params.affiliate,
        },
        recentSearches: state.recentSearches,
      };
    case 'APP/CHOOSE_ACTIVITY':
      return {
        ...initialState,
        params: {
          affiliate: state.params.affiliate,
        },
        recentSearches: state.recentSearches,
      };
    case 'USER/LOGOUT':
      return initialState;
    default:
      return state;
  }
}

export default searchReducer;
