// @flow
import CloseButton from 'components/Button/CloseButton';
import Drawer from 'components/Drawer';
import {FullStoreInfoContent} from 'components/StoreSelect/templates';
import {filterStores, sortByCountry, sortByDistance} from 'components/StoreSelect/utils';
import type {Location} from 'data/app/types';
import type {AffiliateOnVariant} from 'data/product/types';
import useUniqueCountriesAcrossAffiliates from 'hooks/useUniqueCountriesAcrossAffiliates';
import SearchIcon from 'pages/_Page/MainNav/LoggedInNav/SearchAutocomplete/Input/SearchIcon';
import {groupBy} from 'ramda';
import * as React from 'react';
// $ReactHooks
import {useMemo, useState} from 'react';

import {
  DrawerCloseButton,
  DrawerTitle,
  InputWrapper,
  SelectListTitle,
  StoreMenuButton,
  StoreMenuList,
  StyledDrawerBody,
  StyledDrawerHeader,
  StyledStoreSelectDrawer,
  TextInput,
} from './styled';

type ListItemProps = {
  store: AffiliateOnVariant,
  selectedStore?: AffiliateOnVariant,
  showCountryCode?: boolean,
  onClick: (store: AffiliateOnVariant) => void,
};

const StoreSelectListItem = ({store, selectedStore, showCountryCode, onClick}: ListItemProps) => {
  const isSelected = selectedStore && selectedStore.id === store.id;

  return (
    <li>
      <StoreMenuButton isSelected={isSelected} onClick={() => onClick(store)}>
        <FullStoreInfoContent showPlaceIcon={false} {...{store, isSelected, showCountryCode}} />
      </StoreMenuButton>
    </li>
  );
};

type ListProps = {
  stores: AffiliateOnVariant[],
  selectedStore?: AffiliateOnVariant,
  showCountryCode?: boolean,
  onClick: (store: AffiliateOnVariant) => void,
  title?: React.Node,
};

const StoreSelectList = ({title, stores, selectedStore, showCountryCode, onClick}: ListProps) => {
  return stores && stores.length > 0 ? (
    <div>
      {title && <SelectListTitle>{title}</SelectListTitle>}
      <StoreMenuList>
        {stores.map(store => (
          <StoreSelectListItem
            key={store.id}
            store={store}
            selectedStore={selectedStore}
            showCountryCode={showCountryCode}
            onClick={onClick}
          />
        ))}
      </StoreMenuList>
    </div>
  ) : null;
};

export type Props = {
  isOpen?: boolean,
  onClose?: () => void,
  stores: AffiliateOnVariant[],
  selectedStoreId?: number,
  selectedLocations?: ?(Location[]),
  userCoords?: {latitude: number, longitude: number},
  onStoreSelect: (id: string | number) => void,
};

const StoreSelectDrawer = ({
  stores,
  selectedLocations,
  userCoords,
  isOpen,
  onClose,
  selectedStoreId,
  onStoreSelect,
}: Props) => {
  const selectedStore = useMemo(
    () => stores.find(store => store.id === selectedStoreId),
    [selectedStoreId, stores]
  );

  // Show country code only if multiple countries
  const uniqueCountries = useUniqueCountriesAcrossAffiliates(stores);
  const showCountryCode = uniqueCountries.length > 1;

  const [filterText, setFilterText] = useState('');
  // Filter stores based on text input
  const filteredStores = useMemo(() => filterStores(stores, filterText), [stores, filterText]);

  // Split filtered stores into those matching selected locations (chosen) and those that don't (other)
  const {chosen, other} = useMemo(() => {
    const selectedLocationIds = (selectedLocations || []).map(location => location.id);
    const {chosen, other} = groupBy(
      store => (selectedLocationIds.includes(store.location.id) ? 'chosen' : 'other'),
      filteredStores
    );

    //If we have the user's location, then sort by distance, else sort by country alphabetically
    const sort = (stores: AffiliateOnVariant[]) =>
      userCoords ? sortByDistance(stores, userCoords) : sortByCountry(stores);
    return {
      chosen: chosen && sort(chosen),
      other: other && sort(other),
    };
  }, [filteredStores, selectedLocations, userCoords]);

  // On click handler for selecting a store. Slight delay on calling onClose in order to see the
  // store be selected
  const onStoreClick = store => {
    onStoreSelect(store.id);
    setTimeout(() => {
      onClose && onClose();
    }, 200);
  };

  return (
    <Drawer onClose={onClose} isOpen={isOpen} direction="right">
      <StyledStoreSelectDrawer>
        {/* Header with search field */}
        <StyledDrawerHeader>
          <DrawerCloseButton onClick={onClose} />
          <DrawerTitle>Choose a store</DrawerTitle>
          <InputWrapper>
            <SearchIcon />
            <TextInput
              value={filterText}
              onChange={event => setFilterText(event.target.value)}
              placeholder="Search for stores"
              aria-label="Search for stores"
            />
            {filterText && <CloseButton size={12} onClick={() => setFilterText('')} />}
          </InputWrapper>
        </StyledDrawerHeader>
        {/* Stores matching chosen locations*/}
        <StyledDrawerBody>
          <StoreSelectList
            title="From your chosen locations"
            stores={chosen}
            selectedStore={selectedStore}
            onClick={onStoreClick}
            showCountryCode={showCountryCode}
          />
          {/* All other stores */}
          <StoreSelectList
            title={chosen ? 'Other stores' : null}
            stores={other}
            selectedStore={selectedStore}
            onClick={onStoreClick}
            showCountryCode={showCountryCode}
          />
        </StyledDrawerBody>
      </StyledStoreSelectDrawer>
    </Drawer>
  );
};

export default StoreSelectDrawer;
