import React from 'react';

import {HtmlViewerWrapper, RawHtml} from './styled';

type Props = {
  html: string,
};

const HtmlViewer = (props: Props) => {
  const {html} = props;
  const ENV = process.env.NODE_ENV;
  return (
    <React.Fragment>
      <HtmlViewerWrapper dangerouslySetInnerHTML={{__html: html}} />
      {/* Debug only in dev */}
      {ENV === 'development' && <RawHtml>{html}</RawHtml>}
    </React.Fragment>
  );
};

export default HtmlViewer;
