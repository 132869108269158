// @flow
import {stopPropagation} from 'common/events';
import * as React from 'react';

import {Overlay, OverlayWrapperContent, OverlayWrapperWrap} from './styled';

type Props = {
  isOpen: boolean,
  close: Function,
  children: React.Node,
  transparent?: boolean,
};

const OverlayWrapper = ({isOpen, close, children, transparent}: Props) => (
  <OverlayWrapperWrap onClick={stopPropagation}>
    {isOpen && <Overlay onClick={close} transparent={transparent} />}
    <OverlayWrapperContent isOpen={isOpen}>{children}</OverlayWrapperContent>
  </OverlayWrapperWrap>
);

export default OverlayWrapper;
