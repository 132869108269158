// @flow
import type {EnumValue} from 'data/enums/types';
import useFilterOptions from 'hooks/useFilterOptions';
import {sortBy} from 'ramda';
import React from 'react';
// $ReactHooks
import {type HOC, withHandlers} from 'recompose';
import MultiSelectItem from 'searchFilters/Shared/Multiselect/MultiSelectItem';

const DEFAULT_ID_KEY = 'id';

const Multiselect = ({options = [], value, handleSelect, idKey = DEFAULT_ID_KEY}) => {
  const allOptions = useFilterOptions<EnumValue>(options || [], value, idKey);
  const sortedOptions = sortBy(option => option.name, allOptions);

  return (
    <React.Fragment>
      {sortedOptions.map(o => {
        const {checked, disabled, ...rawOptionData} = o;
        const handleClick = () => handleSelect(rawOptionData);

        return (
          <MultiSelectItem
            key={o[idKey]}
            onClick={handleClick}
            name={o.name}
            checked={o.checked}
            disabled={o.disabled}
          />
        );
      })}
    </React.Fragment>
  );
};

type Outter = {|
  options: ?(EnumValue[]),
  onChange: (?(EnumValue[])) => mixed,
  value: ?(EnumValue[]),
  idKey?: string,
|};

const enhancer: HOC<*, Outter> = withHandlers({
  handleSelect: props => (v: EnumValue) => {
    const idKey = props.idKey || DEFAULT_ID_KEY;
    const prevValue = props.value || [];

    if (prevValue.map(x => x[idKey]).includes(v[idKey])) {
      const newValue = prevValue.filter(x => x[idKey] !== v[idKey]);
      return props.onChange(newValue);
    }

    return props.onChange(prevValue.concat(v));
  },
});

export default enhancer(Multiselect);
