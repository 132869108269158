// @flow
import Checkbox from 'components/Checkbox';
import {Text} from 'componentsStyled/Typography/Texts';
import type {Accessory} from 'data/reservations/types';
import {formatMoney} from 'data/units/money/formatters';
import {sum} from 'ramda';
import React from 'react';
import {type HOC, withHandlers} from 'recompose';

import {getBasicPricingHelper} from './helpers.js';
import {AccessoriesSelection, PriceText} from './styled';

const AccessoriesSelect = ({
  options,
  value = [],
  handleSelect,
  hover = true,
  currency,
  reservationLength,
}) => {
  if (!options) {
    return null;
  }

  return (
    <React.Fragment>
      {options &&
        options.map((accessory, i) => {
          const selectedAccessoryNames = value.map(v => v.name);

          const isSelected = selectedAccessoryNames.includes(accessory.name);
          const accessoryPrices = accessory.prices
            ? getBasicPricingHelper(accessory.prices, reservationLength)
            : [];

          return (
            <AccessoriesSelection onClick={() => handleSelect(accessory)} hover={hover} key={i}>
              <Text book={isSelected} black={isSelected}>
                {accessory.name} &nbsp;
                <PriceText active={isSelected}>
                  ({formatMoney(sum(accessoryPrices), false, currency)})
                </PriceText>
              </Text>{' '}
              <Checkbox value={isSelected} />
            </AccessoriesSelection>
          );
        })}
    </React.Fragment>
  );
};

type Outter = {|
  currency?: string,
  options: Accessory[],
  onChange: Function,
  reservationLength: number,
  value: Accessory[],
  hover?: boolean,
|};

const enhancer: HOC<*, Outter> = withHandlers({
  handleSelect: props => v => {
    const prevValue = props.value || [];

    if (prevValue.map(x => x.name).includes(v.name)) {
      const newValue = prevValue.filter(x => x.name !== v.name);
      return props.onChange(newValue);
    }

    return props.onChange(prevValue.concat(v));
  },
});

export default enhancer(AccessoriesSelect);
