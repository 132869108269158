// @flow
import Button from 'components/Button';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import {FixedBottom, FixedBottomSpacer} from 'componentsStyled/Layout/FixedBottom';
import {WrapNoDesktop} from 'componentsStyled/Layout/Wrap';
import {Text} from 'componentsStyled/Typography/Texts';
import {openModal} from 'data/modals/actions';
import {getPaymentMethodQuery} from 'data/user/graphql/queries';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import withUser from 'hoc/withUser';
import CreditCard from 'modals/CreditCard';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import CardBrand from './CardBrand';
import {CardInfo} from './styled';

const Content = ({data, addCard, editCard}) => {
  if (!data)
    return (
      <React.Fragment>
        <WrapNoDesktop>
          <Text>No credit card added.</Text>
        </WrapNoDesktop>
        <FixedBottomSpacer />
        <FixedBottom>
          <Button fullwidth onClick={addCard}>
            Add credit card
          </Button>
        </FixedBottom>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <WrapNoDesktop>
        <CardInfo>
          <Text>Current card: </Text>
          <CardBrand brand={data.brand} />
          <Text book black>
            •••• {data.last4}
          </Text>
        </CardInfo>
      </WrapNoDesktop>
      <FixedBottomSpacer />
      <Desktop>
        <Button fullwidth onClick={editCard}>
          Edit card
        </Button>
      </Desktop>
      <Mobile>
        <FixedBottom>
          <Button fullwidth onClick={editCard}>
            Edit card
          </Button>
        </FixedBottom>
      </Mobile>
    </React.Fragment>
  );
};

const enhancer: HOC<*, {||}> = compose(
  withUser(),
  withQuery(getPaymentMethodQuery, {
    noEmpty: true,
    config: {
      skip: props => !props.user.customer,
    },
  }),
  withConnect(() => ({}), {
    openModal,
  }),
  withHandlers({
    addCard: props => () =>
      props.openModal(CreditCard, {
        type: 'add',
        data: {...props.data, user: props.user},
      }),
    editCard: props => () =>
      props.openModal(CreditCard, {
        type: 'edit',
        data: {...props.data, user: props.user},
      }),
  })
);

export default enhancer(Content);
