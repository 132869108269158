// @flow
import {mq} from 'common/mediaQuery';
import CloseButton from 'components/Button/CloseButton';
import {storeSelectWrapperCSS} from 'components/StoreSelect/styled';
import {lighten} from 'global/theme';
import styled from 'styled-components';

export const StyledStoreSelectDrawer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${mq.phoneWide} {
    width: 40rem;
  }
`;

export const StyledDrawerHeader = styled.div`
  position: relative;
  background: ${props => props.theme.color.white};
  padding: 1.6rem;
  border-bottom: 1px solid ${props => props.theme.color.neutralGrayLight};
`;

export const StyledDrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2.4rem;
  padding: 1.6rem;
  overflow-y: auto;
  background: ${props => props.theme.color.lighterGray};
`;

export const DrawerTitle = styled.h1`
  margin: 0 0 1.6rem 0;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
`;

export const StoreMenuList = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const SelectListTitle = styled.h2`
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0.8rem;
`;

export const StoreMenuButton = styled.button`
  ${storeSelectWrapperCSS};

  cursor: pointer;
  width: 100%;
`;

export const DrawerCloseButton = styled(CloseButton)`
  position: absolute;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  color: ${props => props.theme.color.secondaryText}
  width: 100%;
  height: 4rem;
  padding: 0 1rem 0 1.4rem;
  gap: 1rem;
  border: 1px solid ${props => props.theme.color.secondaryText};
  border-radius: 4px;

  &:focus-within {
    border: 1px solid ${props => props.theme.color.primaryAccent};
  }

  svg {
    flex-shrink: 0;

    & path {
      fill: ${props => props.theme.color.secondaryText};
    }
  }
`;

export const TextInput = styled.input`
  height: 100%;
  width: 100%;
  padding: 0;
  border-style: none !important;
  outline: none;
  background: transparent;
  color: ${props => props.theme.color.secondaryText};

  &::placeholder {
    color: ${props => lighten(props.theme.color.secondaryText, 0.5)};
  }
`;
