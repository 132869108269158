// @flow
import {DeliverySummaryContainer} from 'components/DeliverySummary/styled';
import StyledLink from 'components/StyledLink';
import {VPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {lighten} from 'global/theme';
import styled, {css} from 'styled-components';

export const Confirmation = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: unset;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0 auto;
`;

export const CheckboxWrap = styled.div`
  margin-right: 1rem;
`;

export const CreditCardInfo = styled.div`
  padding: 1.5rem;

  border-style: solid;
  border-radius: 4px;
  ${props =>
    props.success &&
    css`
      border-color: ${props => props.theme.color.success};
      background-color: ${props => lighten(props.theme.color.success, 0.1)};
    `};
  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.color.error};
      background-color: ${props => lighten(props.theme.color.error, 0.1)};
    `};
`;

export const CardIcon = styled.span`
  margin-right: 8px;
`;

export const UpdateCardLink = styled(StyledLink)``;

export const BasketCheckoutFormWrap = styled(VPaddedFullWidthContainer)`
  ${DeliverySummaryContainer} {
    margin: 3.2rem 0 2rem 0;
  }
`;

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin: 3.2rem 0;
`;
