// @flow
import type {Theme} from 'global/theme';
import React from 'react';
import {withTheme} from 'styled-components';

const SuccessIcon = ({theme}: {theme: Theme}) => (
  <svg width="62" height="62">
    <path
      d="M56.233 0H5.767C2.595 0 0 2.595 0 5.767v50.466C0 59.405 2.595 62 5.767 62h50.466C59.405 62 62 59.405 62 56.233V5.767C62 2.595 59.405 0 56.233 0zM44.626 25.881L29.27 41.165c-.577.577-1.298.865-2.019.865-.72 0-1.442-.288-2.018-.865l-7.859-7.786a2.872 2.872 0 0 1 0-4.11 2.872 2.872 0 0 1 4.11 0l5.767 5.768 13.265-13.265a2.872 2.872 0 0 1 4.11 0 3.008 3.008 0 0 1 0 4.11z"
      fill={theme.color.primaryAccent}
      fillRule="nonzero"
    />
  </svg>
);

export default withTheme(SuccessIcon);
