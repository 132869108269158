// @flow
import Button from 'components/Button';
import {FixedBottom, FixedBottomSpacer} from 'componentsStyled/Layout/FixedBottom';
import {WrapNoDesktop} from 'componentsStyled/Layout/Wrap';
import {notificationSuccess} from 'data/notifications/actions';
import {updatePhoneNumberMutation} from 'data/user/graphql/mutations';
import Alert from 'forms/Alert';
import TextInput from 'forms/Input/Text';
import {phoneNumber as phoneNumberValidator} from 'forms/validators/string';
import withForm from 'forms/withForm';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withUser from 'hoc/withUser';
import React from 'react';
import {type HOC, compose, withProps} from 'recompose';

const UpdatePhoneNumberForm = ({isSubmitting, error = '', data}) => (
  <React.Fragment>
    <WrapNoDesktop>
      <Alert>{error}</Alert>
      <TextInput name="phoneNumber" label="Phone Number" />
    </WrapNoDesktop>
    <FixedBottomSpacer />
    <FixedBottom>
      <Button loading={isSubmitting} fullwidth>
        Save
      </Button>
    </FixedBottom>
  </React.Fragment>
);

const schema = {
  phoneNumber: phoneNumberValidator,
};

const mapDispatchToProps = {
  notificationSuccess,
};

const enhancer: HOC<*, {||}> = compose(
  withUser(),
  withConnect(() => ({}), mapDispatchToProps),
  withMutation(updatePhoneNumberMutation),
  withProps(props => {
    return {formData: {phoneNumber: props.user.phoneNumber}};
  }),
  withForm({
    schema,
    onSubmit: props => v => {
      return props.submitMutation({
        phoneNumber: v.phoneNumber,
      });
    },
    onSuccess: props => () => props.notificationSuccess('Successfully changed'),
  })
);

export default enhancer(UpdatePhoneNumberForm);
