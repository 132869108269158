// @flow
import {storeSelectWrapperCSS} from 'components/StoreSelect/styled';
import styled from 'styled-components';

export const StyledStoreSelectButton = styled.button`
  ${storeSelectWrapperCSS};
  width: 100%;
  cursor: pointer;
`;

export const RightIconWrap = styled.span`
  display: flex;
  gap: 1rem;
`;
