// @flow
import {locationFragment} from 'data/app/graphql/fragments';
import {manufacturerFragment} from 'data/manufacturer/fragments';
import gql from 'graphql-tag';

const parameters = `
    ... on ParameterFloatValue {
      id
      name
      label
      type
      floatValue
      unit
      displayedUnit
    }
    ... on ParameterIntValue {
      id
      name
      label
      type
      intValue
      unit
      displayedUnit
    }
    ... on ParameterEnumFlattenedValue {
      id
      name
      label
      type
      enumValues
    }
    ... on ParameterStringValue {
      id
      name
      label
      type
      stringValue
    }
`;

export const productFragment = gql`
  fragment productFragment on Product {
    id
    name
    description
    variantVariables
    variants {
      id
      name
      affiliates {
        address
        id
        pricing
        hasAccessories
        name
        deliverySummary
        allowDeliveryFulfilment
        allowInStoreFulfilment
        location {
          name
          id
          lat
          lng
          country {
            id
            code
            name
            currency
          }
        }
      }
    }
    images {
      alt
      order
      sizes {
        url
        width
        height
      }
    }
    manufacturer {
      ...manufacturerFragment
    }
    categories {
      categoryId
      name
    }
    ratingAverage
    ratingCount
  }
  ${manufacturerFragment}
`;

export const productFragmentMinimal = gql`
  fragment productFragmentMinimal on Product {
    id
    name
    description
    variantVariables
    images {
      alt
      order
      sizes {
        url
        width
        height
      }
    }
    manufacturer {
      ...manufacturerFragment
    }
    categories {
      categoryId
      name
    }
    ratingAverage
    ratingCount
  }
  ${manufacturerFragment}
`;

export const productOfferingFragment = gql`
  fragment productOfferingFragment on Product {
    id
    name
    description
    manufacturer {
      id
      name
    }
    variants {
      id
      name
      affiliates {
        id
        pricing
        hasAccessories
        name
        deliverySummary
        allowDeliveryFulfilment
        allowInStoreFulfilment
        location {
          id
          lat
          lng
          country {
            id
            code
            currency
          }
        }
      }
    }
    images {
      id
      alt
      order
      sizes {
        id
        url
        width
        height
      }
    }
    categories {
      categoryId
      name
      relatedCategories {
        name
        categoryId
      }
    }
  }
`;

export const productVariantFragment = gql`
  fragment productVariantFragment on ProductVariant {
    id
    name
    parameters {
      ${parameters}
    }
    product {
      ...productFragmentMinimal
    }
    affiliates {
      id
      name
      availability {
        startDate: start
        endDate: end
      }
      pricing
      hasAccessories
      location {
        ...locationFragment
      }
    }
  }
  ${locationFragment}
  ${manufacturerFragment}
  ${productFragmentMinimal}
`;

export const productItemFragment = gql`
  fragment productItemFragment on ProductItem {
    id
    productVariant {
      ...productVariantFragment
    }
    code
    affiliate {
      id
      name
      address
    }
  }
  ${productVariantFragment}
`;

export const productVariantMinFragment = gql`
  fragment productVariantMinFragment on ProductVariant {
    id
    parameters {
     ${parameters}
    }
    product {
      ...productFragmentMinimal
    }
  }
  ${productFragmentMinimal}
`;

export const productItemMinFragment = gql`
  fragment productItemMinFragment on ProductItem {
    id
    productVariant {
      ...productVariantMinFragment
    }
    code
    affiliate {
      id
      name
      address
    }
  }
  ${productVariantMinFragment}
`;

export const productFragmentWithVariants = gql`
  fragment productFragment on Product {
    id
    name
    description
    images {
      alt
      order
      sizes {
        url
        width
        height
      }
    }
    manufacturer {
      ...manufacturerFragment
    }
    categories {
      categoryId
      name
    }
    variants {
      id
      parameters {
        ${parameters}
      }
    }
  }
  ${manufacturerFragment}
`;
