// @flow
import type {StoreState} from 'global/store/reducers';
import {connect} from 'react-redux';
import type {HOC} from 'recompose';

function withConnect<MapperAdd, DispatchAdd, Outter>(
  mapper: (state: StoreState) => MapperAdd,
  dispatch: DispatchAdd
): HOC<{...$Exact<MapperAdd>, ...$Exact<DispatchAdd>, ...$Exact<Outter>}, Outter> {
  return connect(mapper, dispatch || {});
}

export default withConnect;
