// @flow
import styled from 'styled-components';

export const Inner = styled.div`
  max-width: 63rem;
  margin: 0 auto;
`;

export const Raw = styled.div`
  .center {
    text-align: center;
  }

  .bold {
    font-weight: ${props => props.theme.fontWeights.bold};
  }

  h2,
  h3 {
    font-size: 1.6rem;
    margin: 0;
    display: inline;
  }

  p {
    margin: 1rem 0 0;
  }

  a {
    color: ${props => props.theme.color.primaryAccent};
  }

  ol {
    padding-left: 2rem;
    margin: 0;
    position: relative;
  }

  li {
    margin-top: 1rem;
  }

  br {
    content: ' ';
    display: block;
    height: 1rem;
    line-height: 1rem;
  }

  > ol {
    counter-reset: list-level-1;

    > li {
      counter-increment: list-level-1;
      list-style: none;

      &::before {
        position: absolute;
        left: 0;
        content: counter(list-level-1);
      }

      > ol {
        counter-reset: list-level-2;
        padding-left: 3.6rem;

        > li {
          counter-increment: list-level-2;
          list-style: none;

          &::before {
            position: absolute;
            left: 0;
            content: counter(list-level-1) '.' counter(list-level-2);
          }

          > ol {
            list-style: lower-alpha;
          }
        }
      }
    }

    ul {
      list-style: disc;
      margin-left: 3rem;
    }
  }
`;
