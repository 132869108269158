// @flow
import {assertNever} from 'common/flow';
import type {Activity, Location} from 'data/app/types';

/** Returns a nicely formatted activity from its name */
export function formatActivity(a: ?Activity): string {
  if (!a) {
    return 'Gear';
  }

  switch (a.name) {
    case 'sups':
      return 'SUP';
    case 'surfs':
      return 'Surf';
    case 'skis':
      return 'Ski';
    case 'snowboards':
      return 'Snowboard';
    case 'splitboards':
      return 'Splitboard';
    case 'mountain_bikes':
      return 'Mountain Bike';
    case 'road_bikes':
      return 'Road Bike';
    default:
      return assertNever(a.name);
  }
}

/**
 * Formats the heading of the desktop filters
 */
export const formatFilter = (title: string) => (v: {name: string}[]) => {
  if (!v || v.length === 0) return title;
  if (v.length === 1) return v[0].name;
  return `${v.length} ${title}`;
};

export const formatLocation = (geo: Location[]): mixed =>
  geo.length > 1 ? 'Nearby' : geo.map(geo => geo.name);
