// @flow
import FooterLinks from 'components/FooterLinks';
import {HPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {FooterMargin} from 'componentsStyled/Layout/Spacers';
import {WhiteWrap} from 'componentsStyled/Layout/WhiteWrap';
import * as React from 'react';

const SectionFooter = () => (
  <WhiteWrap>
    <HPaddedFullWidthContainer>
      <FooterLinks />
      <FooterMargin />
    </HPaddedFullWidthContainer>
  </WhiteWrap>
);

export default SectionFooter;
