// @flow
import Button from 'components/Button';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {Text} from 'componentsStyled/Typography/Texts';
import withSubmit from 'hoc/withSubmit';
import ModalBody from 'modals/_Body';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import ModalHeader from 'modals/_Header';
import React from 'react';
import type {Component, HOC} from 'recompose';

import {ButtonsWrap} from './styled';

type Outter = $Exact<{
  close: Function,
  onConfirm: void => Promise<mixed>,
  title: string,
  message?: string,
  component?: Component,
}>;

type Inner = $Exact<{
  ...Outter,
  submit: () => Promise<void>,
  loading: boolean,
}>;

export const ConfirmModal = ({title, message, component, submit, close, loading}: Inner) => (
  <ModalBody close={close} data-cy={'confirm-modal'}>
    <ModalHeader close={close} title={title} />
    <ModalContent>
      <Wrap>{component ? component : <Text center>{message}</Text>}</Wrap>
    </ModalContent>
    <ModalControls>
      <ButtonsWrap>
        <Button onClick={close} secondary>
          Close
        </Button>
        <Button loading={loading} onClick={() => submit()} data-cy={'confirm-button'}>
          Confirm
        </Button>
      </ButtonsWrap>
    </ModalControls>
  </ModalBody>
);

const enhancer: HOC<*, Outter> = withSubmit({
  submit: props => props.onConfirm,
  onSuccess: props => props.close,
  errorText: 'Something went wrong!',
});

export default enhancer(ConfirmModal);
