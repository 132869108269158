// @flow
import CustomElement from 'components/CustomElement';
import SectionFooter from 'components/SectionFooter';
import {HPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {selectAppConfig, selectTheme} from 'data/app/selectors';
import {selectIsMasquerading, selectLoggedIn} from 'data/user/selectors';
import withConnect from 'hoc/withConnect';
import * as React from 'react';
import type {Component, HOC} from 'recompose';

import LogoLink from '../../components/LogoLink';
import MainNav from './MainNav';
import MasqueradeBanner from './MasqueradeBanner';
import {BodyWrap, FooterWrap, HeaderWrap, MessageWrap, NavWrap, PageWrap} from './styled';

const Page = ({
  isMasquerading,
  loggedIn,
  theme,
  appConfig,
  filter: Filter,
  message: Message,
  children,
}) => {
  return (
    <PageWrap>
      <MasqueradeBanner isMasquerading={isMasquerading} />
      <HeaderWrap advancedHeader={theme && theme.advancedHeader}>
        {theme && theme.advancedHeader && (
          <CustomElement html={theme.header.html} css={theme.header.css} />
        )}
        {theme && !theme.advancedHeader && theme.logo.logo && (
          <LogoLink type="image" imgSrc={theme.logo.logo} logoLink={theme.logo.logoLink} />
        )}
        {theme && !theme.advancedHeader && !theme.logo.logo && appConfig && (
          <LogoLink
            type="label"
            imgSrc={theme.logo.logo}
            logoLink={theme.logo.logoLink}
            label={appConfig.tenantTitle}
          />
        )}
      </HeaderWrap>
      <NavWrap>
        <MainNav loggedIn={loggedIn} />
      </NavWrap>
      {Message && (
        <MessageWrap>
          <HPaddedFullWidthContainer>
            <Message />
          </HPaddedFullWidthContainer>
        </MessageWrap>
      )}
      {Filter && <Filter />}
      <BodyWrap>{children}</BodyWrap>
      <FooterWrap>
        <SectionFooter />
        {theme && <CustomElement html={theme.footer.html} css={theme.footer.css} />}
      </FooterWrap>
    </PageWrap>
  );
};

const mapStateToProps = state => ({
  loggedIn: selectLoggedIn(state),
  isMasquerading: selectIsMasquerading(state),
  theme: selectTheme(state),
  appConfig: selectAppConfig(state),
});

type Outter = {|
  children: React.Node,
  filter?: Component,
  message?: Component,
|};

const enhancer: HOC<*, Outter> = withConnect(mapStateToProps);

export default enhancer(Page);
