// @flow
import {selectAppConfig} from 'data/app/selectors';
import type {AppConfig} from 'data/app/types';
import type {ProductVariantAvailableAffiliate} from 'data/product/types';
import type {OriginalReservation} from 'data/reservations/types';
import type {DateRange} from 'data/units/date/types';
import {formatMoney} from 'data/units/money/formatters';
import {getPricePerDayArray} from 'data/units/money/helpers';
import withConnect from 'hoc/withConnect';
import {sum} from 'ramda';
import React from 'react';

import {H2} from '../../../componentsStyled/Typography';
import {Text} from '../../../componentsStyled/Typography/Texts';

type Props = {
  variantAffiliate: ProductVariantAvailableAffiliate,
  dates: ?DateRange,
  originalReservation?: ?OriginalReservation,
  appConfig: ?AppConfig,
};

const PriceWrapper = ({children}) => <div data-cy={'price-estimate'}>{children}</div>;

const Price = ({variantAffiliate, dates, originalReservation, appConfig}: Props) => {
  const pricing = variantAffiliate.pricing;
  const price = getPricePerDayArray(dates, variantAffiliate, originalReservation);
  const currency =
    appConfig && appConfig.showMinimalProductTileInfo
      ? undefined
      : variantAffiliate.location.country.currency;
  const currencyString = currency ? currency : '';

  if (pricing && pricing.length > 1) {
    const costString = formatMoney(sum(price), false, currency);
    const priceDesc = costString ? `${costString} ${currencyString} total` : undefined;
    return (
      <PriceWrapper>
        <div>{priceDesc}</div>
        <div>{price.length} days</div>
      </PriceWrapper>
    );
  }
  const costString = formatMoney(pricing[0], false, currency);
  const costTotal = formatMoney(sum(price), false, currency);
  const priceDesc1 = costString ? `${price.length} days x ${costString}` : undefined;

  return (
    <PriceWrapper>
      <Text>{priceDesc1}</Text>
      <H2>
        {costTotal} {currencyString} total
      </H2>
    </PriceWrapper>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

export default withConnect(mapStateToProps)(Price);
