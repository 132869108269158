// @flow
import React from 'react';

import {Circular, LoaderWrap, Path, StyledLoader} from './styled';

// SOURCE: https://codepen.io/jczimm/pen/vEBpoL

const Loader = ({...props}: {}) => (
  <LoaderWrap {...props}>
    <StyledLoader>
      <Circular viewBox="25 25 50 50">
        <Path cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" {...props} />
      </Circular>
    </StyledLoader>
  </LoaderWrap>
);

export default Loader;
