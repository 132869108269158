// @flow
import {mqMax} from 'common/mediaQuery';
import {lighten} from 'global/theme';
import styled, {css} from 'styled-components';

export const TipWrap = styled.div`
  left: -13.2rem;
  bottom: 2.5rem;
  position: absolute;
  display: none;
  min-width: 30rem;
  max-height: 20rem;
  background: ${props => props.theme.color.white};
  box-shadow: -0.5rem 0.5rem 1rem 0.5rem ${props => lighten(props.theme.color.primaryText, 0.1)};
  border-radius: 0.4rem;
  padding: 1rem;
  z-index: ${props => props.theme.zIndices.overlay};

  ${p =>
    p.modal &&
    css`
      left: -5.2rem;
    `}

  ${mqMax.tabletWide} {
    left: -5rem;

    ${p =>
      p.withButton &&
      css`
        left: -15rem;
      `}
  }

  ${mqMax.phone} {
    min-width: 20rem;
  }

  &::after {
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    bottom: -1rem;
    content: '';
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid ${props => props.theme.color.white};

    ${p =>
      p.modal &&
      css`
        left: -15.9rem;
      `}

    ${mqMax.tabletWide} {
      left: -16.5rem;

      ${p =>
        p.withButton &&
        css`
          left: 3.7rem;
        `}
    }

    ${mqMax.phone} {
      left: -6.45rem;

      ${p =>
        p.withButton &&
        css`
          left: 13.6rem;
        `}
    }
  }

  ${p =>
    p.active &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const ToolTipContainer = styled.div`
  display: inline-block;
  justify-content: center;
  max-width: 3rem;
  margin: 0 1rem;
  cursor: pointer;
`;

export const ToolTipWrap = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 2.5rem;
  margin-left: -2rem;
  width: 4;
  position: relative;
`;
