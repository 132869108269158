// @flow
import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';

import introspectionQueryResultData from './introspectionQueryResultData';

// needed for dynamic params in products
// source: https://www.apollographql.com/docs/react/recipes/fragment-matching.html
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
  // https://www.apollographql.com/docs/react/v2/caching/cache-configuration#custom-identifiers
  dataIdFromObject: object => {
    switch (object.__typename) {
      // The unique identifier for ProductVariantAffiliate is the ID of the variant + the ID of the affiliate.
      // However, the object that is returned only has the ID of the affiliate. This causes Apollo to incorrectly
      // identify ProductVariantAffiliates with the same affiliate ID but different variant IDs as the same. Apollo
      // will then cache one and re-use it incorrectly wherever a ProductVariantAffiliate with that affiliate ID
      // appears. To get around this we are setting the unique identifier for ProductVariantAffiliates to always
      // return null so that they are not cached.
      case 'ProductVariantAffiliate':
        return null;
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

export default cache;
