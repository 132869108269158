// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import UserIcon from 'assets/icons/UserIcon';
import ContextMenu from 'components/ContextMenu';
import OverlayWrapper from 'components/OverlayWrapper';
import urls from 'data/router/urls';
import {logout} from 'data/user/actions';
import withConnect from 'hoc/withConnect';
import withOpen from 'hoc/withOpen';
import withUser from 'hoc/withUser';
import React from 'react';
import {type HOC, compose} from 'recompose';

import {MenuLink, MenuLinks, Toggle} from './styled';

const AvatarMenu = ({close, toggleOpen, isOpen, user, logout}) => {
  const rotationCondition = isOpen ? 'up' : undefined;
  return (
    <OverlayWrapper isOpen={isOpen} close={close}>
      <Toggle open={isOpen} onClick={toggleOpen} isSelected={isOpen}>
        <UserIcon />
        <ArrowIcon color="current" marginLeft rotation={rotationCondition} />
      </Toggle>
      <ContextMenu isOpen={isOpen} close={close} isRightAligned>
        <MenuLinks>
          <MenuLink to={urls.account.index} onClick={close}>
            Account
          </MenuLink>
          <MenuLink onClick={logout}>Logout</MenuLink>
        </MenuLinks>
      </ContextMenu>
    </OverlayWrapper>
  );
};
const enhancer: HOC<*, {}> = compose(
  withUser(),
  withOpen,
  withConnect(() => ({}), {logout})
);

export default enhancer(AvatarMenu);
