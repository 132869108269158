// @flow
import {SubGroupWrap} from 'components/CollapsibleParagraph/styled';
import {H3} from 'componentsStyled/Typography';
import styled from 'styled-components';

export const StyledFilterPanel = styled.div`
  border-bottom: solid 1px ${props => props.theme.color.lightGray};

  ${SubGroupWrap} {
    padding: 1.6rem;
    height: 8rem;
    margin: 0;

    ${H3} {
      font-weight: 500;
    }
  }
`;

export const CollapseContent = styled.div`
  padding: 0.8rem 0;
`;
