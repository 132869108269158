// @flow
import YoutubeVideo from 'components/YoutubeVideo';
import {WhiteWrap} from 'componentsStyled/Layout/WhiteWrap';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import React from 'react';

import {Content, StyledContainer as Container} from './styled';

type Props = {
  title: string,
  videoURL: string,
};

const SectionVideo = ({title, videoURL}: Props) => (
  <WhiteWrap>
    <Container>
      <PageTitle>{title}</PageTitle>
      <Content>
        <YoutubeVideo source={videoURL} />
      </Content>
    </Container>
  </WhiteWrap>
);

export default SectionVideo;
