import 'rxjs/add/observable/from';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/throttleTime';

import searchEpics from 'data/search/epics';
import userEpics from 'data/user/epics';
import {combineEpics, createEpicMiddleware} from 'redux-observable';

export const rootEpic = combineEpics(...userEpics, ...searchEpics);

const epicMiddleware = createEpicMiddleware();

export default epicMiddleware;
