// @flow
import styled, {css} from 'styled-components';

export const DesktopRelativePositionWrap = styled.div`
  position: relative;
  height: 100%;
`;

export const DesktopFilterContent = styled.div`
  display: flex;
  flex-direction: ${p => p.flexDirection || 'column'};

  padding: 1.6rem;
  min-width: 26rem;
  ${p =>
    p.noXPadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
  ${p =>
    p.minWidth &&
    css`
      min-width: ${p.minWidth}rem;
    `}
`;
