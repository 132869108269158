// @flow
import styled, {css} from 'styled-components';

export const ModalControlsWrap = styled.div`
  flex-shrink: 0;
  background: ${props => props.theme.color.white};
  padding: 1.5rem 2.4rem;
  display: flex;
  justify-content: space-between;

  ${p =>
    p.column &&
    css`
      flex-direction: column;
      align-items: center;
    `}
`;
