// @flow
import {persistReducer as reduxPersistReducer} from 'redux-persist';
import storage from 'redux-persist/es/storage';

type Config = {|
  key: string,
  whitelist?: string[],
  blacklick?: string[],
|};

// incrementing this value will result in persisted values being invalidated
const version = 4;

function persistReducer<T>(reducer: T, c: Config): T {
  return reduxPersistReducer(
    {
      ...c,
      version,
      migrate: (state, a, b, c) => {
        if (
          !state ||
          !state._persist ||
          !state._persist.version ||
          state._persist.version !== version
        ) {
          return Promise.resolve(undefined);
        }
        return Promise.resolve(state);
      },
      storage,
    },
    reducer
  );
}

export default persistReducer;
