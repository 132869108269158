import Description from 'components/Description';
import styled from 'styled-components';

export const TabTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const StyledDescription = styled(Description)`
  &.ql-editor {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
  }
`;
