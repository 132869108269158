// @flow
import type {ExtractActionTypes} from 'common/redux/types';
import type {ID} from 'data/enums/types';

import * as actions from './actions';
import type {Activity, AppConfig, Category, CategoryName, Location, Theme} from './types';

export type AppState = {|
  /** Config passed from the backend to the frontend */
  appConfig: ?AppConfig,
  /** App theme, including header and footer */
  theme: ?Theme,
  /**
   * Selected activity (NOTE: Unused due to app UI changes:
   * https://app.clubhouse.io/awayco/story/1238/user-app-ui-changes)
   */
  activity: ?Activity,
  /** Selected categories */
  categories: ?(Category[]),
  /** All selectable categories */
  allCategories: Category[],
  /** Selected location */
  geolocation: ?(Location[]),
  /** Whether the GDPR dialog has been accepted */
  GDPRHidden: boolean,
  /**
   * The callback that redirects the user to a new page after logging in. Ideally we shouldn't
   * have functions in state, but this is a workaround to the infinite loading bug after logging in.
   */
  redirectCallback: ?Function,
  selectedStoreId: ?ID,
|};

const initialState: AppState = {
  appConfig: undefined,
  theme: undefined,
  activity: undefined,
  categories: undefined,
  allCategories: [],
  geolocation: undefined,
  GDPRHidden: false,
  redirectCallback: undefined,
  selectedStoreId: undefined,
};

type Action = ExtractActionTypes<typeof actions>;

const nearbyGeolocations = (a: ?Activity, g: ?{[CategoryName]: Location[]}) => {
  if (!a || !g) return undefined;

  return g[a.name].filter(geo => geo.nearby);
};

function appReducer(state: AppState = initialState, action: Action): AppState {
  switch (action.type) {
    case 'APP/SET_CONFIG':
      return {
        ...state,
        appConfig: action.payload,
      };
    case 'APP/SET_THEME':
      return {
        ...state,
        theme: action.payload,
      };
    case 'APP/PREFETCH_GEOLOCATIONS':
      return {
        ...state,
        geolocation: state.geolocation
          ? state.geolocation
          : nearbyGeolocations(state.activity, action.payload),
      };
    case 'APP/SET_ALL_CATEGORIES':
      return {
        ...state,
        allCategories: action.payload,
      };
    case 'APP/CHOOSE_ACTIVITY':
      return {
        ...state,
        activity: action.payload,
        // NOTE(ray): There is no longer a mapping from activity to product
        // groups due to generalisation of categories:
        // https://app.clubhouse.io/awayco/story/1228/manage-product-groups
        // As such we do not want to manipulate the selected categories.
      };
    case 'APP/CHOOSE_CATEGORY':
      return {
        ...state,
        categories: action.payload,
      };
    case 'APP/CHOOSE_GEOLOCATION':
      return {
        ...state,
        geolocation: action.payload,
      };
    case 'APP/HIDE_GDPR':
      return {
        ...state,
        GDPRHidden: true,
      };
    case 'USER/LOGOUT':
    /* Fall through */
    case 'SEARCH/CLEAR':
      return {
        ...state,
        activity: undefined,
        categories: undefined,
        geolocation: undefined,
      };
    case 'APP/CHOOSE_REDIRECT_CALLBACK':
      return {
        ...state,
        redirectCallback: action.payload,
      };
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        redirectCallback: undefined,
      };
    case 'APP/SET_SELECTED_STORE_ID':
      return {
        ...state,
        selectedStoreId: action.payload,
      };
    default:
      return state;
  }
}

export default appReducer;
