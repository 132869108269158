// @flow
import {type User} from 'data/user/types';
import withLocation from 'hoc/withLocation';
import * as React from 'react';
import {type HOC} from 'recompose';

import HamburgerToggle from './HamburgerToggle';
import OverlayWrapper from './OverlayWrapper';
import Sidebar from './Sidebar';

const Hamburger = ({toggleOpen, close, isOpen, user}) => (
  <OverlayWrapper isOpen={isOpen} onClose={close}>
    <HamburgerToggle toggle={toggleOpen} />
    <Sidebar isOpen={isOpen} user={user} close={close} />
  </OverlayWrapper>
);

type Outter = {|
  user?: User,
  isOpen: boolean,
  open: Function,
  close: Function,
  toggleOpen: Function,
|};

const enhancer: HOC<*, Outter> = withLocation(props => props.close());

export default enhancer(Hamburger);
