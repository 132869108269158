// @flow
import * as React from 'react';

import {WarningWrap} from './styled';

type Props = {|
  children: React.Node,
|};

const Warning = ({children}: Props) => <WarningWrap>{children}</WarningWrap>;

export default Warning;
