// @flow
import type {ProductVariantAvailableAffiliate} from 'data/product/types';
import {dayStatus} from 'data/reservations/constants';
import type {DayStatus} from 'data/reservations/types';
import {dateStringFromMoment} from 'data/units/date/helpers';
import type {DateRangeMoment, DateRangeValue, DateString} from 'data/units/date/types';
import type Moment from 'moment';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import DayPrice from '../DayPrice';
import {isActive} from '../helpers';
import {DayWrap, StyledDay} from './styled';

const AvailableDay = ({variantAffiliate, day, status, value, handleChange, clear}) => {
  const dayActive = value && value.startDate && value.startDate.isSame(day, 'day');
  const lastDayActive = value && value.endDate && value.endDate.isSame(day, 'day');

  return (
    <DayWrap onClick={() => handleChange(dateStringFromMoment(day))} clear={clear}>
      <StyledDay
        active={isActive(day, value)}
        dayActive={dayActive}
        lastDayActive={lastDayActive}
        dateRangeSelected={value.startDate && value.endDate}
        data-cy={'calendar-day'}
      >
        {day.date()}
      </StyledDay>
      {status !== dayStatus.availableHidePrice && (
        <DayPrice
          day={dateStringFromMoment(day)}
          value={value}
          variantAffiliate={variantAffiliate}
        />
      )}
    </DayWrap>
  );
};

type Outter = {|
  variantAffiliate?: ProductVariantAvailableAffiliate,
  day: Moment,
  status: DayStatus,
  value: DateRangeMoment,
  onChange: (?DateRangeValue) => mixed,
  originalReservation?: DateRangeMoment,
  clear: Function,
|};

const enhancer: HOC<*, Outter> = compose(
  withHandlers({
    handleChange: props => (v: DateString) => {
      const fixedStartDate = props.originalReservation && props.originalReservation.startDate;
      const selectedStartDate = props.value && props.value.startDate;
      const hasStartDate = fixedStartDate || selectedStartDate;

      // set new start date
      if (!hasStartDate) {
        return props.onChange({
          startDate: v,
          endDate: undefined,
        });
      }

      const endDate = props.value && props.value.endDate;
      const hasAllValues = hasStartDate && endDate;

      // If values are set and we select new date, clear and set new start date
      if (hasAllValues && !props.originalReservation) {
        if (props.clear) {
          return props.clear(v);
        } else {
          return props.onChange({
            startDate: v,
            endDate: undefined,
          });
        }
      }

      // set new end date
      return props.onChange({
        startDate: dateStringFromMoment(selectedStartDate),
        endDate: v,
      });
    },
  })
);

export default enhancer(AvailableDay);
