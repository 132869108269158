// @flow
import {trackLoginError, trackLoginSuccess} from 'analytics/login/analyticsTraits';
import Button from 'components/Button';
import SocialLogin from 'components/SocialLogin';
import StyledLink from 'components/StyledLink';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {Note} from 'componentsStyled/Typography/Other';
import {chooseRedirectCallback} from 'data/app/actions';
import {selectAppConfig} from 'data/app/selectors';
import {openModal} from 'data/modals/actions';
import {login} from 'data/user/actions';
import {loginMutation} from 'data/user/graphql/mutations';
import Alert from 'forms/Alert';
import TextInput from 'forms/Input/Text';
import {emailRequired, stringRequired} from 'forms/validators/string';
import withForm from 'forms/withForm';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withRouter from 'hoc/withRouter';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import SignUp from 'modals/SignUp';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

const LoginForm = ({appConfig, error, isSubmitting, openForgotPassword, close, openSignup}) => {
  const tenantTitle = appConfig ? appConfig.tenantTitle : 'Awayco';

  const article =
    appConfig && (appConfig.tenantTitle[0].toLowerCase() !== 'a' || 'e' || 'i' || 'o' || 'u')
      ? 'a'
      : 'an';

  return (
    <React.Fragment>
      <ModalContent>
        <Wrap stack bottom>
          <SocialLogin close={close} />
          <Alert>{error}</Alert>
          <TextInput name="email" type="email" label="Email" />
          <TextInput name="password" label="Password" type="password" />
          <Note>
            <StyledLink onClick={openForgotPassword}>Forgot password?</StyledLink>
          </Note>
        </Wrap>
        <Wrap stack bottom>
          <Note>
            Not {article} {tenantTitle} member?{' '}
            <StyledLink onClick={openSignup}>Sign up now</StyledLink>
          </Note>
        </Wrap>
      </ModalContent>
      <ModalControls>
        <Button fullwidth loading={isSubmitting} id={'submit-login'}>
          Log In
        </Button>
      </ModalControls>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const mapDispatchToProps = {
  login,
  openModal,
  chooseRedirectCallback,
};

const schema = {
  email: emailRequired,
  password: stringRequired,
};

type Outter = {|
  close: Function,
  openForgotPassword: Function,
  callback?: Function,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps),
  withMutation(loginMutation),
  withHandlers({
    openSignup: props => () => {
      props.close();
      props.openModal(SignUp, {callback: props.callback});
    },
  }),
  withForm({
    schema,
    onSubmit: props => props.submitMutation,
    onSuccess: props => result => {
      props.callback && chooseRedirectCallback(props.callback);

      props.login(result);
      trackLoginSuccess(result);

      if (props.callback) {
        props.callback();
      }
      props.close();
    },
    onError: props => result => {
      trackLoginError(result);
    },
  })
);

export default enhancer(LoginForm);
