// @flow
import {dayStatus} from 'data/reservations/constants';
import styled, {css} from 'styled-components';

export const DayWrap = styled.div`
  width: calc(100% / 7);
  cursor: pointer;
  position: relative;
  ${p =>
    p.status === 'blocked' &&
    css`
      opacity: 0.5;
      &::after {
        content: '';
        position: absolute;
        top: 1.7rem;
        left: 50%;
        height: 0.2rem;
        width: 2rem;
        background: ${props => props.theme.color.secondaryText};
        transform: translateX(-50%);
      }
    `}
  ${p =>
    p.status === dayStatus.past &&
    css`
      opacity: 0.5;
    `}
`;

export const StyledDay = styled.div`
  color: ${props => props.theme.color.secondaryText};
  height: 3.6rem;
  line-height: 3.6rem;
  font-size: 1.5rem;
  text-align: center;
  padding: 0;

  ${p =>
    (p.status === dayStatus.closed || p.status === dayStatus.closedHidePrice) &&
    css`
      opacity: 0.25;
      background: ${props => props.theme.color.red};
      color: ${props => props.theme.color.white};
    `}
  ${p =>
    p.active &&
    css`
      opacity: 1;
      color: ${props => props.theme.color.white};
      font-weight: ${props => props.theme.fontWeights.book};
      background: ${props => props.theme.color.primaryAccent};
    `}
  ${p =>
    p.status === dayStatus.originalReservation &&
    css`
      background: ${props => props.theme.color.secondaryText};
      color: ${props => props.theme.color.white};
    `}
  ${p =>
    p.dayActive &&
    css`
      border-top-left-radius: 3.6rem;
      border-bottom-left-radius: 3.6rem;
    `}
  ${p =>
    p.lastDayActive &&
    css`
      border-top-right-radius: 3.6rem;
      border-bottom-right-radius: 3.6rem;
    `}
  ${p =>
    p.dayOfReturn &&
    css`
      background: ${props => props.theme.color.black};
      color: ${props => props.theme.color.white};
      border-top-right-radius: 3.6rem;
      border-bottom-right-radius: 3.6rem;
      border-top-left-radius: 3.6rem;
      border-bottom-left-radius: 3.6rem;
    `}
`;
