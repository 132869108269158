// @flow
import SectionFooter from 'components/SectionFooter';
import SectionVideo from 'components/SectionVideo';
import {metadata, setMetadata} from 'data/app/metadata';
import {selectGeolocation} from 'data/app/selectors';
import {openModal} from 'data/modals/actions';
import withConnect from 'hoc/withConnect';
import withOnMount from 'hoc/withOnMount';
import Login from 'modals/Login';
import SignUp from 'modals/SignUp';
import Page from 'pages/_Page';
import queryString from 'querystring';
import {tail} from 'ramda';
import React from 'react';
import {type HOC, compose} from 'recompose';

import SectionBlog from './SectionBlog';
import SectionDestinations from './SectionDestinations';
import SectionHero from './SectionHero';
import SectionTopProducts from './SectionTopProducts';
import SectionTopSellers from './SectionTopSellers';

// NOTE: Unused due to app UI changes:
// https://app.clubhouse.io/awayco/story/1238/user-app-ui-changes
const HomePage = ({geolocations}) => (
  <Page>
    <SectionHero />
    <SectionTopProducts geolocations={geolocations} />
    <SectionTopSellers geolocations={geolocations} />
    <SectionBlog />
    <SectionVideo title="Expand your world" videoURL="https://www.youtube.com/embed/JpeeyQmqCXo" />
    <SectionDestinations />
    <SectionFooter />
  </Page>
);

const mapDispatchToProps = {
  openModal,
};

type Outter = {|
  location: {search: string},
|};

const mapStateToProps = state => ({
  geolocations: selectGeolocation(state),
});

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withConnect(mapStateToProps),
  withOnMount(props => {
    setMetadata(metadata.home);

    const urlParams = queryString.parse(tail(String(props.location.search)));

    if (urlParams.hasOwnProperty('signup')) {
      props.openModal(SignUp, {});
    } else if (urlParams.hasOwnProperty('login')) {
      props.openModal(Login, {});
    }
  })
);

export default enhancer(HomePage);
