// @flow
import {Relative} from 'componentsStyled/Layout/Position';
import {FieldTitle} from 'componentsStyled/Typography/Titles';
import styled, {css} from 'styled-components';

export const InputWrapWrap = styled.label`
  display: block;
  width: 100%;
  ${p =>
    !p.noMargin &&
    css`
      margin-bottom: 2.4rem;
    `}
`;

export const MultiInputWrapWrap = styled.fieldset`
  display: block;
  width: 100%;
  margin-bottom: 2.4rem;
  border: none;
  padding: 0;
`;

export const Error = styled.div`
  color: ${props => props.theme.color.red};
  font-size: 1.2rem;
  position: ${props => props.errorPosition || 'relative'};
`;

export const LabelPositionWrap = styled(Relative)`
  ${props =>
    props.labelPosition === 'right' &&
    css`
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;

      ${FieldTitle} {
        margin-bottom: 0;
      }
    `}
`;
