// @flow
import {UncontrolledCollapsibleParagraph} from 'components/CollapsibleParagraph';
import Loader from 'components/Loader';
import {FilterPanelsContext} from 'modals/FiltersModal/index';
import * as React from 'react';
// $ReactHooks
import {useContext} from 'react';

import {CollapseContent, StyledFilterPanel} from './styled';

export type Props = {
  title: string,
  value: {name: string}[],
  children: React.Node,
  plural?: string,
  options: any[],
  panelKey: string,
  loading?: boolean,
};

const FilterPanel = ({title, children, value, plural, panelKey, options, loading}: Props) => {
  const {expandedPanelKey, setExpandedPanelKey} = useContext(FilterPanelsContext);
  const isOpen = panelKey === expandedPanelKey;
  const toggleIsOpen = () => (isOpen ? setExpandedPanelKey() : setExpandedPanelKey(panelKey));

  const showSubtitle = value && value.length > 0 && !isOpen;
  const subtitle = showSubtitle
    ? value.length === 1
      ? value[0].name
      : `${value.length} ${plural || `${title}s`}`
    : undefined;

  const hasNoOptions = !options || options.length === 0;
  const hasNoSelections = !value || value.length === 0;

  if (hasNoOptions && hasNoSelections) {
    return null;
  }

  return (
    <StyledFilterPanel>
      <UncontrolledCollapsibleParagraph
        isCollapsed={isOpen}
        toggleCollapse={toggleIsOpen}
        group={title}
        subtitle={subtitle}
        showExpandText={false}
      >
        {loading ? <Loader /> : <CollapseContent>{children}</CollapseContent>}
      </UncontrolledCollapsibleParagraph>
    </StyledFilterPanel>
  );
};

export default FilterPanel;
