// @flow
import {noop} from 'common/functions';
import {selectRouterLocation} from 'data/router/selectors';
import type {RouterLocation} from 'data/router/types';
import withConnect from 'hoc/withConnect';
import withOnNextProps from 'hoc/withOnNextProps';
import {type HOC, compose} from 'recompose';

const mapStateToProps = state => ({
  routerlocation: selectRouterLocation(state),
});

type Added = {|
  routerlocation: RouterLocation,
|};

const withLocation = <T>(
  onChangeFn: T => mixed = noop
  // $ExpectError
): HOC<{...$Exact<Added>, ...$Exact<T>}, T> =>
  compose(
    withConnect(mapStateToProps),
    withOnNextProps((prev, next) => {
      if (prev.location !== next.location) {
        onChangeFn(next);
      }
    })
  );

export default withLocation;
