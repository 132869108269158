// @flow
import type {NotificationType} from 'data/notifications/types';
import React from 'react';

import IconCheck from './IconCheck';
import IconError from './IconError';
import IconWarn from './IconWarn';

type Props = {|
  type: NotificationType,
|};

const Icon = ({type}: Props) => {
  if (type === 'success') return <IconCheck />;
  if (type === 'warning') return <IconWarn />;
  if (type === 'error') return <IconError />;

  return null;
};

export default Icon;
