// @flow
import React from 'react';

const IconError = () => (
  <svg width="22" height="22" viewBox="0 0 22 22">
    <path
      fill={props => props.theme.color.white}
      fillRule="evenodd"
      d="M1.10050506 9.58578644l8.48528138-8.48528138c.78104856-.78104858 2.04737856-.78104858 2.82842716 0l8.4852813 8.48528138c.7810486.78104856.7810486 2.04737856 0 2.82842716l-8.4852813 8.4852813c-.7810486.7810486-2.0473786.7810486-2.82842716 0l-8.48528138-8.4852813c-.78104858-.7810486-.78104858-2.0473786 0-2.82842716zM10 6v7h2V6h-2zm0 8v2h2v-2h-2z"
    />
  </svg>
);

export default IconError;
