// @flow
import Button from 'components/Button';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {Text} from 'componentsStyled/Typography/Texts';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import React from 'react';

type Props = {|
  close: Function,
|};

const Success = ({close}: Props) => (
  <React.Fragment>
    <ModalContent>
      <Wrap>
        <Text>We’ve just sent password resetting instructions to your email.</Text>
      </Wrap>
    </ModalContent>
    <ModalControls>
      <Button fullwidth onClick={close}>
        Okay
      </Button>
    </ModalControls>
  </React.Fragment>
);

export default Success;
