// @flow
import {TickSVG} from 'assets/icons/TickIcon';
import {lighten} from 'global/theme';
import styled, {css} from 'styled-components';

export const StyledStoreSelect = styled.div``;

export const storeSelectWrapperCSS = css`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  border: solid 1px ${props => props.theme.color.neutralGray};
  border-radius: 0.25em;
  background: ${props => props.theme.color.white};
  padding: 0.8rem 1.6rem;
  min-height: 6.4rem;
  color: ${props => props.theme.color.neutralGrayDarker};

  &:hover {
    border-color: ${props => props.theme.color.neutralGrayDarker};
    box-shadow: none;
  }

  svg {
    flex-shrink: 0;
  }

  ${props => {
    return (
      props.isSelected &&
      css`
        border: 1px solid ${props.theme.color.primaryAccent};
        box-shadow: 0 0 0 1px ${props.theme.color.primaryAccent};
        background: ${props => props.theme.color.white};
        color: ${props => props.theme.color.neutralGrayDarker};

        &:hover {
          border-color: ${props => props.theme.color.primaryAccentDark};
          box-shadow: 0 0 0 1px ${props.theme.color.primaryAccentDark};

          & ${TickSVG} {
            color: ${props => props.theme.color.primaryAccentDark};
          }
        }
      `
    );
  }}

  &:focus,
  &:focus-within {
    outline: none;
    background: ${props => lighten(props.theme.color.primaryAccent, 0.05)};
  }
`;

export const StoreDetails = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-right: auto;
  overflow: hidden;
`;

export const Title = styled.p`
  font-size: 1.6rem;
  margin: 0;
  text-align: left;
  word-break: break-word;
`;

export const Subtitle = styled.p`
  font-size: 1.2rem;
  margin: 0;
  text-align: left;
  word-break: break-word;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  svg {
    flex-shrink: 0;
  }
`;
