// @flow
import {mq} from 'common/mediaQuery';
import {center} from 'componentsStyled/Shared';
import styled from 'styled-components';

export const GalleryWrap = styled.div`
  position: relative;
`;

export const CarouselWrap = styled.div`
  position: relative;

  ${mq.tabletWide} {
    position: absolute;
    width: 50vw;
    left: 0;
    height: 50vh;
  }
`;

export const ImageWrap = styled.div`
  height: 24rem;
  position: relative;
  outline: none;

  ${mq.tabletWide} {
    height: 50vh;
  }
`;

export const StyledImage = styled.img`
  ${center};
  max-width: 90%;
  max-height: 90%;
`;
