// @flow
import type {ExtractActionTypes} from 'common/redux/types';

import * as actions from './actions';
import type {PendingReservation} from './types';

export type BasketState = {|
  items: PendingReservation[],
|};

const initialState: BasketState = {
  items: [],
};

type Action = ExtractActionTypes<typeof actions>;

function basketReducer(state: BasketState = initialState, action: Action): BasketState {
  switch (action.type) {
    case 'BASKET/ADD_TO_BASKET':
      return {
        ...state,
        items: state.items.concat([action.payload.reservation]),
      };
    case 'BASKET/REMOVE_ITEM':
      // Remove all items created at a particular time - handles bundle removal
      const timeCreated = action.payload.timeCreated;
      const newItems = timeCreated
        ? state.items.filter(item => item.timeCreated !== timeCreated)
        : [];
      return {
        ...state,
        items: newItems,
      };
    case 'BASKET/CLEAR_BASKET':
    case 'USER/LOGOUT':
      return {
        ...state,
        items: initialState.items,
      };
    default:
      return state;
  }
}

export default basketReducer;
