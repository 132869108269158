// @flow
import type {GraphqlError} from 'common/graphql/types';
import {push} from 'connected-react-router';
import {notificationError, notificationSuccess} from 'data/notifications/actions';
import withConnect from 'hoc/withConnect';
import {defaultSubmitName} from 'hoc/withMutation';
import {prop} from 'ramda';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

const mapDispatchToProps = {
  notificationSuccess,
  notificationError,
  push,
};

type Config<Props, Values, Result> = {|
  successText?: string | (Props => string),
  errorText?: string,
  onSuccess?: Props => Result => mixed,
  onError?: Props => GraphqlError => mixed,
  redirect?: (Props, Result) => string,
  redirectState?: (Props, Result) => any,
  submit?: Props => Values => Promise<Result>,
|};

type Added<Values, Result> = {|
  submit: Values => Promise<Result>,
  loading: boolean,
|};

function withSubmit<Props, Values, Result>(
  c: Config<Props, Values, Result>
  // $ExpectError
): HOC<{...$Exact<Added<Values, Result>>, ...$Exact<Props>}, Props> {
  const {
    successText,
    onSuccess,
    errorText = 'There was a problem processing your request, please try again',
    onError,
    redirect,
    redirectState,
    submit = prop(defaultSubmitName),
  } = c;

  return compose(
    withStateHandlers(
      {
        loading: false,
      },
      {
        setLoading: () => (v: boolean) => ({loading: v}),
      }
    ),
    withConnect(() => ({}), mapDispatchToProps),
    withHandlers({
      submit: props => values => {
        props.setLoading(true);
        return submit(props)(values)
          .then(result => {
            props.setLoading(false);
            if (onSuccess) {
              onSuccess(props)(result);
            }
            if (successText) {
              const text = typeof successText === 'function' ? successText(props) : successText;

              props.notificationSuccess(text);
            }
            if (redirect) {
              const url = redirect(props, result);
              const state = redirectState ? redirectState(props, result) : undefined;
              props.push(url, state);
            }
          })
          .catch(e => {
            props.setLoading(false);
            if (onError) {
              const newError = onError(props)(e);
              if (newError) {
                return props.notificationError(newError);
              }
            }

            if (errorText) {
              return props.notificationError(errorText);
            } else {
              throw e;
            }
          });
      },
    })
  );
}

export default withSubmit;
