// @flow
import {trackReservation} from 'analytics/reservations/analyticsTraits';
import Button from 'components/Button';
import {openModal} from 'data/modals/actions';
import {reservationStatuses} from 'data/reservations/constants';
import {cancelReservationMutation} from 'data/reservations/graphql/mutations';
import type {Reservation} from 'data/reservations/types';
import urls from 'data/router/urls';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withRouter from 'hoc/withRouter';
import withSubmit from 'hoc/withSubmit';
import withUser from 'hoc/withUser';
import ConfirmModal from 'modals/Confirm';
import CancellationFeeConfirm from 'pages/Reservation/CancellationFeeConfirm';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

const CancelReservation = ({reservation, handleCancel}) => {
  if (
    reservation.status !== reservationStatuses.confirmed &&
    reservation.status !== reservationStatuses.pre_processing
  )
    return null;

  return (
    <Button onClick={handleCancel} fullwidth secondary data-cy={'cancel-reservation-button'}>
      Cancel reservation
    </Button>
  );
};

const mapDispatchToProps = {
  openModal,
};

type Outter = {|
  reservation: Reservation,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withConnect(() => ({}), mapDispatchToProps),
  withUser(),
  withMutation(cancelReservationMutation),
  withSubmit({
    redirect: () => urls.reservations,
    successText: 'Canceled successfully!',
    errorText: 'Failed to cancel reservation, please try again.',
    onSuccess: props => () => {
      // Track charge
      trackReservation('Order Cancelled', props.reservation, props.user, {charged: 'applied'});
    },
  }),
  withHandlers({
    handleCancel: props => () => {
      const {cancellationFee, cancellationFeeStartsAt, currency, totalPrice} =
        props.reservation.pricingDetail;

      props.openModal(ConfirmModal, {
        title: 'Are you sure?',
        component: (
          <CancellationFeeConfirm
            cancellationFee={cancellationFee}
            cancellationFeeStartsAt={cancellationFeeStartsAt}
            currency={currency}
            totalPrice={totalPrice}
          />
        ),
        onConfirm: () =>
          props.submit({
            bookingId: props.reservation.booking.bookingId,
            reservationId: props.reservation.id,
          }),
      });
    },
  })
);

export default enhancer(CancelReservation);
