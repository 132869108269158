// @flow
import {getDataFromSelector} from 'common/graphql/helpers';
import {getRouterVariables} from 'common/graphql/routing';
import type {Mutation} from 'common/graphql/types';
import withRouter from 'hoc/withRouter';
import {identity} from 'ramda';
import {graphql} from 'react-apollo';
import {type HOC, compose} from 'recompose';

const getRefetchQueries = (refetch, props) => {
  if (!refetch.length) return [];

  return refetch.map(query => ({
    query: query.gql,
    variables: getRouterVariables(props),
  }));
};

export const defaultSubmitName = 'submitMutation';

type Added<Variables, Result> = {
  submitMutation: Variables => Promise<Result>,
};

function withMutation<Variables, Result, Outter>(
  m: Mutation<Variables, Result>
): HOC<{...$Exact<Added<Variables, Result>>, ...$Exact<Outter>}, Outter> {
  const {gql, refetch = [], selector, transform = identity} = m;

  return compose(
    withRouter,
    graphql(gql, {
      props: ({mutate, ownProps}) => ({
        [defaultSubmitName]: variables =>
          mutate({
            variables,
            refetchQueries: getRefetchQueries(refetch, ownProps),
          })
            .then(getDataFromSelector(selector || []))
            .then(transform),
      }),
    })
  );
}

export default withMutation;
