// @flow
import Button from 'components/Button';
import {push} from 'connected-react-router';
import {selectBasketItems} from 'data/basket/selectors';
import {setShippingAddressFields} from 'data/reservations/actions';
import {selectShippingAddress} from 'data/reservations/selectors';
import urls from 'data/router/urls';
import InputCheckbox from 'forms/Input/Checkbox';
import TextInput from 'forms/Input/Text';
import TextArea from 'forms/Input/TextArea';
import withForm from 'forms/withForm';
import withConnect from 'hoc/withConnect';
import DeliveryAddressField from 'pages/DeliveryAddress/DeliveryAddressForm/DeliveryAddressField';
import {FormFields, InlineFieldWrap} from 'pages/DeliveryAddress/DeliveryAddressForm/styled';
import {pathOr, uniqBy} from 'ramda';
import React from 'react';
// $ReactHooks
import {useMemo} from 'react';
import Collapse from 'react-collapse';
import type {HOC} from 'recompose';
import {compose} from 'recompose';

import schema from './schema';

const DeliveryAddressForm = ({values, setFieldValue, items}) => {
  const {enableAdditionalContact, useManualAddress, address} = values;

  const location = useMemo(() => {
    // $ExpectError flow doesn't know flatmap
    const allCoords = items.flatMap(pendingReservation => {
      //Non-delivery reservations shouldn't impact the coordinates we use
      if (pendingReservation.fulfillmentType !== 'DELIVERY') {
        return [];
      }

      const location = pathOr({}, ['affiliate', 'location'], pendingReservation);
      const {lat, lng} = location;

      if (lat && lng) {
        return [{lat, lng}];
      }

      return [];
    });

    const uniqueCoords = uniqBy(({lng, lat}) => `${lat}|${lng}`, allCoords);

    //If exactly one unique lat/lng pair then return it, else return null
    if (uniqueCoords && uniqueCoords.length === 1) {
      //NOTE: For whatever reason the react places library we use requires lat/lng to be functions
      return {lat: () => uniqueCoords[0].lat, lng: () => uniqueCoords[0].lng};
    }
    return null;
  }, [items]);

  //If we have a unique location, then bias the address search to favour address within 50km
  const searchOptions = location ? {location, radius: 50000} : null;

  return (
    <>
      <FormFields>
        <InlineFieldWrap>
          <TextInput name="name" label="Name" />
          <TextInput name="company" label="Company (optional)" />
        </InlineFieldWrap>
        <DeliveryAddressField
          address={address}
          useManualAddress={useManualAddress}
          setFieldValue={setFieldValue}
          searchOptions={searchOptions}
        />
        <TextArea name="instructions" label="Delivery instructions (optional)" />
        <InputCheckbox
          labelPosition="right"
          name="enableAdditionalContact"
          label="This delivery is for someone else"
          data-cy="additional-contact-checkbox"
        />
        <Collapse isOpened={enableAdditionalContact}>
          <TextInput name="additionalEmail" label="Additional contact email (optional)" />
          <TextInput name="additionalPhone" label="Additional contact number (optional)" />
        </Collapse>
      </FormFields>
      <Button data-cy="checkout-button" fullwidth>
        Checkout
      </Button>
    </>
  );
};

const mapStateToProps = state => ({
  formData: selectShippingAddress(state),
  items: selectBasketItems(state),
});

const mapDispatchToProps = {
  setShippingAddressFields,
  push,
};

const enhancer: HOC<*, {}> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withForm({
    schema,
    onSubmit: props => async data => {
      //Clear out additional contact info if checkbox not checked
      const dataToSave = data.enableAdditionalContact
        ? data
        : {
            ...data,
            additionalPhone: null,
            additionalEmail: null,
          };
      props.setShippingAddressFields(dataToSave);
    },
    onSuccess: props => props.push(urls.basketCheckout),
  })
);

export default enhancer(DeliveryAddressForm);
