// @flow
import {assertNever} from 'common/flow';
import type {NotificationType} from 'data/notifications/types';
import type {Theme} from 'global/theme';

export const getText = (text: string): string => {
  if (!text) {
    return '';
  }

  if (typeof text === 'string') {
    return text;
  }

  console.warn('had to stringify notification');
  return JSON.stringify(text);
};

export const getColor = (theme: Theme, t: NotificationType): string => {
  switch (t) {
    case 'success':
      return theme.color.green;
    case 'warning':
      return theme.color.yellow;
    case 'error':
      return theme.color.red;
    default:
      return assertNever(t);
  }
};
