// @flow
import PlaceIcon from 'components/AffiliateName/PlaceIcon';
import {StyledAnimatedTickIcon} from 'components/StoreSelect/StoreSelectRadioButton/styled';
import type {AffiliateOnVariant} from 'data/product/types';
import {getPriceRange} from 'data/units/money/formatters';
import * as React from 'react';

import {StoreDetails, Subtitle, Title} from './styled';
import {getPriceRangeString} from './utils';

export type LayoutProps = {
  title?: React.Node,
  subtitle?: React.Node,
  prefixIcon?: React.Node,
  suffixIcon?: React.Node,
};

export const DefaultLayout = ({title, subtitle, prefixIcon, suffixIcon}: LayoutProps) => (
  <>
    {prefixIcon}
    <StoreDetails>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </StoreDetails>
    {suffixIcon}
  </>
);

export type StoreContentProps = {
  store: AffiliateOnVariant,
  showCountryCode?: boolean,
  isSelected?: boolean,
  showPlaceIcon?: boolean,
};

export const FullStoreInfoContent = ({
  store,
  showCountryCode = true,
  isSelected,
  showPlaceIcon = true,
}: StoreContentProps) => {
  const {name, location, pricing} = store;
  const {
    name: locationName,
    country: {code: countryCode, currency},
  } = location;
  const [priceMin, priceMax] = getPriceRange(pricing);

  return (
    <DefaultLayout
      prefixIcon={showPlaceIcon ? <PlaceIcon /> : null}
      title={
        <>
          <strong>{name}, </strong>
          {locationName} {showCountryCode && countryCode}
        </>
      }
      subtitle={getPriceRangeString(priceMin, priceMax, currency)}
      suffixIcon={<StyledAnimatedTickIcon show={isSelected} />}
    />
  );
};
