// @flow
import {isScrollEnd} from 'common/events';
import {type HOC, lifecycle} from 'recompose';
import {fromEvent} from 'rxjs';

function withScrollEnd<T>(onScrollEnd: T => *): HOC<T, T> {
  return lifecycle({
    componentDidMount() {
      this.sub = fromEvent(document, 'scroll').subscribe(e => {
        if (isScrollEnd(e.target.scrollingElement)) {
          onScrollEnd(this.props);
        }
      });
    },
    componentWillUnmount() {
      this.sub.unsubscribe();
    },
  });
}

export default withScrollEnd;
