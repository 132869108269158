// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

export const AffiliateListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  ${mq.tabletWide} {
    justify-content: center;
  }
`;

export const AffliateCarouselWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
`;

export const CarouselItemWrap = styled.div`
  display: flex;
  margin: 0 0.5rem;
  justify-content: center;
  outline: none;
`;

export const IconWrap = styled.span`
  svg {
    margin-right: 1rem;
  }
`;
