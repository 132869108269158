// @flow
import styled, {css} from 'styled-components';

export const DayWrap = styled.div`
  width: calc(100% / 7);
  cursor: pointer;
  position: relative;
`;

export const StyledDay = styled.div`
  height: 3.6rem;
  line-height: 3.6rem;
  font-size: 1.5rem;
  color: ${props => props.theme.color.secondaryText};
  text-align: center;
  padding: 0;

  ${p =>
    p.active &&
    css`
      color: ${props => props.theme.color.white};
      font-weight: ${props => props.theme.fontWeights.book};
      background: ${props => props.theme.color.primaryAccent};
    `}

  ${p =>
    p.dayOfDelivery &&
    css`
      background: ${props => props.theme.color.black};
      color: ${props => props.theme.color.white};
      border-top-left-radius: 3.6rem;
      border-bottom-left-radius: 3.6rem;
      border-top-right-radius: 3.6rem;
      border-bottom-right-radius: 3.6rem;
    `}
  ${p =>
    p.dayActive &&
    css`
      border-top-left-radius: 3.6rem;
      border-bottom-left-radius: 3.6rem;
    `}
  ${p =>
    p.lastDayActive &&
    css`
      border-top-right-radius: 3.6rem;
      border-bottom-right-radius: 3.6rem;
    `}
  ${p =>
    p.dayOfReturn &&
    css`
      background: ${props => props.theme.color.black};
      color: ${props => props.theme.color.white};
      border-top-right-radius: 3.6rem;
      border-bottom-right-radius: 3.6rem;
      border-top-left-radius: 3.6rem;
      border-bottom-left-radius: 3.6rem;
    `}
`;
