// @flow
import {type Theme} from 'global/theme';
import {css} from 'styled-components';

const getTheme = (props: {secondary: boolean, disabled: boolean, theme: Theme}) => {
  if (props.secondary) {
    return css`
      color: ${props.theme.color.black};
      border-color: ${props.theme.color.black};
    `;
  } else {
    // Primary button style
    return css`
      background-color: ${props.theme.color.primaryAccent};
      border-color: ${props.theme.color.primaryAccent};
      color: ${props.theme.color.white};

      &:active,
      &:hover {
        ${props =>
          !props.disabled &&
          css`
            background-color: ${props.theme.color.primaryAccentDark};
            border-color: ${props.theme.color.primaryAccentDark};
          `}
      }
      &:focus {
        color: ${props.theme.color.white};
      }
    `;
  }
};

export default getTheme;
