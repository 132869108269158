// @flow
import type {Selector} from 'common/redux/types';
import {compose, prop} from 'ramda';

import type {RouterState} from './reducer';
import type {RouterLocation} from './types';

const base: Selector<RouterState> = prop('router');

export const selectRouterLocation: Selector<RouterLocation> = compose(prop('location'), base);

export const selectPathname: Selector<string> = compose(prop('pathname'), selectRouterLocation);
