// @flow
import type {Notification} from 'data/notifications/types';
import React from 'react';

import {getText} from './helpers';
import Icon from './Icon';
import {NotificationText, NotificationTileWrap} from './styled';

type Props = {|
  notification: Notification,
  closing: boolean,
  onClick: Function,
|};

const NotificationTile = ({onClick, closing, notification}: Props) => (
  <NotificationTileWrap onClick={onClick} closing={closing} type={notification.type}>
    <Icon type={notification.type} />
    <NotificationText>{getText(notification.text)}</NotificationText>
  </NotificationTileWrap>
);

export default NotificationTile;
