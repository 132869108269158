// @flow
import BasketItemPreview from 'components/BasketItemPreview';
import type {Reservation} from 'data/reservations/types';
import urls from 'data/router/urls';
import * as React from 'react';

import EmptyState from './EmptyState';
import {ReservationItemLink} from './styled';

type Props = {|
  reservations: Reservation[],
|};

const ReservationsList = ({reservations}: Props) => {
  if (reservations.length === 0) return <EmptyState />;
  return (
    <React.Fragment>
      {reservations.map((r, i) => (
        <ReservationItemLink key={r.id} to={urls.reservation(r.id)}>
          <BasketItemPreview
            index={i}
            product={r.productItem.productVariant.product}
            variant={r.productItem.productVariant}
            startDate={r.booking.start}
            endDate={r.booking.end}
          />
        </ReservationItemLink>
      ))}
    </React.Fragment>
  );
};

export default ReservationsList;
