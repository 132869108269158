import FilterInput from 'searchFilters/Shared/FilterInput';
import styled from 'styled-components';

export const MobileFilterInput = styled(FilterInput)`
  display: flex;
  gap: 0.8rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  align-items: center;
  background: ${props => props.theme.color.white};
  padding: 0.8rem 5rem;
  border: 1px solid ${props => props.theme.color.secondaryText};
  color: currentColor;
`;

export const FilterCount = styled.span`
  text-align: center;
  background: ${props => props.theme.color.primaryAccent};
  color: ${props => props.theme.color.white};
  min-width: 2.4rem;
  line-height: 1.6rem;
  padding: 4px;
  border-radius: 50%;
`;
