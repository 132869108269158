// @flow
// $Import
import * as React from 'react';
import styled from 'styled-components';

export type Props = {
  size?: number,
  className?: string,
  style?: Object,
};

export const TickSVG = styled.svg``;

const TickIcon = React.forwardRef(({size = 24, className, style}: Props, ref) => (
  <TickSVG
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    innerRef={ref}
    style={style}
    className={className}
  >
    <path
      d="M20 6L9 17L4 12"
      stroke={'currentColor'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </TickSVG>
));

export default TickIcon;
