// @flow
import type {NotificationType} from './types';

const showNotification = (t: NotificationType) => (text: string) => ({
  type: 'NOTIFICATION/SHOW_NOTICATION',
  payload: {
    type: t,
    text,
  },
});

export const notificationSuccess = showNotification('success');
export const notificationWarning = showNotification('warning');
export const notificationError = showNotification('error');

export const closeNotification = () => ({
  type: 'NOTIFICATION/CLOSE_NOTIFICATION',
});
