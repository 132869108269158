// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import {Container, FullWidthContainer} from 'componentsStyled/Layout/Containers';
import urls from 'data/router/urls';
import withRouter from 'hoc/withRouter';
import SubPage from 'pages/_Page/SubPage';
import SettingList from 'pages/Account/Routes/SettingList';
import * as React from 'react';
import type {HOC} from 'recompose';

import {Inner, Left, Right, Split} from './styled';

const SettingContent = ({children, title, narrow, location}) => {
  return (
    <SubPage title={title} backUrl={urls.account.index}>
      <Desktop>
        <Container>
          <Split>
            <Left>
              <SettingList />
            </Left>
            <Right>
              <Inner narrow={narrow}>{children}</Inner>
            </Right>
          </Split>
        </Container>
      </Desktop>
      <Mobile>
        <FullWidthContainer>{children}</FullWidthContainer>
      </Mobile>
    </SubPage>
  );
};

type Outter = {|
  children: React.Node,
  title: string,
  narrow?: boolean,
|};

const enhancer: HOC<*, Outter> = withRouter;
export default enhancer(SettingContent);
