// @flow
import Smudge from 'components/Smudge';
import {Container} from 'componentsStyled/Layout/Containers';
import React from 'react';

import BlogPost from './BlogPost';
import {Content, DestinationsWrap} from './styled';

const SectionBlog = () => (
  <Smudge>
    <Container>
      <Content>
        <DestinationsWrap>
          <BlogPost
            title="How to surf in north county San Diego"
            subTitle="Local Knowledge, Surf"
            imageUrl="https://takeme.awayco.com/wp-content/uploads/2020/01/IMG_9292-1024x682.jpg"
            link="https://takeme.awayco.com/how-to-surf-in-north-county-san-diego/"
          />
          <BlogPost
            title="How to surf in Sydney"
            subTitle="Local Knowledge, Surf"
            imageUrl="https://takeme.awayco.com/wp-content/uploads/2019/12/Optimized-Icebergs_Footer_2000x1200-1-1-1024x682.jpg"
            link="https://takeme.awayco.com/how-to-surf-in-sydney/"
          />
          <BlogPost
            title="Kye Petersen Shaper Profile"
            subTitle="Snow"
            imageUrl="https://takeme.awayco.com/wp-content/uploads/2019/12/2019-12-21-5.jpg"
            link="https://takeme.awayco.com/kye-petersen-shaper-profile/"
          />
          <BlogPost
            title="How to surf in Ericeira, Portugal"
            subTitle="Local Knowledge, Surf"
            imageUrl="https://takeme.awayco.com/wp-content/uploads/2019/12/Optimized-DSC06495-1024x682.jpg"
            link="https://takeme.awayco.com/how-to-surf-in-ericeira-portugal/"
          />
          <BlogPost
            title="Take me AwayCo: KC Deane goes to Maverick's"
            subTitle="Surf, Travel"
            imageUrl="https://takeme.awayco.com/wp-content/uploads/2019/12/Optimized-DSC_5494.jpg"
            link="https://takeme.awayco.com/take-me-awayco-kc-deane-goes-to-mavericks/"
          />
          <BlogPost
            title="Surf and Ski in the same day, with Jacob Wester"
            subTitle="Snow"
            imageUrl="https://takeme.awayco.com/wp-content/uploads/2019/11/wester.jpg"
            link="https://takeme.awayco.com/surf-and-ski-in-the-same-day-with-jacob-wester/"
          />
        </DestinationsWrap>
      </Content>
    </Container>
  </Smudge>
);

export default SectionBlog;
