// @flow
import LocationArrowIcon from 'assets/icons/LocationArrowIcon';
import SortIcon from 'assets/icons/SortIcon';
import Loader from 'components/Loader';
import {selectCategories} from 'data/app/selectors';
import type {Location} from 'data/app/types';
// $Import
import withConnect from 'hoc/withConnect';
import withEmpty from 'hoc/withEmpty';
import withUserLocation from 'hoc/withUserLocation';
import type {FilterOption} from 'hooks/useFilterOptions';
import useFilterOptions from 'hooks/useFilterOptions';
import {groupBy, keys, sortBy} from 'ramda';
import * as React from 'react';
// $ReactHooks
import {useState} from 'react';
import {type HOC, compose, withHandlers} from 'recompose';
import type {SortBy} from 'searchFilters/FilterComponents/LocationFilter/utils';
import {
  locationGrouper,
  locationSorter,
  SORT_OPTIONS,
} from 'searchFilters/FilterComponents/LocationFilter/utils';
import MultiSelectItem from 'searchFilters/Shared/Multiselect/MultiSelectItem';

import {CountryTitle, Group, StyledSortButton} from './styled';

type SortButtonProps = {
  sortBy: SortBy,
  onClick: () => void,
};

const SortButton = ({sortBy, onClick}: SortButtonProps) => {
  return (
    <StyledSortButton onClick={onClick}>
      {sortBy === SORT_OPTIONS.NEARBY ? (
        <>
          <SortIcon /> Order alphabetically
        </>
      ) : (
        <>
          <LocationArrowIcon /> Order by nearby locations
        </>
      )}
    </StyledSortButton>
  );
};

type LocationListProps = {
  locations: FilterOption<Location>[],
  onClick: (location: Location) => any,
};

const LocationList = ({locations, onClick}: LocationListProps) => (
  <>
    {locations.map(location => {
      const {checked, disabled, ...rawLocationData} = location;
      const handleClick = () => onClick(rawLocationData);

      return (
        <MultiSelectItem
          key={location.id}
          onClick={handleClick}
          name={location.name}
          checked={location.checked}
          disabled={location.disabled}
        />
      );
    })}
  </>
);

const LocationFilter = ({
  options = [],
  value,
  handleSelect,
  loading: userLocationLoading,
  userLocation,
  getLocation,
}) => {
  const locations = useFilterOptions(options, value, 'id');
  const [sortByOption, setSortByOption] = useState(SORT_OPTIONS.ALPHABETICAL);
  const toggleSortByOption = () => {
    setSortByOption(currentValue => {
      const newValue =
        currentValue === SORT_OPTIONS.ALPHABETICAL
          ? SORT_OPTIONS.NEARBY
          : SORT_OPTIONS.ALPHABETICAL;

      //If changing to sort by 'nearby' and we don't yet have the user's location or have not
      //attempted to fetch it yet, then attempt to get it.
      if (newValue === SORT_OPTIONS.NEARBY && !userLocation && !userLocationLoading) {
        getLocation();
      }

      return newValue;
    });
  };

  const sortedLocations = sortBy(locationSorter(sortByOption, userLocation), locations);
  const groups = groupBy(locationGrouper(sortByOption, userLocation), sortedLocations);
  const sortedGroups = keys(groups).sort();

  return (
    <>
      <SortButton sortBy={sortByOption} onClick={toggleSortByOption} />
      {sortByOption === SORT_OPTIONS.NEARBY && userLocationLoading ? (
        <Loader />
      ) : (
        <>
          {sortedGroups.map(country => (
            <Group key={country}>
              {sortedGroups.length > 1 && (
                <CountryTitle small nowrap>
                  {country}
                </CountryTitle>
              )}
              <LocationList locations={groups[country]} onClick={handleSelect} />
            </Group>
          ))}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  categories: selectCategories(state),
});

type Outter = {|
  onChange: (Location[]) => mixed,
  value: ?(Location[]),
  options: Location[],
|};

const enhancer: HOC<*, Outter> = compose(
  withEmpty(props => {
    const hasNoOptions = !props.options || props.options.length === 0;
    const hasNoSelections = !props.value || props.value.length === 0;
    return hasNoOptions && hasNoSelections;
  }),
  withConnect(mapStateToProps),
  withHandlers({
    isSelected:
      ({value}) =>
      (location: Location) =>
        !!value && value.some(selectedLocation => selectedLocation.id === location.id),
  }),
  withHandlers({
    handleSelect:
      ({value, isSelected, onChange}) =>
      (location: Location) => {
        const selectedLocations = value || [];

        //If the location is selected => remove it
        if (isSelected(location)) {
          return onChange(
            selectedLocations.filter(selectedLocation => selectedLocation.id !== location.id)
          );
        }

        //Else, add it
        return onChange([...selectedLocations, location]);
      },
  }),
  withUserLocation()
);

export default enhancer(LocationFilter);
