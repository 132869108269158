// @flow
import config from 'global/config';

export const trackPageView = () => {
  try {
    window.analytics.page();
  } catch (e) {
    console.warn('error logging page view');
  }
};

// initialize
if (config.segmentKey) {
  window.analytics.load(config.segmentKey);
  trackPageView();
}

export const resetAnalytics = () => {
  window.analytics.reset();
};
