// @flow
import RadioPill from 'components/Radio/RadioPill';
import useRadioGroup from 'components/Radio/useRadioGroup';
import {FullStoreInfoContent} from 'components/StoreSelect/templates';
import type {AffiliateOnVariant} from 'data/product/types';
import * as React from 'react';

import {StoreSelectRadioButtonContainer} from './styled';

export type Props = {
  value: string | number,
  checked?: boolean,
  store: AffiliateOnVariant,
};

const StoreSelectRadioButton = ({value, checked: checkedProp, store}: Props) => {
  const {checked} = useRadioGroup({value, checked: checkedProp});

  return (
    <StoreSelectRadioButtonContainer data-cy="store-select-radio-button" isSelected={checked}>
      <RadioPill value={value}>
        <FullStoreInfoContent store={store} isSelected={checked} />
      </RadioPill>
    </StoreSelectRadioButtonContainer>
  );
};

export default StoreSelectRadioButton;
