// @flow
import Checkbox from 'components/Checkbox';
import withFormField, {type ReqOutter} from 'forms/withFormField';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

const InputCheckbox = ({handleChange, value, big, 'data-cy': dataCy}) => (
  <Checkbox big={big} value={value} onClick={handleChange} data-cy={dataCy} />
);

type Outter = {|
  ...$Exact<ReqOutter>,
  big?: boolean,
  'data-cy'?: string,
|};

const enhancer: HOC<*, Outter> = compose(
  withFormField,
  withHandlers({
    handleChange: props => () => {
      props.onChange(!props.value);
    },
  })
);

export default enhancer(InputCheckbox);
