// @flow
import {Spread} from 'componentsStyled/Layout/Flex';
import {Text} from 'componentsStyled/Typography/Texts';
import {FieldTitle} from 'componentsStyled/Typography/Titles';
import {formatReadableDateRange} from 'data/units/date/formatters';
import * as React from 'react';

type Props = {
  dates: any,
  originalReservation?: any,
};

const Dates = ({dates, originalReservation}: Props) => (
  <React.Fragment>
    <FieldTitle>Dates</FieldTitle>
    {originalReservation ? (
      <div>
        <Spread>
          <Text>Previous Dates</Text>
          <Text>{formatReadableDateRange(originalReservation)}</Text>
        </Spread>
        <Spread>
          <Text book>New Dates</Text>
          <Text book>{formatReadableDateRange(dates)}</Text>
        </Spread>
      </div>
    ) : (
      <Text>{formatReadableDateRange(dates)}</Text>
    )}
  </React.Fragment>
);

export default Dates;
