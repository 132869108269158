// @flow
import withUser from 'hoc/withUser';
import * as React from 'react';
import {type HOC, compose, withStateHandlers} from 'recompose';

import Hamburger from '../../Hamburger';
import SearchAutocomplete from '../SearchAutocomplete';

const MobileLoggedInNav = ({
  user,
  hamburgerOpen,
  openHamburger,
  toggleHamburger,
  closeBoth,
  searchOpen,
  openSearch,
}) => (
  <React.Fragment>
    <Hamburger
      user={user}
      isOpen={hamburgerOpen}
      open={openHamburger}
      close={closeBoth}
      toggleOpen={toggleHamburger}
    />
    <SearchAutocomplete initialValue="" isOpen={searchOpen} open={openSearch} close={closeBoth} />
  </React.Fragment>
);

const enhancer: HOC<*, {||}> = compose(
  withUser(),
  withStateHandlers(
    {
      hamburgerOpen: false,
      searchOpen: false,
    },
    {
      closeBoth: () => () => ({hamburgerOpen: false, searchOpen: false}),
      openHamburger: () => () => ({hamburgerOpen: true, searchOpen: false}),
      openSearch: () => () => ({hamburgerOpen: false, searchOpen: true}),
      toggleHamburger:
        ({hamburgerOpen}) =>
        () => ({
          searchOpen: false,
          hamburgerOpen: !hamburgerOpen,
        }),
    }
  )
);

export default enhancer(MobileLoggedInNav);
