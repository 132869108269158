// @flow
import {openModal} from 'data/modals/actions';
import withConnect from 'hoc/withConnect';
import ModalBody from 'modals/_Body';
import ModalHeader from 'modals/_Header';
import React from 'react';
import {type HOC, compose} from 'recompose';

import SignUpForm from './SignUpForm';

const SignUpModal = ({close, openLogin, onSuccess, callback}) => (
  <ModalBody close={close} data-cy={'sign-up-form'}>
    <ModalHeader close={close} title="Create Your Account" />
    <SignUpForm close={close} onSuccess={onSuccess} callback={callback} />
  </ModalBody>
);

type Outter = {|
  close: Function,
  onSuccess?: Function,
  callback?: Function,
|};

const enhancer: HOC<*, Outter> = compose(withConnect(() => ({}), {openModal}));

export default enhancer(SignUpModal);
