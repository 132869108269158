// @flow
import {transformResponse} from 'common/graphql/helpers';
import type {Mutation, Query} from 'common/graphql/types';

import apolloClient from './index';

export function query<Res, Params>(
  {gql, transform, selector}: Query<Res, Params>,
  variables?: Params
): Promise<Res> {
  return apolloClient
    .query({
      query: gql,
      variables,
    })
    .then(transformResponse(selector, transform));
}

export function mutate<Res, Params>(
  {gql, selector}: Mutation<Params, Res>,
  variables: Params
): Promise<Res> {
  return apolloClient
    .mutate({
      mutation: gql,
      variables,
    })
    .then(transformResponse(selector));
}
