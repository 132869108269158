// @flow
import Carousel from 'components/Carousel';
import {minHeightImg} from 'data/images/helpers';
import type {Image} from 'data/images/types';
import {prop, sortBy} from 'ramda';
import React from 'react';
import {type HOC, compose, withProps} from 'recompose';

import {CarouselWrap, GalleryWrap, ImageWrap, StyledImage} from './styled';

// TODO(Jude): Make mobile (small screen) image navigation more intuitive. As a result of getting rid of small images
// it is not 100% clear that there are more images to swipe through when using a small screen

const Gallery = ({sortedImages}) => (
  <GalleryWrap>
    <CarouselWrap>
      <Carousel
        individual
        thumbnails={sortedImages}
        customStyle={{slidesToShow: 1, withoutControls: true}}
      >
        {sortedImages.map((image, idx) => {
          const imgSize = minHeightImg(image, 600);
          return (
            <ImageWrap key={idx}>
              <StyledImage src={imgSize.url} alt={image.alt} draggable="false" />
            </ImageWrap>
          );
        })}
      </Carousel>
    </CarouselWrap>
  </GalleryWrap>
);

type Outter = {|
  images: Image[],
|};

const enhancer: HOC<*, Outter> = compose(
  withProps(props => ({
    sortedImages: sortBy(prop('order'), props.images),
  }))
);

export default enhancer(Gallery);
