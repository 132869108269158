// @flow
import type {Query} from 'common/graphql/types';
import type {ID} from 'data/enums/types';
import type {DateString} from 'data/units/date/types';
import gql from 'graphql-tag';
import {length} from 'ramda';

import type {AccessoryQueryInput, BuyItPricing, Reservation, ReservationPricing} from '../types';
import {reservationFragment, reservationMinFragment} from './fragments';

export const reservationDetailQuery: Query<Reservation> = {
  gql: gql`
    query reservationDetailQuery($id: Int!) {
      reservation {
        get(id: $id) {
          ...reservationFragment
          availableExtensionIntervals {
            startDate: start
            endDate: end
          }
        }
      }
    }
    ${reservationFragment}
  `,
  selector: ['reservation', 'get'],
  options: () => ({
    fetchPolicy: 'network-only',
  }),
};

export const reservationListQuery: Query<Reservation[]> = {
  gql: gql`
    query reservationListQuery {
      reservation {
        list {
          data {
            ...reservationMinFragment
          }
        }
      }
    }
    ${reservationMinFragment}
  `,
  // $Ramda
  selector: ['reservation', 'list', 'data'],
};

export const reservationCountQuery: Query<number> = {
  gql: gql`
    query reservationCountQuery {
      reservation {
        list(filter: {limit: 9999999, status: [confirmed, pre_processing]}) {
          data {
            id
          }
        }
      }
    }
  `,
  transform: length,
  selector: ['reservation', 'list', 'data'],
};

export type ReservationPricingQueryInput = {|
  productVariantId: ID,
  affiliateId: ID,
  start: DateString,
  end: DateString,
  accessories?: AccessoryQueryInput[],
  ignoreTax?: boolean,
  bundleComponentId?: number,
|};

export type ExtendReservationPricingQueryInput = {|
  reservationId: ID,
  end: DateString,
  accessories?: AccessoryQueryInput,
|};

export const reservationPricingQuery: Query<
  ReservationPricing,
  {|
    input: ReservationPricingQueryInput,
  |}
> = {
  gql: gql`
    query reservationPricingQuery($input: PriceInput!) {
      reservation {
        getPrice(input: $input) {
          totalPrice
          fullPrice
          totalDays
          prices
          currency
          discounts {
            percentage
            type
            detail
            limitDays
          }
          accessories {
            name
            prices
          }
          tax
        }
      }
    }
  `,
  selector: ['reservation', 'getPrice'],
};

export const reservationExtensionPricingQuery: Query<
  ReservationPricing,
  {|
    input: ExtendReservationPricingQueryInput,
  |}
> = {
  gql: gql`
    query reservationExtensionPricingQuery($input: ExtensionPriceInput!) {
      reservation {
        getExtensionPrice(input: $input) {
          totalPrice
          fullPrice
          totalDays
          prices
          currency
          discounts {
            percentage
            type
            detail
            limitDays
          }
          accessories {
            name
            prices
          }
          tax
        }
      }
    }
  `,
  selector: ['reservation', 'getExtensionPrice'],
};

export const reservationBuyItPricingQuery: Query<BuyItPricing | null, {reservationId: number}> = {
  gql: gql`
    query reservationBuyItPricingQuery($reservationId: Int!) {
      reservation {
        getBuyItPrice(reservationId: $reservationId) {
          currency
          rrpPrice
          proceedsAmount
          discountedPrice
        }
      }
    }
  `,
  selector: ['reservation', 'getBuyItPrice'],
  options: () => ({
    fetchPolicy: 'network-only',
  }),
};
