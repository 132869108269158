// @flow
import {locationFragment} from 'data/app/graphql/fragments';
import gql from 'graphql-tag';

const dayFragment = gql`
  fragment dayFragment on OpeningInfoForDay {
    closed
    hours {
      start
      end
      pauseStart
      pauseEnd
    }
  }
`;

export const affiliateFragmentMinimal = gql`
  fragment affiliateFragmentMinimal on Affiliate {
    id
    name
    address
    images {
      sizes {
        url
        width
        height
      }
    }
    location {
      ...locationFragment
    }
    discountOptOuts {
      id
      discountId
    }
  }
  ${locationFragment}
`;

export const affiliateFragmentFull = gql`
  fragment affiliateFragmentFull on Affiliate {
    id
    name
    description
    phone
    website
    address
    logo
    location {
      ...locationFragment
    }
    categories {
      categoryId
      name
    }
    images {
      sizes {
        url
        width
        height
      }
    }
    openingHours {
      mon {
        ...dayFragment
      }
      tue {
        ...dayFragment
      }
      wed {
        ...dayFragment
      }
      thu {
        ...dayFragment
      }
      fri {
        ...dayFragment
      }
      sat {
        ...dayFragment
      }
      sun {
        ...dayFragment
      }
    }
    specialOpeningDates {
      date
      ...dayFragment
    }
    discountOptOuts {
      id
      discountId
    }
  }
  ${locationFragment}
  ${dayFragment}
`;

export const affiliateFulfilmentFragment = gql`
  fragment affiliateFulfilmentFragment on Affiliate {
    id
    allowInStoreFulfilment
    address
    allowDeliveryFulfilment
    deliverySummary
  }
`;

export const affiliateExtensionFragment = gql`
  fragment affiliateExtensionFragment on Affiliate {
    id
    allowInStoreBookingExtension
    inStoreExtensionCutoffDays
    allowDeliveryBookingExtension
    deliveryExtensionCutoffDays
  }
`;
