// @flow
import Loader from 'components/Loader';
import ProductList from 'components/ProductList';
import {FullWidthContainer} from 'componentsStyled/Layout/Containers';
import type {Category, Location} from 'data/app/types';
import {listProductOfferingsQuery} from 'data/product/graphql/queries';
import withQuery from 'hoc/withQuery';
import withScrollEnd from 'hoc/withScrollEnd';
import useUniqueCountriesAcrossAffiliates from 'hooks/useUniqueCountriesAcrossAffiliates';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

const NearbyProducts = ({data, loading, loadingMore, ...rest}) => {
  const {affiliates, products} = data;
  const uniqueCountries = useUniqueCountriesAcrossAffiliates(affiliates);

  return (
    <FullWidthContainer data-cy={'nearby-products'}>
      <ProductList
        productOfferings={products}
        loading={loading}
        showPrices={uniqueCountries.length === 1}
      />
      <Loader hide={!loadingMore} pb0 />
    </FullWidthContainer>
  );
};

type Outter = {|
  geolocations: ?(Location[]),
  categories: ?(Category[]),
|};

const enhancer: HOC<*, Outter> = compose(
  withQuery(listProductOfferingsQuery, {
    variables: ({geolocations, categories, ...rest}) => ({
      categoryIds: categories ? categories.map(category => `${category.categoryId}`) : undefined,
      locationIds: geolocations ? geolocations.map(geo => geo.id) : undefined,
      ...rest,
    }),
  }),
  withScrollEnd(props => props.loadMore())
);

export default enhancer(NearbyProducts);
