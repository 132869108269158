// @flow
import {trackReservation} from 'analytics/reservations/analyticsTraits';
import Button from 'components/Button';
import {push} from 'connected-react-router';
import {openModal} from 'data/modals/actions';
import {extendReservationMutation} from 'data/reservations/graphql/mutations';
import {selectDates} from 'data/reservations/selectors';
import type {OriginalReservation} from 'data/reservations/types';
import urls from 'data/router/urls';
import {clear} from 'data/search/actions';
import {getDaysApart} from 'data/units/date/helpers';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withSubmit from 'hoc/withSubmit';
import withUser from 'hoc/withUser';
import ReservationSuccess from 'modals/ReservationSuccess';
import ExtendReservationConfirm from 'pages/Reservation/ExtendReservationConfirm';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {ButtonsWrap} from './styled';

const ConfirmExtension = ({submit, loading, cancelExtension, notAccepted, errorFunction}) => (
  <ButtonsWrap>
    <Button
      large
      onClick={notAccepted ? errorFunction : submit}
      loading={loading}
      disabled={loading}
    >
      Confirm Extension
    </Button>
    <Button large secondary onClick={cancelExtension} loading={loading} disabled={loading}>
      Cancel
    </Button>
  </ButtonsWrap>
);

type Outter = {
  originalReservation: OriginalReservation,
  loading: boolean,
};

const mapDispatchToProps = {
  clear,
  openModal,
};

const mapStateToProps = state => ({
  dates: selectDates(state),
});

const enhancer: HOC<*, Outter> = compose(
  withUser(),
  withConnect(mapStateToProps, mapDispatchToProps),
  withMutation(extendReservationMutation),
  withSubmit({
    submit: props => () => {
      if (!props.dates || !props.originalReservation) {
        throw new Error('Impossible!');
      }

      return props.submitMutation({
        reservationId: props.originalReservation.resId,
        end: props.dates.endDate,
      });
    },
    redirect: (_, res) => urls.reservation(res.id),
    onSuccess: props => res => {
      props.clear();
      props.openModal(ReservationSuccess, {
        component: <ExtendReservationConfirm extendedReservation={res} />,
      });

      // Track extended days
      const extendedDays = getDaysApart(res.booking.end, props.originalReservation.endDate);
      trackReservation('Reservation Extended', res, props.user, {extendedDays: extendedDays});
    },
  }),
  withHandlers({
    cancelExtension: props => () => {
      props.clear();
      push(urls.products);
    },
  })
);

export default enhancer(ConfirmExtension);
