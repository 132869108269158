// @flow
import {reservationCountQuery} from 'data/reservations/graphql/queries';
import urls from 'data/router/urls';
import withQuery from 'hoc/withQuery';
import React from 'react';
import type {HOC} from 'recompose';

import {ReservationLinkWrap} from './styled';

const ReservationsLink = ({data}) => (
  <ReservationLinkWrap to={urls.reservations} data-cy={'reservations-link'}>
    Reservations&nbsp;{Boolean(data) ? `(${data})` : null}
  </ReservationLinkWrap>
);

const enhancer: HOC<*, {}> = withQuery(reservationCountQuery, {
  noEmpty: true,
  noLoader: true,
});

export default enhancer(ReservationsLink);
