// @flow
import type {FloatParam, VariantParam} from 'data/product/types';
import {formatMmToFeetInch, formatMmToInchFractions} from 'data/units/length/formatters';

const conversionTable = {
  mm: 1,
  cm: 10,
  m: 100,
};

const convertUnit = (p: FloatParam) => {
  const from = conversionTable[p.unit];
  const to = conversionTable[p.displayedUnit];
  if (from && to) {
    const ratio = from / to;
    return `${(p.floatValue * ratio).toFixed(1)} ${p.displayedUnit}`;
  }
  if (p.displayedUnit === 'ft_in') {
    return formatMmToFeetInch(p.floatValue);
  }
  if (p.displayedUnit === 'in_frac') {
    return formatMmToInchFractions(p.floatValue);
  }

  return `${p.floatValue}${p.displayedUnit}`;
};

export const formatVariantParam =
  (name: string) =>
  (params: VariantParam[]): string => {
    // can be undefined due to some error on the backed, so this is best kept here
    const param = params.find(p => p.name === name);

    if (!param) {
      return '';
    }

    if (param.type === 'string_value') {
      return param.stringValue;
    }
    if (param.type === 'enum_value') {
      return param.enumValues.join(', ');
    }

    if (param.type === 'float_value') {
      return convertUnit(param) || '';
    }

    return '';
  };
