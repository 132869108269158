// @flow
import * as React from 'react';
import {type Component, type HOC, compose, withHandlers} from 'recompose';

import withSocialLogin, {type RequiredProps} from '../withSocialLogin';
import {SocialStyledButton} from './styled';

const SocialLoginButton = ({children, handleClick, icon: Icon, type}) => (
  <SocialStyledButton onClick={handleClick} type="button" t={type}>
    <Icon />
    {children}
  </SocialStyledButton>
);

type Outter = {|
  ...RequiredProps,
  children: React.Node,
  icon: Component,
  // for colors, ... the component doesn't forward the provider prop, so we have to have another one :facepalm:
  type: 'facebook' | 'google',
|};

const enhancer: HOC<*, Outter> = compose(
  withSocialLogin,
  withHandlers({
    handleClick: props => e => {
      e.preventDefault();
      props.triggerLogin(e);
    },
  })
);

export default enhancer(SocialLoginButton);
