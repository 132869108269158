// @flow
import Smudge from 'components/Smudge';
import {Container} from 'componentsStyled/Layout/Containers';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import {listCountries} from 'data/locations/queries';
import type {Country} from 'data/locations/types';
import withQuery from 'hoc/withQuery';
import {flatten} from 'ramda';
import React from 'react';

import Destination from './Destination';
import {DestinationsWrap} from './styled';

type Props = {
  data: Country[],
};

const SectionDestinations = ({data}: Props) => {
  const regions = data.length;
  const locations = flatten(data.map(country => country.locations)).length;

  return (
    <Smudge>
      <Container>
        <PageTitle>
          {regions} Regions <br />
          {locations} Locations
        </PageTitle>
        <DestinationsWrap maxHeight={locations}>
          {data.map((country, index) => (
            <Destination country={country} key={index} />
          ))}
        </DestinationsWrap>
      </Container>
    </Smudge>
  );
};

export default withQuery(listCountries)(SectionDestinations);
