// @flow
// $ReactHooks
import {useMemo} from 'react';

export type FilterOption<T> = T & {
  checked: boolean,
  disabled?: true,
};

// Returns a list of all unique options + selected values. If an option appears in the selectedValues
// array then it is marked as 'checked'. If it also DOES NOT appear in the options array then
// it is marked as 'disabled'
const useFilterOptions = function <T: Object>(
  options: Array<T>,
  selectedValues: ?Array<T>,
  idKey: string
): FilterOption<T>[] {
  return useMemo(() => {
    let optionsMarkedAsSelected = options.map(option => {
      const isSelected =
        !!selectedValues &&
        selectedValues.find(selectedValue => selectedValue[idKey] === option[idKey]);
      return {
        ...option,
        checked: isSelected,
      };
    });

    if (selectedValues) {
      selectedValues.forEach(selectedValue => {
        const isAValidOption = optionsMarkedAsSelected.find(
          option => option[idKey] === selectedValue[idKey]
        );
        if (!isAValidOption) {
          optionsMarkedAsSelected = [
            ...optionsMarkedAsSelected,
            {...selectedValue, checked: true, disabled: true},
          ];
        }
      });
    }

    return optionsMarkedAsSelected;
  }, [options, selectedValues, idKey]);
};

export default useFilterOptions;
