// @flow
import ShopIcon from 'assets/icons/ShopIcon';
import TruckIcon from 'assets/icons/TruckIcon';
import ShowMore from 'components/ShowMore';
import Tabs from 'components/Tabs';
import type {FulfillmentType} from 'data/bookings/types';
import type {AffiliateOnVariant} from 'data/product/types';
import {StyledDescription, TabTitle} from 'pages/Product/FulfilmentTabs/styled';
import {path} from 'ramda';
import React from 'react';

type Props = {
  affiliate: AffiliateOnVariant,
  currentFulfilmentType: FulfillmentType,
  onCurrentFulfillmentTypeChange: (fulfillmentType: FulfillmentType) => void,
};

//Convert FufillmentType to tabIndex
const fulfilmentTypeToTabIndex: {[FulfillmentType]: number} = {
  IN_STORE: 0,
  DELIVERY: 1,
};

//Convert tabIndex to FufillmentType
const tabIndexToFulfilmentType: FulfillmentType[] = ['IN_STORE', 'DELIVERY'];

const FulfilmentTabs = ({
  affiliate,
  currentFulfilmentType = 'IN_STORE',
  onCurrentFulfillmentTypeChange,
}: Props) => {
  const {address, deliverySummary, allowInStoreFulfilment, allowDeliveryFulfilment} = affiliate;
  const currentTabIndex = fulfilmentTypeToTabIndex[currentFulfilmentType];
  const setCurrentTabIndex = index =>
    onCurrentFulfillmentTypeChange(tabIndexToFulfilmentType[index]);

  //Gives us an array of the fulfilment types (as tab definitions) that have been allowed by the store
  const tabs = [
    allowInStoreFulfilment && {
      title: (
        <TabTitle>
          <ShopIcon /> Pick up
        </TabTitle>
      ),
      content: (
        <>
          <p>{address}</p>
        </>
      ),
    },
    allowDeliveryFulfilment && {
      title: (
        <TabTitle>
          <TruckIcon /> Delivery
        </TabTitle>
      ),
      content: (
        <>
          <ShowMore maxHeight={'7rem'}>
            <StyledDescription description={deliverySummary} multiLine />
          </ShowMore>
        </>
      ),
    },
  ].filter(x => x);

  //No fufilment => nothing to show
  if (tabs.length === 0) {
    return null;
  }

  //Only 1 fulfilment type allowed => no tabs
  if (tabs.length === 1) {
    //Really just need tabs[0], but flow doesn't like that
    const tab = path(['0'], tabs) || {};

    return (
      <>
        <div>{tab.title}</div>
        <div>{tab.content}</div>
      </>
    );
  }

  //Else we have multiple fulfilment types => use tabs
  return (
    <Tabs
      currentTabIndex={currentTabIndex}
      onCurrentTabIndexChange={setCurrentTabIndex}
      //$ExpectError | Flow infers the incorrect type for tabs
      tabs={tabs}
      fullWidthTabs={true}
    />
  );
};

export default FulfilmentTabs;
