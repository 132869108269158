// @flow
import BagItem from 'components/Bag/BagItem';
import {BagContext} from 'components/Bag/index';
import {LineItemName, LineItems, SubtotalAmountGroup} from 'components/Bag/styled';
import type {AffiliateMinimal} from 'data/affiliate/types';
import type {FulfillmentType} from 'data/bookings/types';
import type {ReservationPricingForDate} from 'data/reservations/types';
import {formatMoney} from 'data/units/money/formatters';
import {sum} from 'ramda';
import React from 'react';
// $ReactHooks
import {useContext} from 'react';

export type Props = {
  affiliate: AffiliateMinimal,
  pricingDetails: ReservationPricingForDate,
  fulfillmentType: FulfillmentType,
};

const BagBookingGroup = ({pricingDetails, fulfillmentType}: Props) => {
  const {reservations, currency, subTotal, deliveryFee, date} = pricingDetails;
  const {canDelete, showCurrencyCode} = useContext(BagContext);

  // Flow doesn't understand flatMap
  // $ExpectError
  const allAccessories = reservations.flatMap(reservation => reservation.pricing.accessories);

  return (
    <div>
      {/*Individual bag items*/}
      {reservations.map((reservationPricingDetails, i) => (
        <BagItem
          key={i}
          groupedByDate={date}
          fulfillmentType={fulfillmentType}
          reservationPricingDetails={reservationPricingDetails}
        />
      ))}
      {/*Accessory prices*/}
      <LineItems canDelete={canDelete}>
        {allAccessories.map(({name, prices}, i) => (
          <React.Fragment key={i}>
            <LineItemName>{name}</LineItemName>
            <span>{formatMoney(sum(prices), true, currency)}</span>
          </React.Fragment>
        ))}
        {/* Delivery Fee */}
        {deliveryFee != null && (
          <>
            <LineItemName>Delivery Fee</LineItemName>
            <span>{deliveryFee === 0 ? 'Free' : formatMoney(deliveryFee, true, currency)}</span>
          </>
        )}
        {/*  Subtotal */}
        <>
          <LineItemName>Subtotal</LineItemName>
          <SubtotalAmountGroup>
            <span>{formatMoney(subTotal, true, currency)}</span>
            {showCurrencyCode && <span>{currency}</span>}
          </SubtotalAmountGroup>
        </>
      </LineItems>
    </div>
  );
};

export default BagBookingGroup;
