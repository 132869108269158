// @flow
import React from 'react';

const LockerIcon = () => (
  <svg width="15" height="20" fill="none" viewBox="0 0 20 26" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m17.565 10.658h-1.8742v-4.9666c0-3.1381-2.5531-5.6912-5.6911-5.6912-3.1381 0-5.6911 2.553-5.6911 5.6912v4.9666h-1.8743c-1.0336 0-1.8744 0.8409-1.8744 1.8744v11.593c0 1.0335 0.84083 1.8743 1.8744 1.8743h15.131c1.0336 0 1.8744-0.8408 1.8744-1.8743v-11.593c0-1.0335-0.8408-1.8744-1.8744-1.8744zm-12.007-4.9666c0-2.449 1.9925-4.4415 4.4415-4.4415 2.449 0 4.4415 1.9925 4.4415 4.4415v4.9666h-8.883v-4.9666zm12.632 18.434c0 0.3445-0.2803 0.6248-0.6248 0.6248h-15.131c-0.3445 0-0.62482-0.2802-0.62482-0.6248v-11.593c0-0.3445 0.28032-0.6248 0.62482-0.6248h15.131c0.3445 0 0.6248 0.2803 0.6248 0.6248v11.593z"
      fill="#000F32"
    />
  </svg>
);

export default LockerIcon;
