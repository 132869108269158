// @flow
import AddressAutocomplete from 'components/AddressAutocomplete';
import withFormField, {type ReqOutter} from 'forms/withFormField';
import React from 'react';
import type {HOC} from 'recompose';

const AddressInput = ({
  onChange,
  value,
  onSelect,
  placeholder,
  debounce,
  searchOptions,
  suggestionsFilter,
  highlightFirstSuggestion,
  'data-cy': dataCy,
}) => {
  //Call the select handler with no selection whenever the text input is changed
  //So consumer can know when the user has updated the text input since a dropdown
  //option was selected
  const handleChange = (...props) => {
    onChange(...props);
    onSelect && onSelect();
  };

  return (
    <AddressAutocomplete
      {...{
        onChange: handleChange,
        value,
        onSelect,
        placeholder,
        debounce,
        searchOptions,
        suggestionsFilter,
        highlightFirstSuggestion,
        'data-cy': dataCy,
      }}
    />
  );
};

type Outter = {|
  ...$Exact<ReqOutter>,
  onSelect?: (address?: string, placeId?: ?string, suggestion?: ?Object) => any,
  placeholder?: string,
  debounce?: number,
  searchOptions?: Object,
  suggestionsFilter?: Object => boolean,
  highlightFirstSuggestion?: boolean,
  'data-cy'?: string,
|};

const enhancer: HOC<*, Outter> = withFormField;

export default enhancer(AddressInput);
