// @flow
import {Container} from 'componentsStyled/Layout/Containers';
import {openModal} from 'data/modals/actions';
import {parseSearchFromLocation} from 'data/router/helpers';
import withConnect from 'hoc/withConnect';
import withRouter from 'hoc/withRouter';
import Login from 'modals/Login';
import Page from 'pages/_Page';
import React from 'react';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import ResetPasswordForm from './ResetPasswordForm';
import Success from './Success';

const ResetPasswordPage = ({passwordReseted, setPasswordReseted, openLogin}) => (
  <Page>
    <Container>
      {passwordReseted ? (
        <Success openLogin={openLogin} />
      ) : (
        <ResetPasswordForm setPasswordReseted={setPasswordReseted} />
      )}
    </Container>
  </Page>
);

const mapDispatchToProps = {
  openModal,
};

const enhancer: HOC<*, {||}> = compose(
  withRouter,
  withConnect(() => ({}), mapDispatchToProps),
  withStateHandlers(
    {
      passwordReseted: false,
    },
    {
      setPasswordReseted: () => (passwordReseted: boolean) => ({passwordReseted}),
    }
  ),
  withHandlers({
    openLogin: props => () => {
      const loginUrl = parseSearchFromLocation(props.location).loginUrl;
      if (loginUrl) {
        window.location = loginUrl;
      } else {
        return props.openModal(Login, {});
      }
    },
  })
);

export default enhancer(ResetPasswordPage);
