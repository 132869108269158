// @flow
import AffiliateList from 'components/AffiliateList';
import {Container} from 'componentsStyled/Layout/Containers';
import {nearbyStoresQuery} from 'data/affiliate/graphql';
import type {Category, Location} from 'data/app/types';
import type {SearchParams} from 'data/search/types';
import withQuery from 'hoc/withQuery';
import * as React from 'react';
import {type HOC} from 'recompose';

const RecommendedStores = ({data, loading}) => {
  // when we can't find stores, we also can't find products, so no message
  // is displayed here as the NearbyProducts component already has that
  if (!data || !(data.length > 0)) {
    return <React.Fragment />;
  }

  return (
    <Container>
      <AffiliateList affiliates={data} loading={loading} />
    </Container>
  );
};

type Outter = {|
  geolocations?: ?(Location[]),
  categories: ?(Category[]),
  params: SearchParams,
|};

const enhancer: HOC<*, Outter> = withQuery(nearbyStoresQuery, {
  noEmpty: true,
  variables: ({geolocations, categories, params}) => ({
    categories: categories && categories.map(category => category.name),
    locationIds: geolocations && geolocations.map(geo => geo.id),
    discountId: params.discountId,
    limit: 30,
  }),
});

export default enhancer(RecommendedStores);
