// @flow
import type {Query} from 'common/graphql/types';
import type {Manufacturer} from 'data/manufacturer/types';
import gql from 'graphql-tag';

import {manufacturerFragment} from './fragments';

export const manufacturerByNameQuery: Query<Manufacturer, {name: string}> = {
  gql: gql`
    query manufacturerByNameQuery($name: String!) {
      manufacturer {
        detailByName(name: $name) {
          ...manufacturerFragment
        }
      }
    }
    ${manufacturerFragment}
  `,
  selector: ['manufacturer', 'detailByName'],
};
