// @flow
import type {DayStatus, DiscountType, ReservationStatus} from './types';

export const reservationStatuses: {
  [ReservationStatus]: ReservationStatus,
} = {
  confirmed: 'confirmed',
  checked_out: 'checked_out',
  finished: 'finished',
  cancelled: 'cancelled',
  pre_processing: 'pre_processing',
  post_processing: 'post_processing',
  inbound: 'inbound',
  outbound: 'outbound',
  overdue: 'overdue',
  delayed: 'delayed',
};

export const upcomingReservationStatuses: ReservationStatus[] = [
  reservationStatuses.confirmed,
  reservationStatuses.outbound,
  reservationStatuses.pre_processing,
];

export const activeReservationStatuses: ReservationStatus[] = [
  reservationStatuses.checked_out,
  reservationStatuses.overdue,
];

export const completedReservationStatuses: ReservationStatus[] = [
  reservationStatuses.post_processing,
  reservationStatuses.finished,
  reservationStatuses.inbound,
  reservationStatuses.delayed,
];

export const cancelledReservationStatuses: ReservationStatus[] = [reservationStatuses.cancelled];

export const dayStatus: {[DayStatus]: DayStatus} = {
  past: 'past',
  closed: 'closed',
  closedHidePrice: 'closedHidePrice',
  blocked: 'blocked',
  available: 'available',
  availableHidePrice: 'availableHidePrice',
  closedSelected: 'closedSelected',
  originalReservation: 'originalReservation',
};

export const discountTypes: {[DiscountType]: DiscountType} = {
  promocode: 'promocode',
};
