// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import PlaceIcon from 'components/AffiliateName/PlaceIcon';
import {DefaultLayout} from 'components/StoreSelect/templates';
import {getPriceRangeString} from 'components/StoreSelect/utils';
import type {AffiliateOnVariant} from 'data/product/types';
import {getPriceRange} from 'data/units/money/formatters';
import useUniqueCountriesAcrossAffiliates from 'hooks/useUniqueCountriesAcrossAffiliates';
// $ReactHooks
import {useMemo} from 'react';
import * as React from 'react';

import {RightIconWrap, StyledStoreSelectButton} from './styled';

type TitleProps = {
  selectedStore: AffiliateOnVariant,
  stores: AffiliateOnVariant[],
};

const TitleComponent = ({stores, selectedStore}: TitleProps) => {
  if (!!selectedStore) {
    const {location} = selectedStore;
    return (
      <>
        <strong>{selectedStore.name}, </strong>
        {location.name} {location.country.code}
      </>
    );
  }
  return `Available in ${stores.length} store${stores.length === 1 ? '' : 's'}`;
};

type SubTitleProps = {
  stores: AffiliateOnVariant[],
};

const SubtitleComponent = ({stores}: SubTitleProps) => {
  const uniqueCountries = useUniqueCountriesAcrossAffiliates(stores);

  const [minPrice, maxPrice] = useMemo(() => {
    // $ExpectError - flow doesn't know flatMap
    const allPrices = stores.flatMap(affiliate => affiliate.pricing);
    return getPriceRange(allPrices);
  }, [stores]);

  return useMemo(() => {
    if (uniqueCountries.length === 1) {
      return getPriceRangeString(minPrice, maxPrice, uniqueCountries[0].currency);
    }
    return `${uniqueCountries.length} countries`;
  }, [uniqueCountries, minPrice, maxPrice]);
};

export type Props = {
  stores: AffiliateOnVariant[],
  onClick: () => void,
  selectedStoreId: number,
};

const StoreSelectButton = ({stores = [], onClick, selectedStoreId}: Props) => {
  const selectedStore = useMemo(
    () => stores.find(store => store.id === selectedStoreId),
    [selectedStoreId, stores]
  );

  const isSelected = !!selectedStore;

  return (
    <StyledStoreSelectButton data-cy="store-select-drawer-button" {...{onClick, isSelected}}>
      <DefaultLayout
        prefixIcon={<PlaceIcon />}
        title={<TitleComponent {...{selectedStore, stores}} />}
        subtitle={isSelected ? null : <SubtitleComponent stores={stores} />}
        suffixIcon={
          <RightIconWrap>
            {!selectedStore && 'Choose'}
            <ArrowIcon rotation="right" />
          </RightIconWrap>
        }
      />
    </StyledStoreSelectButton>
  );
};

export default StoreSelectButton;
