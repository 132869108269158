// @flow
export const preventDefault = (e: SyntheticInputEvent<*>) => e.preventDefault();

export const stopPropagation = (e: SyntheticInputEvent<*>) => e.stopPropagation();

export const isEvent = (e: SyntheticInputEvent<*>) =>
  Boolean(e && e.stopPropagation && e.preventDefault);

export const isScrollEnd = (e: any) => {
  const tolerance = 10;
  // fix for Bug #39, some tablet browsers fail to correctly re-calculate clientHeight
  const height = Math.max(e.clientHeight, window.innerHeight);
  const isEnd = e.scrollHeight - e.scrollTop <= height + tolerance;
  return isEnd;
};
