// @flow
import type {ExceptionDay, RegularDay} from './types';

export const formatTelNumber = (s: string): string => s.replace(/ /g, '');

export const formatOpeningHour = (v: RegularDay | ExceptionDay): string => {
  if (v.closed || !v.hours) return 'CLOSED';

  if (v.hours.pauseStart && v.hours.pauseEnd)
    return `${v.hours.start} - ${v.hours.pauseStart}, ${v.hours.pauseEnd} - ${v.hours.end}`;

  return `${v.hours.start} - ${v.hours.end}`;
};
