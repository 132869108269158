// @flow
import StyledLink from 'components/StyledLink';
import styled, {css} from 'styled-components';

export const BackLink = styled(StyledLink)`
  font-size: 1.5rem;
  color: ${props => props.theme.color.secondaryText};
  font-weight: ${props => props.theme.fontWeights.ultra};
  position: absolute;
  left: 1.5rem;
  top: 50%;
  display: flex;
  align-items: center;
  transform: translateY(-50%);

  ${p =>
    p.withFilter &&
    css`
      top: 30%;
    `}

  span {
    margin-left: 0.8rem;
    font-weight: ${props => props.theme.fontWeights.ultra};
  }
`;
