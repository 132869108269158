import {mq, mqMax} from 'common/mediaQuery';
import {NavLink} from 'componentsStyled/Typography';
import styled from 'styled-components';

export const FooterLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Links = styled.div`
  display: flex;
  margin-top: 3.5rem;

  ${mq.tablet} {
    margin-top: 4.5rem;
  }

  ${mq.tabletWide} {
    margin-top: 5.5rem;
  }

  ${mq.desktop} {
    margin-top: 7rem;
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${mqMax.tabletWide} {
    & + & {
      margin-left: 1rem;
    }
  }
`;

export const PoweredWrap = styled.div`
  margin-top: 2rem;
`;
