// @flow
import StyledLink from 'components/StyledLink';
import {Text} from 'componentsStyled/Typography/Texts';
import {selectTheme} from 'data/app/selectors';
import withConnect from 'hoc/withConnect';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

import {SearchResultsCountWrap} from './styled';

const formatProductCount = count => (count > 1 ? `${count} products` : 'one product');

const formatAffiliatesCount = count => (count > 1 ? `${count} shops` : 'one shop');

type Outer = {|
  /** Total number of product offerings across all pages */
  productsTotal: number,
  /** Number of affiliates returned (which may not be the total) */
  affiliatesCount: number,
  clear: Function,
|};

const SearchResultsCount = ({theme, productsTotal, affiliatesCount, clear}) => {
  const hasProducts = productsTotal > 0;
  const hasAffiliates = affiliatesCount > 0;
  const hasSomething = hasProducts || hasAffiliates;

  function renderProductsAndAffiliateCounts() {
    if (theme && !theme.enableStoreCarousel && hasProducts) {
      // Not showing stores
      return `We found ${formatProductCount(productsTotal)} for you`;
    } else if (hasProducts && !hasAffiliates) {
      // Found only products
      return `We found ${formatProductCount(productsTotal)} for you`;
    } else if (!hasProducts && hasAffiliates) {
      // Found only affiliates
      return `We found ${formatAffiliatesCount(affiliatesCount)} for you`;
    } else if (hasProducts && hasAffiliates) {
      // Found both products and affiliates
      return `We found ${formatProductCount(productsTotal)} and ${formatAffiliatesCount(
        affiliatesCount
      )} for you`;
    }
  }

  return (
    <SearchResultsCountWrap>
      {/* Empty search state */}
      {!hasSomething && (
        <React.Fragment>
          <Text>
            No products here. Email{' '}
            <StyledLink to="mailto:support@awayco.com">support@awayco.com</StyledLink> and tell us
            what you are looking for.
          </Text>
          <Text primary onClick={clear}>
            Clear all filters
          </Text>
        </React.Fragment>
      )}

      {/* Show counts */}
      {hasSomething && <Text>{renderProductsAndAffiliateCounts()}</Text>}
    </SearchResultsCountWrap>
  );
};

const mapStateToProps = state => ({
  theme: selectTheme(state),
});

const enhancer: HOC<*, Outer> = compose(withConnect(mapStateToProps));

export default enhancer(SearchResultsCount);
