// @flow
import ProductList from 'components/ProductList';
import Smudge from 'components/Smudge';
import {Container} from 'componentsStyled/Layout/Containers';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import type {Location} from 'data/app/types';
import {listProductOfferingsQuery} from 'data/product/graphql/queries';
import withQuery from 'hoc/withQuery';
import React from 'react';
import {type HOC, compose} from 'recompose';

const SectionTopProducts = ({data, loading}) => (
  <React.Fragment>
    <Smudge>
      <Container>
        <PageTitle>Popular Gear Near You</PageTitle>
        <ProductList productOfferings={data.products} loading={loading} />
      </Container>
    </Smudge>
  </React.Fragment>
);

type Outter = {|
  geolocations: ?(Location[]),
|};

const enhancer: HOC<*, Outter> = compose(
  withQuery(listProductOfferingsQuery, {
    variables: ({geolocations, ...rest}) => ({
      limit: 6,
      locationIds: geolocations ? geolocations.map(geo => geo.id) : undefined,
      ...rest,
    }),
  })
);

export default enhancer(SectionTopProducts);
