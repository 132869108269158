// @flow
import type {ProductVariantAvailableAffiliate} from 'data/product/types';
import {isNil} from 'ramda';

export const getPriceForDay = (
  a?: ProductVariantAvailableAffiliate,
  daysFromStart: ?number
): ?number => {
  if (isNil(daysFromStart) || daysFromStart < 0 || !a || a.pricing.length === 1) return undefined;

  if (daysFromStart > a.pricing.length - 1) return a.pricing[a.pricing.length - 1];

  return a.pricing[daysFromStart];
};
