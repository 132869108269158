// @flow
import StyledLink from 'components/StyledLink';
import {Title} from 'componentsStyled/Typography/Titles';
import styled from 'styled-components';

export const ModalHeaderWrap = styled.div`
  position: relative;
  width: 100%;
  height: 6rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${props => props.theme.zIndices.header};
  background: ${props => props.theme.color.white};
`;

export const LeftIconWrap = styled(StyledLink)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const RightIconWrap = styled.div`
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const ModalTitle = styled(Title)`
  font-size: 2.3rem;
  margin-bottom: 0;
`;
