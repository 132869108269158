// @flow
import type {ToolTipContent} from 'data/tooltips/types';

const passwordHintToolTipText: Array<string> = [
  `We advise using at least a capital letter and one special character.`,
  `Example: _Str0ngPassword!`,
];

export const passwordHintToolTip: ToolTipContent = {
  title: 'Password Hint: ',
  body: passwordHintToolTipText,
  modal: true,
};
