// @flow
import styled from 'styled-components';

export const ButtonsWrap = styled.div`
  display: flex;
  width: 100%;

  > button {
    flex: 1;
    min-width: 0;

    &:first-child {
      margin-right: 3rem;
    }
  }
`;
