// @flow
import {formatVariantParam} from 'data/product/formatters';
import type {ProductVariant} from 'data/product/types';
import type {User} from 'data/user/types';

import {tryTrack} from '../helpers';

export const productTrait = (productVariant: ProductVariant, user: User) => ({
  email: user.email,
  productId: productVariant.id,
  // TODO(Jude): Handle multiple categories
  category: productVariant.product.categories[0].name,
  brand: productVariant.product.manufacturer.name,
  model: productVariant.product.name,
  width: formatVariantParam('width')(productVariant.parameters),
  length: formatVariantParam('length')(productVariant.parameters),
  thickness: formatVariantParam('thickness')(productVariant.parameters),
  volume: formatVariantParam('volume')(productVariant.parameters),
  gender: formatVariantParam('gender')(productVariant.parameters),
  binding: formatVariantParam('binding')(productVariant.parameters),
  terrain: formatVariantParam('terrain')(productVariant.parameters),
});

export const trackProductView = (p: ProductVariant, u: User) =>
  tryTrack('Product Viewed', () => productTrait(p, u));
