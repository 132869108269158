// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

export const CalendarWrap = styled.div`
  min-width: 27.2rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const MonthsWrap = styled.div`
  & > div:first-child {
    margin-bottom: 3rem;
  }

  ${mq.tabletWide} {
    display: flex;
    justify-content: space-between;

    & > div:first-child {
      margin-bottom: 0;
      margin-right: 3rem;
    }
  }
`;
