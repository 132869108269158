// @flow
import * as React from 'react';
import {branch, renderNothing} from 'recompose';
import scrollIntoView from 'scroll-into-view';

import {AlertWrap} from './styled';

type Props = {|
  children: React.Node,
  scroll?: boolean,
|};

class Alert extends React.Component<Props> {
  ref = null;

  constructor() {
    super();
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.scroll && this.ref) {
      scrollIntoView(this.ref.current);
    }
  }

  render() {
    return <AlertWrap innerRef={this.ref}>{this.props.children}</AlertWrap>;
  }
}

export default branch(props => !props.children, renderNothing)(Alert);
