// @flow
import BannedIcon from 'assets/icons/BannedIcon';
import PlaceIcon from 'components/AffiliateName/PlaceIcon';
import RadioGroup from 'components/Radio/RadioGroup';
import StoreSelectButton from 'components/StoreSelect/StoreSelectButton';
import StoreSelectDrawer from 'components/StoreSelect/StoreSelectDrawer';
import StoreSelectRadioButton from 'components/StoreSelect/StoreSelectRadioButton';
import {RadioContainer, Title, TitleWrapper} from 'components/StoreSelect/styled';
import type {Location} from 'data/app/types';
import type {AffiliateOnVariant} from 'data/product/types';
import * as React from 'react';
// $ReactHooks
import {useState} from 'react';

export type Props = {
  stores: AffiliateOnVariant[],
  selectedStoreId: number,
  onStoreSelect: (storeId: string | number) => void,
  selectedLocations?: ?(Location[]),
  userCoords?: {latitude: number, longitude: number},
};

const StoreSelect = ({
  stores,
  selectedStoreId,
  onStoreSelect,
  selectedLocations,
  userCoords,
}: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // No stores => No store warning
  if (!stores || stores.length === 0) {
    return (
      <TitleWrapper>
        <BannedIcon />
        This product is not available
      </TitleWrapper>
    );
  }

  // 1 store => Store info
  if (stores.length === 1) {
    const store = stores[0];
    return (
      <TitleWrapper>
        <PlaceIcon />
        <Title>
          <strong>{store.name}, </strong>
          {store.location.name} {store.location.country.code}
        </Title>
      </TitleWrapper>
    );
  }

  // 2 - 3 stores => radio buttons
  if (stores.length <= 3) {
    return (
      <RadioContainer>
        <RadioGroup value={selectedStoreId} name={'store'} onChange={onStoreSelect}>
          {stores.map(store => (
            <StoreSelectRadioButton key={store.id} store={store} value={store.id} />
          ))}
        </RadioGroup>
      </RadioContainer>
    );
  }

  // > 3 stores => store drawer
  return (
    <>
      <StoreSelectButton
        stores={stores}
        onClick={() => setIsDrawerOpen(true)}
        selectedStoreId={selectedStoreId}
      />
      <StoreSelectDrawer
        onClose={() => setIsDrawerOpen(false)}
        selectedStoreId={selectedStoreId}
        isOpen={isDrawerOpen}
        stores={stores}
        onStoreSelect={onStoreSelect}
        selectedLocations={selectedLocations}
        userCoords={userCoords}
      />
    </>
  );
};

export default StoreSelect;
