// @flow
import type {Query} from 'common/graphql/types';
import {affiliateFragmentMinimal, affiliateFulfilmentFragment} from 'data/affiliate/fragments';
import type {AffiliateMinimal} from 'data/affiliate/types';
import {paginationFragment} from 'data/app/graphql/fragments';
import type {CategoryName} from 'data/app/types';
import type {FulfillmentType} from 'data/bookings/types';
import type {ID} from 'data/enums/types';
import type {DateRange, DateString} from 'data/units/date/types';
import gql from 'graphql-tag';
import {path} from 'ramda';

import type {Product, ProductVariant, RelatedCategory} from '../types';
import {
  productFragment,
  productFragmentWithVariants,
  productOfferingFragment,
  productVariantFragment,
} from './fragments';

/**
 * Fetches the details for a product item.
 */
export const productDetailQuery: Query<
  ProductVariant,
  {|
    productVariantId: ID,
    affiliateId: ID,
  |}
> = {
  gql: gql`
    query productDetailQuery($productVariantId: Int!, $affiliateId: Int!) {
      product {
        getVariant(id: $productVariantId, affiliateId: $affiliateId) {
          ...productVariantFragment
        }
        getProductItem(productVariantId: $productVariantId, affiliateId: $affiliateId) {
          code
        }
      }
    }
    ${productVariantFragment}
  `,
  // need to transform in new data
  transform: data => ({
    ...data.getVariant,
    code: data.getProductItem.code,
  }),
  selector: ['product'],
};

export const fullProductDetailQuery: Query<
  {
    product: Product,
  },
  {
    productId: ID,
  }
> = {
  gql: gql`
    query fullProductDetailQuery($productId: Int!) {
      product {
        fullDetail(productId: $productId) {
          ...productFragment
        }
      }
    }
    ${productFragment}
  `,
  transform: (data: any) => {
    const product = path(['product', 'fullDetail'], data) || [];

    return {
      // $Ramda
      product,
    };
  },
};

/**
 * Fetches product variants for a product offered by an affiliate.
 */
export const listProductVariantsQuery: Query<
  {
    affiliate: AffiliateMinimal,
    productVariants: ProductVariant[],
  },
  {
    affiliateId: ID,
    productId: ID,
  }
> = {
  gql: gql`
    query listProductVariantsQuery(
      $affiliateId: Int!
      $productId: Int!
      $discountId: Int
      $limit: Int
      $offset: Int
    ) {
      affiliate {
        get(id: $affiliateId) {
          ...affiliateFragmentMinimal
          ...affiliateFulfilmentFragment
        }
      }
      product {
        listProductVariants(
          filter: {
            affiliateId: $affiliateId
            productId: $productId
            discountId: $discountId
            limit: $limit
            offset: $offset
          }
        ) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productVariantFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${productVariantFragment}
    ${affiliateFragmentMinimal}
    ${affiliateFulfilmentFragment}
  `,
  transform: data => ({
    // $Ramda
    affiliate: path(['affiliate', 'get'], data),
    // $Ramda
    productVariants: path(['product', 'listProductVariants', 'data'], data),
  }),
  pagination: ['product', 'listProductVariants'],
};

export const listPopularProductsByReservationCount: Query<
  {
    //TODO(HARRY) write a type for this that captures the variants as children of products
    products: any,
  },
  {
    limit: number,
    offset: number,
    category: string,
    availableOnly: boolean,
  }
> = {
  gql: gql`
    query listPopularProductsByReservationCountQuery(
      $limit: Int
      $offset: Int
      $category: String
      $availableOnly: Boolean
    ) {
      product {
        listPopularProductsByReservationCount(
          filter: {
            limit: $limit
            offset: $offset
            category: $category
            availableOnly: $availableOnly
          }
        ) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${productFragmentWithVariants}
  `,
  transform: data => ({
    products: path(['product', 'listPopularProductsByReservationCount', 'data'], data),
  }),
  pagination: ['product', 'listPopularProductsByReservationCount'],
};

export const listPopularRelatedProductsByReservationCount: Query<
  {
    relatedCategories: RelatedCategory[],
    affiliates: AffiliateMinimal[],
  },
  {
    limit: number,
    category: string,
    productId?: ID,
  }
> = {
  gql: gql`
    query listPopularRelatedProductsByReservationCountQuery(
      $limit: Int
      $category: String
      $productId: Int
    ) {
      product {
        listPopularRelatedProductsByReservationCount(
          filter: {limit: $limit, category: $category, productId: $productId}
        ) {
          relatedCategories {
            name
            products {
              ...productOfferingFragment
            }
          }
        }
      }
      affiliate {
        listAvailable(filter: {}) {
          location {
            country {
              code
              name
              id
            }
          }
        }
      }
    }
    ${productOfferingFragment}
  `,
  transform: data => ({
    // $Ramda
    affiliates: path(['affiliate', 'listAvailable'], data),
    // $Ramda
    relatedCategories: path(
      ['product', 'listPopularRelatedProductsByReservationCount', 'relatedCategories'],
      data
    ),
  }),
  options: () => ({
    fetchPolicy: 'no-cache',
  }),
};

/**
 * Searches for products based on a filter. Returns just the id and name.
 */
export const listProductsMinimalQuery: Query<
  {
    id: ID,
    name: string,
  }[]
> = {
  gql: gql`
    query productListQueryMinimal($filter: ProductFilter) {
      product {
        list(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            id
            name
          }
        }
      }
    }
    ${paginationFragment}
  `,
  selector: ['product', 'list', 'data'],
  pagination: ['product', 'list'],
};

/**
 * Searches for product offerings (NOTE: not variant offerings) based on a
 * filter as well as the affiliates that match the search filter.
 */
export const listProductOfferingsQuery: Query<
  {
    affiliates: Array<AffiliateMinimal>,
    products: Array<Product>,
  },
  {
    productIds?: Array<ID>,
    manufacturerIds?: Array<ID>,
    categoryIds?: Array<string>,
    locationIds?: Array<ID>,
    affiliateId?: ID,
    discountId?: number,
  }
> = {
  gql: gql`
    query listProductOfferingsQuery(
      $productIds: [Int!]
      $locationIds: [Int!]
      $manufacturerIds: [Int!]
      $categoryIds: [String!]
      $affiliateId: Int
      $limit: Int
      $offset: Int
    ) {
      affiliate {
        listAvailable(filter: {}) {
          location {
            country {
              code
              name
              id
            }
          }
        }
      }
      product {
        list(
          filter: {
            productIds: $productIds
            locationIds: $locationIds
            manufacturerIds: $manufacturerIds
            categoryIds: $categoryIds
            affiliateId: $affiliateId
            limit: $limit
            offset: $offset
            availableOnly: true
          }
        ) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productOfferingFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${productOfferingFragment}
  `,

  transform: data => ({
    // $Ramda
    affiliates: path(['affiliate', 'listAvailable'], data),
    // $Ramda
    products: path(['product', 'list', 'data'], data),
  }),

  // Paginate on products (not affiliates)
  pagination: ['product', 'list'],
};

/**
 * Lists product item offerings based on the user's location.
 */
export const nearbyProductsQuery: Query<
  ProductVariant[],
  {|categories: ?(CategoryName[]), locationIds: ?(ID[])|}
> = {
  gql: gql`
    query nearbyProductsQuery(
      $categories: [String]
      $locationIds: [Int!]
      $discountId: Int
      $offset: Int
    ) {
      product {
        listRecommended(
          filter: {
            limit: 8
            categories: $categories
            locationIds: $locationIds
            discountId: $discountId
            offset: $offset
          }
        ) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productVariantFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${productVariantFragment}
  `,
  selector: ['product', 'listRecommended', 'data'],
  pagination: ['product', 'listRecommended'],
};

/**
 * Checks for the intersection in availability for a list of product variants offered by an affiliate.
 */
export const productAvailabilityQuery: Query<
  {
    availability: DateRange[],
    closedDays: DateString[],
    affiliateCurrentDate: DateString,
  },
  {|
    variantsAvailabilityFilter: {|
      productVariantIds: ID[],
      affiliateId: ID,
      start: DateString,
      end: DateString,
      trimClosedFromStart: boolean,
      availabilityForExtension: ?ID,
      fulfillmentType?: FulfillmentType,
    |},
    closedDaysFilter: {|
      affiliateId: ID,
      start: DateString,
      end: DateString,
    |},
    affiliateId: ID,
  |}
> = {
  gql: gql`
    query productAvailabilityQuery(
      $variantsAvailabilityFilter: VariantsAvailabilityFilter
      $closedDaysFilter: AffiliateClosedDatesFilter
      $affiliateId: Int!
    ) {
      product {
        getAvailableDatesForVariants(filter: $variantsAvailabilityFilter) {
          startDate: start
          endDate: end
        }
      }
      affiliate {
        getClosedDates(filter: $closedDaysFilter)
        getCurrentDate(id: $affiliateId)
      }
    }
  `,
  transform: data => ({
    // $Ramda
    availability: path(['product', 'getAvailableDatesForVariants'], data),
    // $Ramda
    closedDays: path(['affiliate', 'getClosedDates'], data),
    // $Ramda
    affiliateCurrentDate: path(['affiliate', 'getCurrentDate'], data),
  }),
  options: () => ({
    fetchPolicy: 'network-only',
  }),
};
