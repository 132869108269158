// @flow
import {stripeCardFunding} from './constants';
import type {StripeToken} from './types';

/**
 * Checks if a given card is prepaid
 * @param {StripeToken} token - The card token returned from the Stripe API
 * @returns {boolean} - true if is a prepaid card
 */
export const isPrepaid = (token: StripeToken): boolean =>
  token.card.funding === stripeCardFunding.prepaid;
