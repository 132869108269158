// @flow
import ReactSocialLogin from 'react-social-login';
import {type Component} from 'recompose';

export type RequiredProps =
  | {|
      provider: 'google',
      appId: string,
      onLoginSuccess: ({|token: {|idToken: string|}|}) => mixed,
      onLoginFailure: string => mixed,
    |}
  | {|
      provider: 'facebook',
      appId: string,
      onLoginSuccess: ({|token: {|accessToken: string|}|}) => mixed,
      onLoginFailure: string => mixed,
    |};

type Added = {|
  triggerLogin: Function,
|};

function withSocialLogin<T: RequiredProps>(c: Component<{|...Added, ...T|}>): Component<T> {
  return ReactSocialLogin(c);
}

export default withSocialLogin;
