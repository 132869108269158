// @flow
import Button from 'components/Button';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {H3} from 'componentsStyled/Typography';
import {Text} from 'componentsStyled/Typography/Texts';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import withUser from 'hoc/withUser';
import ModalBody from 'modals/_Body';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import * as React from 'react';
import type {Component, HOC} from 'recompose';

import {ReservationSuccessWrap} from './styled';
import SuccessIcon from './SuccessIcon';

const ReservationSuccess = ({close, user, component}) => (
  <ModalBody close={close} data-cy={'reservation-success'}>
    <ModalContent>
      <Wrap>
        <ReservationSuccessWrap>
          <SuccessIcon />
          {component ? (
            component
          ) : (
            <div>
              <PageTitle>Congrats!</PageTitle>
              <H3 book black center lh={2}>
                You're ready to go
              </H3>
              <Text center>
                You won’t be charged if you cancel your reservation up to 48 hours in advance
              </Text>
            </div>
          )}
        </ReservationSuccessWrap>
      </Wrap>
    </ModalContent>
    <ModalControls>
      <Button fullwidth onClick={close} data-cy={'view-reservation'}>
        View Reservation
      </Button>
    </ModalControls>
  </ModalBody>
);

type Outter = {|
  close: Function,
  component?: Component,
|};

const enhancer: HOC<*, Outter> = withUser();

export default enhancer(ReservationSuccess);
