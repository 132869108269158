// @flow
import {selectAppConfig} from 'data/app/selectors';
import {clear} from 'data/search/actions';
import withConnect from 'hoc/withConnect';
import withOpen from 'hoc/withOpen';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

import HomeLink from '../../HomeLink';
import ShoppingBasketLink from '../../ShoppingBasketLink';
import SearchAutocomplete from '../SearchAutocomplete';
import AvatarMenu from './AvatarMenu';
import ReservationsLink from './ReservationsLink';
import {LeftWrap, RightWrap, SearchWrap} from './styled';

const DesktopLoggedInNav = ({appConfig, isOpen, open, close, clear}) => (
  <React.Fragment>
    <LeftWrap>
      <HomeLink />
      <ReservationsLink />
    </LeftWrap>
    <SearchWrap>
      <SearchAutocomplete initialValue="" isOpen={isOpen} open={open} close={close} />
    </SearchWrap>
    <RightWrap>
      <ShoppingBasketLink />
      <AvatarMenu />
    </RightWrap>
  </React.Fragment>
);

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const mapDispatchToProps = {
  clear,
};

const enhancer: HOC<*, {||}> = compose(withOpen, withConnect(mapStateToProps, mapDispatchToProps));

export default enhancer(DesktopLoggedInNav);
