// @flow
import {resetAnalytics} from 'analytics/index';
import {ApolloClient} from 'apollo-client';
import {from} from 'apollo-link';
import {createHttpLink} from 'apollo-link-http';
import config from 'global/config';
import {purgePersistor} from 'global/store';

import cache from './cache';
import {purgeTokens} from './crypto';
import customFetch from './customFetch';
import linkErrors from './linkErrors';
import linkExtractTokens from './linkExtractTokens';
import linkSetHeaders from './linkSetHeaders';

const apolloClient = new ApolloClient({
  link: from([
    linkErrors,
    linkSetHeaders,
    linkExtractTokens,
    createHttpLink({uri: config.api, fetch: customFetch}),
  ]),
  cache,
});

export const purgeEverything = (resetStore: boolean): Promise<void> => {
  purgeTokens();
  purgePersistor();
  resetAnalytics();

  return resetStore ? apolloClient.resetStore() : Promise.resolve();
};

export default apolloClient;
