// @flow
import React from 'react';

import {makeWrap} from './styled';

type Props = {|
  html: string,
  css: string,
|};

/**
 * An element allowing you to pass in custom HTML and CSS. Styling is reset and
 * CSS is scoped locally to this element.
 */
const CustomElement = (props: Props) => {
  const Wrap = makeWrap(props.css);
  return <Wrap dangerouslySetInnerHTML={{__html: props.html}} />;
};

export default CustomElement;
