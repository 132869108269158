// @flow
// $Import
import 'react-quill/dist/quill.snow.css'; // CSS needed for HTML exported by Quill WYSIWYG editor

import {MultipleLineText, Text} from 'componentsStyled/Typography/Texts';
import * as React from 'react';
import sanitizeHtml from 'sanitize-html';

type Props = {|
  multiLine?: boolean,
  description: string,
  className?: string,
|};

const Description = ({description, multiLine, className}: Props) => {
  const sanitizedHtml = sanitizeHtml(description, {
    allowedTags: [
      // Formatting
      'b',
      'i',
      'strong',
      'em',
      'strike',
      'abbr',
      'code',
      'pre',
      // Structuring
      'p',
      'div',
      'hr',
      'br',
      'div',
      'span',
      // Linking
      'a',
      // Headings
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      // Lists
      'ul',
      'ol',
      'nl',
      'li',
      // Tables
      'table',
      'thead',
      'caption',
      'tbody',
      'tr',
      'th',
      'td',
      // Notably absent: img
      'iframe',
    ],
    allowedAttributes: false,
    allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com'],
  });

  //Safely returns space-separated list of
  const classNames = ['ql-editor', className].filter(Boolean).join(' ');

  if (multiLine) {
    return (
      <React.Fragment>
        <MultipleLineText
          className={classNames}
          dangerouslySetInnerHTML={{__html: sanitizedHtml}}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Text className={classNames} dangerouslySetInnerHTML={{__html: sanitizedHtml}} />
      </React.Fragment>
    );
  }
};

export default Description;
