// @flow
import withLocation from 'hoc/withLocation';
import * as React from 'react';
import type {HOC} from 'recompose';

import {StyledHref, StyledNavLink} from './styled';

const StyledLink = ({
  children,
  draggable = 'true',
  to,
  className,
  onClick,
  exact,
  underline,
  target,
  'data-cy': dataCy,
}) => {
  // mails and phones
  if (!to || to.startsWith('mailto:') || to.startsWith('tel:'))
    return (
      <StyledHref
        href={to}
        className={className}
        onClick={onClick}
        draggable={draggable}
        target={target ? target : '_blank'}
        data-cy={dataCy}
      >
        {children}
      </StyledHref>
    );

  // external links
  if (to.startsWith('http') || to.startsWith('www.'))
    return (
      <StyledHref
        href={to}
        rel="noreferrer noopener"
        className={className}
        onClick={onClick}
        draggable={draggable}
        target={target ? target : '_blank'}
        data-cy={dataCy}
      >
        {children}
      </StyledHref>
    );

  // everything else
  return (
    <StyledNavLink
      to={to}
      className={className}
      activeClassName="active"
      onClick={onClick}
      exact={exact}
      draggable={draggable}
      underline={underline}
      target={target}
      data-cy={dataCy}
    >
      {children}
    </StyledNavLink>
  );
};

type Outter = {|
  children: React.Node,
  to?: string,
  onClick?: Function,
  exact?: boolean,
  // injected from styled components
  className?: string,
  draggable?: string,
  underline?: boolean,
  'data-cy'?: string,
|};

// to ensure a link is rerendered on location change
const enhancer: HOC<*, Outter> = withLocation();

export default enhancer(StyledLink);
