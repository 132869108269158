// @flow
import {lighten} from 'global/theme';
import styled from 'styled-components';

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Circle = styled.div`
  width: 8rem;
  height: 8rem;
  margin: 2.4rem 0;
  border-radius: 100%;
  background-color: ${props => lighten(props.theme.color.primaryAccent, 0.2)};
`;
