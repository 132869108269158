// @flow
import {ShowMoreButton, ShowMoreContent, ShowMoreWrapper} from 'components/ShowMore/styled';
import * as React from 'react';
// $ReactHooks
import {useEffect, useRef, useState} from 'react';

type Props = {
  children: React.Node,
  initialShowMore?: boolean,
  maxHeight?: string,
};

const ShowMore = ({initialShowMore = false, children, maxHeight = '10rem'}: Props) => {
  const [showMore, setShowMore] = useState(initialShowMore);
  const [hasMoreToShow, setHasMoreToShow] = useState(false);
  const showMoreContentRef = useRef();

  useEffect(() => {
    const content = showMoreContentRef.current;
    if (content) {
      setHasMoreToShow(content.scrollHeight > content.offsetHeight);
    }
  }, [setHasMoreToShow]);

  const toggleShowMore = () => setShowMore(currentValue => !currentValue);

  return (
    <ShowMoreWrapper>
      <ShowMoreContent innerRef={showMoreContentRef} showMore={showMore} maxHeight={maxHeight}>
        {children}
      </ShowMoreContent>
      {(hasMoreToShow || showMore) && (
        <ShowMoreButton onClick={toggleShowMore} role="button">
          {showMore ? 'Show Less' : 'Show More'}
        </ShowMoreButton>
      )}
    </ShowMoreWrapper>
  );
};

export default ShowMore;
