// @flow
import {lighten} from 'global/theme';
import styled, {css} from 'styled-components';

const StyledTextArea = styled.textarea`
  border: solid 0.1rem ${props => props.theme.color.darkGray};
  color: ${props => props.theme.color.secondaryText};
  border-radius: 0.4rem;
  background: ${props => props.theme.color.white};
  padding: 1.5rem 1.7rem;
  font-size: 1.6rem;
  font-weight: ${props => props.theme.fontWeights.normal};
  width: 100%;
  outline: none;

  &:focus {
    border-color: ${props => props.theme.color.primaryAccent};
    z-index: ${props => props.theme.zIndices.minimal};
  }

  &::placeholder {
    color: ${props => lighten(props.theme.color.secondaryText, 0.5)};
  }

  &:disabled {
    background: ${props => props.theme.color.lightGray};
  }

  ${p =>
    p.resize &&
    css`
      resize: ${p.resize};
    `}

  ${p =>
    p.hasError &&
    css`
      border: 2px solid ${props => props.theme.color.error};
    `}
`;

export default StyledTextArea;
