// @flow
import styled from 'styled-components';

export const Center = styled.div`
  justify-content: center;
  display: flex;
`;

export const Callout = styled.div`
  padding: 8px;
  border-radius: 6px;
  background-color: #f7fafc;
  line-height: 24px;
  font-size: 14px;
`;

export const CalloutTitle = styled.span`
  font-weight: 500;
`;
