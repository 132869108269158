// @flow
import type {AffiliateFull} from 'data/affiliate/types';
import React from 'react';

import {AffiliateLink, LinkWrap, SectionTitle} from '../styled';
import RegularOpeningHoursTable from './OpeningHoursTable/Regular';
import SpecialOpeningHoursTable from './OpeningHoursTable/Special';

type Props = {|
  affiliate: AffiliateFull,
|};

const Info = ({affiliate}: Props) => (
  <React.Fragment>
    <SectionTitle>Address</SectionTitle>
    <LinkWrap>
      <AffiliateLink
        to={`https://www.google.com/maps/search/${affiliate.address.split(' ').join('+')}`}
      >
        {affiliate.address}
      </AffiliateLink>
    </LinkWrap>

    <SectionTitle>Store hours</SectionTitle>
    <RegularOpeningHoursTable data={affiliate.openingHours} />
    <SpecialOpeningHoursTable data={affiliate.specialOpeningDates} />
  </React.Fragment>
);

export default Info;
