// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import {displayCancellationFee} from 'data/units/date/helpers';
import {type IsoDate} from 'data/units/date/types';
import {formatMoney} from 'data/units/money/formatters';
import {equals} from 'ramda';
import * as React from 'react';

type Props = {
  cancellationFee: number,
  cancellationFeeStartsAt: IsoDate,
  currency: string,
  totalPrice: number,
};

const CancellationFeeConfirm = ({
  cancellationFee,
  cancellationFeeStartsAt,
  currency,
  totalPrice,
}: Props) => {
  const refund = totalPrice - cancellationFee;
  return (
    <React.Fragment>
      <Text center>Are you sure you want to cancel this booking?</Text>
      {!equals(cancellationFee, 0) && displayCancellationFee(cancellationFeeStartsAt) ? (
        <div>
          <Text primary medium center>
            You will still be charged the cost of your first booking day{' '}
            {formatMoney(cancellationFee, true, currency)}, and the remaining balance{' '}
            {formatMoney(refund, true, currency)} will be refunded.
          </Text>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default CancellationFeeConfirm;
