// @flow
import appReducer, {type AppState} from 'data/app/reducer';
import basketReducer, {type BasketState} from 'data/basket/reducer';
import modalsReducer, {type ModalState} from 'data/modals/reducer';
import notificationReducer, {type NotificationsState} from 'data/notifications/reducer';
import reservationsReducer, {type ReservationsState} from 'data/reservations/reducer';
import {type RouterState} from 'data/router/reducer';
import searchReducer, {type SearchState} from 'data/search/reducer';
import userReducer, {type UserState} from 'data/user/reducer';
import userLocationReducer, {type UserLocationState} from 'data/userLocation/reducer';
import {combineReducers} from 'redux';

import persistReducer from './persistReducer';

export type StoreState = {|
  app: AppState,
  search: SearchState,
  modals: ModalState,
  notifications: NotificationsState,
  user: UserState,
  /** The current reservation being booked */
  reservations: ReservationsState,
  /** Pending reservations yet to be submitted */
  basket: BasketState,
  // this is added via connected-react-router package
  router: RouterState,
  userLocation: UserLocationState,
|};

export default combineReducers({
  app: persistReducer(appReducer, {
    key: 'app',
    whitelist: ['GDPRHidden'],
  }),
  user: persistReducer(userReducer, {
    key: 'user',
    whitelist: ['loggedIn', 'isMasquerading'],
  }),
  search: persistReducer(searchReducer, {
    key: 'search',
    whitelist: ['recentSearches'],
  }),
  reservations: reservationsReducer,
  // XXX(ray): Maybe do not persist it
  basket: persistReducer(basketReducer, {
    key: 'basket',
    whitelist: ['items'],
  }),
  modals: modalsReducer,
  notifications: notificationReducer,
  userLocation: userLocationReducer,
});
