// @flow
import styled from 'styled-components';

export const StyledForm = styled.form`
  flex-grow: 1;
  position: relative;

  svg {
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    fill: ${props => props.theme.color.secondaryText};
    cursor: pointer;
  }

  .search {
    position: absolute;
    left: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    fill: ${props => props.theme.color.secondaryText};
    cursor: pointer;
  }
`;

export const InputStyled = styled.input`
  border-radius: 9000px;
  border: solid 0.1rem ${props => props.theme.color.black};
  height: 4rem;
  line-height: 4rem;
  padding: 0 1.6rem 0 4rem;
  font-size: 1.4rem;
  width: 100%;
  color: ${props => props.theme.color.primaryText};
  -webkit-appearance: none;

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    color: ${p => (p.bold ? p.theme.color.primaryText : p.theme.color.secondaryText)};
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.color.primaryAccent};
  }
`;
