// @flow
import type {ExtractActionTypes} from 'common/redux/types';
import type {ShippingAddress} from 'data/shipping/types';
import type {DateRange} from 'data/units/date/types';

import * as actions from './actions';
import type {Accessory, OriginalReservation} from './types';

export type ReservationsState = {|
  dates: ?DateRange,
  originalReservation: ?OriginalReservation,
  availability: DateRange[],
  accessories: Accessory[],
  shippingAddress: ?ShippingAddress,
|};

const initialState: ReservationsState = {
  dates: undefined,
  originalReservation: undefined,
  availability: [],
  accessories: [],
  shippingAddress: undefined,
};

type Action = ExtractActionTypes<typeof actions>;

function reservationsReducer(
  state: ReservationsState = initialState,
  action: Action
): ReservationsState {
  switch (action.type) {
    case 'RESERVATIONS/PICK_RESERVATION_DATES':
      return {
        ...state,
        dates: action.payload.dates,
      };
    case 'RESERVATIONS/PICK_ORIGINAL_RESERVATION':
      return {
        ...state,
        originalReservation: action.payload.originalReservation,
      };
    case 'RESERVATIONS/PICK_ACCESSORIES':
      return {
        ...state,
        accessories: action.payload.accessories,
      };
    case 'RESERVATIONS/SET_SHIPPING_ADDRESS':
      return {
        ...state,
        shippingAddress: action.payload.shippingAddress,
      };
    case 'RESERVATIONS/SET_AVAILABILITY':
      return {
        ...state,
        availability: action.payload.availability,
      };
    case 'RESERVATIONS/CLEAR_AVAILABILITY':
      return {
        ...state,
        availability: [],
      };
    case 'USER/LOGOUT':
    case 'SEARCH/CLEAR':
    case 'SEARCH/ADD_RECENT_SEARCH':
      return initialState;
    default:
      return state;
  }
}

export default reservationsReducer;
