// @flow
import type {DateRange, DateString} from 'data/units/date/types';
import {path} from 'ramda';

export const setActiveDates = (originalReservation: DateRange) => {
  if (originalReservation)
    return {
      startDate: originalReservation.startDate,
      endDate: undefined,
    };
  return undefined;
};

export const resetDates = (props: any, v?: DateString) => {
  // Clear any error messages
  props.clearError();

  // v or check if extending a reservation
  const startDate = v || path(['originalReservation', 'startDate'], props);

  // Creating reservation and we have start date value
  return props.setValue({
    startDate,
    endDate: undefined,
  });
};
