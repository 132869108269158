// @flow
import TooltipWrapper from 'components/TooltipWrapper';
import {Br} from 'componentsStyled/Typography/Other';
import {Text} from 'componentsStyled/Typography/Texts';
import type {ToolTipContent} from 'data/tooltips/types';
import withOpen from 'hoc/withOpen';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

import {TipWrap, ToolTipContainer} from './styled';
import TipIcon from './TipIcon';

const ToolTip = ({header, content, isOpen, toggleOpen, withButton}) => {
  if (typeof content === 'string') {
    content = {body: content};
  }
  if (!Array.isArray(content.body)) {
    content.body = [content.body];
  }

  return (
    <TooltipWrapper isOpen={isOpen} close={toggleOpen} transparent>
      <ToolTipContainer onClick={toggleOpen}>
        <TipWrap
          active={isOpen}
          header={header}
          withButton={withButton}
          modal={content.modal || false}
        >
          {content.title && (
            <Text inline small bold lh="2">
              {content.title}
              <Br mb={0.9} />
            </Text>
          )}
          {content.body.map((i, key) => (
            <Text inline key={key} small lh="1.8">
              {i}
              <Br mb={0.6} />
            </Text>
          ))}
        </TipWrap>
        <TipIcon />
      </ToolTipContainer>
    </TooltipWrapper>
  );
};

type Outter = {
  header?: string,
  content: ToolTipContent | string,
  withButton?: boolean,
};

const enhancer: HOC<*, Outter> = compose(withOpen);

export default enhancer(ToolTip);
