// @flow
import {Text} from 'componentsStyled/Typography';
import type {OpeningHours} from 'data/affiliate/types';
import React from 'react';

import {Key, OpeningHoursTableWrap, Pair, Val} from '../styled';
import {squashSameOpeningHours} from './helpers';

type Props = {|
  data: OpeningHours,
|};

const Regular = ({data}: Props) => {
  if (!data) return <Text>Not available</Text>;

  return (
    <OpeningHoursTableWrap>
      {squashSameOpeningHours(data).map(x => (
        <Pair key={x.name}>
          <Key nomargin>{x.name}</Key>
          <Val nomargin black>
            {x.v}
          </Val>
        </Pair>
      ))}
    </OpeningHoursTableWrap>
  );
};

export default Regular;
