// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

export const ReservationSuccessWrap = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 4.8rem;

  svg {
    margin-bottom: 3rem;
  }
  ${mq.tabletWide} {
    width: 28.5rem;
    margin: 4.8rem auto 0;
  }
`;
