// @flow
import React from 'react';

const Mastercard = () => (
  <svg width="34" height="24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F79F1A"
        d="M20.048 16.996c-2.73 0-4.944-2.238-4.944-4.998 0-2.76 2.213-4.997 4.944-4.997 2.73 0 4.943 2.237 4.943 4.997s-2.213 4.998-4.943 4.998z"
      />
      <path
        fill="#EA001B"
        d="M18.893 11.998c0 2.76-2.214 4.998-4.944 4.998s-4.944-2.238-4.944-4.998c0-2.76 2.214-4.997 4.944-4.997s4.944 2.237 4.944 4.997z"
      />
      <path
        fill="#FF5F01"
        d="M16.998 8.064a5.007 5.007 0 0 0-1.894 3.934c0 1.597.74 3.02 1.894 3.934a5.009 5.009 0 0 0 1.894-3.934c0-1.597-.74-3.019-1.894-3.934z"
      />
      <rect width="33" height="23" x=".5" y=".5" stroke="#D1DCE7" rx="3" />
    </g>
  </svg>
);

export default Mastercard;
