// @flow
import {mqMax} from 'common/mediaQuery';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  width: calc(100% / 2 - 1rem);
  margin-bottom: 0;

  ${mqMax.phone} {
    width: 100%;
  }
`;

export const Width = styled.div`
  width: ${p => p.width}rem;
`;
