// @flow
import Description from 'components/Description';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {H3, Text} from 'componentsStyled/Typography';
import ModalBody from 'modals/_Body';
import {Body} from 'modals/_Body/styled';
import styled from 'styled-components';

export const Tip = styled(Text)`
  text-align: center;
`;

export const StyledDescription = styled(Description)`
  &.ql-editor {
    padding: 0;
  }
`;

export const DeliveryInfoSection = styled.div`
  margin-top: 3.2rem;

  ${H3} {
    font-weight: normal;
  }
`;

export const StyledModalBody = styled(ModalBody)`
  ${Body} {
    min-height: initial;
  }

  ${Wrap} {
    padding-bottom: 0;
  }
`;
