// @flow
import {errorCodes} from 'common/graphql/errors';
import Button from 'components/Button/index';
import {Container} from 'componentsStyled/Layout/Containers';
import {H1, Text} from 'componentsStyled/Typography';
import urls from 'data/router/urls';
import withRouter from 'hoc/withRouter';
import * as R from 'ramda';
import React from 'react';
import type {HOC} from 'recompose';

const NotFoundPage = ({history}) => {
  return (
    <Container center>
      <H1>Sailing uncharted waters?</H1>
      <Text big center>
        This page was not found, never existed or was removed.
      </Text>
      {/* Hide 'return to home page button' if tenant is not allowed or doesn't exist*/}
      {R.path(['location', 'state', 'error'], history) !== errorCodes.TENANT_NOT_ALLOWED && (
        <Button onClick={() => history.push(urls.home)}>Return to homepage</Button>
      )}
    </Container>
  );
};

const enhancer: HOC<*, {||}> = withRouter;

export default enhancer(NotFoundPage);
