// @flow
import React from 'react';

type Props = {
  colour?: string,
};

const TruckIcon = ({colour = 'currentColor'}: Props) => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 13C8 14.6562 6.65625 16 5 16C3.31562 16 2 14.6562 2 13C0.895313 13 0 12.1031 0 11V2C0 0.895313 0.895313 0 2 0H11C12.1031 0 13 0.895313 13 2V3H14.3344C14.8 3 15.2437 3.18438 15.5719 3.5125L18.4875 6.42812C18.8156 6.75625 19 7.2 19 7.66563V11.5H19.25C19.6656 11.5 20 11.8344 20 12.25C20 12.6656 19.6656 13 19.25 13H18C18 14.6562 16.6562 16 15 16C13.3156 16 12 14.6562 12 13H8ZM11 1.5H2C1.72375 1.5 1.5 1.72375 1.5 2V11C1.5 11.275 1.72375 11.5 2 11.5H2.40125C2.92 10.6031 3.89062 10 5 10C6.10938 10 7.08125 10.6031 7.6 11.5H11C11.275 11.5 11.5 11.275 11.5 11V2C11.5 1.72375 11.275 1.5 11 1.5ZM14.5125 4.57188C14.4656 4.525 14.4031 4.5 14.3344 4.5H13V7.5H17.4375C17.4344 7.49687 17.4312 7.49063 17.4281 7.4875L14.5125 4.57188ZM5 11.5C4.17188 11.5 3.5 12.1719 3.5 13C3.5 13.8281 4.17188 14.5 5 14.5C5.82812 14.5 6.5 13.8281 6.5 13C6.5 12.1719 5.82812 11.5 5 11.5ZM15 14.5C15.8281 14.5 16.5 13.8281 16.5 13C16.5 12.1719 15.8281 11.5 15 11.5C14.1719 11.5 13.5 12.1719 13.5 13C13.5 13.8281 14.1719 14.5 15 14.5Z"
      fill={colour}
    />
  </svg>
);

export default TruckIcon;
