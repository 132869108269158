// @flow
import type {Accessory} from 'data/reservations/types';
import {path} from 'ramda';

type Data = {
  pricingTemplate: {
    listAccessories: Array<{
      name: string,
      prices: Array<number>,
    }>,
  },
  accessory: {
    list: {
      data: Array<{
        name: string,
        preselect: boolean,
      }>,
    },
  },
};

export const mapPreselectToPricingTemplateAccessories = (data: Data): Accessory[] => {
  const reservableAccessories = path(['pricingTemplate', 'listAccessories'], data);
  const allAccessories = path(['accessory', 'list', 'data'], data);
  if (!reservableAccessories || !reservableAccessories.length) {
    return [];
  }

  const transformedData = reservableAccessories.map(reservableAccessory => {
    const matchedAccessory =
      allAccessories &&
      allAccessories.find(accessory => accessory.name === reservableAccessory.name);

    return {
      ...reservableAccessory,
      preselect: matchedAccessory ? matchedAccessory.preselect : false,
    };
  });

  return transformedData;
};
