// @flow
import type {ID} from 'data/enums/types';
import {productDetailQuery} from 'data/product/graphql/queries';
import urls from 'data/router/urls';
import withLocation from 'hoc/withLocation';
import withQuery from 'hoc/withQuery';
import SubPage from 'pages/_Page/SubPage';
import {pathOr} from 'ramda';
import React from 'react';
import {type HOC, compose, withProps} from 'recompose';

import CartForm from './CartForm';

const CartPage = ({data, match, fulfillmentType}) => {
  const variantAffiliate = data.affiliates.find(a => a.id === +match.params.affiliateId);

  if (!variantAffiliate) {
    return null;
  }

  return (
    <SubPage title="Cart" backUrl={urls.product(data.id)}>
      {variantAffiliate && (
        <CartForm
          fulfillmentType={fulfillmentType}
          productVariant={data}
          variantAffiliate={variantAffiliate}
        />
      )}
    </SubPage>
  );
};

type Outter = {|
  match: {|
    params: {|
      productVariantId: ID,
      affiliateId: ID,
    |},
  |},
|};

const enhancer: HOC<*, Outter> = compose(
  withQuery(productDetailQuery, {
    variables: props => ({
      affiliateId: props.match.params.affiliateId,
      productVariantId: props.match.params.productVariantId,
    }),
  }),
  withLocation(() => {}),
  withProps(props => {
    return {
      fulfillmentType: pathOr('IN_STORE', ['location', 'state', 'fulfillmentType'], props),
    };
  })
);

export default enhancer(CartPage);
