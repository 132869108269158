// @flow
import {selectAppConfig} from 'data/app/selectors';
import {openModal} from 'data/modals/actions';
import withConnect from 'hoc/withConnect';
import withOpen from 'hoc/withOpen';
import Login from 'modals/Login';
import SignUp from 'modals/SignUp';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import HomeLink from '../../HomeLink';
import SearchAutocomplete from '../../LoggedInNav/SearchAutocomplete';
import ShoppingBasketLink from '../../ShoppingBasketLink';
import {LeftWrap, RightWrap, SearchWrap, StyledNavLink} from './styled';

const DesktopLoggedOutNav = ({appConfig, login, isOpen, open, close}) => (
  <React.Fragment>
    <LeftWrap>
      <HomeLink />
    </LeftWrap>
    <SearchWrap>
      <SearchAutocomplete initialValue="" isOpen={isOpen} open={open} close={close} />
    </SearchWrap>
    <RightWrap>
      <ShoppingBasketLink />
      <StyledNavLink onClick={login}>Log in</StyledNavLink>
    </RightWrap>
  </React.Fragment>
);

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const mapDispatchToProps = {
  openModal,
};

const enhancer: HOC<*, {||}> = compose(
  withOpen,
  withConnect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    register: props => () => props.openModal(SignUp, {}),
    login: props => () => props.openModal(Login, {}),
  })
);
export default enhancer(DesktopLoggedOutNav);
