// @flow
export type Metadata = {|
  title: string,
  description: string,
|};

export const metadata: {
  ['home' | 'howItWorks' | 'faq' | 'aboutUs']: Metadata,
} = {
  home: {
    title: 'Awayco - book premium outdoor gear at home and away',
    description:
      'Find and ride thousands of surboards, snowboards, skis, SUPs and more at key locations around the world. Enjoy a premium experience without ownership.',
  },
  howItWorks: {
    title: 'How it works - Book, ride, return',
    description:
      "Book your dream gear for your desired destination and dates. Pick up. Enjoy the great outdoors. Sweat. Breathe. Laugh. Fall. Grow. Return whe you're done.",
  },
  faq: {
    title: 'FAQs - Why join?',
    description:
      'With locations all over the world, Awayco lets you travel lighter, experience more and live your greatest passions.',
  },
  aboutUs: {
    title: 'About us - Experience > Ownership',
    description:
      'Awayco is an experience platform that connects you with premium outdoor gear for the moutain, ocean and city.',
  },
};

export const setMetadata = (m: Metadata) => {
  // $ExpectError
  document.head.querySelector('meta[name="title"]').content = m.title;
  // $ExpectError
  document.head.querySelector('meta[name="description"]').content = m.description;
  // $ExpectError
  document.head.querySelector('meta[property="og:title"]').content = m.title;
  // $ExpectError
  document.head.querySelector('meta[property="og:description"]').content = m.description;
};
