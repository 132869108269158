// @flow
import {RadioContext} from 'components/Radio/RadioGroup';
// $ReactHooks
import {useContext} from 'react';

export type Props = {|
  value: any,
  checked?: boolean,
  name?: string,
  onChange?: any => any,
|};

/**
 * To be used within radio button components. Can provide overrides via props, otherwise
 * will return 'checked', 'name' and 'onChange' as provided by nearest RadioContext context.
 * @param props.value - Value of the radio button. used to determine if the radio button should be checked.
 * @param props.checked - Override value. Used to override the returned 'checked' value.
 * @param props.name - Override value. Used to override the returned 'name' value.
 * @param props.onChange - Override value. Used to override the returned 'onChange'
 * @returns {{onChange: (function(): *), name: string, checked: boolean}}
 */
const useRadioGroup = (props: Props) => {
  const contextValues = useContext(RadioContext);

  const getPrioritisedValue = (key: string) =>
    props[key] !== undefined ? props[key] : contextValues[key];

  return {
    checked:
      props.checked !== undefined
        ? props.checked
        : !!contextValues.value && contextValues.value === props.value,
    name: getPrioritisedValue('name'),
    onChange: () => getPrioritisedValue('onChange')(props.value),
  };
};

export default useRadioGroup;
