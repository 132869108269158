// @flow
import {Spread} from 'componentsStyled/Layout/Flex';
import {Text} from 'componentsStyled/Typography/Texts';
import type {Discount} from 'data/reservations/types';
import {formatMoney} from 'data/units/money/formatters';
import React from 'react';

import {calculateDiscount} from '../helpers';

type Props = {
  totalPrice: number,
  fullPrice: number,
  prices: number[],
  discounts: Discount[],
  currency: string,
  tax: number,
};

const ReservationPaymentSummary = ({
  totalPrice,
  fullPrice,
  prices,
  discounts,
  currency,
  tax,
}: Props) => {
  return (
    <React.Fragment>
      <Text bold black>
        Payment Summary
      </Text>
      <Spread>
        <Text>Subtotal</Text>
        <Text>
          {formatMoney(fullPrice, true, currency)} {currency}
        </Text>
      </Spread>
      {discounts.length > 0
        ? discounts.map((discount, i) => {
            if (discount.limitDays && discount.limitDays < 0) {
              return '';
            }

            return (
              <Spread key={i}>
                <Text>Discount</Text>
                <Text>
                  -{formatMoney(calculateDiscount(prices, discount, 0), true, currency)} {currency}
                </Text>
              </Spread>
            );
          })
        : ''}
      <Spread>
        {!!tax && (
          <React.Fragment>
            <Text>Sales Tax</Text>
            <Text>
              {formatMoney(tax, true, currency)} {currency}
            </Text>
          </React.Fragment>
        )}
      </Spread>
      <br />
      <Spread>
        <Text bold black>
          Your Total
        </Text>
        <Text bold black>
          {formatMoney(totalPrice, true, currency)} {currency}
        </Text>
      </Spread>
    </React.Fragment>
  );
};

export default ReservationPaymentSummary;
