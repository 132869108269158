// @flow
import React from 'react';

import {HamburgerToggleWrapper, Line} from './styled';

type Props = {|
  toggle: Function,
|};

const HamburgerToggle = ({toggle}: Props) => (
  <HamburgerToggleWrapper onClick={toggle}>
    <Line />
    <Line />
    <Line />
  </HamburgerToggleWrapper>
);

export default HamburgerToggle;
