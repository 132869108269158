// @flow
import styled, {css} from 'styled-components';

export const Flex = styled.div`
  display: flex;

  ${p =>
    p.end &&
    css`
      justify-content: flex-end;
    `}
`;

export const Spread = styled.div`
  display: flex;
  justify-content: space-between;

  ${p =>
    p.bottom &&
    css`
      align-items: flex-end;
    `}

  ${p =>
    p.middle &&
    css`
      align-items: center;
    `}

  ${p =>
    p.margin &&
    css`
      margin-top: 1rem;
    `}
`;
