// @flow
import {mq} from 'common/mediaQuery';
import {injectGlobal} from 'styled-components';
import styledNormalize from 'styled-normalize';

import type {Theme} from './theme';

export function loadGlobalStyles(
  theme: Theme,
  options?: {|
    externalFontCssUrl?: string,
    bodyFontFamily?: string,
    headingFontFamily?: string,
  |} = {bodyFontFamily: "'Helvetica Neue', sans-serif"}
) {
  const customFontImport = options.externalFontCssUrl
    ? `@import url("${options.externalFontCssUrl}");`
    : '';

  injectGlobal`
${customFontImport}

${styledNormalize};

html {
  font-size: 62.5%;
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 1.6rem;
  color: ${theme.color.primaryText};
  background-color: ${theme.color.background};
  -webkit-font-smoothing: antialiased;

  &, button, input, option, select, textarea {
    font-family: ${options.bodyFontFamily};
  }
}

${
  options.headingFontFamily
    ? `
h1, h2, h3, h4, h5, h6 {
  font-family: ${options.headingFontFamily};
}
`
    : ''
}

.overflowHidden {
  overflow: hidden;
  position: fixed;

  ${mq.tabletWide} {
    overflow: hidden;
    position: initial;
  }
}

a[class] {
  text-decoration: none;
}

button {
  background: none;
  padding: 0;
}

ul {
  padding: 0;
  list-style: none;
}
  `;
}
