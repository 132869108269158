// @flow
import Activities from 'components/Activities';
import {chooseActivity} from 'data/app/actions';
import {selectActivity} from 'data/app/selectors';
import type {Activity} from 'data/app/types';
import {listCountriesPerCategoryQuery} from 'data/locations/queries';
import type {CategoryCountryAggregator} from 'data/locations/types';
import urls from 'data/router/urls';
import type {User} from 'data/user/types';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import withUser from 'hoc/withUser';
import SpecialPage from 'pages/_Page/SpecialPage';
import React from 'react';
import {Redirect} from 'react-router-dom';
import {type HOC, compose} from 'recompose';

import {getCategories, getLocationsNumberPerCategory} from './helpers';
import {StyledTitle} from './styled';

type Props = {
  activity: Activity,
  chooseActivity: typeof chooseActivity,
  data: CategoryCountryAggregator[],
  user: User,
};

const ExplorePage = ({activity, chooseActivity, data, user}: Props) => {
  if (activity) return <Redirect to={urls.products} />;

  const locationsPerActivity = getLocationsNumberPerCategory(data);
  const categories = getCategories(locationsPerActivity);

  return (
    <SpecialPage fixed>
      <StyledTitle>Explore awayco</StyledTitle>
      <br />
      <br />
      <StyledTitle>Ocean</StyledTitle>
      <Activities onChange={chooseActivity} user={user} activity={categories.ocean} />
      <br />
      <br />
      <StyledTitle>Mountain</StyledTitle>
      <Activities onChange={chooseActivity} user={user} activity={categories.mountain} />
      <br />
      <br />
      <StyledTitle>Wheels</StyledTitle>
      <Activities onChange={chooseActivity} user={user} activity={categories.wheels} />
    </SpecialPage>
  );
};

const mapStateToProps = state => ({
  activity: selectActivity(state),
});

const mapDispatchToProps = {
  chooseActivity,
};

const enhancer: HOC<*, Props> = compose(
  withUser(),
  withConnect(mapStateToProps, mapDispatchToProps),
  withQuery(listCountriesPerCategoryQuery)
);

export default enhancer(ExplorePage);
