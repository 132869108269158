// @flow
import {HPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {path} from 'ramda';
import * as React from 'react';

import {TabSwitch, TabSwitchLink} from './styled';

type Tab = {content: React.Node, title: React.Node};

type Props = {|
  tabs: Tab[],
  limitSwitchWidth?: boolean,
  centerSwitch?: boolean,
  fullWidthTabs?: boolean,
  currentTabIndex: number,
  onCurrentTabIndexChange: (index: number) => void,
|};

const Tabs = ({
  tabs,
  currentTabIndex = 0,
  onCurrentTabIndexChange,
  limitSwitchWidth,
  fullWidthTabs,
  centerSwitch,
}: Props) => {
  const SwitchWrap = limitSwitchWidth ? HPaddedFullWidthContainer : React.Fragment;

  const activeContent = path([currentTabIndex, 'content'], tabs);

  return (
    <React.Fragment>
      <SwitchWrap>
        <TabSwitch fullWidthTabs={fullWidthTabs} centerSwitch={centerSwitch}>
          {tabs.map((tab, i) => (
            <TabSwitchLink
              key={i}
              active={currentTabIndex === i}
              onClick={() => onCurrentTabIndexChange(i)}
              data-cy={'tab-switch-link'}
            >
              {tab.title}
            </TabSwitchLink>
          ))}
        </TabSwitch>
      </SwitchWrap>
      {activeContent}
    </React.Fragment>
  );
};

export default Tabs;
