// @flow
import AnimatedTickIcon from 'assets/icons/TickIcon/animated';
import {RadioPillContainer} from 'components/Radio/RadioPill/styled';
import {storeSelectWrapperCSS} from 'components/StoreSelect/styled';
import styled from 'styled-components';

export const StoreSelectRadioButtonContainer = styled.div`
  ${RadioPillContainer} {
    ${storeSelectWrapperCSS};
  }
`;

export const StyledAnimatedTickIcon = styled(AnimatedTickIcon)`
  color: ${props => props.theme.color.primaryAccent};
`;
