// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import styled from 'styled-components';

export const MobileHeaderWrap = styled.header`
  position: fixed;
  width: 100%;
  z-index: ${props => props.theme.zIndices.header};
  background: ${props => props.theme.color.background};
`;

export const HeaderInner = styled.div`
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageInner = styled.div`
  padding-top: 1.6rem;
`;

export const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Wrap = styled.div`
  width: ${props => props.width || '100%'};
`;

export const FooterWrap = styled.footer``;
