// @flow
import Loader from 'components/Loader';
import * as React from 'react';

import {ButtonContent, ButtonLabel, CenterWrap, StyledButton} from './styled';

type Props = {|
  children: React.Node,
  onClick?: mixed => mixed,
  loading?: boolean,
  done?: boolean,
  // styled props
  fullwidth?: boolean,
  disabled?: boolean,
  small?: boolean,
  large?: boolean,
  secondary?: boolean,
  type?: string,
  id?: string,
  'data-cy'?: string,
|};

const getStatusIcon = (props: Props) => {
  if (props.loading) {
    return (
      <CenterWrap>
        <Loader white={!props.secondary} small />
      </CenterWrap>
    );
  }
  return null;
};

const Button = (props: Props) => (
  <StyledButton {...props}>
    <ButtonContent>
      {getStatusIcon(props)}
      <ButtonLabel isHidden={props.done || props.loading}>{props.children}</ButtonLabel>
    </ButtonContent>
  </StyledButton>
);

export default Button;
