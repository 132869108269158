// @flow
import {min, times} from 'ramda';

export const getBasicPricingHelper = (
  priceTemplate: Array<number>,
  length: number
): Array<number> => {
  if (!priceTemplate || !length) {
    return [];
  }

  // number of days we can iterate for pricing
  const maximumDays = min(length, priceTemplate.length);
  const prices = times((index: number) => priceTemplate[index], maximumDays);

  // copy value from the last day as many times as needed
  if (length > maximumDays) {
    const lastDayPricing = prices[prices.length - 1];
    const remainingDays = length - maximumDays;
    times(() => prices.push(lastDayPricing), remainingDays);
  }

  return prices;
};
