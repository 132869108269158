// @flow
import type {Product} from 'data/product/types';
import withEmpty from 'hoc/withEmpty';
import withLoader from 'hoc/withLoader';
import React from 'react';
import {type HOC, compose} from 'recompose';

import ProductTile from './ProductTile';
import {ProductListWrap} from './styled';

/**
 * - bundles and products inline togethor
 * - pricing display for bundles (aggregate of components)
 * - displaying of bundles different affiliates (product filter for bundles)
 */
const ProductList = ({productOfferings, maxTilesPerRow, showPrices, tileQueryParams}) => {
  return (
    <ProductListWrap>
      {productOfferings.map(productOffering => (
        <ProductTile
          key={productOffering.id}
          productOffering={productOffering}
          maxTilesPerRow={maxTilesPerRow}
          showPrices={showPrices}
          queryParams={tileQueryParams}
        />
      ))}
    </ProductListWrap>
  );
};

type Outter = {|
  loading?: boolean,
  productOfferings: Product[],
  maxTilesPerRow?: number,
  showPrices?: boolean,
  tileQueryParams?: string,
|};

const enhancer: HOC<*, Outter> = compose(
  withLoader(props => !!props.loading),
  withEmpty(props => !props.productOfferings || !props.productOfferings.length)
);

export default enhancer(ProductList);
