// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import React from 'react';

import PlaceIcon from './PlaceIcon';
import {AffiliateNameWrap} from './styled';

type Props = {|
  name: string,
|};

const AffiliateName = ({name}: Props) => (
  <AffiliateNameWrap>
    <PlaceIcon />
    {/* TODO - maybe do this in admin instead? or perhaps we keep it just for mobile */}
    <Text>{name.replace('San Francisco', 'SF')}</Text>
  </AffiliateNameWrap>
);

export default AffiliateName;
