// @flow strict-local
import ProductPreview from 'components/ProductPreview';
import ReservationPricing from 'components/ReservationPricing';
import {VPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {FixedBottom, FixedBottomSpacer} from 'componentsStyled/Layout/FixedBottom';
import {Wrap, WrapGrouper} from 'componentsStyled/Layout/Wrap';
import {Note} from 'componentsStyled/Typography/Other';
import {Text} from 'componentsStyled/Typography/Texts';
import {selectAppConfig} from 'data/app/selectors';
import type {ProductVariant, ProductVariantAvailableAffiliate} from 'data/product/types';
import {selectDates, selectOriginalReservation} from 'data/reservations/selectors';
import urls from 'data/router/urls';
import {clear} from 'data/search/actions';
import {getPricePerDayArray} from 'data/units/money/helpers';
import withConnect from 'hoc/withConnect';
import withUser from 'hoc/withUser';
import Confirmation from 'pages/ShoppingBasket/Confirmation';
import {equals, groupWith, sum} from 'ramda';
import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {type HOC, compose, withHandlers, withProps, withState} from 'recompose';

import {getRentalAgreementForClient} from '../../../common/functions';
import CollapsibleParagraph from '../../../components/CollapsibleParagraph';
import ConfirmExtension from './ConfirmExtension';
import Dates from './Dates';

const CheckoutForm = ({
  appConfig,
  productVariant,
  dates,
  variantAffiliate,
  loading,
  user,
  originalReservation,
  hasTermsAndConditions,
  toggleTermsAndConditions,
}) => {
  if (!dates) {
    return <Redirect to={urls.products} />;
  }

  const pricingInput = {
    productVariantId: productVariant.id,
    affiliateId: variantAffiliate.affiliateId,
    start: dates.startDate,
    end: dates.endDate,
  };

  const availability = {
    product: productVariant,
    user: user,
    selectedDates: {
      startDate: dates.startDate,
      endDate: dates.endDate,
    },
    affiliate: variantAffiliate.name,
  };

  // TODO: handle multiple categories
  const category = productVariant.product.categories[0];

  const alertUntickedConditions = () => {
    alert('To proceed with the checkout, please read and then accept the Rental Agreement');
  };

  return (
    <VPaddedFullWidthContainer narrow data-cy={'checkout-page'}>
      <WrapGrouper>
        <Text bold black>
          Booking details
        </Text>
        <Wrap>
          <Dates dates={dates} originalReservation={originalReservation} />
        </Wrap>
        <Wrap>
          <ProductPreview productVariant={productVariant} affiliateName={variantAffiliate.name} />
        </Wrap>
      </WrapGrouper>
      <WrapGrouper>
        <Text bold black>
          Total
        </Text>
        <Wrap>
          <ReservationPricing
            input={pricingInput}
            originalReservation={originalReservation}
            availability={availability}
            categoryName={category.name}
            user={user}
            summary={true}
          />
        </Wrap>
      </WrapGrouper>
      <WrapGrouper>
        <Note>
          You won’t be charged if you cancel your reservation{' '}
          <Text inline book small>
            up to 48 hours
          </Text>{' '}
          in advance.
        </Note>
      </WrapGrouper>
      <WrapGrouper>
        <CollapsibleParagraph group="Rental Agreement">
          <Wrap>
            <Text>{getRentalAgreementForClient(appConfig.tenantTitle)}</Text>
          </Wrap>
        </CollapsibleParagraph>
        <Confirmation
          hasTermsAndConditions={hasTermsAndConditions}
          toggleTermsAndConditions={toggleTermsAndConditions}
        />
      </WrapGrouper>
      <FixedBottomSpacer />
      <FixedBottom>
        <ConfirmExtension
          loading={loading}
          originalReservation={originalReservation}
          notAccepted={!hasTermsAndConditions}
          errorFunction={alertUntickedConditions}
        />
      </FixedBottom>
    </VPaddedFullWidthContainer>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
  dates: selectDates(state),
  originalReservation: selectOriginalReservation(state),
});

type Outter = {|
  productVariant: ProductVariant,
  variantAffiliate: ProductVariantAvailableAffiliate,
|};

const mapDispatchToProps = {
  clear,
};

const enhancer: HOC<*, Outter> = compose(
  withUser(),
  withConnect(mapStateToProps, mapDispatchToProps),
  withState('hasTermsAndConditions', 'setHasTermsAndConditions', false),
  withHandlers({
    toggleTermsAndConditions:
      ({setHasTermsAndConditions}) =>
      () =>
        setHasTermsAndConditions(isAccepted => !isAccepted),
  }),
  withProps(({dates, variantAffiliate}) => {
    const prices = getPricePerDayArray(dates, variantAffiliate);
    return {
      priceGroups: groupWith(equals, prices),
      total: sum(prices),
    };
  })
);

export default enhancer(CheckoutForm);
