// @flow
import StyledLink from 'components/StyledLink';
import {Text} from 'componentsStyled/Typography/Texts';
import styled from 'styled-components';

export const DestinationWrap = styled.div`
  display: table;
  max-width: 15rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 0.5rem 0.5rem 3.5rem 0.5rem;
`;

export const StyledText = styled(Text)`
  margin: 0 0 0.5rem 0;
`;

export const LocationLink = styled(StyledLink)`
  color: ${props => props.theme.color.secondaryText};
`;
