import gql from 'graphql-tag';

import type {Query} from '../../../common/graphql/types';
import type {LegalRentalAgreementContent} from './types';

export const getLegalRentalAgreementContentQuery: Query<LegalRentalAgreementContent> = {
  gql: gql`
    query getLegalRentalAgreementContentQuery {
      legal {
        getLegal {
          content: rentalAgreement
          lastUpdated
        }
      }
    }
  `,
  selector: ['legal', 'getLegal'],
};
