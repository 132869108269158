// @flow
import {Flex, Spread} from 'componentsStyled/Layout/Flex';
import {Hr} from 'componentsStyled/Typography/Other';
import {Text} from 'componentsStyled/Typography/Texts';
import {FieldTitle} from 'componentsStyled/Typography/Titles';
import type {ReservationPricingQueryInput} from 'data/reservations/graphql/queries';
import type {Accessory, Discount, OriginalReservation} from 'data/reservations/types';
import {formatMoney} from 'data/units/money/formatters';
import {equals, groupWith, sum} from 'ramda';
import React from 'react';

import {calculateDiscount} from '../helpers';

type Props = {
  accessories: Accessory[],
  totalPrice: number,
  fullPrice: number,
  prices: number[],
  discounts: Discount[],
  currency: string,
  originalReservation?: ?OriginalReservation,
  input?: ReservationPricingQueryInput,
  categoryName: string,
  tax?: number,
};

const ReservationPricingDetail = ({
  accessories,
  totalPrice,
  fullPrice,
  prices,
  discounts,
  currency,
  originalReservation,
  categoryName,
  tax,
}: Props) => {
  const priceGroups = groupWith(equals, prices || []);

  return (
    <React.Fragment>
      <Text bold black>
        {originalReservation ? 'Extension ' : ''}Payment details
      </Text>
      <FieldTitle>{categoryName}</FieldTitle>
      {priceGroups.map((g, i) => (
        <Spread key={i}>
          <Text>
            {formatMoney(g[0], false, currency)}
            &nbsp;x {g.length} day{g.length > 1 ? 's' : ''}
          </Text>
          <Text>{formatMoney(sum(g), true, currency)}</Text>
        </Spread>
      ))}
      <br />
      {accessories
        ? accessories.map((a, i) => {
            // $Ramda
            const accessoryPrices = groupWith(equals, a.prices || []);

            return (
              <div key={a.name}>
                <FieldTitle>{a.name}</FieldTitle>
                {accessoryPrices.map((p, j) => (
                  <Spread key={a.name + j}>
                    <Text>
                      {formatMoney(p[0], false, currency)}
                      &nbsp;x {p.length} day{p.length > 1 ? 's' : ''}
                    </Text>
                    <Text>{formatMoney(sum(p), true, currency)}</Text>
                  </Spread>
                ))}
                <br />
              </div>
            );
          })
        : ''}
      {discounts && discounts.length > 0 ? (
        <React.Fragment>
          <Hr />
          <Spread>
            <Text>Subtotal</Text>
            <Text>{formatMoney(fullPrice, true, currency)}</Text>
          </Spread>
        </React.Fragment>
      ) : (
        ''
      )}
      {discounts && discounts.length > 0
        ? discounts.map((discount, i) => {
            if (discount.limitDays && discount.limitDays < 0) {
              return '';
            }

            return (
              <Spread key={i}>
                <Text>Discount</Text>
                <Text>-{formatMoney(calculateDiscount(prices, discount, 0), true, currency)}</Text>
              </Spread>
            );
          })
        : ''}
      {tax ? (
        <React.Fragment>
          <FieldTitle>Sales Tax</FieldTitle>
          <Flex end>
            <Text>{formatMoney(tax, true, currency)}</Text>
          </Flex>
        </React.Fragment>
      ) : null}
      <br />
      <Spread data-cy={'total-price'}>
        <Text bold black>
          Your Total
        </Text>
        <Text bold black>
          {formatMoney(totalPrice, true, currency)}
        </Text>
      </Spread>
    </React.Fragment>
  );
};

export default ReservationPricingDetail;
