// @flow
import {Text} from 'componentsStyled/Typography';
import React from 'react';

const Empty = () => (
  <Text center nomargin>
    No results available.
  </Text>
);

export default Empty;
