// @flow
import styled from 'styled-components';

export const ConfirmationStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: unset;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0 auto;
`;

export const CheckboxWrap = styled.div`
  margin-right: 1rem;
`;
