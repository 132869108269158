// @flow
import {DeliverySummaryContainer, DetailsGroup} from 'components/DeliverySummary/styled';
import type {DeliveryDestination} from 'data/bookings/types';
import type {User} from 'data/user/types';
import withUser from 'hoc/withUser';
import {path} from 'ramda';
import React from 'react';
import type {HOC} from 'recompose';

export type Outter = {
  deliveryDestination: DeliveryDestination,
  addressTitle?: string,
  className?: string,
};

export type Props = {
  ...Outter,
  user: User,
};

const DeliverySummary = ({
  deliveryDestination,
  user,
  addressTitle = 'Delivery address',
  className,
}) => {
  if (!deliveryDestination) {
    return null;
  }

  const {name, address, instructions} = deliveryDestination;

  const additionalPhone = path(['additionalContact', 'phone'], deliveryDestination);
  const additionalEmail = path(['additionalContact', 'email'], deliveryDestination);

  const phone = path(['profiles', 0, 'phoneNumber'], user);
  const email = user.email;

  return (
    <DeliverySummaryContainer data-cy="delivery-summary" className={className}>
      <DetailsGroup>
        <p>{addressTitle}</p>
        <p data-cy="name">{name}</p>
        <p data-cy="address">{address}</p>
      </DetailsGroup>
      <DetailsGroup>
        <p>Contact details</p>
        <p data-cy="email">{email}</p>
        <p data-cy="phone">{phone}</p>
      </DetailsGroup>
      {(additionalPhone || additionalEmail) && (
        <DetailsGroup>
          <p>Additional contact</p>
          {additionalEmail && <p data-cy="additional-email">{additionalEmail}</p>}
          {additionalPhone && <p data-cy="additional-phone">{additionalPhone}</p>}
        </DetailsGroup>
      )}
      {instructions && (
        <DetailsGroup>
          <p>Delivery instructions</p>
          <p data-cy="instructions">{instructions}</p>
        </DetailsGroup>
      )}
    </DeliverySummaryContainer>
  );
};

const enhancer: HOC<*, Outter> = withUser();

export default enhancer(DeliverySummary);
