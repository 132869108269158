// @flow
import styled, {css} from 'styled-components';

const transitionSpeed = '200ms';
const transitionTimingFn = 'ease-in-out';

export const RadioPillContainer = styled.label`
  position: relative;
  border: 1px solid ${props => props.theme.color.neutralGray};
  color: ${props => props.theme.color.neutralGrayDarker}
  border-radius: 0.25em;
  padding: 0.25em 0.75em;
  cursor: pointer;

  transition: background ${transitionSpeed} ${transitionTimingFn}, color ${transitionSpeed} ${transitionTimingFn};

  &:focus-within {
    border-color: ${props => props.theme.color.primaryAccent};
  }

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        border-color: ${props => props.theme.color.neutralGrayDarker};
      }
    `}
  
  ${props =>
    props.disabled &&
    css`
      background: ${props => props.theme.color.neutralGrayLight};
      color: ${props => props.theme.color.neutralGrayDark};
      cursor: default;
    `}

  ${props =>
    props.checked &&
    css`
      border-color: ${props.theme.color.primaryAccent};
      background: ${props.theme.color.primaryAccent};
      color: ${props.theme.color.white};

      &:hover {
        border-color: ${props => props.theme.color.primaryAccentDark};
        background: ${props => props.theme.color.primaryAccentDark};
      }

      ${props =>
        props.disabled &&
        css`
          opacity: 0.5;
        `}
    `}
`;

export const HiddenRadioInput = styled.input`
  appearance: none;
  position: absolute;
`;
