// @flow
import {mqMax} from 'common/mediaQuery';
import styled, {css} from 'styled-components';

export const TabSwitch = styled.div`
  display: flex;
  margin-top: 2.5rem;

  ${p =>
    p.centerSwitch &&
    css`
      justify-content: center;
    `}

  ${p =>
    p.fullWidthTabs &&
    css`
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;

      ${TabSwitchLink} {
        margin-right: 0;
        display: flex;
        justify-content: center;
      }
    `}
`;

export const TabSwitchLink = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 2.5rem;
  font-size: 1.4rem;
  color: ${props => props.theme.color.secondaryText};
  font-weight: ${props => props.theme.fontWeights.book};
  letter-spacing: 0.05rem;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-right: 0;
  }

  &::after {
    content: '';
    left: 0;
    right: 0;
    bottom: -0.3rem;
    height: 0.2rem;
    background: ${props => props.theme.color.primaryAccent};
    transition: transform 0.25s ease;
    transform: scaleX(0);
    position: absolute;
  }

  ${p =>
    p.active &&
    css`
      color: ${props => props.theme.color.primaryText};
      cursor: default;

      &::after {
        transform: scaleX(1);
      }
    `}
  ${mqMax.phone} {
    margin-right: 1.2rem;
  }
`;
