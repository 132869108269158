// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import * as React from 'react';

import {AffiliateItemWrap, AffiliateLink, ImageWrap} from './styled';

type Props = {|
  children: React.Node,
  imgUrl: string,
  to: string,
|};

const AffiliateItem = ({children, imgUrl, to}: Props) => (
  <AffiliateLink to={to} draggable="false">
    <AffiliateItemWrap>
      <ImageWrap>
        <img src={imgUrl} alt="Store" draggable="false" />
      </ImageWrap>
      <Text black medium center lh={2}>
        {children}
      </Text>
    </AffiliateItemWrap>
  </AffiliateLink>
);

export default AffiliateItem;
