// @flow
import StyledLink from 'components/StyledLink';
import styled, {css} from 'styled-components';

export const SidebarWrap = styled.div`
  position: fixed;
  top: 0;
  right: 100%;
  width: 100vw;
  height: 100vh;
  padding: 32px 24px;

  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.black};
  transition: transform 0.25s ease;

  ${p =>
    p.isOpen &&
    css`
      transform: translateX(100%);
    `}
`;

export const Header = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const Link = styled(StyledLink)`
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeights.normal};
  text-align: left;
  border: 0;
  color: ${props => props.theme.color.white};

  &.active {
    opacity: 0.5;
  }

  & + & {
    margin-top: 16px;
  }
`;

export const Links = styled.div`
  margin-bottom: 32px;
`;

export const ExternalLink = styled(Link)`
  font-weight: ${props => props.theme.fontWeights.bold};
`;
