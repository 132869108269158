import type {Query} from 'common/graphql/types';
import {ReservationPricingForAffiliate} from 'data/reservations/types';
import gql from 'graphql-tag';

export const estimateGroupingForReservationQuery: Query<ReservationPricingForAffiliate[]> = {
  gql: gql`
    query estimateGroupingForReservationQuery($reservations: [OrderEstimationReservationInput!]!) {
      order {
        estimateGroupingForReservations(reservations: $reservations) {
          affiliate {
            id
            name
            deliveryExplanation
          }
          deliveryReservations {
            date
            reservations {
              productVariantId
              affiliateId
              start
              end
              pricing {
                fullPrice
                totalPrice
                totalDays
                currency
                accessories {
                  prices
                  name
                }
              }
            }
            subTotal
            deliveryFee
            currency
            tax
          }
          inStoreReservations {
            date
            reservations {
              productVariantId
              affiliateId
              start
              end
              pricing {
                fullPrice
                totalPrice
                totalDays
                currency
                accessories {
                  prices
                  name
                }
              }
            }
            subTotal
            currency
            tax
          }
          total
        }
      }
    }
  `,
  selector: ['order', 'estimateGroupingForReservations'],
};
