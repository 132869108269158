// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

export const AccessoriesSelection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.4rem 3rem;
  cursor: pointer;

  ${mq.tabletWide} {
    border: 0;
    padding: 1rem 1.6rem;

    & > *:first-child {
      margin-right: 2rem;
    }
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const PriceText = styled.div`
  display: inline;
  color: ${p => (p.active ? p.theme.color.primaryAccentDark : p.theme.color.secondaryText)};
  font-size: 1.3rem;
`;
