// @flow
// $Import
import * as React from 'react';

export type Props = {
  size?: number,
  className?: string,
  style?: Object,
};

const BannedIcon = ({size = 24, className, style}: Props) => (
  <svg
    width={size}
    height={size}
    style={style}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12C22 17.5234 17.5234 22 12 22C6.47656 22 2 17.5234 2 12C2 6.47656 6.47656 2 12 2C17.5234 2 22 6.47656 22 12ZM5.63008 6.95703C4.53125 8.33984 3.875 10.0938 3.875 12C3.875 16.4883 7.51172 20.125 12 20.125C13.9062 20.125 15.6602 19.4688 17.043 18.3711L5.63008 6.95703ZM20.125 12C20.125 7.51172 16.4883 3.875 12 3.875C10.0938 3.875 8.33984 4.53125 6.95703 5.63008L18.3711 17.043C19.4688 15.6602 20.125 13.9062 20.125 12Z"
      fill="#1A202C"
    />
  </svg>
);

export default BannedIcon;
