import styled from 'styled-components';

export const DeliverySummaryContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const DetailsGroup = styled.div`
  p {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.8rem;

    &:first-child {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 0.5rem;
    }
  }
`;
