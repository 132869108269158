import styled from 'styled-components';

/* TODO(t04435): this file is DUPLICATED FROM ADMIN soo them in sync until a solution to share components is added */
const COUNTER_RESET_PREFIX = 'reset_';
const MAX_COUNTER = 8;

/**
 * Concat css counters in the form of #.#.#
 * @param index: the current index
 * @returns {string} joined counters starting on the top index(list) and ending on a dot(.)
 */
const getCountList = (index: number) => {
  // top level counters
  let list = `counters(list , ".")`;
  // add extra counters based on index
  for (let i = 1; i <= index; i++) {
    list += ` "." counters(${COUNTER_RESET_PREFIX}${i}, ".")`;
  }
  // end with a dot(.)
  list += ' ". "';
  return list;
};

/**
 * The {@link TextEditor} component generates html list in the for of:
 * <ol | ul>
 *   <li>content</li>
 *   <li class="ql-indent-[number]">content</li> // number from 1 - 8
 * <ol | ul>
 * the goal of the class: ql-indent-# varies depending on the parent:
 * ul: it only adds indentation which is common for ol as well
 * ol: ol > li do not support by default the new number index ie:
 *    1
 *    2
 *      2.1 <= not supported see: https://stackoverflow.com/q/4098195/4374291
 *
 *  So this function solves that issue by concatenating multiple counters() see docs:
 *  https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Lists_and_Counters/Using_CSS_counters
 */
const htmlLiStyles = () => {
  let liStyles = `
    ol {
      margin-left: -2rem;
      list-style-type: none;
      counter-reset: list;
      
      // set counter on top level li
      & > li:not([class^="ql-indent"]) {
        counter-reset: ${COUNTER_RESET_PREFIX}1;
        &:before {
          counter-increment: list;
          content: counters(list, ".") ". ";
        }
      }
    }
  `;
  for (let i = 1; i <= MAX_COUNTER; i++) {
    liStyles += `
    // add indentation based on each level
    li.ql-indent-${i} {
      margin-left: calc(3.75rem * ${i});
    }
    
    ol li {
      &:before {
        font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
      }
      
      // add counters for each level
      &.ql-indent-${i} {
        // reset the counter for the next level
        counter-reset: ${COUNTER_RESET_PREFIX}${i + 1};
        &:before {
          // increment current counter
          counter-increment: ${COUNTER_RESET_PREFIX}${i};
          // set content to all previous counter and current
          content: ${getCountList(i)};
        }
      }
    }
    `;
  }

  return liStyles;
};

export const HtmlViewerWrapper = styled.div`
  width: 100%;
  ${htmlLiStyles()};
  img {
    max-width: 100%;
  }
  p {
    font-weight: normal;
  }
  strong {
    font-weight: bold;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-justify {
    text-align: justify;
  }
`;

export const RawHtml = styled.pre`
  width: 100%;
  border: 2px dashed tomato;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
`;
