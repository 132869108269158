import React from 'react';

import {VariantVariableLabel, VariantVariableOption, VariantVariableWrap} from './styled';

type Props = {
  variantVariableLabels: String,
  variantNames: String,
};

const VariantVariablesDisplay = ({variantVariableLabels, variantItemNames}: Props) => {
  const variantVariableLabelsList = variantVariableLabels.split(' | ');
  const variantNames = variantItemNames.split(' | ');
  const labelLength = variantVariableLabelsList.length;
  const variantLength = variantNames.length;

  const maxLengthArray = Array(Math.max(labelLength, variantLength)).fill('');

  return (
    <React.Fragment>
      {maxLengthArray.map((_, index) => {
        return (
          <VariantVariableWrap>
            <VariantVariableLabel>
              {index < labelLength ? variantVariableLabelsList[index] : '-'}
            </VariantVariableLabel>
            <VariantVariableOption>
              {index < variantLength ? variantNames[index] : '-'}
            </VariantVariableOption>
          </VariantVariableWrap>
        );
      })}
    </React.Fragment>
  );
};

export default VariantVariablesDisplay;
