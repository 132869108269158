// @flow
import {isNil} from 'ramda';

const currencySymbols = {
  USD: '$',
  CAD: '$',
  AUD: '$',
  NZD: '$',
  EUR: '€',
  JPY: '¥',
  GBP: '£',
  GIP: '£',
  FKP: '£',
  SHP: '£',
  CHF: 'CHF',
  BRL: 'R$',
  NOK: 'kr',
  SEK: 'kr',
  DKK: 'kr',
  BGN: 'лв',
  HRK: 'kn',
  RON: 'lei',
  CZK: 'Kč',
  HUF: 'Ft',
  PLN: 'zł',
  ALL: 'Lek',
  BYN: 'Br',
  BAM: 'KM',
  ISK: 'kr',
  MDL: 'L',
  MKD: 'Ден',
  RUB: '₽',
  RSD: 'дин',
  UAH: '₴',
  SGD: '$',
  ZAR: 'R',
};

const specialCurrencies = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

export const getSymbol = (currency?: string) => {
  if (!currency || !currencySymbols[currency]) {
    return currencySymbols.USD;
  }

  return currencySymbols[currency];
};

export const formatMoney = (m: ?number, fixed?: boolean, currency?: string): ?string => {
  if (isNil(m)) return undefined;

  const isNatural = (m / 100) % 1 === 0;
  const currencySymbol = getSymbol(currency);

  if ((fixed || !isNatural) && !specialCurrencies.includes(currency)) {
    return `${currencySymbol}${(m / 100).toFixed(2)}`;
  }

  return `${currencySymbol}${m / 100}`;
};

//Returns the Min and Max price for a pricing array as a tuple [min, max]
export const getPriceRange = (pricing: number[]): [number, number] => {
  if (!pricing || pricing.length === 0) {
    return [0, 0];
  }
  return [Math.min(...pricing), Math.max(...pricing)];
};

export const formatPricing = (pricing: number[], currency?: string): ?string => {
  const currencyString = currency ? currency : '';

  if (pricing.length === 1) {
    const valueString = formatMoney(pricing[0], false, currency);
    return `${valueString ? valueString : ''} ${currencyString}`;
  }
  return `${getPriceRange(pricing)
    .map(x => formatMoney(x, false, currency))
    .join(' - ')} ${currencyString}`;
};
