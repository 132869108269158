// @flow
import type {Location} from 'data/app/types';
import {uniqBy} from 'ramda';
// $ReactHooks
import {useMemo} from 'react';

// Need $ReadOnlyArray otherwise flow gets very upset about passing in Exact types
const useUniqueCountriesAcrossAffiliates = (affiliates?: $ReadOnlyArray<{location: Location}>) => {
  return useMemo(() => {
    if (!affiliates || affiliates.length === 0) {
      return [];
    }
    const countries = affiliates.map(affiliate => affiliate.location.country);
    return uniqBy(country => country.code, countries);
  }, [affiliates]);
};

export default useUniqueCountriesAcrossAffiliates;
