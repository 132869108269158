// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

export const SocialStyledButton = styled.button`
  height: 5rem;
  width: 16.5rem;
  max-width: calc(50% - 0.5rem);
  border-radius: 0.5rem;
  font-size: 1.4rem;
  color: #fff;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq.tablet} {
    width: 18.5rem;
  }

  svg {
    margin-right: 1rem;
  }

  background: ${p => (p.t === 'facebook' ? '#3b5998' : ' #ea4335')};
`;
