// @flow
import {Container} from 'componentsStyled/Layout/Containers';
import type {RouterProps} from 'data/router/types';
import {claimMasquerade} from 'data/user/actions';
import {claimMasquerade as claimMasqueradeQuery} from 'data/user/graphql/queries';
import type {User} from 'data/user/types';
import withConnect from 'hoc/withConnect';
import withOnMount from 'hoc/withOnMount';
import withQuery from 'hoc/withQuery';
import withRouter from 'hoc/withRouter';
import React from 'react';
import {type HOC, compose} from 'recompose';

const MasqueradePage = ({data}) => (
  <Container center>
    <h1>Putting on your disguise</h1>
    <div>
      <dl>
        <dt>{data.role}</dt>
        <dd>{data.email}</dd>
      </dl>
    </div>
  </Container>
);

type Outter = {
  user: User,
  claimToken: string,
} & RouterProps;

const enhancer: HOC<*, Outter> = compose(
  // withUser(),
  withRouter,
  withConnect(() => ({}), {claimMasquerade}),
  withQuery(claimMasqueradeQuery, {
    variables: props => props.match.params,
  }),
  withOnMount(props => {
    console.log(props);
    setTimeout(() => {
      props.claimMasquerade(props.data);
    }, 1600);
  })
);

export default enhancer(MasqueradePage);
