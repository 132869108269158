// @flow
import React from 'react';

import SettingContent from '../../SettingContent';
import Content from './Content';

const Payments = () => (
  <SettingContent title="Payments">
    <Content />
  </SettingContent>
);

export default Payments;
