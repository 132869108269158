// @flow
import {identity, isNil, path} from 'ramda';

export const getDataFromSelector = (selector: string[]) => path(['data'].concat(selector));

export const transformResponse =
  (selector: string[] = [], transform?: Function = identity) =>
  (data: Object) => {
    const selectedData = getDataFromSelector(selector)(data);
    return isNil(selectedData) ? undefined : transform(selectedData);
  };
