// @flow

import {compose, path} from 'ramda';
// $ReactHooks
import React, {useEffect, useState} from 'react';

import Button from '../../components/Button';
import {markItemAsInbound} from '../../data/orders/graphql/mutations';
import urls from '../../data/router/urls';
import withLoader from '../../hoc/withLoader';
import withMutation from '../../hoc/withMutation';
import withRouter from '../../hoc/withRouter';
import {FollowUpText, Heading, ReturnConfirmationWrapper, Underline} from './styled';

type Props = $Exact<{
  onReturn(): void,
  confirmReturn({token: string}): Promise<void>,
  token: string | null,
}>;

const ReturnConfirmationMessage = ({errorMsg, onReturn}: {errorMsg: string, onReturn(): void}) => (
  <ReturnConfirmationWrapper>
    <Heading>{errorMsg ? 'An error has occurred' : 'Thanks for returning your booking'}</Heading>
    <Underline />
    <FollowUpText>
      {errorMsg ? errorMsg : 'We will let you know once we have received it.'}
    </FollowUpText>
    <Button onClick={onReturn}>RETURN TO HOMEPAGE</Button>
  </ReturnConfirmationWrapper>
);

const innerEnhancers = withLoader((props: {loading: boolean}) => props.loading);

const MessageWithLoader = innerEnhancers(ReturnConfirmationMessage);

export const ReturnConfirmation = ({onReturn, confirmReturn, token}: Props) => {
  const [errorMsg, setErrorMsg] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const runReturnMutation = async () => {
      try {
        if (!token) {
          throw new Error();
        }
        await confirmReturn({token});
      } catch (e) {
        console.log(e);
        // NOTE(Harry): customers will only receive this email the day of return or when its overdue, if for whatever reason
        // they receive this before the item is fulfilled, they will not be shown an error
        if (path(['graphQLErrors', 0, 'data', 'code'], e) !== 'E_BOOKING_NOT_CHECKED_OUT') {
          setErrorMsg('We could not return your item');
        }
      } finally {
        setLoading(false);
      }
    };
    void runReturnMutation();
    // NOTE(Barry): These deps shouldn't change and I only want to run this effect on first render. This is why I've
    // supressed this error
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return <MessageWithLoader loading={loading} errorMsg={errorMsg} onReturn={onReturn} />;
};

type RouterProps = {
  submitMutation({token: string}): Promise<void>,
  history: {
    replace: string => void,
  },
  location: {
    search: string,
  },
};

const ConnectedReturnConfirmation = ({history, location, submitMutation, ...rest}: RouterProps) => {
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  return (
    <ReturnConfirmation
      onReturn={() => history.replace(urls.home)}
      token={token}
      confirmReturn={submitMutation}
      {...rest}
    />
  );
};

const enhancer = compose(withRouter, withMutation(markItemAsInbound));

export default enhancer(ConnectedReturnConfirmation);
