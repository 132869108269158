// @flow
import {FieldTitle} from 'componentsStyled/Typography/Titles';
import {formatOpeningHour} from 'data/affiliate/formatters';
import type {SpecialOpeningDates} from 'data/affiliate/types';
import {formatReadableFullYear} from 'data/units/date/formatters';
import {momentFromDateString} from 'data/units/date/helpers';
import moment from 'moment';
import {compose, filter, prop, sortBy} from 'ramda';
import * as React from 'react';

import {Key, OpeningHoursTableWrap, Pair, Val} from './styled';

type Props = {|
  data: SpecialOpeningDates,
|};

const filterUpcoming = filter(item =>
  momentFromDateString(item.date).isSameOrAfter(moment(), 'day')
);
const sortByDate = sortBy(prop('date'));

const Special = ({data = []}: Props) => {
  const transformed: SpecialOpeningDates = compose(sortByDate, filterUpcoming)(data);

  if (!transformed.length) return null;

  return (
    <React.Fragment>
      <FieldTitle>Special hours</FieldTitle>
      <OpeningHoursTableWrap>
        {transformed.map((x, i) => (
          <Pair key={i}>
            <Key nomargin>{formatReadableFullYear(x.date)}</Key>
            <Val nomargin black>
              {formatOpeningHour(x)}
            </Val>
          </Pair>
        ))}
      </OpeningHoursTableWrap>
    </React.Fragment>
  );
};

export default Special;
