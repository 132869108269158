// @flow
import type {RouterHistory, RouterLocation} from 'data/router/types';
import {withRouter as reactRouterHOC} from 'react-router';
import type {Component} from 'recompose';

export type HistoryUpdateType = 'replace' | 'push';

export const HISTORY_UPDATE = {
  REPLACE: 'replace',
  PUSH: 'push',
};

type Added = {|
  history: RouterHistory,
  location: RouterLocation,
|};

// $ExpectError
function withRouter<T>(C: Component<{...$Exact<T>, ...$Exact<Added>}>): Component<T> {
  return reactRouterHOC(C);
}

export default withRouter;
