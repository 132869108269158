// @flow
import styled from 'styled-components';

export const Hr = styled.hr`
  border: 0;
  height: 0.1rem;
  background: ${props => props.theme.color.light};
  margin: 1.8rem 0;
  padding: 0;
`;

export const Br = styled.div`
  margin-top: ${p => `${p.mb || 0}rem`};
`;

export const Note = styled.div`
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.4;
  color: ${props => props.theme.color.secondaryText};
  max-width: 30rem;
  margin: 0 auto;
`;
