// @flow
import {type Component, withStateHandlers} from 'recompose';

type Added<T> = {
  value: ?T,
  setValue: (?T) => mixed,
};

type Required<T> = {
  initialValue: ?T,
};

function withValue<K, T: Required<K>>(
  WrappedComponent: Component<{...$Exact<T>, ...$Exact<Added<K>>}>
): Component<T> {
  return withStateHandlers(
    props => ({
      value: props.initialValue,
    }),
    {
      setValue: () => (v: ?K) => ({value: v}),
    }
  )(WrappedComponent);
}

export default withValue;
