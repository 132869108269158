// @flow
import {mq} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import styled from 'styled-components';

export const SettingListLink = styled(StyledLink)`
  color: ${props => props.theme.color.primaryAccent};
  padding: 0 2.4rem;
  line-height: 6rem;
  display: block;
  border-top: 0.1rem solid ${props => props.theme.color.light};
  border-bottom: 0.1rem solid ${props => props.theme.color.light};
  background: ${props => props.theme.color.white};
  font-size: 1.6rem;

  & + & {
    border-top: 0;
  }

  ${mq.tabletWide} {
    background: none;
    padding: 0;
    border: 0;
    font-weight: ${props => props.theme.fontWeights.book};
    color: ${props => props.theme.color.secondaryText};

    &.active {
      color: ${props => props.theme.color.primaryText};
    }
  }
`;
