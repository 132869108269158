import {
  brandFilterQuery,
  categoryFilterQuery,
  locationFilterQuery,
  productFilterQuery,
} from 'data/search/graphql/queries/filters';
import withQuery from 'hoc/withQuery';
import {generateIdMapper} from 'pages/_Page/MainFilter/queryHOCs/utils';
import {path} from 'ramda';
import {compose, mapProps} from 'recompose';

const getCategoryIds = generateIdMapper(['categories'], 'categoryId');
const getBrandIds = generateIdMapper(['params', 'brand']);
const getLocationIds = generateIdMapper(['geolocation']);
const getProductIds = generateIdMapper(['params', 'product']);
const getAffiliateId = path(['params', 'affiliate']);

// ***IMPORTANT ***
// Updates to these default filters should have the same changes made to the mobile queries (in './mobile.js')
// to ensure they remain in sync.

export const withLocationFilterData = compose(
  withQuery(locationFilterQuery, {
    noEmpty: true,
    noLoader: true,
    variables: props => ({
      filter: {
        categoryIds: getCategoryIds(props),
        affiliateId: getAffiliateId(props),
        manufacturerIds: getBrandIds(props),
        productIds: getProductIds(props),
      },
    }),
  }),
  mapProps(({data, loading, ...rest}) => ({
    ...rest,
    locationFilterOptions: data,
    locationFilterOptionsLoading: loading,
  }))
);

export const withCategoryFilterData = compose(
  withQuery(categoryFilterQuery, {
    noEmpty: true,
    noLoader: true,
    variables: props => ({
      filter: {
        locationIds: getLocationIds(props),
        affiliateId: getAffiliateId(props),
        manufacturerIds: getBrandIds(props),
        productIds: getProductIds(props),
        availableOnly: true,
      },
    }),
  }),
  mapProps(({data, loading, ...rest}) => ({
    ...rest,
    categoryFilterOptions: data,
    categoryFilterOptionsLoading: loading,
  }))
);

export const withBrandFilterData = compose(
  withQuery(brandFilterQuery, {
    noEmpty: true,
    noLoader: true,
    variables: props => ({
      filter: {
        categoryIds: getCategoryIds(props),
        affiliateIds: getAffiliateId(props) ? [getAffiliateId(props)] : undefined,
        locationIds: getLocationIds(props),
        productIds: getProductIds(props),
        availableOnly: true,
      },
    }),
  }),
  mapProps(({data, loading, ...rest}) => ({
    ...rest,
    brandFilterOptions: data,
    brandFilterOptionsLoading: loading,
  }))
);

export const withProductFilterData = compose(
  withQuery(productFilterQuery, {
    noEmpty: true,
    noLoader: true,
    variables: props => ({
      filter: {
        categoryIds: getCategoryIds(props),
        affiliateId: getAffiliateId(props),
        locationIds: getLocationIds(props),
        manufacturerIds: getBrandIds(props),
        availableOnly: true,
      },
    }),
  }),
  mapProps(({data, loading, ...rest}) => ({
    ...rest,
    productFilterOptions: data,
    productFilterOptionsLoading: loading,
  }))
);
