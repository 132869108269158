// @flow
import {mqMax} from 'common/mediaQuery';
import styled from 'styled-components';

export const DestinationsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 60rem;
  height: 100%;
  align-items: center;

  ${mqMax.tabletWide} {
    max-height: ${p => `${p.maxHeight * 2}rem`};
    flex-direction: column;
  }
  ${mqMax.phone} {
    max-height: ${p => `${p.maxHeight * 3}rem`};
    flex-direction: column;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  ${mqMax.tabletWide} {
    flex-direction: column;
  }

  margin-bottom: 2rem;
`;
