//@flow
import type {ReservationPricingQueryInput} from 'data/reservations/graphql/queries';
import type {Discount, OriginalReservation} from 'data/reservations/types';
import {getDaysApart} from 'data/units/date/helpers';
import {equals, groupWith, max} from 'ramda';

export const calculatePrices = (
  dates?: ReservationPricingQueryInput,
  originalReservation?: ?OriginalReservation,
  prices: number[]
) => {
  if (dates && originalReservation) {
    const {start} = dates;
    const {endDate} = originalReservation;
    const diff = getDaysApart(endDate, start) + 1;

    return groupWith(equals, prices.slice(diff) || []);
  }
  return groupWith(equals, prices || []);
};

export const calculateDiscount = (prices: number[], discount: Discount, duration: number) => {
  const threshold =
    discount.limitDays && prices.length > discount.limitDays - duration
      ? max(0, discount.limitDays - duration)
      : prices.length;

  const discountPerDay = Array(threshold).fill(discount.percentage);

  const discountPrice = discountPerDay.reduce(
    (accumulator, discountValue, i) =>
      accumulator + prices[i] * (Math.min(100, discountValue) / 100),
    0
  );

  return discountPrice;
};
