// @flow
import gql from 'graphql-tag';

export const paginationFragment = gql`
  fragment paginationFragment on PaginationPageInfoNumber {
    hasNextPage
    nextOffset
    totalCount
  }
`;

export const locationFragment = gql`
  fragment locationFragment on Location {
    id
    name
    country {
      id
      name
      currency
      code
    }
    lat
    lng
  }
`;
