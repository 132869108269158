// @flow
import type {DateRange, DateRangeValue} from 'data/units/date/types';
import React from 'react';

import {StyledHelp} from './styled';

type Props = {|
  value?: DateRangeValue,
  originalReservation?: DateRange,
|};

const Help = ({value, originalReservation}: Props) => {
  if (originalReservation)
    return <StyledHelp>Please select a new END date to extend your reservation</StyledHelp>;
  if (!value || !value.startDate) return <StyledHelp>Please select a START date</StyledHelp>;

  if (!value.endDate) return <StyledHelp>Please select an END date</StyledHelp>;

  return <StyledHelp>Use clear if you wish to change the dates</StyledHelp>;
};

export default Help;
