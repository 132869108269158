// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import React from 'react';

import Mastercard from './Mastercard';
import {CardBrandWrap} from './styled';
import Visa from './Visa';

const getIcon = (brand: string) => {
  if (brand === 'Mastercard') return Mastercard;
  if (brand === 'Visa') return Visa;
};

type Props = {|
  brand: string,
|};

const CardBrand = ({brand}: Props) => {
  const Icon = getIcon(brand);

  return (
    <CardBrandWrap>
      {Icon && <Icon />}
      <Text book black>
        {brand}
      </Text>
    </CardBrandWrap>
  );
};

export default CardBrand;
