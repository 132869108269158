// @flow
import {setUserLocation} from 'data/userLocation/actions';
import {selectUserLocation} from 'data/userLocation/selectors';
import withConnect from 'hoc/withConnect';
import withOnMount from 'hoc/withOnMount';
import {omit} from 'ramda';
import type {HOC} from 'recompose';
import {compose, mapProps, withHandlers, withStateHandlers} from 'recompose';

export type Props = {
  getLocationOnMount?: boolean,
  options: PositionOptions,
};

export type State = {
  loading: boolean,
  error: ?PositionError,
};

export type Added = {
  ...State,
  getLocation: () => void,
  userLocation: ?Position,
};

const mapStateToProps = state => ({
  userLocation: selectUserLocation(state),
});

const mapDispatchToProps = {
  setUserLocation,
};

function withUserLocation<Outter>(
  props?: Props // $ExpectError
): HOC<{...$Exact<Added>, ...$Exact<Outter>}, Outter> {
  return compose(
    withConnect(mapStateToProps, mapDispatchToProps),
    withStateHandlers(
      () => ({
        loading: false,
        error: null,
      }),
      {
        setLoading: () => loading => ({loading}),
        setError: () => error => ({error}),
      }
    ),
    withHandlers({
      getLocation:
        ({setLoading, setError, setUserLocation}) =>
        () => {
          //Note: these are default values except for timeout which we are setting to 10s instead of infinite.
          const locationOptions = (props && props.options) || {
            timeout: 10000,
            enableHighAccuracy: false,
            maximumAge: 0,
          };

          const onSuccess = (position: Position) => {
            setError(null);
            setUserLocation(position);
            setLoading(false);
          };

          const onError = (error: PositionError) => {
            setError(error);
            setLoading(false);
          };

          setLoading(true);
          navigator.geolocation.getCurrentPosition(onSuccess, onError, locationOptions);
        },
    }),
    withOnMount(props => {
      if (props && props.getLocationOnMount) {
        props.getLocation();
      }
    }),
    mapProps(omit(['setLoading', 'setError', 'setUserLocation']))
  );
}

export default withUserLocation;
