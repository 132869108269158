// @flow
import styled from 'styled-components';

export const ProductPreviewWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageWrap = styled.div`
  width: 5rem;
  height: 7.5rem;
  display: flex;
  position: relative;
  margin-right: 2.5rem;

  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
