// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

export const CarouselWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: ${p => p.height && `${p.height}rem`};

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;

export const ImageWrap = styled.div`
  height: 24rem;
  position: relative;

  ${mq.tabletWide} {
    height: calc(100vh - 6rem);
  }
`;
