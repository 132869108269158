// @flow
import {Space} from 'componentsStyled/Layout/Spacers';
import {Text} from 'componentsStyled/Typography/Texts';
import {selectBasketItems} from 'data/basket/selectors';
import type {FulfillmentType} from 'data/bookings/types';
import type {AffiliateOnVariant, Product} from 'data/product/types';
import {formatPricing, getPriceRange} from 'data/units/money/formatters';
import withConnect from 'hoc/withConnect';
import withRouter from 'hoc/withRouter';
import useQueryParam from 'hooks/useQueryParam';
import {fulfilmentParamTransformer} from 'hooks/useQueryParam/transformers';
import Reserve from 'pages/Product/Detail/Reserve';
import FulfilmentTabs from 'pages/Product/FulfilmentTabs';
import VariantSelect from 'pages/Product/VariantSelect';
import {path} from 'ramda';
import React from 'react';
// $ReactHooks
import {useEffect, useMemo, useState} from 'react';
import type {HOC} from 'recompose';
import {compose} from 'recompose';

//Returns the provided fulfilmentType if it's valid for the affiliate,
//Else it provides the next best option (the other fulfilmentType, or null
//if there is no valid fulfilmentType)
const getValidFulfilmentType = (affiliate: AffiliateOnVariant, fulfilmentType: FulfillmentType) => {
  const {allowInStoreFulfilment, allowDeliveryFulfilment} = affiliate;
  const allowedFulfilmentTypes = [
    allowInStoreFulfilment && 'IN_STORE',
    allowDeliveryFulfilment && 'DELIVERY',
  ].filter(Boolean);

  //provided typed is valid
  if (allowedFulfilmentTypes.includes(fulfilmentType)) {
    return fulfilmentType;
  }

  //else, if there is at least a valid fulfilmentType return it
  if (allowedFulfilmentTypes.length) {
    return allowedFulfilmentTypes[0];
  }

  //else return null
  return null;
};

const Detail = ({product, items, history, variantAffiliates}) => {
  //NOTE: This will not necessarily be the actual `AffiliateOnVariant` object that the user selects (as that
  // will be based on the variant selected), but is used for displaying information about the affiliate (NOT the
  // specific affiliate + variant combination chosen by the user)
  const representativeAffiliate = variantAffiliates[0];

  //Get only the variants that exist for the selected affiliate
  const productVariants = useMemo(
    () =>
      product.variants.filter(variant =>
        variant.affiliates.some(
          variantAffiliate => variantAffiliate.id === representativeAffiliate.id
        )
      ),
    [product.variants, representativeAffiliate]
  );

  // Set the initial fulfilment tab based on the last item added to the cart
  const initialFulfilmentType = path([items.length - 1, 'fulfillmentType'], items) || 'IN_STORE';
  const [fulfilmentType, setFulfilmentType] = useState(
    getValidFulfilmentType(representativeAffiliate, initialFulfilmentType)
  );
  const [selectedVariantId, setSelectedVariantId] = useState(null);
  const selectedVariant = useMemo(
    () => productVariants.find(({id}) => id === selectedVariantId),
    [productVariants, selectedVariantId]
  );

  //This is the ACTUAL AffiliateOnVariant the user has selected, it represent the combination of the
  //affiliate chosen + the variant chosen.
  const selectedAffiliateOnVariant = useMemo(() => {
    if (!selectedVariant) {
      return null;
    }
    return selectedVariant.affiliates.find(({id}) => id === representativeAffiliate.id);
  }, [selectedVariant, representativeAffiliate]);

  //We'll display the full price range across all variants if none is selected, else we will display
  //the price range for the selected variant
  const [minPrice, maxPrice] = useMemo(() => {
    if (selectedAffiliateOnVariant) {
      return getPriceRange(selectedAffiliateOnVariant.pricing);
    }
    // $ExpectError - Flow doesn't know flatMap
    const allPrices = variantAffiliates.flatMap(variantAffiliate => variantAffiliate.pricing);
    return getPriceRange(allPrices);
  }, [variantAffiliates, selectedAffiliateOnVariant]);

  useEffect(() => {
    setFulfilmentType(getValidFulfilmentType(representativeAffiliate, fulfilmentType));
  }, [representativeAffiliate, fulfilmentType]);

  useQueryParam({
    value: fulfilmentType,
    setValue: setFulfilmentType,
    paramName: 'fulfilment',
    ...fulfilmentParamTransformer,
    history,
  });

  useQueryParam({
    value: selectedVariantId,
    setValue: setSelectedVariantId,
    paramName: 'variant',
    transformFromParam: id => (id ? Number(id) : null),
    history,
  });

  return (
    <React.Fragment>
      {representativeAffiliate && (
        <>
          <VariantSelect
            value={selectedVariantId}
            onChange={setSelectedVariantId}
            productVariants={productVariants}
          />
          <Text>
            <Text inline black book>
              {formatPricing(
                [maxPrice, minPrice],
                representativeAffiliate.location.country.currency
              )}
            </Text>{' '}
            per day
          </Text>
          <Space />
          <FulfilmentTabs
            currentFulfilmentType={fulfilmentType}
            onCurrentFulfillmentTypeChange={setFulfilmentType}
            affiliate={representativeAffiliate}
          />
        </>
      )}
      <Space />
      <Reserve
        disabled={!selectedVariant || !selectedAffiliateOnVariant || !fulfilmentType}
        product={product}
        fulfillmentType={fulfilmentType}
        reservationInput={selectedVariant}
        variantAffiliate={selectedAffiliateOnVariant}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  items: selectBasketItems(state),
});

type Outter = {
  product: Product,
  variantAffiliates: AffiliateOnVariant[],
};

const enhancer: HOC<*, Outter> = compose(withRouter, withConnect(mapStateToProps));

export default enhancer(Detail);
