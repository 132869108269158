// @flow
import {mqMax} from 'common/mediaQuery';
import {HPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import styled from 'styled-components';

export const MainFilterWrap = styled(HPaddedFullWidthContainer)`
  display: flex;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  align-items: center;

  ${mqMax.phone} {
    overflow: scroll;
  }
`;
