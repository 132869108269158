// @flow
import {breakpoints} from 'common/mediaQuery';
import MediaQuery from 'components/MediaQuery';
import * as React from 'react';

type Props = {|
  children: React.Node,
  breakpoint?: number,
|};

const Mobile = ({children, breakpoint = breakpoints.tabletWide}: Props) => (
  <MediaQuery breakpoint={breakpoint}>{matches => matches && children}</MediaQuery>
);

export default Mobile;
