// @flow
import {trackActivity} from 'analytics/app/analyticsTraits';
import Carousel from 'components/Carousel';
import {type Activity} from 'data/app/types';
import {type User} from 'data/user/types';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import ActivityTile from './ActivityTile';
import {Wrap} from './styled';

type Props = {|
  onChange: Activity => mixed,
  user?: ?User,
  handleChange?: Function,
  activity: any,
|};

const Activities = ({activity, handleChange, user}) => (
  <Wrap>
    <Carousel customStyle={{cellSpacing: 10, slidesToShow: 3}}>
      {activity.map((category, idx) => (
        <ActivityTile
          key={idx}
          title={category.name}
          subtitle={`${category.locations} locations`}
          img={category.image}
          onClick={() => handleChange(category.activity, user)}
        />
      ))}
    </Carousel>
  </Wrap>
);

const enhancer: HOC<*, Props> = compose(
  withHandlers({
    handleChange: props => (activity: Activity, user?: ?User) => {
      trackActivity(activity, user);
      props.onChange(activity);
    },
  })
);

export default enhancer(Activities);
