// @flow
import {type Error as ErrorType} from 'components/Calendar/ErrorMessage';
import {selectAppConfig} from 'data/app/selectors';
import type {AppConfig} from 'data/app/types';
import type {ProductVariantAvailableAffiliate} from 'data/product/types';
import {dayStatus} from 'data/reservations/constants';
import type {DateRangeMoment, DateRangeValue, DateString} from 'data/units/date/types';
import type {User} from 'data/user/types';
import withConnect from 'hoc/withConnect';
import withUser from 'hoc/withUser';
import moment from 'moment';
import {range, splitEvery} from 'ramda';
import React from 'react';
import {type HOC, compose} from 'recompose';

import AvailableDay from './Day/AvailableDay';
import {getStatus} from './Day/helpers';
import UnavailableDay from './Day/UnavailableDay';
import {EmptyDay, MonthWrap, Row, StyledMonth, WeekDay} from './styled';

type Props = {|
  user: User,
  month: moment$Moment,
  value: DateRangeMoment,
  onChange: (?DateRangeValue) => mixed,
  onError?: ErrorType => mixed,
  closedDays: DateString[],
  availabilityDates?: DateRangeMoment[],
  variantAffiliate?: ProductVariantAvailableAffiliate,
  originalReservation?: DateRangeMoment,
  currentDate: moment$Moment,
  clear: Function,
  appConfig: AppConfig,
|};

const Month = ({
  month,
  onChange,
  onError,
  value,
  variantAffiliate,
  availabilityDates,
  originalReservation,
  closedDays,
  currentDate,
  clear,
  appConfig,
}: Props) => {
  const lastMonth = month.clone().subtract(1, 'months');
  const daysInLastMonth = lastMonth.daysInMonth();
  const thisMonthStartsWith = month.startOf('month').weekday();

  const daysFromLastMonth = range(
    daysInLastMonth + 1 - thisMonthStartsWith,
    daysInLastMonth + 1
  ).map(d => undefined);

  const daysFromThisMonth = range(1, month.daysInMonth() + 1).map(d => month.clone().date(d));

  const dayRows = splitEvery(7, daysFromLastMonth.concat(daysFromThisMonth));

  return (
    <MonthWrap data-cy={'calendar-month'}>
      <StyledMonth>{month.format('MMMM YYYY')}</StyledMonth>
      <div>
        <Row>
          {moment.weekdays().map(wd => (
            <WeekDay key={wd}>{wd.slice(0, 3)}</WeekDay>
          ))}
        </Row>
        {dayRows.map((row, i) => (
          <Row key={`r${i}`}>
            {row.map((col, j) => {
              if (!col) return <EmptyDay key={`r${i}-c${j}`} />;

              const status = getStatus(
                col,
                value,
                availabilityDates,
                closedDays,
                originalReservation,
                currentDate
              );

              if (status === dayStatus.available || status === dayStatus.availableHidePrice) {
                return (
                  <AvailableDay
                    variantAffiliate={variantAffiliate}
                    key={`r${i}-c${j}`}
                    day={col}
                    status={status}
                    value={value}
                    onChange={onChange}
                    originalReservation={originalReservation}
                    clear={clear}
                  />
                );
              }

              return (
                <UnavailableDay
                  variantAffiliate={variantAffiliate}
                  key={`r${i}-c${j}`}
                  day={col}
                  status={status}
                  value={value}
                  onError={onError}
                />
              );
            })}
          </Row>
        ))}
      </div>
    </MonthWrap>
  );
};

type Outter = {};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const enhancer: HOC<*, Outter> = compose(withUser(), withConnect(mapStateToProps));

export default enhancer(Month);
