// @flow
import {type Error as ErrorType} from 'components/Calendar/ErrorMessage';
import type {ProductVariantAvailableAffiliate} from 'data/product/types';
import {dayStatus} from 'data/reservations/constants';
import type {DayStatus} from 'data/reservations/types';
import {dateStringFromMoment} from 'data/units/date/helpers';
import type {DateRangeMoment} from 'data/units/date/types';
import type Moment from 'moment';
import React from 'react';

import DayPrice from '../DayPrice';
import {isActive} from '../helpers';
import {DayWrap, StyledDay} from './styled';

type Props = {|
  variantAffiliate?: ProductVariantAvailableAffiliate,
  day: Moment,
  status: DayStatus,
  value: DateRangeMoment,
  onError?: ErrorType => mixed,
|};

const UnavailableDay = ({variantAffiliate, day, status, value, onError}: Props) => {
  const dayActive = value && value.startDate && value.startDate.isSame(day, 'day');
  const lastDayActive = value && value.endDate && value.endDate.isSame(day, 'day');

  return (
    <DayWrap onClick={onError ? () => onError(status) : null} status={status}>
      <StyledDay
        dayActive={dayActive}
        lastDayActive={lastDayActive}
        status={status}
        active={isActive(day, value)}
        dateRangeSelected={value.startDate && value.endDate}
      >
        {day.date()}
      </StyledDay>
      {status !== dayStatus.blocked && status !== dayStatus.closedHidePrice && (
        <DayPrice
          day={dateStringFromMoment(day)}
          value={value}
          variantAffiliate={variantAffiliate}
        />
      )}
    </DayWrap>
  );
};

export default UnavailableDay;
