// @flow
import {capitalise} from 'common/functions';
import ReservationsList from 'components/ReservationList';
import Tabs from 'components/Tabs';
import {VPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {OffsetNoPadding} from 'componentsStyled/Layout/Offset';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import {
  activeReservationStatuses,
  cancelledReservationStatuses,
  completedReservationStatuses,
  upcomingReservationStatuses,
} from 'data/reservations/constants';
import {reservationListQuery} from 'data/reservations/graphql/queries';
import type {Reservation, ReservationStatus} from 'data/reservations/types';
import withLocation from 'hoc/withLocation';
import withQuery from 'hoc/withQuery';
import Page from 'pages/_Page';
import {groupBy, path, values} from 'ramda';
import * as React from 'react';
// $ReactHooks
import {useState} from 'react';
import {type HOC, compose, withProps} from 'recompose';

export const groupReservationsByStatus = (
  reservations: Reservation[],
  statuses: ReservationStatus[]
) => {
  const groupedData = groupBy(x => x.status, reservations);
  const groupedReservations = [];
  statuses.forEach(status => {
    if (groupedData.hasOwnProperty(status)) {
      groupedData[status].map(reservation => groupedReservations.push(reservation));
    }
  });
  return groupedReservations;
};

const Reservations = ({data, initialTabIndex}) => {
  const upcomingReservations = groupReservationsByStatus(data, upcomingReservationStatuses);
  const currentReservations = groupReservationsByStatus(data, activeReservationStatuses);
  const pastReservations = groupReservationsByStatus(data, completedReservationStatuses);
  const cancelledReservations = groupReservationsByStatus(data, cancelledReservationStatuses);

  const groups = {
    upcoming: upcomingReservations || [],
    current: currentReservations || [],
    past: pastReservations || [],
    cancelled: cancelledReservations || [],
  };
  //find the first group with at least one reservation, favouring 'current' above others
  const currentEmpty = groups.current.length === 0;
  const nonEmptyIndex = currentEmpty ? values(groups).findIndex(x => x.length) : 1; // if possible, select current reservations tab
  //Prioritise tab index prop if given, otherwise use nonEmptyindex.
  //Default to 0 if there are no reservations.
  const startingIndex = initialTabIndex ?? (nonEmptyIndex === -1 ? 0 : nonEmptyIndex);

  const [currentTabIndex, setCurrentTabIndex] = useState(startingIndex);

  //Flow doesn't know how to type object.entries
  const tabs = Object.entries(groups).map(([key, value]) => ({
    title: capitalise(key),
    content: (
      <OffsetNoPadding>
        {/* $ExpectError */}
        <ReservationsList reservations={value} />
      </OffsetNoPadding>
    ),
  }));

  return (
    <Page>
      <VPaddedFullWidthContainer data-cy={'reservations-screen'}>
        <PageTitle>Reservations</PageTitle>
        <Tabs
          tabs={tabs}
          currentTabIndex={currentTabIndex}
          onCurrentTabIndexChange={setCurrentTabIndex}
          centerSwitch
        />
      </VPaddedFullWidthContainer>
    </Page>
  );
};

const enhancer: HOC<*, {||}> = compose(
  withQuery(reservationListQuery, {
    noEmpty: true,
  }),
  withLocation(() => {}),
  withProps(props => ({
    initialTabIndex: path(['location', 'state', 'initialTabIndex'], props),
  }))
);

export default enhancer(Reservations);
