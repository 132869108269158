// @flow strict-local
import type {ProductVariant} from 'data/product/types';
import type {ShippingAddress} from 'data/shipping/types';
import type {DateRange} from 'data/units/date/types';

import type {Accessory, OriginalReservation} from './types';

export const pickReservationDates = (d: DateRange, p: ProductVariant) => ({
  type: 'RESERVATIONS/PICK_RESERVATION_DATES',
  payload: {
    dates: d,
    productVariant: p,
  },
});

export const pickOriginalReservation = (r: OriginalReservation) => ({
  type: 'RESERVATIONS/PICK_ORIGINAL_RESERVATION',
  payload: {
    originalReservation: r,
  },
});

export const pickAccessories = (accessories: Accessory[]) => ({
  type: 'RESERVATIONS/PICK_ACCESSORIES',
  payload: {
    accessories,
  },
});

// export const setShippingAddress = (shippingAddress: string) => ({
//   type: "RESERVATIONS/SET_SHIPPING_ADDRESS",
//   payload: {
//     shippingAddress,
//   },
// })

export const setShippingAddressFields = (shippingAddress: ShippingAddress) => ({
  type: 'RESERVATIONS/SET_SHIPPING_ADDRESS',
  payload: {
    shippingAddress,
  },
});

export const setAvailability = (a: DateRange[]) => ({
  type: 'RESERVATIONS/SET_AVAILABILITY',
  payload: {
    availability: a,
  },
});

export const clearAvailability = () => ({
  type: 'RESERVATIONS/CLEAR_AVAILABILITY',
});
