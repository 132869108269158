import styled from 'styled-components';

export const BagWarningWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    flex-shrink: 0;
  }
`;
