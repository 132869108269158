// @flow
import type {ExtractActionTypes} from 'common/redux/types';

import * as actions from './actions';
import type {Notification} from './types';

export type NotificationsState = Notification[];

const initialState: NotificationsState = [];

type Action = ExtractActionTypes<typeof actions>;

function notificationsReducer(
  state: NotificationsState = initialState,
  action: Action
): NotificationsState {
  switch (action.type) {
    case 'NOTIFICATION/SHOW_NOTICATION':
      return state.concat(action.payload);
    case 'NOTIFICATION/CLOSE_NOTIFICATION':
      return state.slice(1);
    default:
      return state;
  }
}

export default notificationsReducer;
