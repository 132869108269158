// @flow
import gql from 'graphql-tag';

export const productRatingFragment = gql`
  fragment productRatingFragment on Rating {
    id
    productId
    value
  }
`;

export const profileFragment = gql`
  fragment profileFragment on Profile {
    id
    userId
    firstName
    lastName
    isPrimary
    userGender {
      id
      name: value
    }
    birthdate
    phoneNumber
    height
    weight
    bootLength
    din
    waveType {
      id
      name: value
    }
    surfStance {
      id
      name: value
    }
    snowboardStance {
      id
      name: value
    }
    frontfootRotation
    backfootRotation
    stanceWidth
    stanceOffset
    skillLevel {
      id
      name: value
    }
    skierType {
      id
      name: value
    }
    ratings {
      ...productRatingFragment
    }
    units
  }
  ${productRatingFragment}
`;

export const userFragment = gql`
  fragment userFragment on UserPublic {
    id
    email
    phoneNumber
    customer
    allowedCategories
    profiles {
      ...profileFragment
    }
  }
  ${profileFragment}
`;
