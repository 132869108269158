// @flow
import React from 'react';

export type Props = {
  size?: number,
};

const CartIcon = ({size = 28}: Props) => (
  <svg
    width={size}
    height={size * (13 / 14)}
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1429 25.0001C10.774 25.0001 11.2857 24.4884 11.2857 23.8572C11.2857 23.226 10.774 22.7144 10.1429 22.7144C9.51167 22.7144 9 23.226 9 23.8572C9 24.4884 9.51167 25.0001 10.1429 25.0001Z"
      stroke="#111111"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.7141 25.0001C23.3453 25.0001 23.857 24.4884 23.857 23.8572C23.857 23.226 23.3453 22.7144 22.7141 22.7144C22.083 22.7144 21.5713 23.226 21.5713 23.8572C21.5713 24.4884 22.083 25.0001 22.7141 25.0001Z"
      stroke="#111111"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 1H5.57143L8.63429 16.3029C8.73879 16.829 9.02504 17.3017 9.44291 17.6381C9.86078 17.9744 10.3837 18.1531 10.92 18.1429H22.0286C22.5649 18.1531 23.0878 17.9744 23.5057 17.6381C23.9235 17.3017 24.2098 16.829 24.3143 16.3029L26.1429 6.71429H6.71429"
      stroke="#111111"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CartIcon;
