// @flow
import Loader from 'components/Loader';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import ProductList from 'components/ProductList';
import Tabs from 'components/Tabs';
import {Container, HPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {Text} from 'componentsStyled/Typography/Texts';
import {FieldTitle, PageTitle} from 'componentsStyled/Typography/Titles';
import type {AffiliateFull, AffiliateMinimal} from 'data/affiliate/types';
import type {Manufacturer} from 'data/manufacturer/types';
import type {Product} from 'data/product/types';
import useUniqueCountriesAcrossAffiliates from 'hooks/useUniqueCountriesAcrossAffiliates';
import React from 'react';
// $ReactHooks
import {useState} from 'react';

import Contacts from './Contacts';
import ImageGallery from './ImageGallery';
import {getImages} from './ImageGallery/helpers';
import Info from './Info';
import {Left, Right, Split} from './styled';

type Props = {|
  loadingMore: boolean,
  affiliate: AffiliateFull,
  products: Product[],
  manufacturers: Manufacturer[],
  allAffiliates: AffiliateMinimal[],
|};

const Content = ({affiliate, products, loadingMore, allAffiliates}: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const uniqueCountries = useUniqueCountriesAcrossAffiliates(allAffiliates);
  const showPrices = uniqueCountries.length === 1;

  const tabs = [
    {
      title: 'Inventory',
      content: (
        <React.Fragment>
          <ProductList
            showPrices={showPrices}
            productOfferings={products}
            tileQueryParams={`store=${affiliate.id}`}
          />
          <Loader hide={!loadingMore} pb0 />
        </React.Fragment>
      ),
    },
    {
      title: 'Store description',
      content: (
        <HPaddedFullWidthContainer>
          <Text lh={2.6}>{affiliate.description}</Text>
          <Contacts affiliate={affiliate} />
        </HPaddedFullWidthContainer>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Desktop>
        <Container>
          <PageTitle>{affiliate.name}</PageTitle>
          <Split>
            <Left>
              <ImageGallery
                items={getImages(affiliate.images)}
                showIndex={true}
                infinite={false}
                showNav={true}
                showBullets={false}
                showThumbnails={true}
                showFullscreenButton={false}
                showPlayButton={false}
                thumbnailPosition="bottom"
              />
            </Left>
            <Right>
              <FieldTitle>About</FieldTitle>
              <Text>{affiliate.description}</Text>
              <Info affiliate={affiliate} />
              <Contacts affiliate={affiliate} />
            </Right>
          </Split>
          <ProductList
            showPrices={showPrices}
            productOfferings={products}
            tileQueryParams={`store=${affiliate.id}`}
          />
          <Loader hide={!loadingMore} pb0 />
        </Container>
      </Desktop>
      <Mobile>
        <ImageGallery
          items={getImages(affiliate.images)}
          showIndex={true}
          infinite={false}
          showNav={false}
          showBullets={true}
          showThumbnails={true}
          showFullscreenButton={false}
          showPlayButton={false}
          thumbnailPosition="bottom"
        />
        <HPaddedFullWidthContainer>
          <Info affiliate={affiliate} />
        </HPaddedFullWidthContainer>
        <Tabs
          tabs={tabs}
          currentTabIndex={currentTabIndex}
          onCurrentTabIndexChange={setCurrentTabIndex}
          limitSwitchWidth
        />
      </Mobile>
    </React.Fragment>
  );
};

export default Content;
