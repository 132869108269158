// @flow
import {Label} from 'componentsStyled/Typography/Texts';
import styled from 'styled-components';

export const VariantVariableWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

export const VariantVariableLabel = styled(Label)`
  min-width: 10rem;
`;

export const VariantVariableOption = styled.div`
  color: black;
  margin-left: 2rem;
`;
