// @flow
import type {IsoDate} from 'data/units/date/types';

import type {PendingReservation} from './types';

export const addToBasket = (reservation: PendingReservation) => ({
  type: 'BASKET/ADD_TO_BASKET',
  payload: {reservation},
});

export const removeFromBasket = (timeCreated: IsoDate) => ({
  type: 'BASKET/REMOVE_ITEM',
  payload: {timeCreated},
});

export const clearBasket = () => ({
  type: 'BASKET/CLEAR_BASKET',
});
