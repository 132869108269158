// @flow
import {selectPrimary} from 'data/user/helpers';
import type {User} from 'data/user/types';

import {tryTrack} from '../helpers';

const loginSuccessTrait = (u: User) => ({
  path: window.location.pathname,
  email: u.email,
  profile: selectPrimary(u),
});

const loginErrorTrait = (err: string) => ({
  path: window.location.pathname,
  error: err,
});

export const trackLoginSuccess = (u: User) => tryTrack('User Login', () => loginSuccessTrait(u));

export const trackLoginError = (err: string) =>
  tryTrack('User Login Error', () => loginErrorTrait(err));
