// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

export const Offset = styled.div`
  padding-left: 1.6rem;

  ${mq.tabletWide} {
    padding: 0;
    max-width: 56rem;
    margin: 0 auto;
  }
`;

export const OffsetNoPadding = styled(Offset)`
  padding-left: 0;
`;
