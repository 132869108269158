// @flow
import urls from 'data/router/urls';
import * as React from 'react';

import {SettingListLink} from './styled';

const SettingList = () => (
  <React.Fragment>
    <SettingListLink to={urls.account.password}>Reset Password</SettingListLink>
    <SettingListLink to={urls.account.payments}>Payment Method</SettingListLink>
    <SettingListLink to={urls.account.phoneNumber}>Edit Phone Number</SettingListLink>
    <SettingListLink to={urls.account.deleteAccount}>Delete Account</SettingListLink>
  </React.Fragment>
);

export default SettingList;
