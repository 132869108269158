// @flow
import type {RouterProps} from 'data/router/types';
import {path} from 'ramda';

export const getRouterVariables = (props: $Supertype<RouterProps>) => {
  const id: ?number = path(['match', 'params', 'id'], props);
  if (id) {
    return {id};
  }
  return {};
};
