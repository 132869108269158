// @flow
import React from 'react';

import {MasqueradeBannerWrap} from './styled';

type Props = {|
  isMasquerading: boolean,
|};

const MasqueradeBanner = ({isMasquerading}: Props) => {
  if (isMasquerading) {
    return (
      <MasqueradeBannerWrap>
        <p>You are currently masquerading</p>
      </MasqueradeBannerWrap>
    );
  }
  return false;
};

export default MasqueradeBanner;
