// @flow
import StyledLink from 'components/StyledLink';
import styled from 'styled-components';

export const Toggle = styled(StyledLink)`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme.color.primaryText};
  transition: color 0.25s ease;

  &:hover {
    color: ${props => props.theme.color.primaryAccent};
  }
`;
export const MenuLinks = styled.div`
  padding: 1rem 0;
`;

export const MenuLink = styled(StyledLink)`
  display: block;
  font-size: 1.6rem;
  color: ${props => props.theme.color.primaryText};
  line-height: 4rem;
  padding: 0 2rem;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.color.lightGray};
  }
`;
