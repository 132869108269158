// @flow
import type {ProductVariant} from 'data/product/types';
import {momentFromDateString} from 'data/units/date/helpers';
import type {DateRangeValue} from 'data/units/date/types';
import type {User} from 'data/user/types';

import {tryTrack} from '../helpers';
import {productTrait} from '../product/analyticsTraits';

export type Availability = {|
  product: ProductVariant,
  user: User,
  affiliate: string,
  selectedDates?: ?DateRangeValue,
|};

const availabilityTrait = (a: Availability) => ({
  ...productTrait(a.product, a.user),
  affiliate: a.affiliate,
  start:
    a.selectedDates && a.selectedDates.startDate
      ? momentFromDateString(a.selectedDates.startDate).format('MM/DD/YYYY')
      : '',
  end:
    a.selectedDates && a.selectedDates.endDate
      ? momentFromDateString(a.selectedDates.endDate).format('MM/DD/YYYY')
      : '',
});

export const trackAvailability = (eventName: 'Availability' | 'Selected Dates', a: Availability) =>
  tryTrack(eventName, () => availabilityTrait(a));
