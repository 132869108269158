// @flow
import * as React from 'react';

import bg1 from './bg1.jpg';
import bg2 from './bg2.jpg';
import bg3 from './bg3.jpg';
import bg4 from './bg4.jpg';
import {Layer, StyledHeroSection} from './styled';

// TODO - lazyload
// changing the size of the array at runtime would probably disrupt the animation
// so the best course of action i can see, is to start like so:
// [bg1, bg1, bg1] and then sequentially load other images and mutate the array and trigger rerender
const images = [bg1, bg2, bg3, bg4];

type Props = {|
  children: React.Node,
|};

const HeroSection = ({children}: Props) => (
  <StyledHeroSection>
    {images.map((x, i) => (
      <Layer key={i} index={i} count={images.length} src={x} />
    ))}
    {children}
  </StyledHeroSection>
);

export default HeroSection;
