// @flow
import * as React from 'react';

export default (
  <React.Fragment>
    <p className="bold">Waiver and Release</p>
    <p>
      You agree to indemnify and hold the shop, Awayco, their parents, subsidiaries, affiliates, and
      their respective officers, owners, managers, investors, directors, employees, partners,
      representatives, contractors and licensors (collectively, the “Parties”) harmless from any
      losses, costs, liabilities and expenses relating to or arising out of: (a) your use of, or
      inability to use any products; (b) your violation of any rights of another party; or (c) your
      violation of any applicable laws, rules or regulations and this waiver and release will
      survive any termination of your Account or your access to the Service.
    </p>
    <p className="bold">As Is.</p>
    <p>
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE FULL EXTENT PERMITTED BY APPLICABLE LAW YOUR
      USE OF THE SERVICE AND/OR PRODUCTS IS AT YOUR SOLE RISK, AND THE SERVICE AND/OR PRODUCTS ARE
      PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. THE PARTIES EXPRESSLY
      DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR
      IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT ARISING FROM USE OF THE
      SERVICE AND/OR PRODUCTS.
    </p>
    <p className="bold">PRODUCT CONDITION.</p>
    <p>
      YOU ACCEPT THE PRODUCT AS IS AND YOU ARE RESPONSIBLE FOR THE CARE OF THE PRODUCT WHILE IN YOUR
      POSSESSION. PRODUCTS ARE CHECKED OUT IN PROPER WORKING CONDITION AND MUST BE RETURNED IN THE
      SAME CONDITION.
    </p>
    <p className="bold">ASSUMPTION OF RISK.</p>
    <p>
      (a) YOU EXPRESSLY UNDERSTAND AND ACCEPT THAT USING THE PRODUCTS AND PARTICIPATING IN ANY
      ASSOCIATED ACTIVITIES MAY EXPOSE YOU TO HAZARDS SUCH AS PERSONAL INJURY, RISK OF DEATH, OR
      LOSS OF OR DAMAGE TO PROPERTY. YOU ALSO UNDERSTAND THAT YOU SHOULD BE IN GOOD PHYSICAL HEALTH
      TO PARTICIPATE IN ACTIVITIES ASSOCIATED WITH THE PRODUCTS, AND YOU REPRESENT AND WARRANT THAT
      YOU HAVE NO SIGNIFICANT MEDICAL CONDITIONS OR HEALTH ISSUES THAT PREVENT YOU FROM
      PARTICIPATING IN SUCH ACTIVITIES. (b) YOU EXPRESSLY UNDERSTAND AND ACCEPT THAT PRODUCTS MAY BE
      DESIGNED TO REDUCE RISK OR DEGREE OF INJURIES BUT IT IS NO GUARANTEE OF YOUR SAFETY. (c) YOU
      HEREBY ASSUME ALL RISK OF INJURY OR LOSS ARISING OUT OF OR IN CONNECTION WITH RENTING AND/OR
      USING THE PRODUCTS AND PARTICIPATING IN RELATED ACTIVITIES. TO THE EXTENT PERMITTED BY LAW,
      YOU HEREBY IRREVOCABLY RELEASE, HOLD HARMLESS, AND FOREVER DISCHARGE PARTIES FROM ANY AND ALL
      LIABILITY OR CLAIMS, ACTIONS, CAUSES OF ACTION, DEMANDS, COSTS AND EXPENSES AND LIABILITIES
      FOR INJURY, ILLNESS, DEATH OR LOSS OF OR DAMAGE TO PROPERTY THAT YOU MAY SUFFER WHILE RENTING
      AND/OR USING THE PRODUCTS AND PARTICIPATING IN ANY AND ALL RELATED ACTIVITIES.
    </p>
  </React.Fragment>
);
