// @flow
import type {Image} from 'data/images/types';
import * as React from 'react';

import {ImageSwitchNumericWrap} from './styled';

type Props = {|
  images: Image[],
  activeIndex: number,
|};

const ImageSwitchNumeric = ({images, activeIndex}: Props) => (
  <ImageSwitchNumericWrap>{`${activeIndex + 1} / ${images.length}`}</ImageSwitchNumericWrap>
);

export default ImageSwitchNumeric;
