// @flow
import styled, {css} from 'styled-components';

export const ArrowWrap = styled.div`
  transform: rotate(${p => p.rotation}deg);
  display: flex;
  ${p =>
    p.marginLeft &&
    css`
      margin-left: 1rem;
    `}
  ${p =>
    p.floatRight &&
    css`
      float: right;
    `}
`;
