// @flow
import {Title} from 'componentsStyled/Typography';
import urls from 'data/router/urls';
import * as React from 'react';

import LogoIcon from './LogoIcon';
import LogoIconFull from './LogoIconFull';
import LogoIconSmall from './LogoIconSmall';
import {StyledLogo, StyledLogoLink} from './styled';

type Props = {|
  type: 'small' | 'default' | 'full' | 'image' | 'label',
  onClick?: Function,
  imgSrc?: string,
  label?: string,
  logoLink?: string,
|};

const LogoLink = ({type, onClick, imgSrc, label, logoLink}: Props) => {
  function renderLogo() {
    if (type === 'small') {
      return <LogoIconSmall />;
    } else if (type === 'default') {
      return <LogoIcon />;
    } else if (type === 'full') {
      return <LogoIconFull />;
    } else if (type === 'image') {
      return <StyledLogo src={imgSrc} alt="logo" />;
    } else if (type === 'label') {
      return <Title>{label}</Title>;
    }
  }

  return (
    <StyledLogoLink to={logoLink ? logoLink : urls.home} onClick={onClick}>
      {renderLogo()}
    </StyledLogoLink>
  );
};

export default LogoLink;
