// @flow
import {trackSignup} from 'analytics/membership/analyticsTraits';
import Button from 'components/Button';
import SocialLogin from 'components/SocialLogin';
import StyledLink from 'components/StyledLink';
import {Col, Row} from 'componentsStyled/Layout/Grid';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {Note} from 'componentsStyled/Typography/Other';
import {chooseRedirectCallback} from 'data/app/actions';
import {selectAppConfig} from 'data/app/selectors';
import {openModal} from 'data/modals/actions';
import urls from 'data/router/urls';
import {passwordHintToolTip} from 'data/tooltips/signup';
import {login} from 'data/user/actions';
import {registerMutation} from 'data/user/graphql/mutations';
import Alert from 'forms/Alert';
import TextInput from 'forms/Input/Text';
import withForm from 'forms/withForm';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import Login from 'modals/Login';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {schema} from './schema';
import {SmallNote, SmallStyledLink, StyledText as Text} from './styled';

const SignUpForm = ({appConfig, close, error, isSubmitting, openLogin}) => {
  const tenantTitle = appConfig ? appConfig.tenantTitle : 'Awayco';

  const article =
    appConfig && (appConfig.tenantTitle[0].toLowerCase() !== 'a' || 'e' || 'i' || 'o' || 'u')
      ? 'a'
      : 'an';

  return (
    <React.Fragment>
      <ModalContent>
        <Wrap stack bottom>
          <SocialLogin close={close} signup />
          <Alert>{error}</Alert>
          <Row>
            <Col>
              <TextInput name="firstName" label="First Name" />
            </Col>
            <Col>
              <TextInput name="lastName" label="Last Name" />
            </Col>
          </Row>
          <TextInput type="email" name="email" label="Email" />
          <TextInput name="phoneNumber" label="Phone Number" placeholder="e.g. +1-707-123-4567" />
          <TextInput
            name="password"
            label="Password"
            type="password"
            autoComplete="new-password"
            toolTip={passwordHintToolTip}
          />
          <SmallNote>
            By joining, you agree to our{' '}
            <SmallStyledLink to={urls.terms} target="_blank">
              Terms &amp; Conditions
            </SmallStyledLink>
            .
          </SmallNote>
        </Wrap>
        <Wrap stack bottom>
          <Note>
            Already {article} {tenantTitle} member?{' '}
            <StyledLink onClick={openLogin}>Log in</StyledLink>
          </Note>
        </Wrap>
      </ModalContent>
      <ModalControls>
        <Button fullwidth loading={isSubmitting}>
          <Text>Sign Up</Text>
        </Button>
      </ModalControls>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const mapDispatchToProps = {
  login,
  openModal,
  chooseRedirectCallback,
};

type Outter = {|
  close: Function,
  onSuccess?: Function,
  callback?: Function,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withMutation(registerMutation),
  withHandlers({
    openLogin: props => () => {
      props.close();
      props.openModal(Login, {callback: props.callback});
    },
  }),
  withForm({
    schema,
    onSubmit: props => props.submitMutation,
    onSuccess: props => result => {
      props.callback && props.chooseRedirectCallback(props.callback());

      props.login(result);
      trackSignup(result);

      if (props.callback) {
        props.callback();
      }

      props.close();

      if (props.onSuccess) {
        return props.onSuccess();
      }
    },
  })
);

export default enhancer(SignUpForm);
