// @flow
import Warning from 'components/Warning';
import {dayStatus} from 'data/reservations/constants';
import React from 'react';

export type Error = string;

type Props = {|
  error?: Error,
  closeModal: () => void,
|};

const ErrorMessage = ({error, closeModal}: Props) => {
  if (!error) return null;
  switch (error) {
    case dayStatus.blocked:
      return <Warning>Product is booked for that day</Warning>;
    case dayStatus.closedSelected:
      return (
        <Warning>
          The shop is closed on your START or END date. Please select a new START date.
        </Warning>
      );
    case dayStatus.past:
      return <Warning>Date cannot be in the past</Warning>;
    case dayStatus.closed:
    case dayStatus.closedHidePrice:
      return <Warning>Shop is closed that day</Warning>;
    default:
      return null;
  }
};

export default ErrorMessage;
