// @flow
import {mq} from 'common/mediaQuery';
import styled, {css} from 'styled-components';

import {align, color, display, fontWeight, uppercase} from './enhancers';

export const Text = styled.div`
  font-size: 1.6rem;
  color: ${props => props.theme.color.primaryText};
  margin: ${p => p.margin || 0};
  line-height: ${p => p.lh || 2.4}rem;
  ${fontWeight};
  ${color};
  ${uppercase};
  ${display};
  ${align};

  ${p =>
    p.strong &&
    css`
      font-weight: bold;
    `}

  ${p =>
    p.inline &&
    css`
      display: inline;
    `}

  ${p =>
    p.small &&
    css`
      font-size: 1.4rem;
    `}
  ${p =>
    p.verySmall &&
    css`
      font-size: 1.3rem;
      line-height: 1.2rem;
    `}
  ${p =>
    p.bigOnDesktop &&
    css`
      ${mq.tabletWide} {
        font-size: 1.9rem;
      }
    `}
  ${p =>
    p.bigOnAllDisplays &&
    css`
      font-size: 1.9rem;
    `}
  ${p =>
    p.strikethrough &&
    css`
      text-decoration: line-through;
    `}
  ${p =>
    p.nowrap &&
    css`
      white-space: nowrap;
    `}
  ${p =>
    p.onClick &&
    css`
      cursor: pointer;
    `}
`;

export const MultipleLineText = styled(Text)`
  white-space: pre-line;
`;

export const Label = styled.div`
  font-size: 1.3rem;
  font-weight: ${props => props.theme.fontWeights.book};
  color: ${props => props.theme.color.primaryText};
`;

export const SUP = styled.sup`
  font-size: medium;
  vertical-align: super;
`;
