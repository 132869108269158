// @flow
import ProductPreview from 'components/ProductPreview';
import ReservationPricingDetail from 'components/ReservationPricing/ReservationPricingDetail';
import {VPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {FixedBottom, FixedBottomSpacer} from 'componentsStyled/Layout/FixedBottom';
import {Space} from 'componentsStyled/Layout/Spacers';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {Text} from 'componentsStyled/Typography/Texts';
import {FieldTitle, PageTitle} from 'componentsStyled/Typography/Titles';
import {reservationStatuses} from 'data/reservations/constants';
import {reservationDetailQuery} from 'data/reservations/graphql/queries';
import {formatReadableDateRange} from 'data/units/date/formatters';
import withQuery from 'hoc/withQuery';
import Page from 'pages/_Page';
import {path} from 'ramda';
import React from 'react';
import {type HOC} from 'recompose';

import BuyIt from './BuyIt';
import CancelReservation from './CancelReservation';
import ExtendReservation from './ExtendReservation';
import {HighlightedWrap, StyledDeliverySummary} from './styled';

const Reservation = ({data}) => {
  const dates = {
    startDate: data.booking.start,
    endDate: data.booking.end,
  };

  const fulfillmentType = path(['booking', 'fulfillmentType'], data);
  const deliveryDestination = path(['booking', 'deliveryDestination'], data);

  // TODO(Jude): Handle multiple categories
  const categoryName = data.productItem.productVariant.product.categories[0].name;

  return (
    <Page>
      <VPaddedFullWidthContainer narrow>
        <PageTitle>Reservation Details</PageTitle>
        <Wrap stack>
          <ProductPreview
            productVariant={data.productItem.productVariant}
            affiliateName={data.productItem.affiliate.name}
          />
        </Wrap>
        <Wrap stack>
          <FieldTitle>Dates</FieldTitle>
          <Text>{formatReadableDateRange(dates)}</Text>
          <Space />
          <ExtendReservation reservation={data} />
        </Wrap>
        {fulfillmentType === 'DELIVERY' && deliveryDestination ? (
          <Wrap stack>
            <StyledDeliverySummary
              addressTitle="Delivering to"
              deliveryDestination={deliveryDestination}
            />
          </Wrap>
        ) : (
          <Wrap stack>
            <FieldTitle>Address</FieldTitle>
            <Text>{data.productItem.affiliate.address}</Text>
          </Wrap>
        )}
        <HighlightedWrap stack>
          <FieldTitle>Reservation Code</FieldTitle>
          <Text>{data.externalId}</Text>
        </HighlightedWrap>
        {data.basketCode && (
          <HighlightedWrap stack>
            <FieldTitle>Booking Code</FieldTitle>
            <Text>{data.booking.externalId}</Text>
          </HighlightedWrap>
        )}
        <Wrap stack>
          <ReservationPricingDetail {...data.pricingDetail} categoryName={categoryName} />
        </Wrap>
        <Wrap stack>
          <FixedBottom>
            <CancelReservation reservation={data} />
          </FixedBottom>
        </Wrap>

        {data.status === reservationStatuses.checked_out && (
          <Wrap stack>
            <BuyIt reservation={data} />
          </Wrap>
        )}

        <FixedBottomSpacer />
      </VPaddedFullWidthContainer>
    </Page>
  );
};

const enhancer: HOC<*, {||}> = withQuery(reservationDetailQuery);

export default enhancer(Reservation);
