import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  background: white;
  border-radius: 10rem;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
`;

export default StyledButton;
