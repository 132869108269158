// @flow
import NearbyIcon from 'assets/icons/NearbyIcon';
import {breakpoints} from 'common/mediaQuery';
import Carousel from 'components/Carousel';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import {Text} from 'componentsStyled/Typography/Texts';
import type {AffiliateMinimal} from 'data/affiliate/types';
import {minWidthImg, selectIndexImage} from 'data/images/helpers';
import urls from 'data/router/urls';
import withEmpty from 'hoc/withEmpty';
import withLoader from 'hoc/withLoader';
import withOpen from 'hoc/withOpen';
import React from 'react';
import {type HOC, compose} from 'recompose';

import AffiliateItem from './AffiliateItem';
import {IconWrap} from './styled';

const AffiliateList = ({isOpen, toggleOpen, affiliates}) => {
  return (
    <React.Fragment>
      <Desktop breakpoint={breakpoints.laptop}>
        <Carousel customStyle={{slidesToShow: 5}}>
          {affiliates.map((a, i) => {
            return (
              <AffiliateItem
                key={a.id}
                imgUrl={minWidthImg(selectIndexImage(a.images), 229).url}
                to={urls.affiliate(a.id)}
              >
                {a.name}
                <Text>
                  <IconWrap>
                    <NearbyIcon />
                  </IconWrap>
                  {a.location.name}
                </Text>
              </AffiliateItem>
            );
          })}
        </Carousel>
      </Desktop>
      <Mobile breakpoint={breakpoints.laptop}>
        <Carousel mobileStyle={{slidesToShow: 2}} breakpoint={breakpoints.phoneWide}>
          {affiliates.map((a, i) => {
            return (
              <AffiliateItem
                to={urls.affiliate(a.id)}
                imgUrl={minWidthImg(selectIndexImage(a.images), 229).url}
                key={i}
              >
                <Text inline black bold small>
                  {a.name}
                </Text>
                <Text verySmall>
                  <IconWrap>
                    <NearbyIcon />
                  </IconWrap>
                  {a.location.name}
                </Text>
              </AffiliateItem>
            );
          })}
        </Carousel>
      </Mobile>
    </React.Fragment>
  );
};

type Outter = {|
  affiliates: AffiliateMinimal[],
  loading?: boolean,
|};

const enhancer: HOC<*, Outter> = compose(
  withOpen,
  withLoader(props => !!props.loading),
  withEmpty(props => !props.affiliates || !props.affiliates.length)
);

export default enhancer(AffiliateList);
