// @flow
import {momentFromDateString} from './helpers';
import type {DateRange, DateString} from './types';

export const formatReadableFullYear = (date: ?DateString) => {
  if (!date) return undefined;

  return momentFromDateString(date).format('MMM. DD, YYYY');
};

export const formatReadableDateRange = (v: DateRange): string => {
  const {startDate, endDate} = v;
  const formatDate = v => momentFromDateString(v).format('MMM D');

  if (startDate && endDate) return `${formatDate(startDate)} - ${formatDate(endDate)}`;

  if (startDate) return `from ${formatDate(startDate)}`;

  return `to ${formatDate(endDate)}`;
};

export const formatDate = (dateString: DateString): string => {
  if (!dateString) {
    return '';
  }
  return momentFromDateString(dateString).format('MMM D');
};
