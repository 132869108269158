import styled from 'styled-components';

export const AddressInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3.2rem;
`;

export const ManualAddressButton = styled.span`
  align-self: flex-end;
  cursor: pointer;
  color: ${props => props.theme.color.primaryAccent};
  margin-top: -2rem;
`;
