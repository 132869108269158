// @flow
import type {RouterLocation} from 'data/router/types';
import * as React from 'react';
import {Redirect} from 'react-router';
import type {Component} from 'recompose';

function withRedirect<T>(
  predicate: T => boolean,
  url: T => string | $Shape<RouterLocation>
): (Component: Component<T>) => Component<T> {
  return Component => props => {
    if (predicate(props)) {
      return <Redirect to={url(props)} />;
    }

    return <Component {...props} />;
  };
}

export default withRedirect;
