// @flow
import React from 'react';

import SettingContent from '../../SettingContent';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = () => (
  <SettingContent title="Reset password">
    <ChangePasswordForm />
  </SettingContent>
);

export default ChangePassword;
