// @flow
import * as React from 'react';
// $ReactHooks
import {useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import {CSSTransition} from 'react-transition-group';

import {DrawerContainer, Overlay, StyledDrawer} from './styled';

// Based partly on: https://codesandbox.io/s/drawer-component-react-hooks-sg357?file=/src/Drawer.jsx:604-763

export type Props = {
  isOpen?: boolean,
  onClose?: () => void,
  children?: React.Node,
  direction?: 'left' | 'right' | 'top' | 'bottom',
};

const DRAWER_ROOT_ID = 'portal-drawer-root';
const ANIMATION_TIME = 250;

const createPortalRoot = () => {
  const root = document.createElement('div');
  root.setAttribute('id', DRAWER_ROOT_ID);
  return root;
};

const Drawer = ({isOpen, onClose, children, direction = 'left'}: Props) => {
  const bodyRef = useRef(document.querySelector('body'));
  const portalRootRef = useRef(document.getElementById(DRAWER_ROOT_ID) || createPortalRoot());
  const stopScroll = () => (bodyRef.current.style.overflow = 'hidden');
  const allowScroll = () => (bodyRef.current.style.overflow = '');

  useEffect(() => {
    //Add the portal root to end of body
    bodyRef.current.appendChild(portalRootRef.current);

    //Set the elements as variables so they are available in clean up function
    const portal = portalRootRef.current;
    const bodyEl = bodyRef.current;

    return () => {
      // Clean up the portal when drawer component unmounts
      portal.remove();
      // Ensure scroll overflow is removed
      bodyEl.style.overflow = '';
    };
  }, []);

  return createPortal(
    <CSSTransition
      unmountOnExit
      onEnter={stopScroll}
      onExit={allowScroll}
      timeout={ANIMATION_TIME}
      in={isOpen}
      classNames="drawer"
    >
      <DrawerContainer direction={direction} timeout={ANIMATION_TIME}>
        <Overlay onClick={() => onClose && onClose()} />
        <StyledDrawer direction={direction}>{children}</StyledDrawer>
      </DrawerContainer>
    </CSSTransition>,
    portalRootRef.current
  );
};

export default Drawer;
