// @flow
import {formatOpeningHour} from 'data/affiliate/formatters';
import type {OpeningHours} from 'data/affiliate/types';
import {compose, groupWith, map, mapObjIndexed, omit, values} from 'ramda';

type SubItem = {|name: string, v: string|};

const formatSubarray = (sub: SubItem[]) => {
  if (sub.length > 1) {
    const firstEl = sub[0];
    const lastEl = sub[sub.length - 1];

    return {name: `${firstEl.name} - ${lastEl.name}`, v: firstEl.v};
  }

  return sub[0];
};

export const squashSameOpeningHours = (data: OpeningHours): SubItem[] =>
  compose(
    map(formatSubarray),
    groupWith((a, b) => a.v === b.v),
    values,
    mapObjIndexed((v, k) => ({name: k, v: formatOpeningHour(v)})),
    omit(['__typename'])
  )(data);
