// @flow
import gql from 'graphql-tag';

export const bookingMinFragment = gql`
  fragment bookingMinFragment on Booking {
    bookingId
    externalId
    start
    end
    returnedAt
  }
`;

export const deliveryDestinationFragment = gql`
  fragment deliveryDestinationFragment on Booking {
    deliveryDestination {
      name
      company
      address
      instructions
      additionalContact {
        email
        phone
      }
    }
  }
`;

export const bookingFragment = gql`
  fragment bookingFragment on Booking {
    fulfillmentType
    bookingId
    externalId
    start
    end
    affiliateId
    order {
      orderId
      externalId
    }
    ...deliveryDestinationFragment
  }
  ${deliveryDestinationFragment}
`;
