// @flow
import {mq} from 'common/mediaQuery';
import styled, {css} from 'styled-components';

export const FixedBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.color.white};
  padding: 1.5rem 2.4rem;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -0.1rem 0.5rem 0 ${props => props.theme.color.shadow};
  z-index: ${props => props.theme.zIndices.overlayOverlay};

  ${p =>
    p.column &&
    css`
      flex-direction: column;
      align-items: center;
      text-align: center;
    `}

  ${mq.tabletWide} {
    z-index: initial;
    position: relative;
    box-shadow: none;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    margin-top: 2rem;
  }
`;

export const FixedBottomSpacer = styled.div`
  height: 13rem;

  ${mq.tabletWide} {
    display: none;
  }
`;
