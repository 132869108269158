// @flow
import type {ExtractActionTypes} from 'common/redux/types';
import * as actions from 'data/user/actions';

export type UserLocationState = ?Position;
type Action = ExtractActionTypes<typeof actions>;

const initialState: UserLocationState = null;

function userLocationReducer(
  state: UserLocationState = initialState,
  action: Action
): UserLocationState {
  switch (action.type) {
    case 'USER_LOCATION/SET':
      return action.payload;
    default:
      return state;
  }
}

export default userLocationReducer;
