// @flow
import {mq, mqMax} from 'common/mediaQuery';
import {StyledButton as Button} from 'components/Button/styled';
import {Text} from 'componentsStyled/Typography/Texts';
import {lighten} from 'global/theme';
import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: ${props => props.theme.zIndices.notification};
  background: ${props => lighten(props.theme.color.white, 0.95)};
  min-height: 5rem;
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  width: 90%;
  justify-content: space-around;
  align-items: center;

  > * {
    margin-right: 1rem;
  }

  ${mqMax.phoneWide} {
    width: 100%;
    margin: 1rem 0;
    flex-direction: column;
    justify-content: center;
  }

  ${mq.desktop} {
    justify-content: center;

    > * {
      margin-right: 2rem;
    }
  }
`;

export const StyledText = styled(Text)`
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;

  ${mqMax.phoneWide} {
    text-align: center;
    margin-bottom: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  min-width: 2rem;
  height: 2.5rem;
  line-height: 0;
  border-radius: 0;
  font-size: 1rem;

  ${mqMax.phoneWide} {
    width: 50%;
    justify-content: center;
    font-size: 1.2rem;
    border-radius: 0.25rem;
  }

  align-items: center;
`;
