// @flow
import getErrorMessage from 'common/graphql/getErrorMessage';
import StyledLink from 'components/StyledLink';
import type {StripeCard} from 'data/stripe/types';
import {createCustomerMutation, editCreditCardMutation} from 'data/user/graphql/mutations';
import withMutation from 'hoc/withMutation';
import withSubmit from 'hoc/withSubmit';
import ModalBody from 'modals/_Body';
import ModalHeader from 'modals/_Header';
import React from 'react';
import {type HOC, compose, withProps} from 'recompose';

import CreditCardForm from './CreditCardForm';

/**
 *
 * @param {Function} close - Handler for closing the modal
 * @param {string} type - Tells the component which mutation to use. "add" will use createCustomer, "edit" will use editCustomer
 * @param {Function} submit - Handler for submitting the mutation
 * @param {StripeCard} data - Contains information about the credit card if it was created previously
 * @param {boolean} allowSkip - Allows for modal to be skippable
 * @param {boolean} minimal - If true, will render only the CreditCardForm without the modal
 * @param {string} displayText - If set, will override the default display text on the CreditCardForm input
 * @param {string} submitText - If set, will override the text for the submit button on the CreditCardForm input
 */
const CreditCard = ({
  close,
  type,
  submit,
  data,
  allowSkip = true,
  minimal,
  displayText,
  submitText,
  noClose,
  currentStep,
  finalStep,
}) => {
  if (minimal) {
    return (
      <CreditCardForm
        onSubmit={submit}
        type={type}
        data={data}
        displayText={displayText}
        submitText={submitText}
        currentStep={currentStep}
        finalStep={finalStep}
      />
    );
  }

  const title = type === 'edit' ? 'Edit card' : 'Add card';

  return (
    <ModalBody close={noClose ? undefined : close}>
      <ModalHeader close={noClose ? undefined : close} title={title}>
        {allowSkip && <StyledLink onClick={noClose ? undefined : close}>Skip</StyledLink>}
      </ModalHeader>
      <CreditCardForm
        onSubmit={submit}
        type={type}
        data={data}
        currentStep={currentStep}
        finalStep={finalStep}
      />
    </ModalBody>
  );
};

type Outter = {|
  close: Function,
  type: 'add' | 'edit',
  data?: StripeCard,
  onSuccess?: Function,
  allowSkip?: boolean,
  minimal?: boolean,
  displayText?: string,
  submitText?: string,
  noClose?: boolean,
  currentStep?: string,
  finalStep?: string,
|};

const enhancer: HOC<*, Outter> = compose(
  withMutation(createCustomerMutation),
  withProps(props => ({
    add: props.submitMutation,
    card: {
      last4: props.data && props.data.last4,
      type: props.data && props.data.brand,
    },
  })),
  withMutation(editCreditCardMutation),
  withProps(props => ({
    edit: props.submitMutation,
  })),
  withSubmit({
    submit: props => v => {
      if (props.type === 'add') {
        return props.add({token: v});
      }
      return props.edit({token: v});
    },
    onSuccess: props => {
      if (props.onSuccess) {
        props.close();
        // $ExpectError
        return props.onSuccess;
      }
      return props.close;
    },
    onError: props => error => {
      return getErrorMessage(error);
    },
  })
);

export default enhancer(CreditCard);
