// @flow
import type {Selector} from 'common/redux/types';
import type {Accessory, OriginalReservation} from 'data/reservations/types';
import type {ShippingAddress} from 'data/shipping/types';
import type {DateRange} from 'data/units/date/types';
import {compose, prop} from 'ramda';

import type {ReservationsState} from './reducer';

const base: Selector<ReservationsState> = prop('reservations');

export const selectDates: Selector<?DateRange> = compose(prop('dates'), base);

export const selectOriginalReservation: Selector<?OriginalReservation> = compose(
  prop('originalReservation'),
  base
);

export const selectAccessories: Selector<Accessory[]> = compose(prop('accessories'), base);

export const selectShippingAddress: Selector<?ShippingAddress> = compose(
  prop('shippingAddress'),
  base
);

export const selectAvailability: Selector<DateRange[]> = compose(prop('availability'), base);
