// @flow
import * as React from 'react';

import {ModalContentWrap} from './styled';

type Props = {|
  children: React.Node,
  id?: string,
|};

const ModalContent = ({children, id}: Props) => (
  <ModalContentWrap id={id}>{children}</ModalContentWrap>
);

export default ModalContent;
