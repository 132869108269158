import {identifyUser} from 'analytics/user/analyticsTraits';
import {noopAction} from 'common/redux/actions';
import {push} from 'connected-react-router';
import {selectRedirectCallback} from 'data/app/selectors';
import {onAffiliatePage, onSearchPage} from 'data/router/helpers';
import {selectPathname} from 'data/router/selectors';
import urls from 'data/router/urls';
import {purgeEverything} from 'global/apolloClient';
import {ofType} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

import {onProductPage, onProductsPage, onReservePage} from '../router/helpers';
import {logoutFinished} from './actions';

const loginEpic = (action$, state$) =>
  action$.pipe(
    ofType('USER/LOGIN'),
    switchMap(({payload}) => {
      const path = selectPathname(state$.value);
      identifyUser(payload);

      if (
        !onSearchPage(path) &&
        !onAffiliatePage(path) &&
        !onProductPage(path) &&
        !onProductsPage(path) &&
        !onReservePage(path)
      ) {
        return of(push(`${urls.home}${window.location.search}`));
      }

      const redirectCallback = selectRedirectCallback(state$.value);

      // TODO: ideally a push shouldn't forced here, but rather a noop action
      // due to a react-apollo issue (#3425) with does not update network status correctly
      // this will be kept like this until it's fixed and we update the react-apollo version
      // store.dispatch in rxJS middleware is going to be deprecated as it shouldn't be used
      // under normal circumstances
      return redirectCallback ? state$.dispatch(redirectCallback()) : of(push(`${path}`));
    }),
    catchError(error => of(noopAction()))
  );

const logoutEpic = action$ =>
  action$.pipe(
    ofType('USER/LOGOUT'),
    switchMap(() => purgeEverything(true)),
    switchMap(() => of(push(urls.home), logoutFinished())),
    catchError(error => of(push(urls.home), logoutFinished()))
  );
//Navigate user to the homepage and dispatch the logout completed action.

const claimMasqueradeEpic = (action$, store) =>
  action$.pipe(
    ofType('USER/MASQUERADE'),
    switchMap(() => of(push(urls.home), noopAction())),
    catchError(error => of(noopAction()))
  );

const epics = [loginEpic, logoutEpic, claimMasqueradeEpic];
export default epics;
