// @flow
import StyledTextArea from 'componentsStyled/Forms/TextArea';
import withFormField, {type ReqOutter} from 'forms/withFormField';
import React from 'react';
import {type HOC} from 'recompose';

//The supported (non-experimental) keyword values for CSS resize style
export type CSSResize = 'none' | 'both' | 'horizontal' | 'vertical';

const TextArea = ({value = '', onChange, rows = 3, resize = 'none', ...rest}) => (
  <StyledTextArea {...{rows, onChange, value, resize}} {...rest} />
);

type Outter = {|
  ...$Exact<ReqOutter>,
  placeholder?: string,
  rows?: number,
  resize?: CSSResize,
  autoComplete?: string,
  disabled?: boolean,
  hasError?: boolean,
|};

const enhancer: HOC<*, Outter> = withFormField;

export default enhancer(TextArea);
