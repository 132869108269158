// @flow
import {Text} from 'componentsStyled/Typography';
import styled from 'styled-components';

export const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.7rem;
  margin: 0;
`;
