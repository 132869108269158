// @flow
import styled, {css} from 'styled-components';

export const PrevNextArrowsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: -3rem;
  position: relative;
  z-index: ${props => props.theme.zIndices.minimal};
`;

export const ArrowWrap = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${p =>
    p.disabled &&
    css`
      pointer-events: none;
      opacity: 0.25;
    `}
`;
