// @flow
import type {User} from '../../data/user/types';
import {tryTrack} from '../helpers';

const creditCardTrait = (u: User, desc: string) => ({
  email: u && u.email,
  description: desc,
});

export const trackCard = (eventName: 'Card Rejected', u: User, desc: string) =>
  tryTrack('Card Rejected', () => creditCardTrait(u, desc));
