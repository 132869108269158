// @flow
import useRadioGroup from 'components/Radio/useRadioGroup';
import * as React from 'react';

import {HiddenRadioInput, RadioPillContainer} from './styled';

export type Props = {
  children?: React.Node,
  value: any,
  name?: string,
  checked?: boolean,
  onChange?: (value: any) => any,
  disabled?: boolean,
  className?: string,
  'data-cy'?: string,
};

const RadioPill = ({
  children,
  disabled,
  name,
  value,
  onChange,
  checked,
  className,
  'data-cy': dataCy,
}: Props) => {
  const {
    checked: internalChecked,
    name: internalName,
    onChange: internalOnChange,
  } = useRadioGroup({
    name,
    value,
    onChange,
    checked,
  });

  return (
    <RadioPillContainer
      data-cy={dataCy}
      className={className}
      disabled={disabled}
      checked={internalChecked}
    >
      {children}
      <HiddenRadioInput
        disabled={disabled}
        checked={internalChecked}
        name={internalName}
        onChange={internalOnChange}
        value={value}
        type="radio"
      />
    </RadioPillContainer>
  );
};

export default RadioPill;
