// @flow
import {hideGDPR} from 'data/app/actions';
import {selectGDPRHidden} from 'data/app/selectors';
import withConnect from 'hoc/withConnect';
import React from 'react';

import {Layout, StyledButton as Button, StyledText as Text, Wrap} from './styled';

const GDPRWarning = ({GDPRHidden, hideGDPR}) =>
  !GDPRHidden && (
    <Wrap>
      <Layout>
        <Text>
          By clicking "Accept Cookies", you agree to the storing of cookies on your device to
          enhance site navigation, analyze site usage, and assist in our marketing efforts.
        </Text>
        <Button onClick={hideGDPR}>Accept Cookies</Button>
      </Layout>
    </Wrap>
  );

const mapStateToProps = state => ({
  GDPRHidden: selectGDPRHidden(state),
});

const mapDispatchToProps = {
  hideGDPR,
};

export default withConnect(mapStateToProps, mapDispatchToProps)(GDPRWarning);
