/// @flow
import type {Query} from 'common/graphql/types';
import type {Category} from 'data/app/types';
import type {Location} from 'data/app/types';
import type {Manufacturer} from 'data/manufacturer/types';
import type {Product} from 'data/product/types';
import gql from 'graphql-tag';

export const locationFilterQuery: Query<
  Location[],
  {
    categoryIds?: number[],
    affiliateId?: number,
    manufacturerIds?: number[],
    productIds?: number[],
  }
> = {
  gql: gql`
    query LocationFilterQuery($filter: LocationNearbyFilter) {
      location {
        listLocations(filter: $filter) {
          id
          name
          lat
          lng
          country {
            id
            code
            name
          }
        }
      }
    }
  `,
  selector: ['location', 'listLocations'],
};

export const categoryFilterQuery: Query<
  Category[],
  {
    affiliateId?: number,
    manufacturerIds?: number[],
    productIds?: number[],
    locationIds?: number[],
    availableOnly?: boolean,
  }
> = {
  gql: gql`
    query CategoryFilterQuery($filter: CategoryListFilter) {
      category {
        list(filter: $filter) {
          categoryId
          name
        }
      }
    }
  `,
  selector: ['category', 'list'],
};

export const brandFilterQuery: Query<
  Manufacturer[],
  {
    categoryIds?: number[],
    affiliateIds?: number[],
    productIds?: number[],
    locationIds?: number[],
    availableOnly?: boolean,
  }
> = {
  gql: gql`
    query ManufacturerFilterQuery($filter: ManufacturerFilter) {
      manufacturer {
        list(filter: $filter) {
          data {
            id
            name
          }
        }
      }
    }
  `,
  selector: ['manufacturer', 'list', 'data'],
};

export const productFilterQuery: Query<
  Product[],
  {
    categoryIds?: number[],
    affiliateId?: number,
    manufacturerIds?: number[],
    locationIds?: number[],
  }
> = {
  gql: gql`
    query ProductFilterQuery($filter: ProductFilter) {
      product {
        list(filter: $filter) {
          data {
            name
            id
          }
        }
      }
    }
  `,
  selector: ['product', 'list', 'data'],
};
