// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import React from 'react';

import {CenterContainer, Circle} from './styled';

const NoDelete = () => (
  <CenterContainer>
    <Circle />
    <Text black book center>
      Please return the board
    </Text>
    <Text center>
      Unfortunately you can not delete your account while you have current reservations.
    </Text>
  </CenterContainer>
);

export default NoDelete;
