// @flow
import Checkbox from 'components/Checkbox';
import * as React from 'react';

import {MultiSelectItemName, StyledMultiSelectItem} from './styled';

export type Props = {
  checked?: boolean,
  disabled?: boolean,
  name: string,
  onClick: () => any,
};

const MultiSelectItem = ({checked, name, onClick, disabled}: Props) => {
  const handleClick = () => !disabled && onClick();

  return (
    <StyledMultiSelectItem disabled={disabled} onClick={handleClick}>
      <MultiSelectItemName disabled={disabled}>{name}</MultiSelectItemName>
      <Checkbox disabled={disabled} value={checked} />
    </StyledMultiSelectItem>
  );
};

export default MultiSelectItem;
