// @flow
import * as React from 'react';

import {SmudgeContainer} from './styled';

type Props = {|
  children?: React.Node,
  center?: boolean,
  first?: boolean,
|};

const Smudge = ({children, first, center}: Props) => (
  <SmudgeContainer first={first}>{children}</SmudgeContainer>
);

export default Smudge;
