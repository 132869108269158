// @flow
import styled from 'styled-components';

export const ImageSwitchNumericWrap = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  color: ${props => props.theme.color.secondaryText};
  font-size: 1.3rem;
`;
