// @flow
import styled from 'styled-components';

export const HamburgerToggleWrapper = styled.button`
  position: relative;
  width: 2.6rem;
  height: 2.6rem;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;
`;

export const Line = styled.span`
  height: 0.2rem;
  width: 1.8rem;
  margin: 0.15rem 0;
  background: ${props => props.theme.color.black};
`;
