// @flow
import styled from 'styled-components';

export const MasqueradeBannerWrap = styled.div`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6.4rem;
  position: relative;
  background-color: #341d5c;
  color: #fff;
`;
