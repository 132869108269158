// @flow
import React from 'react';

const DebugPage = () => (
  <button
    onClick={() => {
      throw new Error('Simulated error');
    }}
  >
    Simulate error
  </button>
);

export default DebugPage;
