// @flow
import React from 'react';

export type Props = {
  size?: number,
};

const SortIcon = ({size = 20}: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.85482 13.5313L6.42677 16.2003V1.07499C6.42677 0.483155 5.94919 0.00290119 5.35557 0.00290119C4.76195 0.00290119 4.28437 0.483155 4.28437 1.07499V16.2012L1.85766 13.5701C1.64703 13.3413 1.35955 13.2256 1.07122 13.2256C0.81181 13.2256 0.550795 13.319 0.344589 13.5101C-0.0905856 13.9108 -0.116919 14.5893 0.284602 15.025L4.52923 19.6713C4.9337 20.1096 5.69817 20.1096 6.10255 19.6713L10.3472 15.025C10.7489 14.5894 10.7224 13.9109 10.2872 13.5101C9.93048 13.1028 9.25206 13.134 8.85482 13.5313ZM18.9955 4.97505L14.7509 0.328724C14.3464 -0.109575 13.5819 -0.109575 13.1776 0.328724L8.85482 4.97505C8.45312 5.41058 8.47963 6.0891 8.91481 6.4899C9.12115 6.68098 9.38212 6.77444 9.64144 6.77444C9.93017 6.77444 10.2177 6.65866 10.4279 6.42992L12.854 3.80119V18.9274C12.854 19.5166 13.336 19.9986 13.9252 19.9986C14.5143 19.9986 14.9964 19.5184 14.9964 18.9265V3.80119L17.4231 6.43233C17.822 6.86786 18.4996 6.89696 18.9361 6.49231C19.3704 6.09088 19.3972 5.41246 18.9955 4.97505Z"
      fill="currentColor"
    />
  </svg>
);

export default SortIcon;
