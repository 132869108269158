// @flow
import {mqMax} from 'common/mediaQuery';
import {lighten} from 'global/theme';
import styled, {css} from 'styled-components';

export const Overlay = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: ${props => lighten(props.theme.color.primaryText, 0.6)};
`;

//Determine which way to translate the drawer based on the provided direction
const translate = css`
  ${props => {
    switch (props.direction) {
      case 'right':
        return css`
          transform: translateX(100%);
        `;
      case 'bottom':
        return css`
          transform: translateY(100%);
        `;
      case 'top':
        return css`
          transform: translateY(-100%);
        `;
      case 'left':
      default:
        return css`
          transform: translateX(-100%);
        `;
    }
  }}
`;

export const StyledDrawer = styled.div`
  position: fixed;
  background: ${props => props.theme.color.white};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;

  //Specific styling based on direction
  ${props => {
    switch (props.direction) {
      case 'right':
        return css`
          top: 0;
          right: 0;
          height: 100%;
        `;
      case 'bottom':
        return css`
          inset: auto 0 0;
        `;
      case 'top':
        return css`
          inset: 0 0 auto 0;
        `;
      case 'left':
      default:
        return css`
          top: 0;
          left: 0;
          height: 100%;
        `;
    }
  }}
  //Fullscreen when in mobile viewport
  ${mqMax.phoneWide} {
    inset: 0;
  }
`;

export const DrawerContainer = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${props => props.theme.zIndices.drawer};

  //Added when opened
  &.drawer-enter {
    ${Overlay} {
      opacity: 0;
    }

    ${StyledDrawer} {
      ${translate}
    }
  }

  //Class that is animated TO when entering
  &.drawer-enter-active {
    ${Overlay} {
      transition: opacity ${props => props.timeout}ms;
      opacity: 1;
    }

    ${StyledDrawer} {
      transition: transform ${props => props.timeout}ms ease-in-out;
      transform: translateX(0);
    }
  }

  //Added when closed
  &.drawer-exit {
    ${Overlay} {
      opacity: 1;
    }

    ${StyledDrawer} {
      transform: translate(0);
    }
  }

  //Class that is animated TO when exiting
  &.drawer-exit-active {
    ${Overlay} {
      transition: opacity ${props => props.timeout}ms;
      opacity: 0;
    }

    ${StyledDrawer} {
      transition: transform ${props => props.timeout}ms ease-in-out;
      ${translate}
    }
  }
`;
