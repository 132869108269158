// @flow
import type {Query} from 'common/graphql/types';
import type {CategoryName} from 'data/app/types';
import type {ID} from 'data/enums/types';
import gql from 'graphql-tag';
import {pathOr} from 'ramda';

export type AutocompleteItemType = 'product' | 'affiliate' | 'manufacturer';

export type AutocompleteItem = {
  id: ID,
  name: string,
  type: AutocompleteItemType,
};

export type AutocompleteQueryData = {
  [string]: AutocompleteItem[],
};

type AffiliateItem = {id: string, name: string};
type ProductItem = {id: string, name: string, category: {id: string, name: string}};
type ManufacturerItem = {id: string, name: string};

export const searchAutoCompleteQuery: Query<
  AutocompleteQueryData,
  {categories?: ?(CategoryName[]), query: ?string, locationIds?: ?(ID[])}
> = {
  gql: gql`
    query searchAutoCompleteQuery($query: String, $categories: [String], $locationIds: [Int!]) {
      affiliate {
        list(filter: {name: $query, categories: $categories, allowed: true, limit: 5}) {
          data {
            id
            name
          }
        }
      }
      product {
        list(filter: {name: $query, locationIds: $locationIds, limit: 5, availableOnly: true}) {
          data {
            id
            name
            categories {
              categoryId
              name
            }
          }
        }
      }
      manufacturer {
        list(filter: {name: $query, limit: 5, categories: $categories, locationIds: $locationIds}) {
          data {
            id
            name
          }
        }
      }
    }
  `,
  transform: (data: {
    affiliate: {
      list: {
        data: AffiliateItem[],
      },
    },
    product: {
      list: {
        data: ProductItem[],
      },
    },
    manufacturer: {
      list: {
        data: ManufacturerItem[],
      },
    },
  }) => {
    // this function is not very pretty, but given we don't have the types from this in the first place
    // it remains partially typed like this
    const addType = (type: AutocompleteItemType, arr: Array<Object>): AutocompleteItem[] =>
      arr.map(x => ({
        ...x,
        type,
      }));

    // $Ramda
    const products: Array<ProductItem> = pathOr([], ['product', 'list', 'data'], data);
    // $Ramda
    const affiliates: Array<Object> = pathOr([], ['affiliate', 'list', 'data'], data);
    // $Ramda
    const manufacturers: Array<Object> = pathOr([], ['manufacturer', 'list', 'data'], data);

    return {
      products: addType('product', products),
      affiliates: addType('affiliate', affiliates),
      manufacturers: addType('manufacturer', manufacturers),
    };
  },
};
