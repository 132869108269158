// @flow
import urls from 'data/router/urls';
import {selectLoggedIn} from 'data/user/selectors';
import withConnect from 'hoc/withConnect';
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import type {Component, HOC} from 'recompose';

const LoggedInRoute = ({component, loggedIn, ...rest}) =>
  loggedIn ? <Route {...rest} component={component} /> : <Redirect to={urls.home} />;

const mapStateToProps = state => ({
  loggedIn: selectLoggedIn(state),
});

type Outter = {|
  component: Component<mixed>,
  path: string,
  exact?: boolean,
|};

const enhancer: HOC<*, Outter> = withConnect(mapStateToProps);

export default enhancer(LoggedInRoute);
