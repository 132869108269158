// @flow
import styled from 'styled-components';

export const CardBrandWrap = styled.div`
  margin: 0 1rem;
  display: flex;

  svg {
    width: 3.4rem;
    height: 2.4rem;
    margin-right: 0.5rem;
  }
`;
