// @flow
import HtmlViewer from 'components/HtmlViewer';
import {Container} from 'componentsStyled/Layout/Containers';
import {H1} from 'componentsStyled/Typography';
import type {LegalRentalAgreementContent} from 'data/legal/graphql/types';
import moment from 'moment';
import Page from 'pages/_Page';
import React from 'react';
import {type HOC, compose} from 'recompose';

import {getLegalRentalAgreementContentQuery} from '../../data/legal/graphql/queries';
import withQuery from '../../hoc/withQuery';

type Props = {
  data: LegalRentalAgreementContent,
};

const RentalAgreementPage = (props: Props) => {
  const {data} = props;
  const {content, lastUpdated} = data;
  return (
    <Page>
      <Container>
        {/* TODO(t04435): Remove inline styles | Need to have a closer look at duplicated components per page  */}
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <H1>Rental Agreement</H1>
          <p style={{position: 'absolute', bottom: 0}}>
            {content
              ? lastUpdated && `Last Updated: ${moment(lastUpdated).format('MMM d, YYYY')}`
              : 'No rental agreement provided'}
          </p>
        </div>
        {content ? <HtmlViewer html={content} /> : ''}
      </Container>
    </Page>
  );
};

const enhancer: HOC<*, Props> = compose(withQuery(getLegalRentalAgreementContentQuery));

export default enhancer(RentalAgreementPage);
