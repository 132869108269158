// @flow
import type {DeliveryDestination} from 'data/bookings/types';
import type {ShippingAddress} from 'data/shipping/types';
import {isEmpty, reject} from 'ramda';

export const shippingAddressToDeliveryDestination = ({
  name,
  company,
  address,
  instructions,
  enableAdditionalContact,
  additionalEmail,
  additionalPhone,
}: ShippingAddress): DeliveryDestination => {
  //Remove any keys that are nully or 'empty' (e.g. '' or {})
  const rejectPredicate = val => val == null || isEmpty(val);

  const additionalContact = enableAdditionalContact
    ? reject(rejectPredicate, {email: additionalEmail, phone: additionalPhone})
    : null;

  // $Ramda
  return reject(rejectPredicate, {
    name,
    company,
    address,
    instructions,
    additionalContact,
  });
};
