// @flow
import type {Theme} from 'global/theme';
import React from 'react';
import {withTheme} from 'styled-components';

const NearbyIcon = ({theme}: {theme: Theme}) => (
  <svg width="16" height="16">
    <path
      fill={theme.color.primaryAccent}
      d="M6.254 9.763l1.57 5.621a.821.821 0 0 0 .739.616h.058a.821.821 0 0 0 .761-.515l6.56-14.359A.821.821 0 0 0 14.873.06L.516 6.619a.821.821 0 0 0 .11 1.56l5.628 1.584z"
    />
  </svg>
);

export default withTheme(NearbyIcon);
