// @flow
import styled from 'styled-components';

export const LeftWrap = styled.div`
  width: 160px;
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 40px;
  }
`;

export const SearchWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const RightWrap = styled.div`
  width: 160px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * + * {
    margin-left: 40px;
  }
`;
