// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import type {Reservation} from 'data/reservations/types';
import {momentFromDateString} from 'data/units/date/helpers';
import * as React from 'react';

type Props = {
  extendedReservation: Reservation,
};

const ExtendReservationConfirm = ({extendedReservation}: Props) => {
  // TODO(Jude): Handle muliple categories
  const displayName = extendedReservation.productItem.productVariant.product.categories[0].name;

  return (
    <React.Fragment>
      <PageTitle>Extension Confirmed!</PageTitle>
      <Text book black center lh={2}>
        You're ready to go
      </Text>
      <Text center>
        You now have your {displayName} until{' '}
        {momentFromDateString(extendedReservation.booking.end).format('MMM D')}
      </Text>
    </React.Fragment>
  );
};

export default ExtendReservationConfirm;
