// @flow
import * as React from 'react';
// $ReactHooks
import {useEffect, useMemo, useRef, useState} from 'react';
import {Transition} from 'react-transition-group';

import TickIcon from './index';

export type Props = {
  size?: number,
  color?: string,
  show?: boolean,
  style?: Object,
  duration?: number,
  className?: string,
};

const transitionStyles = {
  entering: {strokeDashoffset: 0, opacity: 1},
  entered: {strokeDashoffset: 0, opacity: 1},
  exiting: {strokeDashoffset: 0, opacity: 0},
};

//Simple explanation of how this animation works: https://css-tricks.com/animate-svg-path-changes-in-css/
const AnimatedTickIcon = ({show, duration = 250, size, color, style, className}: Props) => {
  const iconRef = useRef();
  const [pathLength, setPathLength] = useState(0);

  useEffect(() => {
    if (iconRef.current) {
      const path = iconRef.current.querySelector('path');
      if (path) {
        setPathLength(path.getTotalLength());
      }
    }
  }, []);

  const defaultStyle = useMemo(
    () => ({
      strokeDasharray: `${pathLength} ${pathLength}`,
      strokeDashoffset: -pathLength,
      opacity: 0,
      transition: `stroke-dashoffset ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
    }),
    [pathLength, duration]
  );

  return (
    <Transition in={show} timeout={duration}>
      {state => (
        <TickIcon
          size={size}
          ref={iconRef}
          className={className}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
            ...style,
          }}
        />
      )}
    </Transition>
  );
};

export default AnimatedTickIcon;
