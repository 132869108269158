// @flow
import AddressInput from 'forms/Input/AddressInput';
import TextArea from 'forms/Input/TextArea';
import usePrevious from 'hooks/usePrevious';
import {
  AddressInputWrapper,
  ManualAddressButton,
} from 'pages/DeliveryAddress/DeliveryAddressForm/DeliveryAddressField/styled';
import {path} from 'ramda';
// $ReactHooks
import {useEffect} from 'react';
import React from 'react';
import {geocodeByPlaceId} from 'react-places-autocomplete';

const ALLOWED_PLACE_TYPES_FOR_DELIVERY_ADDRESS = [
  'premise',
  'subpremise',
  'street_address',
  'geocode',
];

export type Props = {
  setFieldValue: (string, any) => any,
  address: string,
  useManualAddress: boolean,
  searchOptions?: ?Object,
};

const DeliveryAddressField = ({setFieldValue, address, useManualAddress, searchOptions}: Props) => {
  const toggleManualAddress = () => setFieldValue('useManualAddress', !useManualAddress);

  const suggestionsFilter = suggestion => {
    if (!(suggestion && suggestion.types)) {
      return false;
    }
    return suggestion.types.every(type => ALLOWED_PLACE_TYPES_FOR_DELIVERY_ADDRESS.includes(type));
  };

  //Called with address, placeId and addressObject when an address is selected from the dropdown. In
  //this case we do a call to geocodeByPlaceId to get the formatted address, update the value to this
  //formatted address and save the full address object.
  //It is otherwise called with no props when the AddressInput text field is changed (i.e. when searching).
  //In this case we clear the addressObject as it no longer matches the text in the input.
  const onAddressSelect = async (address, placeId, addressObject) => {
    if (placeId) {
      const detailedAddresses = await geocodeByPlaceId(placeId);
      const formattedAddress = path([0, 'formatted_address'], detailedAddresses);
      if (formattedAddress) {
        setFieldValue('address', formattedAddress);
        setFieldValue('addressObject', addressObject);
        return;
      }
    }
    setFieldValue('addressObject', null);
  };

  //If we change the value of address while in the 'manual address' mode, then clear
  //The address object as it no longer matches the value in the address field.
  const previousAddress = usePrevious(address);
  useEffect(() => {
    if (useManualAddress && previousAddress !== address) {
      setFieldValue('addressObject', null);
    }
  }, [address, previousAddress, useManualAddress, setFieldValue]);

  return (
    <AddressInputWrapper>
      {useManualAddress ? (
        <TextArea name="address" label="Full address" />
      ) : (
        <AddressInput
          name="address"
          label="Full address"
          onSelect={onAddressSelect}
          suggestionsFilter={suggestionsFilter}
          data-cy={'address-autocomplete'}
          searchOptions={{
            ...(searchOptions || {}),
            types: ['address'],
          }}
        />
      )}
      <ManualAddressButton data-cy="address-toggle" onClick={toggleManualAddress}>
        {useManualAddress ? 'Look up address' : 'Enter address manually'}
      </ManualAddressButton>
    </AddressInputWrapper>
  );
};

export default DeliveryAddressField;
