// @flow
import {mq} from 'common/mediaQuery';
import styled, {css, keyframes} from 'styled-components';

const getKeyframes = (index: number, count: number) => keyframes`
  0% {
    opacity: 0;
  }
  ${(100 / count) * (index - 1) - 5}% {
    opacity: 0;
  }
  ${(100 / count) * (index - 1)}% {
    opacity: 1;
  }
  ${(100 / count) * index - 5}% {
    opacity: 1;
  }
  ${(100 / count) * index}% {
    opacity: 0;
  }
  100% {
      opacity: 0;
  }
`;

const getAnimation = (index: number, count: number) => css`
  animation: ${getKeyframes(index, count)} 12s linear infinite;
`;

export const StyledHeroSection = styled.section`
  max-height: 90vh;
  min-height: 90vh;
  display: flex;
  align-items: center;

  ${mq.tabletWide} {
    background-position-y: bottom;
  }
`;

export const Layer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-image: url(${p => p.src});
  background-size: cover;

  ${mq.phone} {
    background-position: center center;
  }
  background-position: calc(50% - 5rem) calc(50% - 2rem);

  /* first layer is always there */
  ${p => p.index > 0 && getAnimation(p.index + 1, p.count)};
`;
