// @flow
import './index.css';

import {breakpoints} from 'common/mediaQuery';
import ImageSwitch from 'components/ImageSwitch';
import type {Image} from 'data/images/types';
import NukaCarousel from 'nuka-carousel';
import {flatten, prop, sortBy} from 'ramda';
import * as React from 'react';
import ReactMediaQuery from 'react-responsive';
import {type HOC, compose, withHandlers, withProps, withStateHandlers} from 'recompose';

import {desktopView, individualView, mobileView} from './options';
import {CarouselWrap} from './styled';

const Carousel = ({
  activeIndex,
  sortedChildren,
  setActiveIndex,
  height,
  mobileStyle,
  individual,
  thumbnails,
  handleSlideChange,
  customStyle,
  breakpoint = breakpoints.tabletWide,
}) => (
  <CarouselWrap height={height}>
    <ReactMediaQuery minWidth={breakpoint}>
      {(matches: boolean) => {
        const slideCount = flatten(sortedChildren);

        const viewStyle = individual ? individualView : matches ? desktopView : mobileView;
        const style =
          mobileStyle && !matches
            ? {...viewStyle, ...mobileStyle}
            : customStyle
            ? {...viewStyle, ...customStyle}
            : viewStyle;

        return (
          <React.Fragment>
            <NukaCarousel
              withoutControls={slideCount.length < parseInt(style.slidesToShow, 10) ? true : false}
              beforeSlide={handleSlideChange}
              slideIndex={activeIndex}
              {...style}
            >
              {sortedChildren.map((item, index) => item)}
            </NukaCarousel>
            {thumbnails && (
              <ImageSwitch
                images={thumbnails}
                activeIndex={activeIndex}
                onChangeIndex={setActiveIndex}
              />
            )}
          </React.Fragment>
        );
      }}
    </ReactMediaQuery>
  </CarouselWrap>
);

type Props = {
  children: React.Node[],
  mobileStyle?: Object,
  individual?: boolean,
  thumbnails?: Image[],
  customStyle?: Object,
  height?: number,
  breakpoint?: number,
};

const enhancer: HOC<*, Props> = compose(
  withStateHandlers(
    {
      activeIndex: 0,
    },
    {
      setActiveIndex: () => (activeIndex: number) => ({activeIndex}),
    }
  ),
  withHandlers({
    handleSlideChange: props => (current, next) => {
      props.setActiveIndex(next);
    },
  }),
  withProps(props => ({
    // $Ramda
    sortedChildren: sortBy(prop('order'), props.children),
  }))
);

export default enhancer(Carousel);
