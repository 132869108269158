// @flow
import {HPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import styled from 'styled-components';

export const MainNavWrap = styled(HPaddedFullWidthContainer)`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  display: flex;
  align-items: center;
  height: 6.4rem;
  position: relative;
`;
