// @flow
import {isNil, prop, sortBy} from 'ramda';

import type {Image, ImageSize} from './types';

export const selectIndexImage = (x: Image[]): Image => {
  if (x.length === 0) {
    console.warn('images arrived empty');
    return {
      sizes: [
        {
          width: 0,
          height: 0,
          url: '',
        },
      ],
    };
  }
  if (isNil(x[0].order)) {
    return x[0];
  }

  return sortBy(prop('order'), x)[0];
};

const minPropsImg = (x: Image, minValue: number, propName: string): ImageSize => {
  // this shouldn't be needed, but backend is broken
  if (!x || !x.sizes || x.sizes.length === 0) {
    return {
      height: 0,
      width: 0,
      url: '',
    };
  }

  const sortedBySize = sortBy(prop(propName), x.sizes);
  const smallestLargerThanMin = sortedBySize.find(x => x[propName] >= minValue);

  return smallestLargerThanMin || sortedBySize[sortedBySize.length - 1];
};

export const minWidthImg = (x: Image, minWidth: number): ImageSize =>
  minPropsImg(x, minWidth, 'width');

export const minHeightImg = (x: Image, minHeight: number): ImageSize =>
  minPropsImg(x, minHeight, 'height');
