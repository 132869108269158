//@flow
import queryString from 'querystring';
import {tail} from 'ramda';

import {activities} from './constants';

export const parseQueryString = (query: string) => queryString.parse(tail(String(query)));

export const transformActivityParam = (param: string) => {
  if (!param) return;

  switch (param) {
    case 'ocean':
      return activities.SURF;
    case 'mountains':
      return activities.SKI;
    case 'wheels':
      return activities.ROAD_BIKE;
    default:
      return;
  }
};

export const transformActivitiesParam = (param: string) => {
  if (!param) return;

  switch (param) {
    case 'surf':
      return activities.SURF;
    case 'sup':
      return activities.SUP;
    case 'ski':
      return activities.SKI;
    case 'snowboard':
      return activities.SNOWBOARD;
    case 'splitboard':
      return activities.SPLITBOARD;
    case 'road-bike':
      return activities.ROAD_BIKE;
    case 'mountain-bike':
      return activities.MOUNTAIN_BIKE;
    default:
      return;
  }
};
