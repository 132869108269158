// @flow
import type {PendingReservation} from 'data/basket/types';
import {path, uniq} from 'ramda';

export const getUniqueCurrencies = (items: ?(PendingReservation[])) => {
  if (!items || items.length === 0) {
    return [];
  }

  return uniq(
    items
      .map(pendingReservation =>
        path(['affiliate', 'location', 'country', 'currency'], pendingReservation)
      )
      .filter(Boolean)
  );
};
