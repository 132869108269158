// @flow
import {Container} from 'componentsStyled/Layout/Containers';
import {H1} from 'componentsStyled/Typography';
import React from 'react';

import {Inner, Raw} from './styled';
import terms from './terms';

const SectionTermsOfServices = () => (
  <Container>
    <Inner>
      <H1>Terms of Services</H1>
      <Raw>{terms}</Raw>
    </Inner>
  </Container>
);

export default SectionTermsOfServices;
