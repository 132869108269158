// @flow
import type {AffiliateExtensionDetails} from 'data/affiliate/types';
import {reservationStatuses} from 'data/reservations/constants';
import {fromDateString, momentFromDateString} from 'data/units/date/helpers';
import type {DateString} from 'data/units/date/types';
import type {Profile} from 'data/user/types';

import type {Reservation} from './types';

// returning
const isCheckedOut = (r: Reservation) => r.status === reservationStatuses.checked_out;

export const isTodayForReturn = (r: Reservation, d: DateString) =>
  isCheckedOut(r) && momentFromDateString(r.booking.end).isSame(fromDateString(d), 'day');

export const isLateForReturn = (r: Reservation, d: DateString) =>
  isCheckedOut(r) && momentFromDateString(r.booking.end).isBefore(fromDateString(d), 'day');

// picking up
export const isConfirmed = (r: Reservation) =>
  r.status === reservationStatuses.confirmed || r.status === reservationStatuses.pre_processing;

export const isTodayForPickup = (r: Reservation, d: DateString) =>
  isConfirmed(r) && momentFromDateString(r.booking.start).isSame(fromDateString(d), 'day');

export const isLateForPickup = (r: Reservation, d: DateString) =>
  isConfirmed(r) && momentFromDateString(r.booking.start).isBefore(fromDateString(d), 'day');

export const STATES = {
  lateForReturn: 'Late for return',
  returnToday: 'Due for return today',
  lateForPickup: 'Late for pickup',
  pickupToday: 'Due for pickup today',
};

export const checkSkiPreconditions = (profile: Profile) => {
  if (profile.din) return true;

  if (!profile.weight || !profile.birthdate || !profile.height || !profile.skierType) return false;

  return true;
};

export const isWithinExtensionPeriod = (
  reservation: Reservation,
  affiliateExtensionDetails: AffiliateExtensionDetails,
  date: DateString
) => {
  //If missing any data, then return false
  if (!reservation || !affiliateExtensionDetails || !date) {
    return false;
  }

  const [extensionEnabled, extensionCutoffDays] =
    reservation.booking.fulfillmentType === 'DELIVERY'
      ? [
          affiliateExtensionDetails.allowDeliveryBookingExtension,
          affiliateExtensionDetails.deliveryExtensionCutoffDays,
        ]
      : [
          affiliateExtensionDetails.allowInStoreBookingExtension,
          affiliateExtensionDetails.inStoreExtensionCutoffDays,
        ];

  if (!extensionEnabled) {
    return false;
  }

  const finalDayForExtension = momentFromDateString(reservation.booking.end).subtract(
    extensionCutoffDays,
    'days'
  );

  return momentFromDateString(date).isSameOrBefore(finalDayForExtension, 'day');
};
