// @flow
import Loader from 'components/Loader';
import type {
  AutocompleteItem,
  AutocompleteQueryData,
} from 'data/search/graphql/queries/autocomplete';
import * as React from 'react';

import ItemGroups from './ItemGroups';
import RecentSearches from './RecentSearches';
import {NoResults} from './styled';

type Props = {|
  loading: boolean,
  data: AutocompleteQueryData,
  isEmpty: boolean,
  onItemSelect: AutocompleteItem => mixed,
  close: Function,
  activeIndex: number,
|};

const AutocompleteContent = ({isEmpty, loading, data, onItemSelect, activeIndex, close}: Props) => {
  if (isEmpty) {
    return <RecentSearches close={close} />;
  }

  if (loading) {
    return <Loader />;
  }

  let resultsFound = false;
  for (let key of Object.keys(data)) {
    if (data[key].length > 0) {
      resultsFound = true;
      break;
    }
  }
  if (!resultsFound) {
    return <NoResults>No match found.</NoResults>;
  }

  return <ItemGroups data={data} onItemSelect={onItemSelect} activeIndex={activeIndex} />;
};
export default AutocompleteContent;
