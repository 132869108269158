// @flow
import 'analytics/index';

import * as Sentry from '@sentry/react';
import {trackPageView} from 'analytics/index';
import config from 'global/config';
import withRouter from 'hoc/withRouter';
import React, {Component} from 'react';
import {type HOC} from 'recompose';

import Content from './Content';

class App<P: *> extends Component<P, {error: ?string}> {
  state = {
    error: null,
  };

  constructor() {
    super();
    if (config.sentryDsn) {
      Sentry.init({
        dsn: config.sentryDsn,
        environment: config.environment,
      });
    }
  }

  componentDidUpdate(prev: P) {
    if (prev.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
      trackPageView();
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({error});
    Sentry.captureException(error, {extra: errorInfo});
  }

  render() {
    return <Content error={this.state.error} />;
  }
}

// so apparently using connect will break updating this top level component, so withRouter has to be used
const enhancer: HOC<*, {||}> = withRouter;

export default enhancer(App);
