// @flow
import {constructGraphqlError} from 'common/graphql/errors';
import Button from 'components/Button';
import {FixedBottom, FixedBottomSpacer} from 'componentsStyled/Layout/FixedBottom';
import {WrapNoDesktop} from 'componentsStyled/Layout/Wrap';
import {changePasswordMutation} from 'data/auth/graphql/mutations';
import {notificationSuccess} from 'data/notifications/actions';
import Alert from 'forms/Alert';
import TextInput from 'forms/Input/Text';
import {password, stringRequired} from 'forms/validators/string';
import withForm from 'forms/withForm';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import React from 'react';
import {type HOC, compose} from 'recompose';

const ChangePasswordForm = ({isSubmitting, error = ''}) => (
  <React.Fragment>
    <WrapNoDesktop>
      <Alert>{error}</Alert>
      <TextInput
        name="oldPassword"
        label="Old Password"
        type="password"
        placeholder="Old password"
      />
      <TextInput name="password" label="Password" type="password" placeholder="New password" />
      <TextInput
        name="passwordAgain"
        label="Confirm Password"
        type="password"
        placeholder="Confirm password"
      />
    </WrapNoDesktop>
    <FixedBottomSpacer />
    <FixedBottom>
      <Button loading={isSubmitting} fullwidth>
        Save
      </Button>
    </FixedBottom>
  </React.Fragment>
);

const schema = {
  oldPassword: stringRequired,
  password: password,
  passwordAgain: password,
};

const mapDispatchToProps = {
  notificationSuccess,
};

const enhancer: HOC<*, {||}> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withMutation(changePasswordMutation),
  withForm({
    schema,
    onSubmit: props => v => {
      if (v.password !== v.passwordAgain) {
        return constructGraphqlError('Passwords must be same');
      }
      return props.submitMutation({
        oldPassword: v.oldPassword,
        password: v.password,
      });
    },
    onSuccess: props => () => props.notificationSuccess('Successfully changed'),
  })
);

export default enhancer(ChangePasswordForm);
