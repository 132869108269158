// @flow
import {mqMax} from 'common/mediaQuery';
import styled from 'styled-components';

export const Wrapper = styled.div`
  diplay: flex;
  justify-content: center;
`;

export const Iframe = styled.iframe`
  width: 100%;
  min-height: 50rem;
  border: none;

  ${mqMax.phone} {
    min-height: auto;
  }
`;
