// @flow
import Button from 'components/Button';
import {FixedBottom, FixedBottomSpacer} from 'componentsStyled/Layout/FixedBottom';
import {WrapNoDesktop} from 'componentsStyled/Layout/Wrap';
import {push} from 'connected-react-router';
import {openModal} from 'data/modals/actions';
import {activeReservationStatuses} from 'data/reservations/constants';
import {reservationListQuery} from 'data/reservations/graphql/queries';
import urls from 'data/router/urls';
import {logout} from 'data/user/actions';
import {deleteAccountMutation} from 'data/user/graphql/mutations';
import {selectLoggedIn} from 'data/user/selectors';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withQuery from 'hoc/withQuery';
import withSubmit from 'hoc/withSubmit';
import ConfirmModal from 'modals/Confirm';
import SettingContent from 'pages/Account/Routes/SettingContent';
import {groupReservationsByStatus} from 'pages/Reservations';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import Content from './Content';
import NoDelete from './NoDelete';

const DeleteAccount = ({loading, handleDelete, data, push}) => {
  const hasCheckedOutReservations = groupReservationsByStatus(data, activeReservationStatuses);
  return (
    <SettingContent title="Delete account">
      <WrapNoDesktop>{hasCheckedOutReservations ? <NoDelete /> : <Content />}</WrapNoDesktop>
      <FixedBottomSpacer />
      <FixedBottom>
        {hasCheckedOutReservations ? (
          <Button onClick={() => push(urls.reservations)} loading={loading} fullwidth>
            Go to my reservations
          </Button>
        ) : (
          <Button onClick={handleDelete} loading={loading} fullwidth>
            Delete
          </Button>
        )}
      </FixedBottom>
    </SettingContent>
  );
};

const mapStateToProps = state => ({
  loggedIn: selectLoggedIn(state),
});

const mapDispatchToProps = {
  logout,
  openModal,
  push,
};

const enhancer: HOC<*, {||}> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withMutation(deleteAccountMutation),
  withSubmit({
    successText: 'Account successfully deleted!',
    errorText: 'There was a problem deleting your account.',
    onSuccess: props => props.logout,
    submit: props => props.submitMutation,
  }),
  withHandlers({
    handleDelete: props => () => {
      props.openModal(ConfirmModal, {
        title: 'Are you sure?',
        message: 'This action is permanent and cannot be undone.',
        onConfirm: props.submit,
      });
    },
  }),
  withQuery(reservationListQuery, {
    noEmpty: true,
    config: {
      skip: props => !props.loggedIn || props.loading,
    },
  })
);

export default enhancer(DeleteAccount);
