// @flow
import CrossIcon from 'assets/icons/CrossIcon';
import * as React from 'react';

import {StyledCloseButton} from './styled';

export type Props = {
  onClick?: () => void,
  size?: number,
  className?: string,
};

const CloseButton = ({size = 12, onClick, className}: Props) => {
  return (
    <StyledCloseButton className={className} onClick={onClick}>
      <CrossIcon size={size} />
    </StyledCloseButton>
  );
};

export default CloseButton;
