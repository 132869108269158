// @flow
import CustomElement from 'components/CustomElement';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import SectionFooter from 'components/SectionFooter';
import {selectTheme} from 'data/app/selectors';
import withConnect from 'hoc/withConnect';
import RegularPage from 'pages/_Page';
import {FooterWrap, Line, PageContent, PageContentWithFilter} from 'pages/_Page/styled';
import * as React from 'react';
import type {Component, HOC} from 'recompose';

import BackButton from './BackButton';
import {HeaderInner, MobileHeaderWrap, PageInner, StyledText, Wrap} from './styled';

type Props = {|
  children: React.Node,
  title: string,
  backUrl: string,
  filter?: Component,
|};

const SubPage = ({theme, title, backUrl, children, filter: Filter}) => (
  <React.Fragment>
    <Mobile>
      <MobileHeaderWrap>
        <HeaderInner>
          <Wrap width="20%">
            <BackButton to={backUrl} withFilter={!!Filter}>
              Back
            </BackButton>
          </Wrap>
          <Wrap width="70%">
            <StyledText black medium>
              {title}
            </StyledText>
          </Wrap>
        </HeaderInner>
        {Filter && (
          <React.Fragment>
            <Line />
            <Filter />
          </React.Fragment>
        )}
      </MobileHeaderWrap>
      {Filter ? (
        <PageContentWithFilter>
          <PageInner>{children}</PageInner>
        </PageContentWithFilter>
      ) : (
        <PageContent>
          <PageInner>{children}</PageInner>
        </PageContent>
      )}
      <FooterWrap>
        <SectionFooter />
        {theme && <CustomElement html={theme.footer.html} css={theme.footer.css} />}
      </FooterWrap>
    </Mobile>
    <Desktop>
      <RegularPage filter={Filter}>{children}</RegularPage>
    </Desktop>
  </React.Fragment>
);

const mapStateToProps = state => ({
  theme: selectTheme(state),
});

const enhancer: HOC<*, Props> = withConnect(mapStateToProps);

export default enhancer(SubPage);
