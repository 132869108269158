// @flow
import type {Location} from 'data/app/types';
// $Import
import haversineDistance from 'haversine-distance';

export type SortBy = 'ALPHABETICAL' | 'NEARBY';
export const SORT_OPTIONS: {[string]: SortBy} = {
  ALPHABETICAL: 'ALPHABETICAL',
  NEARBY: 'NEARBY',
};

export const locationSorter = (sortBy: SortBy, userLocation: ?Position) => (location: Location) => {
  if (sortBy === SORT_OPTIONS.NEARBY && userLocation) {
    return haversineDistance(userLocation.coords, {
      latitude: location.lat,
      longitude: location.lng,
    });
  }
  return location.name;
};

export const locationGrouper =
  (sortBy: SortBy, userLocation: ?Position) => (location: Location) => {
    if (sortBy === SORT_OPTIONS.NEARBY && userLocation) {
      return 'all';
    }
    return location.country.name;
  };
