import type {ProductVariantAvailableAffiliate} from 'data/product/types';
import {formatMoney} from 'data/units/money/formatters';
import haversineDistance from 'haversine-distance';

export const getPriceRangeString = (min, max, currency) =>
  `${formatMoney(min, false, currency)} - ${formatMoney(max, false, currency)} ${currency} per day`;

export const sortByDistance = (
  stores: ProductVariantAvailableAffiliate[],
  coords: {latitude: number, longitude: number}
) => {
  return stores.sort((a, b) => {
    const getCoords = ({lat, lng}) => ({latitude: lat, longitude: lng});
    const aDistance = haversineDistance(coords, getCoords(a.location));
    const bDistance = haversineDistance(coords, getCoords(b.location));
    return aDistance - bDistance;
  });
};

export const sortByCountry = (stores: ProductVariantAvailableAffiliate[]) =>
  stores.sort((a, b) => a.location.country.name.localeCompare(b.location.country.name));

export const filterStores = (stores: ProductVariantAvailableAffiliate[], filterText: string) => {
  return stores.filter(store => {
    const {
      name,
      location: {name: locationName, country},
    } = store;
    const {currency, code, name: countryName} = country;

    return [name, locationName, countryName, currency, code].some(value =>
      value.toLowerCase().includes(filterText.toLowerCase())
    );
  });
};
