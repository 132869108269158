// @flow
import React from 'react';

export type Props = {
  size?: number,
};

const LocationArrowIcon = ({size = 20}: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.49543 10.0003C0.803585 10.0003 0.170878 9.48107 0.0290473 8.7599C-0.112783 8.03873 0.275688 7.31756 0.955993 7.03871L17.8804 0.114504C18.4526 -0.120117 19.1112 0.0125782 19.5487 0.450569C19.9863 0.889042 20.1209 1.54771 19.8853 2.12128L12.9621 19.0438C12.6832 19.7265 11.962 20.1111 11.2409 19.9717C10.5197 19.8275 9.95717 19.1977 9.95717 18.4621V10.0003H1.49543ZM12.3082 7.69257V14.5485L17.0535 2.94726L5.45225 7.69257H12.3082Z"
      fill="currentColor"
    />
  </svg>
);

export default LocationArrowIcon;
