// @flow
import {mq} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import {center} from 'componentsStyled/Shared';
import styled from 'styled-components';

export const ProductTileWrap = styled(StyledLink)`
  height: ${props => (props.minimal ? '31rem' : '36rem')};
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  padding: 0.8rem;
  background: ${props => props.theme.color.white};
  width: 50%;
  max-width: 50%;
  position: relative;
  outline: none;
  transition: transform 0.25s ease;

  &:hover {
    transform: translateY(-0.9rem);
    position: relative;
    z-index: ${props => props.theme.zIndices.minimal};
  }

  ${mq.tabletWide} {
    height: 44rem;
    width: calc(100% / ${props => props.maxTilesPerRow || 4});
    max-width: calc(100% / ${props => props.maxTilesPerRow || 4});
  }
`;

export const ImageWrap = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
  position: relative;
  min-height: 19.8rem;
  background: ${props => props.theme.color.light};
  border-radius: 0.8rem;

  img {
    ${center};
    max-height: 100%;
    max-width: 100%;
    padding: 0.8rem;
  }
`;

export const Body = styled.div`
  flex-shrink: 0;
  text-align: left;
  padding-top: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
