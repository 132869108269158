// @flow
import {trackReservation} from 'analytics/reservations/analyticsTraits';
import Button from 'components/Button';
import {Hint} from 'componentsStyled/Typography';
import {SubTitle} from 'componentsStyled/Typography/Titles';
import {openModal} from 'data/modals/actions';
import {buyItMutation} from 'data/reservations/graphql/mutations';
import {reservationBuyItPricingQuery} from 'data/reservations/graphql/queries';
import type {Reservation} from 'data/reservations/types';
import urls from 'data/router/urls';
import {formatMoney} from 'data/units/money/formatters';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withQuery from 'hoc/withQuery';
import withRouter from 'hoc/withRouter';
import withSubmit from 'hoc/withSubmit';
import withUser from 'hoc/withUser';
import ConfirmModal from 'modals/Confirm';
import BuyItConfirm from 'pages/Reservation/BuyItConfirm';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {RrpPrice, Wrap} from './styled';

const BuyItButton = ({data, handleBuyIt, reservation}) => {
  if (!data) {
    return null;
  }

  return (
    <Wrap>
      <SubTitle>Enjoying the {reservation.productItem.productVariant.product.name}?</SubTitle>
      <Button onClick={handleBuyIt}>
        Own It for{' '}
        <RrpPrice strikethrough>{formatMoney(data.rrpPrice, false, data.currency)}</RrpPrice>{' '}
        {formatMoney(data.discountedPrice, false, data.currency)}^
      </Button>
      <Hint>
        Own It price is RRP ({formatMoney(data.rrpPrice, true, data.currency)}) less proceeds from
        this booking ({formatMoney(data.proceedsAmount, true, data.currency)}) - this excludes any
        accessories
      </Hint>
      <Hint>^ There may be a minimum purchase price for the product</Hint>
    </Wrap>
  );
};

const mapDispatchToProps = {
  openModal,
};

type Outter = {|
  reservation: Reservation,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withUser(),
  withQuery(reservationBuyItPricingQuery, {
    variables: props => ({
      reservationId: props.reservation.id,
    }),
    noEmpty: true,
  }),
  withMutation(buyItMutation),
  withConnect(() => ({}), mapDispatchToProps),
  withSubmit({
    redirect: () => urls.reservations,
    successText: 'Shop has been contacted!',
    errorText: 'Could not process request. Please try again.',
    onSuccess: props => () => {
      trackReservation('Buy It', props.reservation, props.user);
    },
  }),
  withHandlers({
    handleBuyIt: props => () => {
      props.openModal(ConfirmModal, {
        title: 'Want to own it?',
        component: <BuyItConfirm reservation={props.reservation} />,
        onConfirm: () => props.submit({reservationId: props.reservation.id}),
      });
    },
  })
);

export default enhancer(BuyItButton);
