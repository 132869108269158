// @flow
import {compose, map, prop, reduce, slice, split} from 'ramda';

import urls from './urls';

// from: {pathname: "/reset-password", search: "?code=djkhskjdjkhhdksjhk", hash: "", state: undefined}
// into: {code: "djkhskjdjkhhdksjhk"}
export const parseSearchFromLocation: ({search: string}) => {
  [string]: string,
} = compose(
  reduce((acc, [key, value]) => ({...acc, [key]: value}), {}),
  map(split('=')),
  split('&'),
  slice(1, Infinity),
  prop('search')
);

export const onSearchPage = (path: string): boolean => path === urls.search;

export const onAffiliatePage = (path: string): boolean => path.startsWith(urls.affiliate(''));

export const onProductPage = (path: string): boolean => path.startsWith('/product/');

export const onProductsPage = (path: string): boolean => path.endsWith('/products');

export const onReservePage = (path: string): boolean => path.endsWith('/reserve');
