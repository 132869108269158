// @flow
import type {Selector} from 'common/redux/types';
import {compose, head, prop} from 'ramda';

import type {NotificationsState} from './reducer';
import type {Notification} from './types';

// selects the appropriate substore from root store
const base: Selector<NotificationsState> = prop('notifications');

// composes upon the base selector to select specific properties
export const selectTopmostNotification: Selector<?Notification> = compose(head, base);
