// @flow
import ToolTip from 'components/ToolTip';
import {Relative} from 'componentsStyled/Layout/Position';
import {FieldTitle} from 'componentsStyled/Typography/Titles';
import type {ToolTipContent} from 'data/tooltips/types';
import * as React from 'react';

import {Error, InputWrapWrap, LabelPositionWrap, MultiInputWrapWrap} from './styled';

export type LabelPosition = 'top' | 'right';

type Props = {|
  children: React.Element<*>,
  error: ?string,
  label: ?string,
  labelPosition?: LabelPosition,
  errorPosition: ?string,
  toolTip?: ToolTipContent | string,
  noMargin?: boolean,
|};

const InputWrap = ({
  children,
  label,
  error,
  errorPosition,
  toolTip,
  noMargin,
  labelPosition = 'top',
}: Props) => {
  const unit = children.props.unit;
  const isArray = Array.isArray(unit);
  const withToolTip = toolTip ? true : false;

  if (isArray) {
    return (
      <MultiInputWrapWrap>
        {label && <FieldTitle>{label}</FieldTitle>}
        <Relative>{children}</Relative>
        {error && <Error errorPosition={errorPosition}>{error}</Error>}
      </MultiInputWrapWrap>
    );
  }

  return (
    <InputWrapWrap noMargin={noMargin}>
      <LabelPositionWrap labelPosition={labelPosition}>
        <div>
          {label && <FieldTitle inline={withToolTip}>{label}</FieldTitle>}
          {toolTip && <ToolTip content={toolTip} />}
        </div>
        {children}
      </LabelPositionWrap>
      {error && <Error errorPosition={errorPosition}>{error}</Error>}
    </InputWrapWrap>
  );
};

export default InputWrap;
