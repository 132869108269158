// @flow
import styled from 'styled-components';

export const EmptyStateWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;

  > button {
    margin-top: 2rem;
  }
`;
