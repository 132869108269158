// @flow
import {stopPropagation} from 'common/events';
import * as React from 'react';

import {Overlay, Wrapper, WrapperContent} from './styled';

type Props = {
  isOpen: boolean,
  close: Function,
  children: React.Node,
  transparent?: boolean,
};

const TooltipWrapper = ({isOpen, close, children, transparent}: Props) => (
  <Wrapper onClick={stopPropagation}>
    {isOpen && <Overlay onClick={close} transparent={transparent} />}
    <WrapperContent isOpen={isOpen}>{children}</WrapperContent>
  </Wrapper>
);

export default TooltipWrapper;
