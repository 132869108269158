// @flow
import type {Discount, Reservation} from 'data/reservations/types';
import {formatReadableFullYear} from 'data/units/date/formatters';
import type {User} from 'data/user/types';
import {mergeAll} from 'ramda';

import {tryTrack} from '../helpers';
import {productTrait} from '../product/analyticsTraits';

type EventName =
  | 'First Booking'
  | 'Reservation Created'
  | 'Order Cancelled'
  | 'Reservation Extended'
  | 'Reservation Swap'
  | 'Buy It';

const getTraitBaseParams = (r: Reservation, u: User) => ({
  ...productTrait(r.productItem.productVariant, u),
  affiliate: r.productItem.affiliate.name,
  start: formatReadableFullYear(r.booking.start),
  end: formatReadableFullYear(r.booking.end),
  code: r.externalId,
  status: r.status,
  id: r.id,
  duration: r.pricingDetail.totalDays,
  amount: r.pricingDetail.totalPrice,
  currency: r.pricingDetail.currency,
  ...getDiscountsTrait(r.pricingDetail.discounts),
});

const getDiscountsTrait = (discounts: Discount[]) =>
  mergeAll(
    discounts.map(discount => ({
      [discount.type.concat('Discount')]: discount.detail || '',
    }))
  );

const getTraitExtendedParams = (trait: any, additionalParams?: any) => {
  if (!additionalParams || !additionalParams.extendedDays) {
    return trait;
  }

  return {
    ...trait,
    extendedDays: additionalParams.extendedDays,
  };
};

const getTraitOrderCancelled = (trait: any, additionalParams?: any) => {
  if (!additionalParams || !additionalParams.charged) {
    return trait;
  }

  return {
    ...trait,
    charged: additionalParams.charged,
  };
};

const traitHandler = (eventName: string, r: Reservation, u: User, additionalParams?: any) => {
  const trait = getTraitBaseParams(r, u);

  switch (eventName) {
    case 'Reservation Extended':
      return getTraitExtendedParams(trait, additionalParams);
    case 'Order Cancelled':
      return getTraitOrderCancelled(trait, additionalParams);
    default:
      return trait;
  }
};

export const trackReservation = (e: EventName, r: Reservation, u: User, additionalParams: any) =>
  tryTrack(e, () => traitHandler(e, r, u, additionalParams));
