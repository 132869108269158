// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

export const ButtonsWrap = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  & > button {
    margin: 0.5rem;
  }
  ${mq.tabletWide} {
    justify-content: space-between;
  }
`;
