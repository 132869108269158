// @flow
import type {Mutation} from 'common/graphql/types';
import type {ID} from 'data/enums/types';
import type {DateString} from 'data/units/date/types';
import gql from 'graphql-tag';

import type {Reservation, ReservationInput} from '../types';
import {reservationFragment} from './fragments';
import {reservationCountQuery, reservationListQuery} from './queries';

export const createReservationMutation: Mutation<
  {
    input: ReservationInput,
  },
  Reservation
> = {
  gql: gql`
    mutation createReservationMutation($input: ReservationInput!) {
      reservation {
        create(input: $input) {
          ...reservationFragment
        }
      }
    }
    ${reservationFragment}
  `,
  selector: ['reservation', 'create'],
  refetch: [reservationListQuery, reservationCountQuery],
};

export const basketBookingMutation: Mutation<
  {
    input: {
      reservations: ReservationInput[],
    },
  },
  Reservation
> = {
  gql: gql`
    mutation basketBookingMutation($input: BasketBookingInput!) {
      reservation {
        basketBooking(input: $input) {
          reservations {
            ...reservationFragment
          }
        }
      }
    }
    ${reservationFragment}
  `,
  selector: ['reservation', 'create'],
  refetch: [reservationListQuery, reservationCountQuery],
};

export const cancelReservationMutation: Mutation<{|reservationId: ID|}> = {
  gql: gql`
    mutation cancelReservationMutation($bookingId: String!, $reservationId: Int!) {
      booking {
        cancelBookingReservations(bookingId: $bookingId, reservationIds: [$reservationId]) {
          bookingId
        }
      }
    }
  `,
  refetch: [reservationListQuery, reservationCountQuery],
};

export const extendReservationMutation: Mutation<
  {reservationId: ID, end: DateString},
  Reservation
> = {
  gql: gql`
    mutation extendReservationMutation($reservationId: Int!, $end: String!) {
      reservation {
        extendDuration(reservationId: $reservationId, end: $end) {
          ...reservationFragment
        }
      }
    }
    ${reservationFragment}
  `,
  selector: ['reservation', 'extendDuration'],
  refetch: [reservationListQuery, reservationCountQuery],
};

export const buyItMutation: Mutation<{|reservationId: ID|}> = {
  gql: gql`
    mutation buyItMutation($reservationId: Int!) {
      reservation {
        buyIt(reservationId: $reservationId)
      }
    }
  `,
  refetch: [reservationListQuery, reservationCountQuery],
};
