// @flow
import type {Query} from 'common/graphql/types';
import gql from 'graphql-tag';

import type {AppConfig, Category, Theme} from '../types';

export const appConfigQuery: Query<AppConfig> = {
  gql: gql`
    query appConfigQuery {
      app {
        getConfig {
          tenantName
          tenantTitle
          showMinimalProductTileInfo
          enableBundles
        }
      }
    }
  `,
  options: () => ({
    fetchPolicy: 'network-only',
  }),
  selector: ['app', 'getConfig'],
};

export const themeQuery: Query<Theme> = {
  gql: gql`
    query themeQuery {
      theme {
        getTheme {
          enableStoreCarousel
          advancedHeader
          header {
            html
            css
          }
          footer {
            html
            css
          }
          colors {
            primaryAccent
            primaryAccentDark
            primaryText
            secondaryText
            background
          }
          borderRadiuses {
            button
            filterButton
            input
          }
          fonts {
            externalFontCssUrl
            bodyFontFamily
            headingFontFamily
          }
          logo {
            logo
            logoLink
          }
        }
      }
    }
  `,
  options: () => ({
    fetchPolicy: 'network-only',
  }),
  selector: ['theme', 'getTheme'],
};

export const categoryListQuery: Query<Category[]> = {
  gql: gql`
    query categoryListQuery {
      category {
        listForAvailableProducts {
          categoryId
          name
          discountOptOuts {
            id
            discountId
          }
        }
      }
    }
  `,
  selector: ['category', 'listForAvailableProducts'],
};
