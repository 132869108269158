// @flow
import React from 'react';

const HomeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 20V15H11V20C11 20.5304 10.7893 21.0391 10.4142 21.4142C10.0392 21.7893 9.53044 22 9.00001 22H6.00001C5.46958 22 4.96087 21.7893 4.5858 21.4142C4.21072 21.0391 4.00001 20.5304 4.00001 20V12.41L3.70001 12.71C3.60961 12.814 3.49869 12.8982 3.37421 12.9573C3.24973 13.0164 3.11439 13.0491 2.97666 13.0534C2.83893 13.0578 2.7018 13.0336 2.57386 12.9824C2.44593 12.9312 2.32995 12.8541 2.23321 12.756C2.13647 12.6579 2.06105 12.5408 2.01168 12.4122C1.96232 12.2835 1.94008 12.1461 1.94635 12.0084C1.95262 11.8708 1.98727 11.7359 2.04813 11.6123C2.10899 11.4886 2.19475 11.3789 2.30001 11.29L11.3 2.29001C11.4869 2.10678 11.7383 2.00415 12 2.00415C12.2618 2.00415 12.5131 2.10678 12.7 2.29001L21.7 11.29C21.8653 11.4801 21.9527 11.7256 21.9448 11.9774C21.9369 12.2292 21.8343 12.4688 21.6574 12.6482C21.4805 12.8275 21.2425 12.9336 20.9908 12.945C20.7392 12.9565 20.4925 12.8726 20.3 12.71L20 12.41V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0392 21.7893 18.5304 22 18 22H15C14.4696 22 13.9609 21.7893 13.5858 21.4142C13.2107 21.0391 13 20.5304 13 20ZM18 20V10.41L12 4.41001L6.00001 10.41V20H9.00001V15C9.00001 13.9 9.90001 13 11 13H13C13.5304 13 14.0392 13.2107 14.4142 13.5858C14.7893 13.9609 15 14.4696 15 15V20H18Z"
      fill="currentColor"
    />
  </svg>
);

export default HomeIcon;
