// @flow
import React from 'react';

const LogoIcon = () => (
  <svg width="80" height="15" viewBox="0 0 290.34 51.48">
    <path d="M189.95 0a24 24 0 0 1 17.3 6.65 1.33 1.33 0 0 1 .07 2l-5.6 5.79a1.25 1.25 0 0 1-1.79 0 14.63 14.63 0 0 0-9.65-3.64c-8.08 0-14 6.72-14 14.73s6 14.52 14.08 14.52a15.16 15.16 0 0 0 9.58-3.43 1.4 1.4 0 0 1 1.86.07l5.58 5.93a1.42 1.42 0 0 1-.07 1.93 24.55 24.55 0 0 1-17.37 6.94 25.74 25.74 0 1 1 0-51.48M264.61 40.05a14.3 14.3 0 1 0-14.23-14.23 14.33 14.33 0 0 0 14.23 14.23m0-40a25.74 25.74 0 1 1-25.68 25.81A25.69 25.69 0 0 1 264.61.05M1.86 49.71l48-22.27a1.36 1.36 0 0 0 .86-1.22v-.71a1.36 1.36 0 0 0-.79-1.21l-9-4.18a1.28 1.28 0 0 0-1.06 0l-24 10.85L.77 37.8H.72a1.41 1.41 0 0 0-.73 1.19v9.49a1.26 1.26 0 0 0 1.86 1.21M83.72 1.88l48 22.27a1.36 1.36 0 0 1 .79 1.22v.71a1.36 1.36 0 0 1-.79 1.22l-9 4.18a1.27 1.27 0 0 1-1.06 0L97.72 20.63l-15.1-6.83h-.05a1.42 1.42 0 0 1-.73-1.19V3.09a1.26 1.26 0 0 1 1.88-1.21M18.35 21.31a1.27 1.27 0 0 1-1 0L.78 13.8a1.41 1.41 0 0 1-.79-1.21v-9.5a1.26 1.26 0 0 1 1.86-1.21L28.72 14.4a1.22 1.22 0 0 1 0 2.22zM100.22 30.28a1.27 1.27 0 0 0-1 0l-16.53 7.51A1.41 1.41 0 0 0 81.9 39v9.49a1.26 1.26 0 0 0 1.82 1.22l26.89-12.49a1.22 1.22 0 0 0 0-2.22z" />
  </svg>
);

export default LogoIcon;
