// @flow
import RadioPill from 'components/Radio/RadioPill';
import styled from 'styled-components';

export const StyledVariantSelect = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  margin: 3.2rem 0;
`;

export const VariantPill = styled(RadioPill)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
