// @flow
import React from 'react';

const LogoIconSmall = () => (
  <svg width="30" height="30" viewBox="0 0 430 429.12">
    <path d="M86 257.61c23.82 0 41.43 7.38 57.65 22.15a4.44 4.44 0 0 1 .23 6.67l-18.58 19.29a4.17 4.17 0 0 1-6 0 48.73 48.73 0 0 0-32.12-12.16c-26.92 0-46.68 22.38-46.68 49.06 0 26.45 20 48.36 46.92 48.36 11.19 0 23.33-4 31.92-11.43 1.65-1.43 4.75-1.43 6.19.24l18.58 19.77a4.76 4.76 0 0 1-.24 6.43c-16.21 15.72-36.7 23.11-57.87 23.11-47.65 0-86-37.88-86-85.52a85.8 85.8 0 0 1 86-86M344.27 391c26.18 0 47.64-21.43 47.64-47.4 0-26.2-21.46-47.87-47.64-47.87-26 0-47.4 21.67-47.4 47.87a47.66 47.66 0 0 0 47.4 47.4m0-133.39a85.75 85.75 0 1 1-85.54 86c0-47.64 37.89-86 85.54-86M6.17 159.48l159.71-74a4.51 4.51 0 0 0 2.62-4v-2.37a4.53 4.53 0 0 0-2.62-4l-29.76-13.88a4.25 4.25 0 0 0-3.54 0L52.81 97.29 2.6 119.98l-.18.09a4.7 4.7 0 0 0-2.43 4v31.39a4.19 4.19 0 0 0 6.17 4M61 65.07a4.23 4.23 0 0 1-3.46 0l-54.94-25a4.68 4.68 0 0 1-2.61-4V4.56a4.2 4.2 0 0 1 6.17-4L95.53 42.2a4.05 4.05 0 0 1 0 7.36zM266.24.49L426 74.56a4.52 4.52 0 0 1 2.62 4v2.36a4.52 4.52 0 0 1-2.62 4l-29.8 13.94a4.22 4.22 0 0 1-3.54 0L312.89 62.8l-50.21-22.7-.18-.08a4.72 4.72 0 0 1-2.43-4V4.56c0-3.56 3.09-5.46 6.17-4M321.08 94.89a4.23 4.23 0 0 0-3.46 0l-54.94 25a4.68 4.68 0 0 0-2.61 4v31.56a4.2 4.2 0 0 0 6.17 4l89.37-41.64a4.05 4.05 0 0 0 0-7.36z" />
  </svg>
);

export default LogoIconSmall;
