// @flow
import {trackLoginError, trackLoginSuccess} from 'analytics/login/analyticsTraits';
import {trackSignup} from 'analytics/membership/analyticsTraits';
import getErrorMessage from 'common/graphql/getErrorMessage';
import {openModal} from 'data/modals/actions';
import {login} from 'data/user/actions';
import {loginFacebookMutation} from 'data/user/graphql/mutations';
import config from 'global/config';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withSubmit from 'hoc/withSubmit';
import CreditCard from 'modals/CreditCard';
import React from 'react';
import {type HOC, compose} from 'recompose';

import SocialLoginButton from '../SocialLoginButton';
import FacebookIcon from './FacebookIcon';

const FacebookLogin = ({submit, signup}) => {
  if (!config.facebookAppId) return null;

  return (
    <SocialLoginButton
      provider="facebook"
      appId={config.facebookAppId}
      onLoginSuccess={submit}
      onLoginFailure={console.warn}
      icon={FacebookIcon}
      type="facebook"
    >
      {signup ? 'Signup with Facebook' : 'Login with Facebook'}
    </SocialLoginButton>
  );
};

const mapDispatchToProps = {
  login,
  openModal,
};

type Outter = {|
  close: Function,
  signup?: boolean,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withMutation(loginFacebookMutation),
  withSubmit({
    submit: props => user =>
      props.submitMutation({
        accessToken: user.token.accessToken,
      }),
    onSuccess: props => res => {
      props.login(res);

      if (props.signup) {
        trackSignup(res);
      } else {
        trackLoginSuccess(res);
      }

      props.close();
      if (props.signup) props.openModal(CreditCard, {type: 'add'});
    },
    onError: props => res => {
      const message = getErrorMessage(res);
      trackLoginError(message);
      return message;
    },
  })
);

export default enhancer(FacebookLogin);
