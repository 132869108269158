// @flow
import Checkbox from 'components/Checkbox';
import {Text} from 'componentsStyled/Typography/Texts';
import {getLegalRentalAgreementContentQuery} from 'data/legal/graphql/queries';
import type {LegalRentalAgreementContent} from 'data/legal/graphql/types';
import urls from 'data/router/urls';
import withQuery from 'hoc/withQuery';
import React from 'react';
import {type HOC, compose} from 'recompose';

import {CheckboxWrap, ConfirmationStyled} from './styled';

type Props = {
  hasTermsAndConditions?: boolean,
  toggleTermsAndConditions(): void,
  data: LegalRentalAgreementContent,
};

const Confirmation = (props: Props) => {
  const {hasTermsAndConditions, toggleTermsAndConditions, data} = props;
  const {content} = data;

  return (
    <ConfirmationStyled data-cy={'rental-agreement-confirmation'}>
      <CheckboxWrap>
        <Checkbox
          value={hasTermsAndConditions}
          onClick={toggleTermsAndConditions}
          data-cy={'confirmation-checkbox'}
        />
      </CheckboxWrap>
      <Text black bold>
        I have read and agree to the Rental Agreement
        {content && (
          <span>
            , and{' '}
            <a href={urls.rentalAgreement} target="_blank" rel="noopener noreferrer">
              Rental Agreement
            </a>
          </span>
        )}
      </Text>
    </ConfirmationStyled>
  );
};

type Outter = {
  hasTermsAndConditions?: boolean,
  toggleTermsAndConditions(): void,
};

const enhancer: HOC<*, Outter> = compose(withQuery(getLegalRentalAgreementContentQuery));

export default enhancer(Confirmation);
