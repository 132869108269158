// @flow
import {lighten} from 'global/theme';
import styled, {css} from 'styled-components';

export const OverlayWrapperWrap = styled.div`
  position: relative;

  ${p =>
    p.isOpen &&
    css`
      z-index: ${p.theme.zIndices.overlay};
    `}
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: ${props => props.theme.zIndices.overlayOverlay};
  background: ${props => lighten(props.theme.color.primaryText, 0.1)};

  ${p =>
    p.open &&
    css`
      opacity: 1;
      pointer-events: initial;
    `}
`;

export const Content = styled.div`
  z-index: ${props => props.theme.zIndices.overlayContent};
  position: relative;
`;
