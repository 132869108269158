// @flow
import {SubTitle} from 'componentsStyled/Typography/Titles';
import type {Country} from 'data/locations/types';
import urls from 'data/router/urls';
import React from 'react';

import {DestinationWrap, LocationLink, StyledText} from './styled';

type Props = {
  country: Country,
};

const Destination = ({country}: Props) => (
  <DestinationWrap>
    <SubTitle>{country.name}</SubTitle>
    {country.locations.map((location, index) => (
      <StyledText key={index}>
        <LocationLink
          to={`${urls.products}/?location=${location.name.split('-').pop()}`}
          underline={'true'}
        >
          {location.name}
        </LocationLink>
      </StyledText>
    ))}
  </DestinationWrap>
);

export default Destination;
