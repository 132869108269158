// @flow
import {mq, mqMax} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import {Text} from 'componentsStyled/Typography/Texts';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import styled from 'styled-components';

export const BlogWrap = styled.div`
  display: flex;
  position: relative;
  max-height: 380px;
  max-width: 570px;
  width: 50%;
  height: 100%;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 0.5rem 0.5rem 3.5rem 0.5rem;

  ${mqMax.tabletWide} {
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  filter: brightness(50%) !important;
`;

export const LocationLink = styled(StyledLink)`
  color: ${props => props.theme.color.secondaryText};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitle = styled(PageTitle)`
  z-index: 1;
  display: flex;
  position: relative;
  color: #fff;
  justify-content: center;
  text-align: center;
  font-size: 1.8rem;

  ${mqMax.tabletWide} {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  ${mq.tabletWide} {
    font-size: 3rem;
    margin-bottom: 0;
  }

  &::after {
    width: 0rem;
  }
`;
export const StyledSubTitle = styled(Text)`
  position: relative;
  z-index: 1;
  color: #dddddd;
  font-size: 1.4rem;
  text-align: center;
  font-weight: bold;
  font-style: italic;
`;

export const TextWrap = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  flex-direction: column;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
`;
