// @flow
import {trackSearch} from 'analytics/search/analyticsTraits';
import AffiliateList from 'components/AffiliateList';
import Loader from 'components/Loader';
import ProductList from 'components/ProductList';
import {VPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {Space} from 'componentsStyled/Layout/Spacers';
import {listAffiliatesForSearchQuery} from 'data/affiliate/graphql';
import {selectTheme} from 'data/app/selectors';
import {listProductOfferingsQuery} from 'data/product/graphql/queries';
import urls from 'data/router/urls';
import {clear, clearProduct} from 'data/search/actions';
import {selectIsSearchEmpty, selectSearchQueryVariables} from 'data/search/selectors/index';
import withConnect from 'hoc/withConnect';
import withOnNextProps from 'hoc/withOnNextProps';
import withQuery from 'hoc/withQuery';
import withRedirect from 'hoc/withRedirect';
import withRouter from 'hoc/withRouter';
import withScrollEnd from 'hoc/withScrollEnd';
import withUser from 'hoc/withUser';
import useUniqueCountriesAcrossAffiliates from 'hooks/useUniqueCountriesAcrossAffiliates';
import * as React from 'react';
// $ReactHooks
import {type HOC, compose, mapProps, withHandlers} from 'recompose';

import SearchResultsCount from './SearchResultsCount';

const SearchResults = ({
  theme,
  data,
  count: productOfferingsTotal,
  loadingMore,
  handleClearAll,
  affiliates,
  ...rest
}) => {
  const {affiliates: allAffiliates, products} = data;

  const hasAffiliates = affiliates && affiliates.length > 0;
  const hasProducts = products.length > 0;

  const showStores = theme && theme.enableStoreCarousel && hasAffiliates;
  const showProducts = hasProducts;

  const uniqueCountries = useUniqueCountriesAcrossAffiliates(allAffiliates);

  return (
    <VPaddedFullWidthContainer>
      <SearchResultsCount
        productsTotal={productOfferingsTotal}
        affiliatesCount={affiliates.length}
        clear={handleClearAll}
      />

      {showStores && <AffiliateList affiliates={affiliates} />}
      {showProducts && <Space />}
      {showProducts && (
        <ProductList showPrices={uniqueCountries.length === 1} productOfferings={products} />
      )}

      <Loader hide={!loadingMore} pb0 />
    </VPaddedFullWidthContainer>
  );
};

const mapStateToProps = state => ({
  theme: selectTheme(state),
  searchQueryVariables: selectSearchQueryVariables(state),
  searchEmpty: selectIsSearchEmpty(state),
});

const mapDispatchToProps = {
  clear,
  clearProduct,
};

const enhancer: HOC<*, {||}> = compose(
  withUser(),
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps),
  withRedirect(
    props => props.searchEmpty,
    props => ({pathname: urls.products, search: props.location.search})
  ),
  withQuery(listAffiliatesForSearchQuery, {
    noEmpty: true,
    // $ExpectError
    variables: props => props.searchQueryVariables,
    config: {skip: props => !props.searchQueryVariables},
  }),
  mapProps(({data, ...rest}) => ({
    ...rest,
    affiliates: data,
  })),
  withQuery(listProductOfferingsQuery, {
    noEmpty: true,
    // TODO(ray): Fix type definition around optionals so query typechecks
    // $ExpectError
    variables: props => props.searchQueryVariables,
    config: {skip: props => !props.searchQueryVariables},
  }),
  withOnNextProps(next =>
    trackSearch(next.searchQueryVariables, next.user ? next.user.email : 'anonymous')
  ),
  withScrollEnd(props => props.loadMore()),
  withHandlers({
    handleClearAll: props => () => {
      props.clear();
      props.clearProduct();
    },
  })
);

export default enhancer(SearchResults);
