// @flow
import {FULFILLMENT_TYPE} from 'data/bookings/constants';
import type {FulfillmentType} from 'data/bookings/types';

const ARRAY_SEPARATOR = '+';

/**
 * @param values - Array of value to be converted to a single URL param string (e.g. [1, 20, 3, 5]).
 * @param transformer - Transformer function that transforms a single element of the array to a string
 *                      (e.g. transform 3 to '3')
 * @param separator - The string that separates each transformed when placed in the URL (e.g. a separator
 *                    of '+' would have a query string of `1+20+3+5'
 * @returns {string|null} - Returns the final string of converted values separated by the separator or null.
 */
export const valueArrayToParamString = function <T>(
  values: ?Array<T>,
  transformer: (value: T) => ?string,
  separator?: string = ARRAY_SEPARATOR
) {
  if (!values || values.length === 0) {
    return null;
  }
  const convertedValues = values.map(transformer).filter(Boolean);
  return convertedValues.join(separator);
};

/**
 * @param paramString - The string from the URL parameter to be converted to an array of values
 * @param transformer - Transformer function that transformers a single string portion of the URL params
 *                      to a value (e.g. transforms '3' to 3)
 * @param separator - The string that separates each param in the paramString. (e.g. a paramString of '1+2+3'
 *                    uses a seperator of '+')
 * @returns {Array<$NonMaybeType<?T>>|null} - Returns the final array of converted params or null.
 */
export const paramStringToValueArray = function <T>(
  paramString: ?string,
  transformer: (param: ?string) => ?T,
  separator?: string = ARRAY_SEPARATOR
) {
  if (!paramString) {
    return null;
  }
  const paramArray = paramString.split(separator);
  const convertedParams = paramArray.map<?T>(transformer).filter(Boolean);
  return convertedParams && convertedParams.length !== 0 ? convertedParams : null;
};

export type ParamTransformer<T> = {
  transformToParam: (value: ?T) => ?string,
  transformFromParam: (param: ?string) => ?T,
};

export const booleanParamTransformer: ParamTransformer<boolean> = {
  transformFromParam: param => {
    if (param === 'true') {
      return true;
    } else if (param === 'false') {
      return false;
    }
    return null;
  },
  transformToParam: value => {
    if (value === true) {
      return 'true';
    } else if (value === false) {
      return 'false';
    }
    return null;
  },
};

export const FULFILLMENT_PARAM = {
  DELIVERY: 'delivery',
  IN_STORE: 'pickup',
};

export const fulfilmentParamTransformer: ParamTransformer<FulfillmentType> = {
  transformFromParam: param => {
    if (param === FULFILLMENT_PARAM.IN_STORE) {
      return FULFILLMENT_TYPE.IN_STORE;
    } else if (param === FULFILLMENT_PARAM.DELIVERY) {
      return FULFILLMENT_TYPE.DELIVERY;
    }
    return null;
  },
  transformToParam: value => {
    if (value === FULFILLMENT_TYPE.IN_STORE) {
      return FULFILLMENT_PARAM.IN_STORE;
    } else if (value === FULFILLMENT_TYPE.DELIVERY) {
      return FULFILLMENT_PARAM.DELIVERY;
    }
    return null;
  },
};
