// @flow
import {closeNotification} from 'data/notifications/actions';
import {selectTopmostNotification} from 'data/notifications/selectors';
import withConnect from 'hoc/withConnect';
import React, {PureComponent} from 'react';
import type {HOC} from 'recompose';

import NotificationTile from './NotificationTile';

const TIMEOUT = 4000;

type State = {|
  currentClosing: boolean,
|};

class Notifications<P: *> extends PureComponent<P, State> {
  constructor(props: P) {
    super(props);
    this.state = {currentClosing: false};
    this.init(props);
  }

  timeout: ?TimeoutID = null;

  componentDidUpdate(prev) {
    if (this.props.notification !== prev.notification) {
      this.init(this.props);
    }
  }

  setClosing = () => {
    this.setState({currentClosing: true});
    this.timeout = setTimeout(() => {
      this.props.closeNotification();
      this.setState({currentClosing: false});
    }, 250);
  };

  init = props => {
    if (props.notification) {
      this.timeout = setTimeout(this.setClosing, TIMEOUT);
    }
  };

  handleClick = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.setClosing();
  };

  render() {
    const {notification} = this.props;
    if (!notification) {
      return null;
    }

    return (
      <NotificationTile
        key={notification.text}
        onClick={this.handleClick}
        closing={this.state.currentClosing}
        notification={notification}
      />
    );
  }
}

const mapStateToProps = state => ({
  notification: selectTopmostNotification(state),
});

const mapDispatchToProps = {
  closeNotification,
};

const enhancer: HOC<*, {||}> = withConnect(mapStateToProps, mapDispatchToProps);

export default enhancer(Notifications);
