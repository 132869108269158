// @flow
import getErrorMessage from 'common/graphql/getErrorMessage';
import type {GraphqlError} from 'common/graphql/types';
import {withFormik} from 'formik';
import type {Validator} from 'forms/validators/types';
import {keys, path, pick} from 'ramda';
import React from 'react';
import {type HOC} from 'recompose';
import * as yup from 'yup';

import {Form} from './styled';

type ExtractValidatorType = <T>(validator: Validator<T>) => T;

type Added<Schema> = {
  submitSucceeded: boolean,
  isSubmitting: boolean,
  error?: string,
  resetForm: Function,
  values: $ObjMap<Schema, ExtractValidatorType>,
};

type Config<Props, Res, Schema> = {
  schema: Schema,
  onSubmit: ({...$Exact<Props>, ...$Exact<Added<Schema>>}) => (
    $ObjMap<Schema, ExtractValidatorType>
  ) => Promise<Res>,
  onSuccess?: ({...$Exact<Props>, ...$Exact<Added<Schema>>}) => Res => mixed,
  onError?: ({...$Exact<Props>, ...$Exact<Added<Schema>>}) => (string, GraphqlError) => mixed,
};

function withForm<Outter, Res, Schema: {}>(
  c: Config<Outter, Res, Schema>
): HOC<{...$Exact<Outter>, ...$Exact<Added<Schema>>}, Outter> {
  const {schema, onSubmit, onSuccess, onError} = c;

  return ComposedComponent =>
    withFormik({
      mapPropsToValues: props => {
        if (props.formData) {
          return pick(keys(schema))(props.formData);
        }
        return {};
      },
      validationSchema: yup.object().shape(schema),
      handleSubmit: (values, other) => {
        other.setStatus({...other.status, error: null});

        return onSubmit(other.props)(values)
          .then(result => {
            other.setStatus({
              ...other.status,
              submitSucceeded: true,
            });
            other.setSubmitting(false);
            if (onSuccess) {
              onSuccess({...other, ...other.props, values})(result);
            }
          })
          .catch(error => {
            const message = getErrorMessage(error);
            other.setStatus({
              ...other.status,
              error: message,
            });
            other.setSubmitting(false);
            if (onError) {
              onError({...other, ...other.props})(message, error);
            }
          });
      },
    })(props => (
      <Form
        onSubmit={e => {
          props.setStatus({...props.status, submitted: true});
          return props.handleSubmit(e);
        }}
      >
        <ComposedComponent
          {...props}
          error={path(['status', 'error'], props)}
          submitSucceeded={path(['status', 'submitSucceeded'], props)}
        />
      </Form>
    ));
}

export default withForm;
