import {InputWrapWrap} from 'forms/withFormField/InputWrap/styled';
import styled from 'styled-components';

export const InlineFieldWrap = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
`;

export const FormFields = styled.div`
  //Converting margin to padding to deal with choppy animation with react-collapse
  ${InputWrapWrap} {
    margin: 0;
    padding-bottom: 3.2rem;
  }
`;
