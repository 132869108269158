// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import styled, {css} from 'styled-components';

export const DesktopFilterControlsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1.6rem;
  border-top: 0.3rem solid ${props => props.theme.color.light};
  background-color: #fff;
  position: sticky;
  bottom: 0;
`;

export const ControlButton = styled(Text)`
  cursor: pointer;

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    `}
`;
