// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import type {Image} from 'data/images/types';
import * as React from 'react';

import Iconic from './Iconic';
import Numeric from './Numeric';

type Props = {|
  images: Image[],
  activeIndex: number,
  onChangeIndex: number => void,
|};

const ImageSwitch = ({images, activeIndex, onChangeIndex}: Props) => (
  <React.Fragment>
    <Mobile>
      <Numeric activeIndex={activeIndex} images={images} />
    </Mobile>
    <Desktop>
      <Iconic activeIndex={activeIndex} images={images} onChangeIndex={onChangeIndex} />
    </Desktop>
  </React.Fragment>
);

export default ImageSwitch;
