// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import React from 'react';

import DesktopLoggedInNav from './DesktopLoggedInNav';
import MobileLoggedInNav from './MobileLoggedInNav';

const LoggedInNav = () => (
  <React.Fragment>
    <Mobile>
      <MobileLoggedInNav />
    </Mobile>
    <Desktop>
      <DesktopLoggedInNav />
    </Desktop>
  </React.Fragment>
);

export default LoggedInNav;
