// @flow
import Button from 'components/Button';
import {Container} from 'componentsStyled/Layout/Containers';
import {H1, Text} from 'componentsStyled/Typography';
import {logout} from 'data/user/actions';
import withConnect from 'hoc/withConnect';
import withOnMount from 'hoc/withOnMount';
import React from 'react';
import {type HOC, compose} from 'recompose';

const ErrorPage = () => (
  <Container center>
    <H1>This wave was too high!</H1>
    <Text center>
      Application crashed. This incident was reported and our developers will be working on a fix.
      <br />
      We&apos;re sorry for the inconvenience.
    </Text>
    <Button onClick={() => window.location.reload(true)}>Return to homepage</Button>
  </Container>
);

const enhancer: HOC<*, {}> = compose(
  withConnect(() => ({}), {logout}),
  withOnMount(props => {
    if (window.location.hostname !== 'localhost') {
      // HACK: Log the user out in case of corrupted state
      props.logout();
    }
  })
);

export default enhancer(ErrorPage);
