// @flow
import Page from 'pages/_Page';
import MainFilter from 'pages/_Page/MainFilter';
import React from 'react';

import SearchResults from './SearchResults';

const SearchPage = () => (
  <Page filter={MainFilter}>
    <SearchResults />
  </Page>
);

export default SearchPage;
