// @flow
import {mq} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import styled from 'styled-components';

export const StyledLogoLink = styled(StyledLink)`
  flex-shrink: 0;

  svg {
    fill: ${props => props.theme.color.primaryText};
    transition: fill 0.25s ease;
  }
`;

export const StyledLogo = styled.img`
  object-fit: contain;
  max-height: 64px;

  ${mq.tablet} {
    max-height: 128px;
  }
`;
