// @flow
import Button from 'components/Button';
import {H2, Text} from 'componentsStyled/Typography';
import {parseSearchFromLocation} from 'data/router/helpers';
import {resetPasswordMutation} from 'data/user/graphql/mutations';
import Alert from 'forms/Alert';
import TextInput from 'forms/Input/Text';
import {password} from 'forms/validators/string';
import withForm from 'forms/withForm';
import withMutation from 'hoc/withMutation';
import withRouter from 'hoc/withRouter';
import React from 'react';
import {type HOC, compose} from 'recompose';

import {InnerContainer} from '../styled';

const ResetPasswordForm = ({isSubmitting, error = ''}) => (
  <InnerContainer>
    <H2>Reset password</H2>
    <Text>Please enter your new password</Text>
    <Alert>{error}</Alert>
    <TextInput name="password" label="New Password" type="password" />
    <Button loading={isSubmitting}>Reset password</Button>
  </InnerContainer>
);

const schema = {
  password,
};

type Outter = {|
  setPasswordReseted: boolean => void,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withMutation(resetPasswordMutation),
  withForm({
    schema,
    onSubmit: props => values =>
      props.submitMutation({
        password: values.password,
        code: parseSearchFromLocation(props.location).code,
      }),
    onSuccess: props => () => props.setPasswordReseted(true),
  })
);

export default enhancer(ResetPasswordForm);
