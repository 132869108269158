// @flow
import type {Selector} from 'common/redux/types';
import {compose, head, prop} from 'ramda';

import type {ModalState} from './reducer';
import type {Modal, ModalType} from './types';

// selects the appropriate substore from root store
const base: Selector<ModalState> = prop('modals');

// composes upon the base selector to select specific properties
export const selectModal: Selector<?Modal<*>> = compose(head, base);

export const isModalOpen = (m: ModalType<*>): Selector<boolean> =>
  compose(modals => !!modals.find(x => x.type === m), base);
