// @flow
import {type Theme, lighten} from 'global/theme';
import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const StyledFormContent = styled.div`
  .StripeElement {
    height: 5rem;
    border: solid 0.1rem ${props => props.theme.color.secondaryText};
    border-radius: 0.4rem;
    background: ${props => props.theme.color.light};
    padding: 1.34rem 1.9rem;
    position: relative;

    &--focus {
      border-color: ${props => props.theme.color.primaryAccent};
    }
  }
`;

export const StyledLabel = styled.label`
  margin-bottom: 2.4rem;
  display: block;
`;

export function getStripeStyle(theme: Theme) {
  return {
    base: {
      color: theme.color.secondaryText,
      fontWeight: 400,
      fontFamily: 'sans-serif',
      fontSize: '1.15rem',
      '::placeholder': {color: lighten(theme.color.secondaryText, 0.5)},
    },
    invalid: {color: theme.color.red},
  };
}

export const TextWrap = styled.div`
  margin-bottom: 3rem;
`;
