// @flow
import {mq, mqMax} from 'common/mediaQuery';
import {default as Link} from 'components/StyledLink';
import styled from 'styled-components';

export const AffiliateLink = styled(Link)`
  display: flex;
  justify-content: center;

  ${mqMax.phone} {
    margin: 1rem;
  }
`;

export const AffiliateItemWrap = styled.div`
  width: 16.7rem;
  margin-bottom: 1.2rem;

  ${mq.tabletWide} {
    margin: 0 1.2rem 1.2rem 1.2rem;
  }

  ${mqMax.phoneWide} {
    width: 12rem;
  }
`;

export const ImageWrap = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1.4rem;
  padding-top: calc((112 / 167) * 100%);
  border-radius: 0.2rem;
  overflow: hidden;

  img {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.5rem;

    ${mqMax.phoneWide} {
      padding: 0;
    }
  }
`;

export const Banner = styled.div`
  background: ${props => props.theme.color.primaryText};
  color: ${props => props.theme.color.white};
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  height: 3rem;
  padding: 0.5rem;
  z-index: ${props => props.theme.zIndices.minimal + 1};
  margin: 1rem 0 1rem 1rem;
`;
