// @flow
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {FieldTitle} from 'componentsStyled/Typography/Titles';
import {accessoryListQuery} from 'data/accessories/queries';
import type {PricingInput} from 'data/product/types';
import type {OriginalReservation} from 'data/reservations/types';
import {getDaysApart} from 'data/units/date/helpers';
import withOnMount from 'hoc/withOnMount';
import withQuery from 'hoc/withQuery';
import * as React from 'react';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import AccessoriesSelect from './AccessoriesSelect';
import {mapPreselectedAccessories} from './helpers';

const Accessories = ({data, value, handleChange, currency, pricingInput, originalReservation}) => {
  if (!data || !data.length || originalReservation) {
    return null;
  }

  const reservationDays = getDaysApart(pricingInput.end, pricingInput.start) + 1;

  return (
    <Wrap>
      <FieldTitle>Select accessories</FieldTitle>
      <AccessoriesSelect
        options={data}
        reservationLength={reservationDays}
        value={value}
        onChange={handleChange}
        currency={currency}
      />
    </Wrap>
  );
};

type Outter = {|
  handleAccessoriesSelection: Function,
  pricingInput: PricingInput,
  currency: string,
  originalReservation: ?OriginalReservation,
|};

const enhancer: HOC<*, Outter> = compose(
  withQuery(accessoryListQuery, {
    variables: ({pricingInput}) => ({
      affiliateId: pricingInput.affiliateId,
      productVariantId: pricingInput.productVariantId,
    }),
    noEmpty: true,
  }),
  withStateHandlers(
    ({data}) => ({
      value: mapPreselectedAccessories(data),
    }),
    {
      setLocalValue: () => value => ({value}),
      clearLocalValue: () => () => ({value: undefined}),
    }
  ),
  withHandlers({
    handleChange: props => v => {
      // Visual feedback for the checkbox
      props.setLocalValue(v);

      // Update selection in reservation props
      props.handleAccessoriesSelection(v);
    },
  }),
  withOnMount(props => {
    props.value && props.handleAccessoriesSelection(props.value);
  })
);

export default enhancer(Accessories);
