// @flow
const prefix = 'REACT_APP_';

const keys = {
  ENVIRONMENT: 'ENVIRONMENT',
  API_URI: 'API_URI',
  GOOGLE_MAPS_API_KEY: 'GOOGLE_MAPS_API_KEY',
  STRIPE_API_PUBLIC_KEY: 'STRIPE_API_PUBLIC_KEY',
  SEGMENT_WRITE_KEY: 'SEGMENT_WRITE_KEY',
  SENTRY_PUBLIC_APP_DSN: 'SENTRY_PUBLIC_APP_DSN',
  GOOGLE_CLIENT_ID: 'GOOGLE_CLIENT_ID',
  FACEBOOK_APP_ID: 'FACEBOOK_APP_ID',
  APP_NAME: 'APP_NAME',
  TENANT_TITLE: 'TENANT_TITLE',
};

const getConfig = (key: string, defaultValue: string, hasPrefix = true): string => {
  if (window.APP_CONFIG && window.APP_CONFIG[`${prefix}${key}`]) {
    return window.APP_CONFIG[`${prefix}${key}`];
  } else if (process.env[`${prefix}${key}`] && hasPrefix) {
    return process.env[`${prefix}${key}`] || '';
  } else if (process.env[key] && !hasPrefix) {
    return process.env[key];
  } else {
    return defaultValue;
  }
};

const environment = getConfig(keys.ENVIRONMENT, 'development');
const apiUri = getConfig(keys.API_URI, window.location.origin);
const googleMapsApiKey = getConfig(keys.GOOGLE_MAPS_API_KEY, '');
const stripeApiPublicKey = getConfig(keys.STRIPE_API_PUBLIC_KEY, '');
const segmentWriteKey = getConfig(keys.SEGMENT_WRITE_KEY, '');
const sentryDsn = getConfig(keys.SENTRY_PUBLIC_APP_DSN, '');
const googleClientId = getConfig(keys.GOOGLE_CLIENT_ID, '');
const facebookAppId = getConfig(keys.FACEBOOK_APP_ID, '');
const tenantTitle = getConfig(keys.TENANT_TITLE, '');
const appName = getConfig(keys.APP_NAME, '');

const config = {
  environment,
  api: `${apiUri}/api/graphql`,
  mapsKey: googleMapsApiKey,
  stripeKey: stripeApiPublicKey,
  segmentKey: segmentWriteKey,
  sentryDsn,
  googleClientId,
  facebookAppId,
  tenantTitle,
  appName,
};

/**
 * Hacky convenience method to determine whether we are showing the Patagonia
 * tenant.
 * TODO(ray>someone): All instances of where this is used should have the
 * configuration moved to the backend.
 */
export function isPatagonia(tenantName: string) {
  return tenantName === 'patagonia' || tenantName === 'patagoniablue';
}

window.CONFIG = config;

export default config;
