// @flow
import {getUserQuery} from 'data/user/graphql/queries';
import {selectLoggedIn} from 'data/user/selectors';
import type {User} from 'data/user/types';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import {omit} from 'ramda';
import {type HOC, branch, compose, mapProps, renderNothing, withProps} from 'recompose';

const mapStateToProps = state => ({
  loggedIn: selectLoggedIn(state),
});

type Config = {|
  hideOnLoggedOut: boolean,
|};

type Added = {|
  user: User,
|};

function withUser<T>({hideOnLoggedOut = false}: Config = {}): HOC<
  // $ExpectError
  {...$Exact<Added>, ...$Exact<T>},
  T
> {
  return compose(
    withConnect(mapStateToProps),
    withQuery(getUserQuery, {
      noEmpty: true,
      noLoading: true,
      config: {
        skip: props => !props.loggedIn,
      },
    }),
    withProps(props => {
      const user: ?User = props.data;
      return {user};
    }),
    // remove the default prop data from withQuery, and only carry this information in the user prop
    mapProps(omit(['data'])),
    branch(props => hideOnLoggedOut && !props.user, renderNothing)
  );
}

export default withUser;
