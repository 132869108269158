// @flow
import {mqMax} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import {Text} from 'componentsStyled/Typography';
import {Note} from 'componentsStyled/Typography/Other';
import styled, {css} from 'styled-components';

export const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  margin: 0;

  ${mqMax.tablet} {
    font-size: 1.3rem;
  }
`;

export const smallText = css`
  font-size: 1.2rem;
`;

export const SmallNote = styled(Note)`
  ${smallText}
`;

export const SmallStyledLink = styled(StyledLink)`
  ${smallText}
`;
