// @flow
import urls from 'data/router/urls';
import {triggerRecentSearch} from 'data/search/actions';
import type {AutocompleteItem} from 'data/search/graphql/queries/autocomplete';
import {selectRecentSearches} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import withRouter from 'hoc/withRouter';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {GroupItem, GroupLabel, GroupWrap, ItemGroupsWrap} from '../styled';

const RecentSearches = ({recentSearches, handleClick}) => {
  if (recentSearches.length === 0) return null;

  return (
    <ItemGroupsWrap>
      <GroupWrap>
        <GroupLabel>Recent Searches</GroupLabel>
        {recentSearches.map((s, i) => (
          <GroupItem key={i} onClick={() => handleClick(s)}>
            {s.name}
          </GroupItem>
        ))}
      </GroupWrap>
    </ItemGroupsWrap>
  );
};

const mapStateToProps = state => ({
  recentSearches: selectRecentSearches(state),
});

const mapDispatchToProps = {
  triggerRecentSearch,
};

type Outter = {|
  close: Function,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleClick: props => (item: AutocompleteItem) => {
      props.triggerRecentSearch(item);

      if (item.type === 'affiliate') {
        props.history.push(urls.affiliate(item.id));
      }

      props.close();
    },
  })
);

export default enhancer(RecentSearches);
