// @flow
import React from 'react';

import {BlogWrap, Image, LocationLink, StyledSubTitle, StyledTitle, TextWrap} from './styled';

type Props = {
  title: string,
  subTitle: string,
  imageUrl: string,
  link: string,
};

const BlogPost = ({title, subTitle, imageUrl, link}: Props) => (
  <BlogWrap>
    <LocationLink to={link}>
      <Image src={imageUrl} />
      <TextWrap>
        <StyledTitle>{title}</StyledTitle>
        <StyledSubTitle>{subTitle}</StyledSubTitle>
      </TextWrap>
    </LocationLink>
  </BlogWrap>
);

export default BlogPost;
