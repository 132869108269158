// @flow
import {Or} from 'componentsStyled/Layout/Spacers';
import config from 'global/config';
import React from 'react';

import FacebookLogin from './FacebookLogin';
import {SocialLoginWrap} from './styled';

type Props = {|
  close: Function,
  signup?: boolean,
|};

const SocialLogin = ({close, signup}: Props) => {
  const hasAnyLogin = config.googleClientId || config.facebookAppId;
  return (
    <React.Fragment>
      <SocialLoginWrap>
        <FacebookLogin close={close} signup={signup} />
      </SocialLoginWrap>
      {hasAnyLogin && <Or>or</Or>}
    </React.Fragment>
  );
};

export default SocialLogin;
