// @flow
import {mq} from 'common/mediaQuery';
import {lighten} from 'global/theme';
import styled, {css, keyframes} from 'styled-components';

const modalFadeDuration = 250;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const StyledOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  animation: ${fadeIn} ${modalFadeDuration}ms ease;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  background-color: ${props => lighten(props.theme.color.primaryText, 0.6)};
  transition: opacity 0.5s;

  ${mq.tabletWide} {
    min-height: 65rem;
  }

  ${p =>
    p.open &&
    css`
      opacity: 1;
      pointer-events: initial;
    `}
`;

export const ModalBodyWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: ${props => props.theme.zIndices.modal};
`;

export const Body = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.color.white};

  ${mq.tabletWide} {
    max-width: ${p => (p.wide ? 70 : 43)}rem;
    min-height: 65rem;
  }

  ${props =>
    props.fullscreen &&
    css`
      width: 100vw;
      height: 100vh;
    `}
`;

export const Cross = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: ${props => props.theme.zIndices.minimal};
  top: 1rem;
  left: 1.2rem;

  svg {
    transition: 0.25s ease fill;
  }
`;
