// @flow
import styled, {css} from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline;
  width: 3rem;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${props => props.theme.zIndices.minimal};
  background: rgba(0, 0, 0, 0.1);

  ${p =>
    p.transparent &&
    css`
      background: transparent;
    `}
`;

export const WrapperContent = styled.div`
  ${p =>
    p.isOpen &&
    css`
      position: relative;
      z-index: ${props => props.theme.zIndices.minimal};
    `}
  display: inline;
  width: 3rem;
`;
