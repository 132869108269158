// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import styled, {css} from 'styled-components';

export const StyledMultiSelectItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
  height: 4.5rem;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.color.lightGray};
  }

  ${props =>
    props.disabled &&
    css`
      cursor: default;
    `}
`;

export const MultiSelectItemName = styled(Text)`
  min-width: 0;
  max-width: 30rem;
  text-overflow: ellipsis;
  overflow-x: hidden;

  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.color.neutralGrayDark};
    `}
`;
