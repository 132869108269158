// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import React from 'react';

type Props = {|
  productVariantName: string,
  compact?: boolean,
  center?: boolean,
|};

const ProductDimension = ({productVariantName, compact, center = false}: Props) => {
  if (compact) {
    return (
      <Text black center={center} small>
        {productVariantName}
      </Text>
    );
  }

  return (
    <Text black center={center} bigOnDesktop>
      {productVariantName}
    </Text>
  );
};

export default ProductDimension;
