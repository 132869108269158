// @flow
import urls from 'data/router/urls';
import SubPage from 'pages/_Page/SubPage';
import React from 'react';

import BasketCheckoutForm from './BasketCheckoutForm';

const BasketCheckout = () => {
  return (
    <SubPage title="Checkout Bag" backUrl={urls.shoppingBasket}>
      <BasketCheckoutForm />
    </SubPage>
  );
};

export default BasketCheckout;
