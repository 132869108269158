// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import type {Reservation} from 'data/reservations/types';
import * as React from 'react';

type Props = {
  reservation: Reservation,
};

const OwnItConfirm = ({reservation}: Props) => {
  return (
    <React.Fragment>
      <Text primary medium center>
        By clicking Confirm, {reservation.productItem.affiliate.name} will be notified that you want
        to own the
        {reservation.productItem.productVariant.product.name}. They will be in touch shortly to
        organise payment and pick-up / delivery.
      </Text>
      <Text center>
        Thank you for booking with Awayco. We hope you have many happy years ahead with your{' '}
        {reservation.productItem.productVariant.product.name}.
      </Text>
    </React.Fragment>
  );
};

export default OwnItConfirm;
