// @flow
import * as React from 'react';

import {ModalControlsWrap} from './styled';

type Props = {|
  children: React.Node,
  column?: boolean,
|};

const ModalControls = ({children, column}: Props) => (
  <ModalControlsWrap column={column}>{children}</ModalControlsWrap>
);

export default ModalControls;
